export const SUB_TYPE_HEADLINE = 'SUB_TYPE_HEADLINE'
export const SUB_TYPE_ADDRESS_COPY = 'SUB_TYPE_ADDRESS_COPY'
export const SUB_TYPE_HEADER = 'SUB_TYPE_HEADER'
export const SUB_TYPE_SUBHEADLINE = 'SUB_TYPE_SUBHEADLINE'
export const SUB_TYPE_TEXT_BLOCK = 'SUB_TYPE_TEXT_BLOCK'
export const SUB_ABOUT_US_TEXT = 'SUB_ABOUT_US_TEXT'
export const SUB_TYPE_BUTTON = 'SUB_TYPE_BUTTON'
export const SUB_TYPE_QR = 'SUB_TYPE_QR'
export const SUB_TYPE_WIDGET_IMAGE = 'SUB_TYPE_WIDGET_IMAGE'
export const SUB_TYPE_HEADER_BUTTON = 'SUB_TYPE_HEADER_BUTTON'
export const SUB_TYPE_PAGE_BUTTON = 'SUB_TYPE_PAGE_BUTTON'
export const SUB_TYPE_BUTTON_FRAMEWORK = 'SUB_TYPE_BUTTON_FRAMEWORK'
export const SUB_TYPE_ACCORDION = 'SUB_TYPE_ACCORDION'
export const SUB_TYPE_INPUT = 'SUB_TYPE_INPUT'
export const SUB_TYPE_TABS = 'SUB_TYPE_TABS'
export const SUB_TYPE_CHECKBOX = 'SUB_TYPE_CHECKBOX'
export const SUB_TYPE_RADIO = 'SUB_TYPE_RADIO'
export const SUB_TYPE_TOGGLE = 'SUB_TYPE_TOGGLE'
export const SUB_TYPE_TOOLTIP = 'SUB_TYPE_TOOLTIP'
export const SUB_TYPE_SELECT = 'SUB_TYPE_SELECT'
export const SUB_TYPE_TEXTAREA = 'SUB_TYPE_TEXTAREA'
export const SUB_TYPE_DATAPICKER = 'SUB_TYPE_DATAPICKER'
export const SUB_TYPE_IFRAME = 'SUB_TYPE_IFRAME'
export const SUB_TYPE_IMAGE = 'SUB_TYPE_IMAGE'
export const SUB_TYPE_ICON = 'SUB_TYPE_ICON'
export const SUB_TYPE_TABLE = 'SUB_TYPE_TABLE'
export const SUB_TYPE_MENU_LIST = 'SUB_TYPE_MENU_LIST'
export const SUB_TYPE_CONTENT_BLOCKS_ONE = 'SUB_TYPE_CONTENT_BLOCKS_ONE'
export const SUB_TYPE_PHOTOS_BLOCKS_ONE = 'SUB_TYPE_PHOTOS_BLOCKS_ONE'
export const SUB_TYPE_SHAPES_BLOCKS_ONE = 'SUB_TYPE_SHAPES_BLOCKS_ONE'
export const SUB_TYPE_DECAGON_BLOCKS_ONE = 'SUB_TYPE_DECAGON_BLOCKS_ONE'
export const SUB_TYPE_SQUARE_BLOCKS_ONE = 'SUB_TYPE_SQUARE_BLOCKS_ONE'
export const SUB_TYPE_OVAL = 'SUB_TYPE_OVAL'
export const SUB_TYPE_TRAPEZE_BLOCKS_ONE = 'SUB_TYPE_TRAPEZE_BLOCKS_ONE'
export const SUB_TYPE_TRIANGLE_BLOCKS_ONE = 'SUB_TYPE_TRIANGLE_BLOCKS_ONE'
export const SUB_TYPE_PENTAGON_BLOCKS_ONE = 'SUB_TYPE_PENTAGON_BLOCKS_ONE'
export const SUB_TYPE_ROUNDED_CORNER_SQUARE_BLOCKS_ONE = 'SUB_TYPE_ROUNDED_CORNER_SQUARE_BLOCKS_ONE'
export const SUB_TYPE_HEXAGON_BLOCKS_ONE = 'SUB_TYPE_HEXAGON_BLOCKS_ONE'
export const SUB_TYPE_OCTAGON_BLOCKS_ONE = 'SUB_TYPE_OCTAGON_BLOCKS_ONE'
export const SUB_TYPE_ISOSCELES_TRIANGLE_BLOCKS_ONE = 'SUB_TYPE_ISOSCELES_TRIANGLE_BLOCKS_ONE'
export const SUB_TYPE_BUMPY_CIRCLE_BLOCKS_ONE = 'SUB_TYPE_BUMPY_CIRCLE_BLOCKS_ONE'
export const SUB_TYPE_DIAMOND_BLOCKS_ONE = 'SUB_TYPE_DIAMOND_BLOCKS_ONE'
export const SUB_TYPE_GEM_STONE_BLOCKS_ONE = 'SUB_TYPE_GEM_STONE_BLOCKS_ONE'
export const SUB_TYPE_HEART_BLOCKS_ONE = 'SUB_TYPE_HEART_BLOCKS_ONE'
export const SUB_TYPE_HOLLOW_CIRCLE_BLOCKS_ONE = 'SUB_TYPE_HOLLOW_CIRCLE_BLOCKS_ONE'
export const SUB_TYPE_LOCATION_PIN_BLOCKS_ONE = 'SUB_TYPE_LOCATION_PIN_BLOCKS_ONE'
export const SUB_TYPE_OCTAGRAM_BLOCKS_ONE = 'SUB_TYPE_OCTAGRAM_BLOCKS_ONE'
export const SUB_TYPE_RAIN_DROP_BLOCKS_ONE = 'SUB_TYPE_RAIN_DROP_BLOCKS_ONE'
export const SUB_TYPE_ROUND_TALK_BUBBLE_BLOCKS_ONE = 'SUB_TYPE_ROUND_TALK_BUBBLE_BLOCKS_ONE'
export const SUB_TYPE_SEAL_STAMP_BLOCKS_ONE = 'SUB_TYPE_SEAL_STAMP_BLOCKS_ONE'
export const SUB_TYPE_SEMICIRLE_BLOCKS_ONE = 'SUB_TYPE_SEMICIRLE_BLOCKS_ONE'
export const SUB_TYPE_SHIELD1_BLOCKS_ONE = 'SUB_TYPE_SHIELD1_BLOCKS_ONE'
export const SUB_TYPE_SHIELD2_BLOCKS_ONE = 'SUB_TYPE_SHIELD2_BLOCKS_ONE'
export const SUB_TYPE_SHIELD3_BLOCKS_ONE = 'SUB_TYPE_SHIELD3_BLOCKS_ONE'
export const SUB_TYPE_SPEECH_BUBBLE_BLOCKS_ONE = 'SUB_TYPE_SPEECH_BUBBLE_BLOCKS_ONE'
export const SUB_TYPE_SQUARE_STAMP_BLOCKS_ONE = 'SUB_TYPE_SQUARE_STAMP_BLOCKS_ONE'
export const SUB_TYPE_STAR_BLOCKS_ONE = 'SUB_TYPE_STAR_BLOCKS_ONE'
export const SUB_TYPE_STAR_SHAPE_BLOCKS_ONE = 'SUB_TYPE_STAR_SHAPE_BLOCKS_ONE'
export const SUB_TYPE_TALK_BUBBLE_BLOCKS_ONE = 'SUB_TYPE_TALK_BUBBLE_BLOCKS_ONE'
export const SUB_TYPE_TRIANGLE_ARROW_BLOCKS_ONE = 'SUB_TYPE_TRIANGLE_ARROW_BLOCKS_ONE'
export const SUB_TYPE_BUDGE1_BLOCKS_ONE = 'SUB_TYPE_BUDGE1_BLOCKS_ONE'
export const SUB_TYPE_BUDGE2_BLOCKS_ONE = 'SUB_TYPE_BUDGE2_BLOCKS_ONE'
export const SUB_TYPE_BUDGE3_BLOCKS_ONE = 'SUB_TYPE_BUDGE3_BLOCKS_ONE'
export const SUB_TYPE_BUDGE4_BLOCKS_ONE = 'SUB_TYPE_BUDGE4_BLOCKS_ONE'
export const SUB_TYPE_BUDGE5_BLOCKS_ONE = 'SUB_TYPE_BUDGE5_BLOCKS_ONE'
export const SUB_TYPE_BUDGE6_BLOCKS_ONE = 'SUB_TYPE_BUDGE6_BLOCKS_ONE'
export const SUB_TYPE_BUDGE7_BLOCKS_ONE = 'SUB_TYPE_BUDGE7_BLOCKS_ONE'
export const SUB_TYPE_BUDGE8_BLOCKS_ONE = 'SUB_TYPE_BUDGE8_BLOCKS_ONE'
export const SUB_TYPE_BUDGE9_BLOCKS_ONE = 'SUB_TYPE_BUDGE9_BLOCKS_ONE'
export const SUB_TYPE_BUDGE10_BLOCKS_ONE = 'SUB_TYPE_BUDGE10_BLOCKS_ONE'
export const SUB_TYPE_BUDGE11_BLOCKS_ONE = 'SUB_TYPE_BUDGE11_BLOCKS_ONE'
export const SUB_TYPE_RIBBON1_BLOCKS_ONE = 'SUB_TYPE_RIBBON1_BLOCKS_ONE'
export const SUB_TYPE_RIBBON2_BLOCKS_ONE = 'SUB_TYPE_RIBBON2_BLOCKS_ONE'
export const SUB_TYPE_RIBBON3_BLOCKS_ONE = 'SUB_TYPE_RIBBON3_BLOCKS_ONE'
export const SUB_TYPE_RIBBON4_BLOCKS_ONE = 'SUB_TYPE_RIBBON4_BLOCKS_ONE'
export const SUB_TYPE_RIBBON5_BLOCKS_ONE = 'SUB_TYPE_RIBBON5_BLOCKS_ONE'
export const SUB_TYPE_RIBBON6_BLOCKS_ONE = 'SUB_TYPE_RIBBON6_BLOCKS_ONE'
export const SUB_TYPE_SMILE1_BLOCKS_ONE = 'SUB_TYPE_SMILE1_BLOCKS_ONE'
export const SUB_TYPE_SMILE2_BLOCKS_ONE = 'SUB_TYPE_SMILE2_BLOCKS_ONE'
export const SUB_TYPE_SMILE3_BLOCKS_ONE = 'SUB_TYPE_SMILE3_BLOCKS_ONE'
export const SUB_TYPE_SMILE4_BLOCKS_ONE = 'SUB_TYPE_SMILE4_BLOCKS_ONE'
export const SUB_TYPE_SMILE5_BLOCKS_ONE = 'SUB_TYPE_SMILE5_BLOCKS_ONE'
export const SUB_TYPE_SPECH1_BLOCKS_ONE = 'SUB_TYPE_SPECH1_BLOCKS_ONE'
export const SUB_TYPE_SPECH2_BLOCKS_ONE = 'SUB_TYPE_SPECH2_BLOCKS_ONE'
export const SUB_TYPE_SPECH3_BLOCKS_ONE = 'SUB_TYPE_SPECH3_BLOCKS_ONE'
export const SUB_TYPE_SPECH4_BLOCKS_ONE = 'SUB_TYPE_SPECH4_BLOCKS_ONE'
export const SUB_TYPE_SPECH5_BLOCKS_ONE = 'SUB_TYPE_SPECH5_BLOCKS_ONE'
export const SUB_TYPE_SPECH6_BLOCKS_ONE = 'SUB_TYPE_SPECH6_BLOCKS_ONE'
export const SUB_TYPE_SPECH7_BLOCKS_ONE = 'SUB_TYPE_SPECH7_BLOCKS_ONE'
export const SUB_TYPE_SPECH8_BLOCKS_ONE = 'SUB_TYPE_SPECH8_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS1_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS1_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS2_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS2_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS3_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS3_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS4_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS4_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS5_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS5_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS6_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS6_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS7_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS7_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS8_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS8_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS9_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS9_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS10_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS10_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS11_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS11_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS12_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS12_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS13_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS13_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS14_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS14_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS15_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS15_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS16_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS16_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS17_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS17_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS18_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS18_BLOCKS_ONE'
export const SUB_TYPE_WEB_ARROWS19_BLOCKS_ONE = 'SUB_TYPE_WEB_ARROWS19_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT1_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT1_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT2_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT2_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT3_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT3_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT4_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT4_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT5_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT5_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT6_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT6_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT7_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT7_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT8_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT8_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT9_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT9_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT10_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT10_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT11_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT11_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT12_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT12_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT13_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT13_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT14_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT14_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT15_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT15_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT16_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT16_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT17_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT17_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT18_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT18_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT19_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT19_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT20_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT20_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT21_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT21_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT22_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT22_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT23_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT23_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT24_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT24_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT25_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT25_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT26_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT26_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT27_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT27_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT28_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT28_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT29_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT29_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT30_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT30_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT31_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT31_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT32_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT32_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT33_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT33_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT34_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT34_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT35_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT35_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT36_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT36_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT37_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT37_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT38_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT38_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT39_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT39_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT40_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT40_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT41_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT41_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT42_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT42_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT43_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT43_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT44_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT44_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT45_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT45_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT46_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT46_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT47_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT47_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT48_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT48_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT49_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT49_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT50_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT50_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT51_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT51_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT52_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT52_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT53_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT53_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT54_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT54_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT55_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT55_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT56_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT56_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT57_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT57_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT58_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT58_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT59_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT59_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT60_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT60_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT61_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT61_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT62_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT62_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT63_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT63_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT64_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT64_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT65_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT65_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT66_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT66_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT67_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT67_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT68_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT68_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT69_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT69_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT70_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT70_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT71_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT71_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT72_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT72_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT73_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT73_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT74_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT74_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT75_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT75_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT76_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT76_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT77_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT77_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT78_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT78_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT79_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT79_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT80_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT80_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT81_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT81_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT82_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT82_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT83_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT83_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT84_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT84_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT85_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT85_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT86_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT86_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT87_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT87_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT88_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT88_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT89_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT89_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT90_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT90_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT91_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT91_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT92_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT92_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT93_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT93_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT94_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT94_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT95_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT95_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT96_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT96_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT97_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT97_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT98_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT98_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT99_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT99_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT100_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT100_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT101_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT101_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT102_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT102_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT103_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT103_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT104_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT104_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT105_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT105_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT106_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT106_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT107_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT107_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT108_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT108_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT109_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT109_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT110_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT110_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT111_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT111_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT112_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT112_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT113_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT113_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT114_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT114_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT115_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT115_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT116_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT116_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT117_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT117_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT118_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT118_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT119_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT119_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT120_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT120_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT121_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT121_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT122_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT122_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT123_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT123_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT124_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT124_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT125_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT125_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT126_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT126_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT127_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT127_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT128_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT128_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT129_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT129_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT130_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT130_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT131_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT131_BLOCKS_ONE'
export const SUB_TYPE_ORNAMENT132_BLOCKS_ONE = 'SUB_TYPE_ORNAMENT132_BLOCKS_ONE'
export const SUB_TYPE_SWASH1_BLOCKS_ONE = 'SUB_TYPE_SWASH1_BLOCKS_ONE'
export const SUB_TYPE_SWASH2_BLOCKS_ONE = 'SUB_TYPE_SWASH2_BLOCKS_ONE'
export const SUB_TYPE_SWASH3_BLOCKS_ONE = 'SUB_TYPE_SWASH3_BLOCKS_ONE'
export const SUB_TYPE_SWASH4_BLOCKS_ONE = 'SUB_TYPE_SWASH4_BLOCKS_ONE'
export const SUB_TYPE_SWASH5_BLOCKS_ONE = 'SUB_TYPE_SWASH5_BLOCKS_ONE'
export const SUB_TYPE_SWASH6_BLOCKS_ONE = 'SUB_TYPE_SWASH6_BLOCKS_ONE'
export const SUB_TYPE_SWASH7_BLOCKS_ONE = 'SUB_TYPE_SWASH7_BLOCKS_ONE'
export const SUB_TYPE_SWASH8_BLOCKS_ONE = 'SUB_TYPE_SWASH8_BLOCKS_ONE'
export const SUB_TYPE_SWASH9_BLOCKS_ONE = 'SUB_TYPE_SWASH9_BLOCKS_ONE'
export const SUB_TYPE_SWASH10_BLOCKS_ONE = 'SUB_TYPE_SWASH10_BLOCKS_ONE'
export const SUB_TYPE_LOGO1_BLOCKS_ONE = 'SUB_TYPE_LOGO1_BLOCKS_ONE'
export const SUB_TYPE_LOGO2_BLOCKS_ONE = 'SUB_TYPE_LOGO2_BLOCKS_ONE'
export const SUB_TYPE_LOGO3_BLOCKS_ONE = 'SUB_TYPE_LOGO3_BLOCKS_ONE'
export const SUB_TYPE_LOGO4_BLOCKS_ONE = 'SUB_TYPE_LOGO4_BLOCKS_ONE'
export const SUB_TYPE_LOGO5_BLOCKS_ONE = 'SUB_TYPE_LOGO5_BLOCKS_ONE'
export const SUB_TYPE_LOGO6_BLOCKS_ONE = 'SUB_TYPE_LOGO6_BLOCKS_ONE'
export const SUB_TYPE_BRIHTON1_BLOCKS_ONE = 'SUB_TYPE_BRIHTON1_BLOCKS_ONE'
export const SUB_TYPE_BRIHTON2_BLOCKS_ONE = 'SUB_TYPE_BRIHTON2_BLOCKS_ONE'
export const SUB_TYPE_BRIHTON3_BLOCKS_ONE = 'SUB_TYPE_BRIHTON3_BLOCKS_ONE'
export const SUB_TYPE_BRIHTON4_BLOCKS_ONE = 'SUB_TYPE_BRIHTON4_BLOCKS_ONE'
export const SUB_TYPE_BRIHTON5_BLOCKS_ONE = 'SUB_TYPE_BRIHTON5_BLOCKS_ONE'
export const SUB_TYPE_BRIHTON6_BLOCKS_ONE = 'SUB_TYPE_BRIHTON6_BLOCKS_ONE'
export const SUB_TYPE_BRIHTON7_BLOCKS_ONE = 'SUB_TYPE_BRIHTON7_BLOCKS_ONE'
export const SUB_TYPE_BRIHTON8_BLOCKS_ONE = 'SUB_TYPE_BRIHTON8_BLOCKS_ONE'
export const SUB_TYPE_BRIHTON9_BLOCKS_ONE = 'SUB_TYPE_BRIHTON9_BLOCKS_ONE'
export const SUB_TYPE_BRIHTON10_BLOCKS_ONE = 'SUB_TYPE_BRIHTON10_BLOCKS_ONE'
export const SUB_TYPE_BRIHTON11_BLOCKS_ONE = 'SUB_TYPE_BRIHTON11_BLOCKS_ONE'
export const SUB_TYPE_BRIHTON12_BLOCKS_ONE = 'SUB_TYPE_BRIHTON12_BLOCKS_ONE'
export const SUB_TYPE_BRIHTON13_BLOCKS_ONE = 'SUB_TYPE_BRIHTON13_BLOCKS_ONE'
export const SUB_TYPE_BRIHTON14_BLOCKS_ONE = 'SUB_TYPE_BRIHTON14_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL1_BLOCKS_ONE = 'SUB_TYPE_JOVIAL1_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL2_BLOCKS_ONE = 'SUB_TYPE_JOVIAL2_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL3_BLOCKS_ONE = 'SUB_TYPE_JOVIAL3_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL4_BLOCKS_ONE = 'SUB_TYPE_JOVIAL4_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL5_BLOCKS_ONE = 'SUB_TYPE_JOVIAL5_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL6_BLOCKS_ONE = 'SUB_TYPE_JOVIAL6_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL7_BLOCKS_ONE = 'SUB_TYPE_JOVIAL7_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL8_BLOCKS_ONE = 'SUB_TYPE_JOVIAL8_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL9_BLOCKS_ONE = 'SUB_TYPE_JOVIAL9_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL10_BLOCKS_ONE = 'SUB_TYPE_JOVIAL10_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL11_BLOCKS_ONE = 'SUB_TYPE_JOVIAL11_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL12_BLOCKS_ONE = 'SUB_TYPE_JOVIAL12_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL13_BLOCKS_ONE = 'SUB_TYPE_JOVIAL13_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL14_BLOCKS_ONE = 'SUB_TYPE_JOVIAL14_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL15_BLOCKS_ONE = 'SUB_TYPE_JOVIAL15_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL16_BLOCKS_ONE = 'SUB_TYPE_JOVIAL16_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL17_BLOCKS_ONE = 'SUB_TYPE_JOVIAL17_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL18_BLOCKS_ONE = 'SUB_TYPE_JOVIAL18_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL19_BLOCKS_ONE = 'SUB_TYPE_JOVIAL19_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL20_BLOCKS_ONE = 'SUB_TYPE_JOVIAL20_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL21_BLOCKS_ONE = 'SUB_TYPE_JOVIAL21_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL22_BLOCKS_ONE = 'SUB_TYPE_JOVIAL22_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL23_BLOCKS_ONE = 'SUB_TYPE_JOVIAL23_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL24_BLOCKS_ONE = 'SUB_TYPE_JOVIAL24_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL25_BLOCKS_ONE = 'SUB_TYPE_JOVIAL25_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL26_BLOCKS_ONE = 'SUB_TYPE_JOVIAL26_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL27_BLOCKS_ONE = 'SUB_TYPE_JOVIAL27_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL28_BLOCKS_ONE = 'SUB_TYPE_JOVIAL28_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL29_BLOCKS_ONE = 'SUB_TYPE_JOVIAL29_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL30_BLOCKS_ONE = 'SUB_TYPE_JOVIAL30_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL31_BLOCKS_ONE = 'SUB_TYPE_JOVIAL31_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL32_BLOCKS_ONE = 'SUB_TYPE_JOVIAL32_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL33_BLOCKS_ONE = 'SUB_TYPE_JOVIAL33_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL34_BLOCKS_ONE = 'SUB_TYPE_JOVIAL34_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL35_BLOCKS_ONE = 'SUB_TYPE_JOVIAL35_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL36_BLOCKS_ONE = 'SUB_TYPE_JOVIAL36_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL37_BLOCKS_ONE = 'SUB_TYPE_JOVIAL37_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL38_BLOCKS_ONE = 'SUB_TYPE_JOVIAL38_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL39_BLOCKS_ONE = 'SUB_TYPE_JOVIAL39_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL40_BLOCKS_ONE = 'SUB_TYPE_JOVIAL40_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL41_BLOCKS_ONE = 'SUB_TYPE_JOVIAL41_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL42_BLOCKS_ONE = 'SUB_TYPE_JOVIAL42_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL43_BLOCKS_ONE = 'SUB_TYPE_JOVIAL43_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL44_BLOCKS_ONE = 'SUB_TYPE_JOVIAL44_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL45_BLOCKS_ONE = 'SUB_TYPE_JOVIAL45_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL46_BLOCKS_ONE = 'SUB_TYPE_JOVIAL46_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL47_BLOCKS_ONE = 'SUB_TYPE_JOVIAL47_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL48_BLOCKS_ONE = 'SUB_TYPE_JOVIAL48_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL49_BLOCKS_ONE = 'SUB_TYPE_JOVIAL49_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL50_BLOCKS_ONE = 'SUB_TYPE_JOVIAL50_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL51_BLOCKS_ONE = 'SUB_TYPE_JOVIAL51_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL52_BLOCKS_ONE = 'SUB_TYPE_JOVIAL52_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL53_BLOCKS_ONE = 'SUB_TYPE_JOVIAL53_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL54_BLOCKS_ONE = 'SUB_TYPE_JOVIAL54_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL55_BLOCKS_ONE = 'SUB_TYPE_JOVIAL55_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL56_BLOCKS_ONE = 'SUB_TYPE_JOVIAL56_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL57_BLOCKS_ONE = 'SUB_TYPE_JOVIAL57_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL58_BLOCKS_ONE = 'SUB_TYPE_JOVIAL58_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL59_BLOCKS_ONE = 'SUB_TYPE_JOVIAL59_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL60_BLOCKS_ONE = 'SUB_TYPE_JOVIAL60_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL61_BLOCKS_ONE = 'SUB_TYPE_JOVIAL61_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL62_BLOCKS_ONE = 'SUB_TYPE_JOVIAL62_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL63_BLOCKS_ONE = 'SUB_TYPE_JOVIAL63_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL64_BLOCKS_ONE = 'SUB_TYPE_JOVIAL64_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL65_BLOCKS_ONE = 'SUB_TYPE_JOVIAL65_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL66_BLOCKS_ONE = 'SUB_TYPE_JOVIAL66_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL67_BLOCKS_ONE = 'SUB_TYPE_JOVIAL67_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL68_BLOCKS_ONE = 'SUB_TYPE_JOVIAL68_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL69_BLOCKS_ONE = 'SUB_TYPE_JOVIAL69_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL70_BLOCKS_ONE = 'SUB_TYPE_JOVIAL70_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL71_BLOCKS_ONE = 'SUB_TYPE_JOVIAL71_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL72_BLOCKS_ONE = 'SUB_TYPE_JOVIAL72_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL73_BLOCKS_ONE = 'SUB_TYPE_JOVIAL73_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL74_BLOCKS_ONE = 'SUB_TYPE_JOVIAL74_BLOCKS_ONE'
export const SUB_TYPE_JOVIAL75_BLOCKS_ONE = 'SUB_TYPE_JOVIAL75_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS1_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS1_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS2_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS2_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS3_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS3_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS4_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS4_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS5_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS5_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS6_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS6_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS7_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS7_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS8_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS8_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS9_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS9_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS10_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS10_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS11_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS11_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS12_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS12_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS13_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS13_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS14_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS14_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS15_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS15_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS16_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS16_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS17_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS17_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS18_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS18_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS19_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS19_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS20_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS20_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS21_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS21_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS22_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS22_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS23_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS23_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS24_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS24_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS25_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS25_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS26_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS26_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS27_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS27_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS28_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS28_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS29_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS29_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS30_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS30_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS31_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS31_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS32_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS32_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS33_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS33_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS34_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS34_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS35_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS35_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS36_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS36_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS37_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS37_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS38_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS38_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS39_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS39_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS40_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS40_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS41_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS41_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS42_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS42_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS43_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS43_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS44_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS44_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS45_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS45_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS46_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS46_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS47_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS47_BLOCKS_ONE'
export const SUB_TYPE_BRIXTON_SANS48_BLOCKS_ONE = 'SUB_TYPE_BRIXTON_SANS48_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES1_BLOCKS_ONE = 'SUB_TYPE_TEXTURES1_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES2_BLOCKS_ONE = 'SUB_TYPE_TEXTURES2_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES3_BLOCKS_ONE = 'SUB_TYPE_TEXTURES3_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES4_BLOCKS_ONE = 'SUB_TYPE_TEXTURES4_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES5_BLOCKS_ONE = 'SUB_TYPE_TEXTURES5_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES6_BLOCKS_ONE = 'SUB_TYPE_TEXTURES6_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES7_BLOCKS_ONE = 'SUB_TYPE_TEXTURES7_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES8_BLOCKS_ONE = 'SUB_TYPE_TEXTURES8_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES9_BLOCKS_ONE = 'SUB_TYPE_TEXTURES9_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES10_BLOCKS_ONE = 'SUB_TYPE_TEXTURES10_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES11_BLOCKS_ONE = 'SUB_TYPE_TEXTURES11_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES12_BLOCKS_ONE = 'SUB_TYPE_TEXTURES12_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES13_BLOCKS_ONE = 'SUB_TYPE_TEXTURES13_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES14_BLOCKS_ONE = 'SUB_TYPE_TEXTURES14_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES15_BLOCKS_ONE = 'SUB_TYPE_TEXTURES15_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES16_BLOCKS_ONE = 'SUB_TYPE_TEXTURES16_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES17_BLOCKS_ONE = 'SUB_TYPE_TEXTURES17_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES18_BLOCKS_ONE = 'SUB_TYPE_TEXTURES18_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES19_BLOCKS_ONE = 'SUB_TYPE_TEXTURES19_BLOCKS_ONE'
export const SUB_TYPE_TEXTURES20_BLOCKS_ONE = 'SUB_TYPE_TEXTURES20_BLOCKS_ONE'
export const SUB_TYPE_PHOTO_BLOCKS_ONE = 'SUB_TYPE_PHOTO_BLOCKS_ONE'
export const SUB_TYPE_VIDEO_BLOCKS_ONE = 'SUB_TYPE_VIDEO_BLOCKS_ONE'
export const SUB_TYPE_ICONS_BLOCKS_ONE = 'SUB_TYPE_ICONS_BLOCKS_ONE'
export const SUB_TYPE_QUOTE_WIDGET = 'SUB_TYPE_QUOTE_WIDGET'
export const SUB_TYPE_TYPOGRAPHY_BLOCKS_ONE = 'SUB_TYPE_TYPOGRAPHY_BLOCKS_ONE'
export const SUB_TYPE_MENU_LIST_1 = 'SUB_TYPE_MENU_LIST_1'
export const SUB_TYPE_MENU_LIST_2 = 'SUB_TYPE_MENU_LIST_2'
export const SUB_TYPE_MENU_LIST_3 = 'SUB_TYPE_MENU_LIST_3'
export const SUB_TYPE_MENU_LIST_4 = 'SUB_TYPE_MENU_LIST_4'
export const SUB_TYPE_MENU_LIST_5 = 'SUB_TYPE_MENU_LIST_5'
export const SUB_TYPE_MENU_LIST_6 = 'SUB_TYPE_MENU_LIST_6'
export const SUB_TYPE_MENU_LIST_7 = 'SUB_TYPE_MENU_LIST_7'
export const SUB_TYPE_MENU_LIST_8 = 'SUB_TYPE_MENU_LIST_8'
export const SUB_TYPE_MENU_LIST_9 = 'SUB_TYPE_MENU_LIST_9'
export const SUB_TYPE_MENU_LIST_10 = 'SUB_TYPE_MENU_LIST_10'
export const SUB_TYPE_MENU_LIST_11 = 'SUB_TYPE_MENU_LIST_11'
export const SUB_TYPE_MENU_LIST_12 = 'SUB_TYPE_MENU_LIST_12'
export const SUB_TYPE_MENU_LIST_13 = 'SUB_TYPE_MENU_LIST_13'
export const SUB_TYPE_MENU_LIST_14 = 'SUB_TYPE_MENU_LIST_14'
export const SUB_TYPE_MENU_LIST_15 = 'SUB_TYPE_MENU_LIST_15'
export const SUB_TYPE_MENU_LIST_16 = 'SUB_TYPE_MENU_LIST_16'
export const SUB_TYPE_MENU_PULL_OUT = 'SUB_TYPE_MENU_PULL_OUT'
export const SUB_TYPE_EVENTS_WIDGET = 'SUB_TYPE_EVENTS_WIDGET'
export const SUB_TYPE_DIVIDER = 'SUB_TYPE_DIVIDER'
export const SUB_TYPE_DIVIDER_VERTICAL = 'SUB_TYPE_DIVIDER_VERTICAL'
export const SUB_TYPE_COUNTER = 'SUB_TYPE_COUNTER'
export const SUB_TYPE_FORM = 'SUB_TYPE_FORM'
export const SUB_TYPE_SOCIAL = 'SUB_TYPE_SOCIAL'
export const SUB_TYPE_SOCIAL_PANEL = 'SUB_TYPE_SOCIAL_PANEL'
export const SUB_TYPE_NEW_DESIGN_SOCIAL_PANEL = 'SUB_TYPE_NEW_DESIGN_SOCIAL_PANEL'
export const SUB_TYPE_SLIDER = 'SUB_TYPE_SLIDER'
export const SUB_TYPE_VIDEO = 'SUB_TYPE_VIDEO'
export const SUB_TYPE_EVENTS_WIDGET_COMPACT = 'SUB_TYPE_EVENTS_WIDGET_COMPACT'
export const SUB_TYPE_BLOG_WIDGET = 'SUB_TYPE_BLOG_WIDGET'
export const SUB_TYPE_BLOG_COMPACT_WIDGET = 'SUB_TYPE_BLOG_COMPACT_WIDGET'
export const SUB_TYPE_NEWS_WIDGET = 'SUB_TYPE_NEWS_WIDGET'
export const SUB_TYPE_NEWS_COMPACT_WIDGET = 'SUB_TYPE_NEWS_COMPACT_WIDGET'
export const SUB_TYPE_DEVOTIONS_WIDGET = 'SUB_TYPE_DEVOTIONS_WIDGET'
export const SUB_TYPE_DEVOTIONS_COMPACT_WIDGET = 'SUB_TYPE_DEVOTIONS_COMPACT_WIDGET'
export const SUB_TYPE_READING_PLAN_WIDGET = 'SUB_TYPE_READING_PLAN_WIDGET'
export const SUB_TYPE_READING_PLAN_COMPACT_WIDGET = 'SUB_TYPE_READING_PLAN_COMPACT_WIDGET'
export const SUB_TYPE_PHOTO_WIDGET = 'SUB_TYPE_PHOTO_WIDGET'
export const SUB_TYPE_PHOTO_COMPACT_WIDGET = 'SUB_TYPE_PHOTO_COMPACT_WIDGET'
export const SUB_TYPE_CONTACT_US_WIDGET = 'SUB_TYPE_CONTACT_US_WIDGET'
export const SUB_TYPE_ABOUT_US_WIDGET = 'SUB_TYPE_ABOUT_US_WIDGET'
export const SUB_TYPE_MINISTRIES_WIDGET = 'SUB_TYPE_MINISTRIES_WIDGET'
export const SUB_TYPE_STAFF_WIDGET = 'SUB_TYPE_STAFF_WIDGET'
export const SUB_TYPE_VISITOR_WIDGET = 'SUB_TYPE_VISITOR_WIDGET'
export const SUB_TYPE_SUBSCRIBE_WIDGET = 'SUB_TYPE_SUBSCRIBE_WIDGET'
export const SUB_TYPE_WELCOME_WIDGET = 'SUB_TYPE_WELCOME_WIDGET'
export const SUB_TYPE_MOBILE_APP_WIDGET = 'SUB_TYPE_MOBILE_APP_WIDGET'
export const SUB_TYPE_TV_APP_WIDGET = 'SUB_TYPE_TV_APP_WIDGET'
export const SUB_TYPE_GIVE_WIDGET = 'SUB_TYPE_GIVE_WIDGET'
export const SUB_TYPE_STREAM_WIDGET = 'SUB_TYPE_STREAM_WIDGET'
export const SUB_TYPE_SERMONS_COMPACT_WIDGET = 'SUB_TYPE_SERMONS_COMPACT_WIDGET'
export const SUB_TYPE_LATEST_COMPACT_WIDGET = 'SUB_TYPE_LATEST_COMPACT_WIDGET'
export const SUB_TYPE_SERMONS_WIDGET = 'SUB_TYPE_SERMONS_WIDGET'
export const SUB_TYPE_LIVE_STREAMING_WIDGET = 'SUB_TYPE_LIVE_STREAMING_WIDGET'
export const SUB_TYPE_APP_PAGE_WIDGET = 'SUB_TYPE_APP_PAGE_WIDGET'
export const SUB_TYPE_WATCH_US_ON_TV_WIDGET = 'SUB_TYPE_WATCH_US_ON_TV_WIDGET'
export const SUB_TYPE_WATCH_US_ON_TV_TYPE2_WIDGET = 'SUB_TYPE_WATCH_US_ON_TV_TYPE2_WIDGET'
export const SUB_TYPE_PRIVACY_POLICY_WIDGET = 'SUB_TYPE_PRIVACY_POLICY_WIDGET'
export const SUB_TYPE_PODCASTS = 'SUB_TYPE_PODCASTS'
export const SUB_TYPE_DROPDOWN = 'SUB_TYPE_DROPDOWN'
export const SUB_TYPE_FOOTER = 'SUB_TYPE_FOOTER'
export const SUB_TYPE_FOOTER_SMALL = 'SUB_TYPE_FOOTER_SMALL'
export const SUB_TYPE_NEWS_COMPACT_HORIZONTAL_WIDGET = 'SUB_TYPE_NEWS_COMPACT_HORIZONTAL_WIDGET'
export const SUB_TYPE_BLOG_COMPACT_HORIZONTAL_WIDGET = 'SUB_TYPE_BLOG_COMPACT_HORIZONTAL_WIDGET'
export const SUB_TYPE_SERMONS_COMPACT_HORIZONTAL_WIDGET = 'SUB_TYPE_SERMONS_COMPACT_HORIZONTAL_WIDGET'
export const SUB_TYPE_MINISTRIES_COMPACT_HORIZONTAL_WIDGET = 'SUB_TYPE_MINISTRIES_COMPACT_HORIZONTAL_WIDGET'
export const SUB_TYPE_EVENTS_COMPACT_HORIZONTAL_WIDGET = 'SUB_TYPE_EVENTS_COMPACT_HORIZONTAL_WIDGET'
export const SUB_TYPE_EVENTS_COMPACT_HORIZONTAL_WIDGET1 = 'SUB_TYPE_EVENTS_COMPACT_HORIZONTAL_WIDGET1'
export const SUB_TYPE_EVENTS_COMPACT_HORIZONTAL_WIDGET2 = 'SUB_TYPE_EVENTS_COMPACT_HORIZONTAL_WIDGET2'
export const SUB_TYPE_EVENTS_CUSTOM_WIDGET = 'SUB_TYPE_EVENTS_CUSTOM_WIDGET'
export const SUB_TYPE_EVENTS_CUSTOM_WIDGET_BG = 'SUB_TYPE_EVENTS_CUSTOM_WIDGET_BG'
export const SUB_TYPE_EVENTS_CUSTOM_WIDGET_BANNER = 'SUB_TYPE_EVENTS_CUSTOM_WIDGET_BANNER'
export const SUB_TYPE_EVENTS_CUSTOM_WIDGET_MINI = 'SUB_TYPE_EVENTS_CUSTOM_WIDGET_MINI'
export const SUB_TYPE_EVENTS_CUSTOM_WIDGET_NO_BG = 'SUB_TYPE_EVENTS_CUSTOM_WIDGET_NO_BG'
export const SUB_TYPE_EVENTS_CUSTOM_WIDGET_CALENDAR = 'SUB_TYPE_EVENTS_CUSTOM_WIDGET_CALENDAR'
export const SUB_TYPE_EVENTS_CUSTOM_WIDGET_CALENDAR_IMG = 'SUB_TYPE_EVENTS_CUSTOM_WIDGET_CALENDAR_IMG'
export const SUB_TYPE_READING_PLAN_COMPACT_HORIZONTAL_WIDGET = 'SUB_TYPE_READING_PLAN_COMPACT_HORIZONTAL_WIDGET'
export const SUB_TYPE_TESTIMONIALS_COMPACT_HORIZONTAL_WIDGET = 'SUB_TYPE_TESTIMONIALS_COMPACT_HORIZONTAL_WIDGET'
export const SUB_TYPE_READING_SECTION = 'SUB_TYPE_READING_SECTION'
export const SUB_TYPE_DEVOTIONAL_SECTION = 'SUB_TYPE_DEVOTIONAL_SECTION'
export const SUB_TYPE_CHART_WIDGET = 'SUB_TYPE_CHART_WIDGET'
export const SUB_TYPE_QUICK_FACTS_WIDGET = 'SUB_TYPE_QUICK_FACTS_WIDGET'
export const SUB_TYPE_COUNTDOWN_WIDGET = 'SUB_TYPE_COUNTDOWN_WIDGET'
export const SUB_TYPE_ACCORDION_WIDGET = 'SUB_TYPE_ACCORDION_WIDGET'
export const SUB_TYPE_BLOCKQUOTE_WIDGET = 'SUB_TYPE_BLOCKQUOTE_WIDGET'
export const SUB_TYPE_HOW_IT_WORKS_WIDGET = 'SUB_TYPE_HOW_IT_WORKS_WIDGET'
export const SUB_TYPE_SLIDER_HORIZONTAL_WIDGET = 'SUB_TYPE_SLIDER_HORIZONTAL_WIDGET'
export const SUB_TYPE_SLIDER_HORIZONTAL_WIDGET_TABS = 'SUB_TYPE_SLIDER_HORIZONTAL_WIDGET_TABS'
export const SUB_TYPE_TABS_WIDGET = 'SUB_TYPE_TABS_WIDGET'
export const SUB_TYPE_COLUMNS_WIDGET = 'SUB_TYPE_COLUMNS_WIDGET'
export const SUB_TYPE_LISTS_COLUMNS_WIDGET = 'SUB_TYPE_LISTS_COLUMNS_WIDGET'
export const SUB_TYPE_LIST_EL = 'SUB_TYPE_LIST_EL'
export const SUB_TYPE_CHART_EL = 'SUB_TYPE_CHART_EL'
export const SUB_TYPE_FOOTER_ONE = 'SUB_TYPE_FOOTER_ONE'
export const SUB_TYPE_FOOTER_TWO = 'SUB_TYPE_FOOTER_TWO'
export const SUB_TYPE_FOOTER_THREE = 'SUB_TYPE_FOOTER_THREE'
export const SUB_TYPE_FOOTER_FOUR = 'SUB_TYPE_FOOTER_FOUR'
export const SUB_TYPE_FOOTER_FIVE = 'SUB_TYPE_FOOTER_FIVE'
export const SUB_TYPE_FOOTER_SIX = 'SUB_TYPE_FOOTER_SIX'
export const SUB_TYPE_FOOTER_SEVEN = 'SUB_TYPE_FOOTER_SEVEN'
export const SUB_TYPE_FOOTER_EIGHT = 'SUB_TYPE_FOOTER_EIGHT'
export const SUB_TYPE_MAP_TEMPLATE = 'SUB_TYPE_MAP_TEMPLATE'
export const SUB_TYPE_CONTENT_WIDGET_LIST = 'SUB_TYPE_CONTENT_WIDGET_LIST'
export const SUB_TYPE_LATEST_UPDATE_HORIZONTAL_WIDGET = 'SUB_TYPE_LATEST_UPDATE_HORIZONTAL_WIDGET'
export const SUB_TYPE_CONTENT_WIDGET_ITEM = 'SUB_TYPE_CONTENT_WIDGET_ITEM'
export const SUB_TYPE_GROUP = 'SUB_TYPE_GROUP'
export const SUB_TYPE_DIRECTIONS_ICON = 'SUB_TYPE_DIRECTIONS_ICON'
export const SUB_TYPE_CONTENT_BLOCK = 'SUB_TYPE_CONTENT_BLOCK'
export const SUB_TYPE_CONTENT_LIST_BLOCK = 'SUB_TYPE_CONTENT_LIST_BLOCK'
export const SUB_TYPE_CONTENT_BLOCK_CONTAINER = 'SUB_TYPE_CONTENT_BLOCK_CONTAINER'
export const SUB_TYPE_CONTENT_LIST_BLOCK_CONTAINER = 'SUB_TYPE_CONTENT_LIST_BLOCK_CONTAINER'
export const SUB_TYPE_PAYMENT_WIDGET = 'SUB_TYPE_PAYMENT_WIDGET'
export const SUB_TYPE_LIVE_STREAMING_WIDGET2 = 'SUB_TYPE_LIVE_STREAMING_WIDGET2'
export const SUB_TYPE_SERMONS_WIDGET2 = 'SUB_TYPE_SERMONS_WIDGET2'
export const SUB_TYPE_LIVE_NEW_WIDGET = 'SUB_TYPE_LIVE_NEW_WIDGET'
export const SUB_TYPE_DONATION_WIDGET = 'SUB_TYPE_DONATION_WIDGET'
export const SUB_TYPE_MISSION = 'SUB_TYPE_MISSION'
export const SUB_TYPE_MINISTRY = 'SUB_TYPE_MINISTRY'
export const SUB_TYPE_CLOCK_WIDGET = 'SUB_TYPE_CLOCK_WIDGET'
export const SUB_TYPE_MINISTRY2 = 'SUB_TYPE_MINISTRY2'
export const SUB_TYPE_VIDEO_FULL = 'SUB_TYPE_VIDEO_FULL'
export const SUB_TYPE_PRAYER_WALL_WIDGET = 'SUB_TYPE_PRAYER_WALL_WIDGET'
export const SUB_TYPE_CTA_WIDGET = 'SUB_TYPE_CTA_WIDGET'
export const SUB_TYPE_CUSTOM_LOGO = 'SUB_TYPE_CUSTOM_LOGO'
