var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":_vm.transition}},[_c('div',{staticClass:"w-modal",style:({'z-index': _vm.zIndex})},[_c('div',{staticClass:"w-modal__bg w-modal__bg_show",class:{
        'w-modal__bg_no-bg': _vm.noBackground,
        'w-modal__blur': _vm.blur,
      },on:{"click":function($event){$event.stopPropagation();return _vm.closeModal()}}}),_c('div',{staticClass:"w-modal__container",class:[
        _vm.containerClass,
        {'w-modal__container_dark': _vm.darkMode},
        {'w-modal__container_mobile': _vm.isMobile},
        {'w-modal__container_no-padding': _vm.noPadding},
        {'w-modal__container_full': _vm.full}
      ],style:({
        maxHeight: _vm.maxHeight
      })},[_c('div',{staticClass:"w-modal__close",class:_vm.closeIconClass,on:{"click":_vm.closeModal}},[_c('i',{class:_vm.iconClass})]),_c('vue-perfect-scrollbar',{staticClass:"w-modal-content"},[_vm._t("default")],2)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }