import * as types from './../../constants/store/mutations-types'
import _ from 'lodash'

const state = {
  eventsListAll: [],
  eventsCalendarData: [],
  shoppingCartEventsData: {},
  eventsMonthData: [],
  currentEventsCount: 1,
  pageViewType: 'list',
  currentDate: new Date(),
  eventsFilterQuery: '',
  eventsSearchQuery: '',
  infiniteLoadingRef: false,
  notFoundEvents: false,
  printStatus: false,
  eventsListSetTimeoutId: false,
  ifLoaderStateEvents: false,
  actualEventsWithTickets: [],
  eventsListTemp: []
}

const getters = {
  eventsListAll: state => state.eventsListAll,
  eventsCalendarData: state => state.eventsCalendarData,
  eventsMonthData: state => state.eventsMonthData,
  currentEventsCount: state => state.currentEventsCount,
  pageViewType: state => state.pageViewType,
  currentDate: state => state.currentDate,
  eventsFilterQuery: state => state.eventsFilterQuery,
  eventsSearchQuery: state => state.eventsSearchQuery,
  infiniteLoadingRef: state => state.infiniteLoadingRef,
  notFoundEvents: state => state.notFoundEvents,
  printStatus: state => state.printStatus,
  eventsListSetTimeoutId: state => state.eventsListSetTimeoutId,
  ifLoaderStateEvents: state => state.ifLoaderStateEvents,
  shoppingCartEventsData: state => state.shoppingCartEventsData,
  actualEventsWithTickets: state => state.actualEventsWithTickets,
  eventsListTemp: state => state.eventsListTemp
}
const actions = {
  setAllEventsData ({ commit }, data) {
    commit(types.SET_ALL_EVENTS_DATA, { data: data })
  },
  updateShoppingCartEventsData ({ commit }, data) {
    commit(types.UPDATE_SHOPPING_CART_EVENTS_DATA, data)
  },
  setEventsCalendarData ({ commit }, data) {
    commit(types.SET_EVENTS_CALENDAR_DATA, { data: data })
  },
  pushNewEventsData ({ commit }, data) {
    commit(types.PUSH_NEW_EVENTS_DATA, { data: data })
  },
  pushNewEventsCalendarData ({ commit }, data) {
    commit(types.PUSH_NEW_EVENTS_CALENDAR_DATA, { data: data })
  },
  setCurrentEventsCount ({ commit }, data) {
    commit(types.SET_CURRENT_EVENTS_COUNT, { data: data })
  },
  setPageViewType ({ commit }, data) {
    commit(types.SET_PAGE_VIEW_TYPE, data)
  },
  setCurrentDate ({ commit }, data) {
    commit(types.SET_CURRENT_DATE, data)
  },
  setEventsMonthData ({ commit }, data) {
    commit(types.SET_EVENTS_MONTH_DATA, data)
  },
  setEventsFilterQuery ({ commit }, data) {
    commit(types.SET_EVENTS_FILTER_QUERY, data)
  },
  setEventsSearchQuery ({ commit }, data) {
    commit(types.SET_EVENTS_SEARCH_QUERY, data)
  },
  setInfiniteLoadingRef ({ commit }, data) {
    commit(types.SET_INFINITE_LOADING_REF, data)
  },
  setNotFoundEvents ({ commit }, data) {
    commit(types.SET_NOT_FOUND_EVENTS, data)
  },
  setPrintStatus ({ commit }, data) {
    commit(types.SET_PRINT_STATUS, data)
  },
  setIfLoaderEvents ({ commit }, data) {
    commit(types.SET_IFLOADER_EVENTS, data)
  },
  setActualEventsWithTickets ({ commit }, data) {
    commit(types.SET_ACTUAL_EVENTS_WITH_TICKETS, data)
  }
}
const mutations = {
  [types.SET_ALL_EVENTS_DATA] (state, { data }) {
    if (data.length > 0) {
      state.eventsListAll = data
    } else {
      state.eventsListAll = []
    }
  },
  [types.UPDATE_SHOPPING_CART_EVENTS_DATA] (state, data) {
    state.shoppingCartEventsData = data
  },
  [types.SET_EVENTS_CALENDAR_DATA] (state, { data }) {
    state.eventsCalendarData = data
  },
  [types.PUSH_NEW_EVENTS_DATA] (state, { data }) {
    // clearTimeout(state.eventsListSetTimeoutId)
    state.eventsListTemp = []
    state.eventsListTemp.push(...data.items)
    let timeOut = 0
    let count = 0
    for (let i = 0; i < state.eventsListTemp.length; i++) {
      if (_.find(state.eventsListAll, function (obj) {
        return obj.id === state.eventsListTemp[i].id
      }) === undefined) {
        if (state.eventsListTemp[i].description === undefined) {
          state.eventsListTemp[i].description = ''
        }
        state.eventsListSetTimeoutId = setTimeout(function () {
          if (_.find(state.eventsListAll, function (obj) {
            return obj.id === state.eventsListTemp[i].id
          }) === undefined) {
            count++
            state.eventsListAll.push(state.eventsListTemp[i])
            if (count === state.eventsListTemp.length) {
              state.eventsListTemp = []
            }
          }
        }, timeOut * 100)
        timeOut++
      } else {
        count++
      }
    }
  },
  [types.PUSH_NEW_EVENTS_CALENDAR_DATA] (state, { data }) {
    for (let i = 0; i < data.length; i++) {
      if (typeof data[i].id === 'number') {
        data[i].id = data[i].id + '_' + data[i].startDate
      }
      if (_.find(state.eventsCalendarData, function (obj) {
        return obj.id === data[i].id
      }) === undefined) {
        state.eventsCalendarData.push(data[i])
      } else {
        if (_.find(state.eventsCalendarData, function (obj) {
          return obj.id === data[i].id
        }) === undefined) {
          state.eventsCalendarData.push(data[i])
        }
      }
    }
  },
  [types.SET_CURRENT_EVENTS_COUNT] (state, { data }) {
    state.currentEventsCount = data
  },
  [types.SET_PAGE_VIEW_TYPE] (state, data) {
    state.pageViewType = data
  },
  [types.SET_CURRENT_DATE] (state, data) {
    state.currentDate = data
  },
  [types.SET_EVENTS_MONTH_DATA] (state, data) {
    for (let i = 0; i < data.length; i++) {
      if (_.find(state.eventsMonthData, function (obj) {
        return obj === data[i]
      }) === undefined) {
        state.eventsMonthData.push(data[i])
      }
    }
  },
  [types.SET_EVENTS_FILTER_QUERY] (state, data) {
    state.eventsFilterQuery = data
  },
  [types.SET_EVENTS_SEARCH_QUERY] (state, data) {
    state.eventsSearchQuery = data
  },
  [types.SET_INFINITE_LOADING_REF] (state, data) {
    state.infiniteLoadingRef = data
  },
  [types.SET_NOT_FOUND_EVENTS] (state, data) {
    state.notFoundEvents = data
  },
  [types.SET_PRINT_STATUS] (state, data) {
    state.printStatus = data
  },
  [types.SET_IFLOADER_EVENTS] (state, data) {
    state.ifLoaderStateEvents = data
  },
  [types.SET_ACTUAL_EVENTS_WITH_TICKETS] (state, data) {
    state.actualEventsWithTickets = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
