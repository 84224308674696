import Vue from 'vue'
import Vuex from 'vuex'
import site from './modules/site'
import modalsRender from './modules/modals'
import modalMediaContent from '@@@/store/modules/modalMedia'
import modalVideoContent from '@@@/store/modules/modalVideo'
import forms from '@@@/store/modules/forms'
import formsList from '@@@/store/modules/formsList'
import formModule from '@@@/components/forms/store/index'
import userData from '@@@/store/modules/userData'
import templatesElements from '@@@/store/modules/templates'
import eventsStore from '@@@/components/widgets/events/src/store/index'
import blogStore from '@@@/components/widgets/blog/src/store/index'
import newsStore from '@@@/components/widgets/news/src/store/index'
import devotionsStore from '@@@/components/widgets/devotions/src/store/index'
import readingPlanStore from '@@@/components/widgets/readingPlan/src/store/index'
import photoStore from '@@@/components/widgets/photo/src/store/index'
import contactUsStore from '@@@/components/widgets/contactUs/src/store/index'
import ministriesStore from '@@@/components/widgets/ministries/src/store/index'
import aboutUsStore from '@@@/components/widgets/aboutUs/src/store/index'
import staffStore from '@@@/components/widgets/staff/src/store/index'
import sermonsWidget from '@@@/components/widgets/sermonsWidget/store/indexMain'
import modeView from '@@@/store/modules/modeView'
import parallaxScroll from '@@@/store/modules/parallaxScroll'
import formMod from '@@@/store/modules/formMod'
import authUserData from '@@@/store/modules/authUserData'
import privacyPolicy from '@@@/store/modules/privacyPolicy'
// import modulesSermons from '@@@/components/widgets/sermonsWidget/store/indexExport'
import currentTimeZone from '@@@/components/widgets/public/store/modules/currentTimeZone'
import ticketWidget from '@@@/components/widgets/tickets-widget/src/store/index'
import standardListWidgetTemplate from '@@@/components/widgets/standardListWidgetTemplate/src/store/index'
import publicWidgetStore from '@@@/components/widgets/public/store/index'
import standardHorizontalWidgetTemplateStore from '@@@/components/widgets/standardHorizontalWidgetTemplate/src/store/index'
import prayerWallWidget from '@@@/components/widgets/prayerWallWidgeet/store/index'

Vue.use(Vuex)

const modules = {
  site,
  modalsRender,
  modalMediaContent,
  modalVideoContent,
  modeView,
  templatesElements,
  parallaxScroll,
  userData,
  forms,
  formModule,
  formsList,
  formMod,
  authUserData,
  privacyPolicy,
  currentTimeZone,
  publicWidgetStore
}
Object.assign(modules, eventsStore)
Object.assign(modules, blogStore)
Object.assign(modules, newsStore)
Object.assign(modules, devotionsStore)
Object.assign(modules, readingPlanStore)
Object.assign(modules, photoStore)
Object.assign(modules, contactUsStore)
Object.assign(modules, ministriesStore)
Object.assign(modules, aboutUsStore)
Object.assign(modules, staffStore)
// Object.assign(modules, modulesSermons)
Object.assign(modules, ticketWidget)
Object.assign(modules, currentTimeZone)
Object.assign(modules, publicWidgetStore)
Object.assign(modules, standardHorizontalWidgetTemplateStore)
Object.assign(modules, standardListWidgetTemplate)
Object.assign(modules, sermonsWidget)
Object.assign(modules, prayerWallWidget)
console.log('modules', modules)
export default new Vuex.Store({
  modules
})
