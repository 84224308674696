<template>
  <div :id="divName" />
</template>

<script>
import Player from '@vimeo/player'

export default {
  name: 'VimeoTemplate',
  props: ['id'],
  data () {
    return {
      divName: 'divFrame'
    }
  },
  created () {
    // this.divName = this.divName + '_' + this.id
    this.divName = `${this.divName}_${this.id}`
  },
  mounted () {
    const options = {
      id: this.id,
      responsive: true
    }
    // eslint-disable-next-line no-new
    new Player(this.divName, options)
  }
}
</script>
