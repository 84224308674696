import * as types from '../../mutations-types'

const state = {
  startPageVideo: '',
  startPageRight: '',
  startPageBottom: '',
  viewPageVideo: '',
  viewPageRight: '',
  viewPageBottom: '',
  viewRelatedSermons: '',
  transitionTimeVideoPage: 2000,
  transitionTimeout: 2000
}

const getters = {
  startPageVideo: state => state.startPageVideo,
  startPageRight: state => state.startPageRight,
  startPageBottom: state => state.startPageBottom,
  viewPageVideo: state => state.viewPageVideo,
  viewPageRight: state => state.viewPageRight,
  viewPageBottom: state => state.viewPageBottom,
  viewRelatedSermons: state => state.viewRelatedSermons,
  transitionTimeVideoPage: state => state.transitionTimeVideoPage,
  transitionTimeout: state => state.transitionTimeout
}
const actions = {
  startPageGoOut ({ commit }) {
    commit(types.START_PAGE_GO_OUT)
  },
  viewPageGoOut ({ commit }) {
    commit(types.VIEW_PAGE_GO_OUT)
  },
  startPageIn ({ commit }) {
    commit(types.START_PAGE_IN)
  },
  viewPageIn ({ commit }) {
    commit(types.VIEW_PAGE_IN)
  },
  startPageEmpty ({ commit }) {
    commit(types.START_PAGE_EMPTY)
  },
  viewPageEmpty ({ commit }) {
    commit(types.VIEW_PAGE_EMPTY)
  },
  viewPageRelatedSermons ({ commit }) {
    commit(types.VIEW_PAGE_RELATED_SERMONS)
  }
}
const mutations = {
  [types.START_PAGE_GO_OUT] (state) {
    state.viewPageVideo = 'route-step-3'
    state.viewPageRight = 'route-step-2'
    state.viewPageBottom = 'route'
    state.viewRelatedSermons = 'route'
  },
  [types.VIEW_PAGE_GO_OUT] (state) {
    state.startPageVideo = 'route-step-3'
    state.startPageRight = 'route-step-2'
    state.startPageBottom = 'route'
  },
  [types.START_PAGE_IN] (state) {
    state.startPageVideo = 'route-step-3-active'
    state.startPageRight = 'route-step-2-active'
    state.startPageBottom = 'route-active'
  },
  [types.VIEW_PAGE_IN] (state) {
    state.viewPageVideo = 'route-step-3-active'
    state.viewPageRight = 'route-step-2-active'
    state.viewPageBottom = 'route-active'
    state.viewRelatedSermons = 'route-active'
  },
  [types.START_PAGE_EMPTY] (state) {
    state.startPageVideo = ''
    state.startPageRight = ''
    state.startPageBottom = ''
  },
  [types.VIEW_PAGE_EMPTY] (state) {
    state.viewPageVideo = ''
    state.viewPageRight = ''
    state.viewPageBottom = ''
  },
  [types.VIEW_PAGE_RELATED_SERMONS] (state) {
    state.viewRelatedSermons = ''
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
