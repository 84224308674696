import * as domens from '../constants/domains'
import * as domains from 'API/organization/domains'
const axios = require('axios')
const instWithCred = axios.create({
  baseURL: domens.CURRENT_DOMAIN,
  withCredentials: true
})

export default {
  getSermons: function ({ offset, sort }, callback) {
    instWithCred.post('iphone.php/sermons/sermons?count=12&offset=' + offset + sort)
      .then(function (response) {
        callback(response)
      })
  },
  getOneSermons: function (id, callback) {
    instWithCred.post('iphone.php/sermons/sermon/' + id)
      .then(function (response) {
        callback(response)
      })
  },
  getGeneralSettings: function (callback) {
    const instWithCred = axios.create({
      baseURL: domains.APPLICATION_CONFIGURATION_DOMAIN
    })
    instWithCred.get('configuration')
      .then(function (response) {
        callback(response)
      })
  },
  getMostVievedSermons: function (callback) {
    instWithCred.post('iphone.php/sermons/sermons?count=8&offset=0&sort_by=popularity&sort_dir=asc')
      .then(function (response) {
        callback(response)
      })
  },
  personalSettings: function (callback) {
    instWithCred.get('/iphone.php/sermons/getSettings')
      .then(function (response) {
        callback(response)
      })
  },
  updatePersonalSettings: function (data, callback) {
    const form = {}
    for (const i in data) {
      const title = data[i].name
      const value = data[i].value
      form[title] = value
    }
    instWithCred.post('/iphone.php/sermons/saveSettings', form)
      .then(function (response) {
        callback(response)
      })
  },
  getTranscript: function (data, callback) {
    const lang = (data.lang !== '') ? '&lang=' + data.lang : ''
    instWithCred.get('iphone.php/sermons/transcriptText?audio_id=' + data.audioId + lang)
      .then(function (response) {
        callback(response)
      })
  }
}
