export const SET_ALL_PHOTO_DATA = 'SET_ALL_PHOTO_DATA'
export const PUSH_NEW_PHOTO_DATA = 'PUSH_NEW_PHOTO_DATA'
export const SET_CURRENT_PHOTO_COUNT = 'SET_CURRENT_PHOTO_COUNT'
export const SET_PHOTO_SORT_QUERY = 'SET_PHOTO_SORT_QUERY'
export const SET_PHOTO_SEARCH_QUERY = 'SET_PHOTO_SEARCH_QUERY'
export const SET_INFINITE_LOADING_REF_PHOTO = 'SET_INFINITE_LOADING_REF_PHOTO'
export const SET_NOT_FOUND_PHOTO = 'SET_NOT_FOUND_PHOTO'
export const SET_NOT_FOUND_PHOTO_COMPACT = 'SET_NOT_FOUND_PHOTO_COMPACT'
export const SET_NO_DATA_PHOTO_POST = 'SET_NO_DATA_PHOTO_POST'
export const PUSH_PHOTO_ALBUM_DATA = 'PUSH_PHOTO_ALBUM_DATA'
export const SET_PHOTO_ALBUM_ACTIVE = 'SET_PHOTO_ALBUM_ACTIVE'
export const PRESET_PHOTO_IMAGE_UPDATE = 'PRESET_PHOTO_IMAGE_UPDATE'
export const SET_CURRENT_PHOTO_ALBUMS_PAGE = 'SET_CURRENT_PHOTO_ALBUMS_PAGE'
export const SET_ALBUM_PHOTO_ALL_DATA = 'SET_ALBUM_PHOTO_ALL_DATA'
export const SET_PHOTO_IN_ALBUM_SORT_QUERY = 'SET_PHOTO_IN_ALBUM_SORT_QUERY'
export const SET_PHOTO_IN_ALBUM_SEARCH_QUERY = 'SET_PHOTO_IN_ALBUM_SEARCH_QUERY'
export const CLEAR_PHOTO_ALBUM_DATA = 'CLEAR_PHOTO_ALBUM_DATA'
export const SET_INFINITE_LOADING_REF_PHOTO_IN_ALBUM = 'SET_INFINITE_LOADING_REF_PHOTO_IN_ALBUM'
export const SET_NOT_FOUND_PHOTO_IN_ALBUM = 'SET_NOT_FOUND_PHOTO_IN_ALBUM'
export const SET_PRESET_ALBUM_PHOTO_IMG = 'SET_PRESET_ALBUM_PHOTO_IMG'
export const PUSH_NEW_PHOTO_COMPACT_DATA = 'PUSH_NEW_PHOTO_COMPACT_DATA'
// export const SET_PRINT_STATUS = 'SET_PRINT_STATUS'
