import * as TabsItems from '../../constants/tabs/tabsList'
import * as VideoItems from '../../constants/video/videoItemList'
import * as types from '../mutations-types'

const state = {
  TabsItemsRight: TabsItems.TABS_RIGHT,
  TabActiveContent: 1,
  FilterVideo: filter('videoViewed')
}

function filter (tag) {
  const current = []
  for (let g = 0; g < VideoItems.VIDEO_ITEM_LIST.length; g++) {
    if (VideoItems.VIDEO_ITEM_LIST[g][tag] === true) {
      current.push(VideoItems.VIDEO_ITEM_LIST[g])
    }
  }
  return current
}

const getters = {
  TabsItemsRight: state => state.TabsItemsRight,
  FilterVideo: state => state.FilterVideo
}
const actions = {
  toggleTab ({ commit }, tab) {
    commit(types.TOGGLE_TAB, { tab: tab })
  }
}
const mutations = {
  [types.TOGGLE_TAB] (state, { tab }) {
    for (let i = 0; i < state.TabsItemsRight.length; i++) {
      if (state.TabsItemsRight[i] === tab) {
        state.TabsItemsRight[i].active = true
        state.TabActiveContent = state.TabsItemsRight[i].key
      } else {
        state.TabsItemsRight[i].active = false
      }
    }
    if (state.TabActiveContent === 1) {
      state.FilterVideo = filter('videoViewed')
    }
    if (state.TabActiveContent === 2) {
      state.FilterVideo = filter('videoSuggested')
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
