import { FORM } from '../constants/defaults/form'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  state: {
    form: FORM,
    formId: null,
    isNavOpen: false,
    activeFieldIndex: null,
    activeTab: 'SidebarsFormSettings',
    sidebarMessage: '',
    isSavingError: false,
    isSaving: false,
    versions: [],
    customFields: [],
    ifLoaderForm: false
  },
  mutations,
  getters,
  actions
}
