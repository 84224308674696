import * as types from './../../../../public/constants/store/mutations-types'
import * as TabsEventList from '../../../../public/constants/tabs/tabsListWidgets'
import _ from 'lodash'

const state = {
  currentTabEventContent: TabsEventList.TABS_CONTENT_EVENT.TABS_ABOUT,
  currentTabEventId: false,
  eventTabsList: TabsEventList.TABS_EVENT_LIST
}

const getters = {
  currentTabEventContent: state => state.currentTabEventContent,
  eventTabsList: state => state.eventTabsList,
  currentTabEventId: state => state.currentTabEventId
}
const actions = {
  setActiveTabEvent ({ commit }, { route, activeEventId }) {
    commit(types.SET_ACTIVE_TAB_EVENT, { route: route, activeEventId: activeEventId })
  }
}
const mutations = {
  [types.SET_ACTIVE_TAB_EVENT] (state, { route, activeEventId }) {
    const activeTabs = _.find(state.eventTabsList, function (obj) {
      return obj.isActive === true
    })
    if (activeTabs !== undefined) {
      activeTabs.isActive = false
    }
    let currentTabs = _.find(state.eventTabsList, function (obj) {
      return obj.tabsName === route
    })
    if (currentTabs === undefined) {
      currentTabs = _.find(state.eventTabsList, function (obj) {
        return obj.tabsName === TabsEventList.TABS_NAME_EVENT.TABS_ABOUT
      })
    }
    currentTabs.isActive = true
    state.currentTabEventContent = currentTabs.content
    const currentEvent = _.find(this.getters.eventDataArray, function (obj) {
      return obj.id === activeEventId
    })
    currentEvent.activeTab = route
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
