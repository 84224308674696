// export const API_URL_FORMS = 'https://lifestylechristianity.impactfactors.net/api/forms/1.0'
// export const SUBMIT_URL_FORMS = 'https://lifestylechristianity.impactfactors.net/api/forms/1.0/{id}/submit'
// window.API_PATH = 'https://nbcchicago.impactfactors.net/'
// window.API_PATH = 'https://lifestylechristianity.impactfactors.net/'
export const CURRENT_SERVER = window.API_PATH

export const API_URL_FORMS = CURRENT_SERVER + 'api/forms/1.0'
export const SUBMIT_URL_FORMS = CURRENT_SERVER + 'api/forms/1.0/{id}/submit'
// export const API_URL_FORMS = 'http://dfsf3-dev.impactfactors.net/app_dev.php/api/forms/1.0'
// export const SUBMIT_URL_FORMS = 'http://dfsf3-dev.impactfactors.net/app_dev.php/api/forms/1.0/{id}/submit'
