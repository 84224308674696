import * as types from './../../constants/store/mutations-types'

const state = {
  organizationContacts: []
}

const getters = {
  organizationContacts: state => state.organizationContacts
}
const actions = {
  setOrganizationContacts ({ commit }, data) {
    commit(types.SET_ORGANIZATION_CONTACTS, { data: data })
  }
}
const mutations = {
  [types.SET_ORGANIZATION_CONTACTS] (state, { data }) {
    state.organizationContacts = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
