// import generalSettings from './../../../public/store/modules/generalSettings'
// import ifLoader from './../../../public/store/modules/ifLoader'
// import currentTimeZone from './../../../public/store/modules/currentTimeZone'
import blogList from './modules/blogList'
import postList from './modules/postList'

const modules = {
  // generalSettings,
  // currentTimeZone,
  // ifLoader,
  blogList,
  postList
}

export default modules
