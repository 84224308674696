import { NUMBER } from '../../../types/fieldsTypes'

export default {
  title: 'Untitled',
  name: 'Untitled',
  type: NUMBER,
  group: 'regular',
  properties: {
    size: {
      description: 'Display size',
      values: [
        {
          id: 'small',
          title: 'Small'
        },
        {
          id: 'medium',
          title: 'Medium'
        },
        {
          id: 'large',
          title: 'Large'
        }
      ],
      selected: {
        id: 'small'
      }
    },
    options: {
      values: [
        {
          id: 'optional',
          title: 'Optional Field'
        },
        {
          id: 'required',
          title: 'Required Field'
        },
        {
          id: 'no_duplicates',
          title: 'No Duplicates',
          description: 'No duplicates are available'
        }
      ],
      selected: {
        id: ['optional']
      }
    },
    visibility: {
      values: [
        {
          id: 'everyone',
          title: 'Everyone',
          description: 'Every person could fill your form'
        },
        {
          id: 'admin',
          title: 'Admin Only'
        },
        {
          id: 'registered',
          title: 'Registered Users Only'
        },
        {
          id: 'custom_category',
          title: 'Custom Category Only',
          description: 'Visible only to this category of users'
        }
      ],
      selected: {
        id: 'everyone',
        categories: []
      }
    },
    range: {
      description: 'Limit digits or value on this field',
      formats: [
        {
          id: 'digit',
          title: 'Digits'
        },
        {
          id: 'value',
          title: 'Value'
        }
      ],
      selected: {
        min: 0,
        max: null,
        id: 'digit'
      }
    },
    predefined: {
      optional: true,
      description: '',
      selected: null
    },
    placeholder: {
      optional: true,
      description: '',
      selected: null
    },
    instructions: {
      selected: ' '
    },
    system: {
      selected: false
    }
  }
}
