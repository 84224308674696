import socialAuth from './modules/socialAuth'
import tabsPanel from './modules/tabs'
import profilePhoto from './modules/profilePhoto'
import tabVideoContent from './modules/tabVideo'
import videoList from './modules/videoList'
import videoContent from './modules/videoContent'
import favorites from './modules/favorites'
import tabPanels from './modules/bible/tabAllPanel'
import routerTransition from './modules/router/transition'
import series from './modules/series'
import embedVideo from './modules/router/embedVideo'
import filters from './modules/filters'
import player from './modules/player'
import bookmarks from './modules/bookmarks'
import schedule from './modules/schedule'
import controlsTab from './modules/bible/controlsTab'
import bibleBookRead from './modules/bible/bibleBookRead'
import cloudBased from './modules/cloudBased'
import chatEntity from './modules/chat'
import transcript from './modules/trancript'
import generalSettingsWidgetPublic from '@@@/components/widgets/public/store/modules/generalSettingsWidgetPublic'
import map from './modules/map'

const modules = {
  socialAuth,
  tabsPanel,
  profilePhoto,
  tabVideoContent,
  videoList,
  videoContent,
  favorites,
  tabPanels,
  routerTransition,
  series,
  embedVideo,
  filters,
  player,
  bookmarks,
  schedule,
  controlsTab,
  bibleBookRead,
  cloudBased,
  chatEntity,
  transcript,
  map,
  generalSettingsWidgetPublic
}

export default modules
