export const fonts = [
  {
    key: 'regular',
    value: ''
  },
  {
    key: 'italic',
    value: 'Italic'
  },
  {
    key: '100',
    value: 'Thin'
  },
  {
    key: '100italic',
    value: 'Thin-Italic'
  },
  {
    key: '200',
    value: 'ExtraLight'
  },
  {
    key: '200italic',
    value: 'ExtraLight-Italic'
  },
  {
    key: '300',
    value: 'Light'
  },
  {
    key: '300italic',
    value: 'Light-Italic'
  },
  {
    key: '500',
    value: 'Medium'
  },
  {
    key: '500italic',
    value: 'Medium-Italic'
  },
  {
    key: '600',
    value: 'SemiBold'
  },
  {
    key: '600italic',
    value: 'SemiBold-Italic'
  },
  {
    key: '700',
    value: 'Bold'
  },
  {
    key: '700italic',
    value: 'Bold-Italic'
  },
  {
    key: '800',
    value: 'ExtraBold'
  },
  {
    key: '800italic',
    value: 'ExtraBold-Italic'
  },
  {
    key: '900',
    value: 'Black'
  },
  {
    key: '900italic',
    value: 'Black-Italic'
  }
]
