import * as types from './../../../../public/constants/store/mutations-types'

const state = {
  preferredPaymentMethod: false,
  preferredPaymentMethodError: false
}

const getters = {
  preferredPaymentMethod: state => state.preferredPaymentMethod,
  preferredPaymentMethodError: state => state.preferredPaymentMethodError
}
const actions = {
  setPreferredPaymentMethod ({ commit }, data) {
    commit(types.SET_PREFERRED_PAYMENT_METHOD, data)
  },
  setPreferredPaymentMethodError ({ commit }, data) {
    commit(types.SET_PREFERRED_PAYMENT_METHOD_ERROR, data)
  }
}
const mutations = {
  [types.SET_PREFERRED_PAYMENT_METHOD] (state, data) {
    state.preferredPaymentMethod = data
  },
  [types.SET_PREFERRED_PAYMENT_METHOD_ERROR] (state, data) {
    state.preferredPaymentMethodError = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
