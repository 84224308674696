export const VIDEO_ITEM_LIST =
  [
    {
      videoImg: require('../../assets/images/index/videoexample.jpg'),
      videoTitle: 'Et harum quidem rerum facilis',
      videoViewed: true,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: true
    },
    {
      videoImg: require('../../assets/images/index/videoexample-02.jpg'),
      videoTitle: 'Cras justo odio dapibus ac facilisis',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample-03.jpg'),
      videoTitle: 'Nam libero tempore, cum soluta nobis est ...',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample.jpg'),
      videoTitle: 'Et harum quidem rerum facilis',
      videoViewed: true,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: true

    },
    {
      videoImg: require('../../assets/images/index/videoexample-02.jpg'),
      videoTitle: 'Cras justo odio dapibus ac facilisis',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample-03.jpg'),
      videoTitle: 'Nam libero tempore, cum soluta nobis est ...',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample.jpg'),
      videoTitle: 'Et harum quidem rerum facilis',
      videoViewed: true,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample-02.jpg'),
      videoTitle: 'Cras justo odio dapibus ac facilisis',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample-03.jpg'),
      videoTitle: 'Nam libero tempore, cum soluta nobis est ...',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample.jpg'),
      videoTitle: 'Et harum quidem rerum facilis',
      videoViewed: true,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample-02.jpg'),
      videoTitle: 'Cras justo odio dapibus ac facilisis',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample-03.jpg'),
      videoTitle: 'Nam libero tempore, cum soluta nobis est ...',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample.jpg'),
      videoTitle: 'Et harum quidem rerum facilis',
      videoViewed: true,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample-02.jpg'),
      videoTitle: 'Cras justo odio dapibus ac facilisis',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample-03.jpg'),
      videoTitle: 'Nam libero tempore, cum soluta nobis est ...',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample.jpg'),
      videoTitle: 'Et harum quidem rerum facilis',
      videoViewed: true,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample-02.jpg'),
      videoTitle: 'Cras justo odio dapibus ac facilisis',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample-03.jpg'),
      videoTitle: 'Nam libero tempore, cum soluta nobis est ...',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample.jpg'),
      videoTitle: 'Et harum quidem rerum facilis',
      videoViewed: true,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample-02.jpg'),
      videoTitle: 'Cras justo odio dapibus ac facilisis',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample-03.jpg'),
      videoTitle: 'Nam libero tempore, cum soluta nobis est ...',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample.jpg'),
      videoTitle: 'Et harum quidem rerum facilis',
      videoViewed: true,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample-02.jpg'),
      videoTitle: 'Cras justo odio dapibus ac facilisis',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample-03.jpg'),
      videoTitle: 'Nam libero tempore, cum soluta nobis est ...',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample.jpg'),
      videoTitle: 'Et harum quidem rerum facilis',
      videoViewed: true,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample-02.jpg'),
      videoTitle: 'Cras justo odio dapibus ac facilisis',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    },
    {
      videoImg: require('../../assets/images/index/videoexample-03.jpg'),
      videoTitle: 'Nam libero tempore, cum soluta nobis est ...',
      videoViewed: false,
      videoSuggested: false,
      date: '11.11.2017',
      campus: 'ggdst',
      popularity: '10.1',
      favorites: false
    }
  ]
