import * as types from '../mutations-types'

const state = {
  listSermons: [],
  listSeries: [],
  listAuthors: [],
  favoritesList: []
}

const getters = {
  listSermons: state => state.listSermons,
  listSeries: state => state.listSeries,
  listAuthors: state => state.listAuthors,
  favoritesList: state => state.favoritesList
}
const actions = {
  loadData ({ commit }, data) {
    commit(types.LOAD_FAVORITES_DATA, { data })
  },
  addFavorites ({ commit }, { data }) {
    commit(types.ADD_FAVORITES_DATA, { data })
  },
  removeFavorites ({ commit }, { data }) {
    commit(types.REMOVE_FAVORITES_DATA, { data })
  },
  setFavoritesListData ({ commit }, { data }) {
    commit(types.SET_FAVORITES_LIST_DATA, { data: data })
  }
}
const mutations = {
  [types.LOAD_FAVORITES_DATA] (state, { data }) {
    state.listSermons = data
  },
  [types.ADD_FAVORITES_DATA] (state, { data }) {
    state.listSermons.unshift(data)
  },
  [types.REMOVE_FAVORITES_DATA] (state, { data }) {
    state.listSermons = state.listSermons.filter(el => el.id !== data)
  },
  [types.SET_FAVORITES_LIST_DATA] (state, { data }) {
    if (data.length) {
      state.favoritesList.push(...data)
    } else {
      state.favoritesList = data
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
