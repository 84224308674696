export const SET_ALL_DEVOTIONS_DATA = 'SET_ALL_DEVOTIONS_DATA'
export const PUSH_NEW_DEVOTIONS_DATA = 'PUSH_NEW_DEVOTIONS_DATA'
export const SET_EVENT_DATA = 'SET_EVENT_DATA'
export const SET_CURRENT_DEVOTIONS_COUNT = 'SET_CURRENT_DEVOTIONS_COUNT'
export const PRESET_CAMPUSES = 'PRESET_CAMPUSES'
export const SET_DEVOTIONS_FILTER_QUERY = 'SET_DEVOTIONS_FILTER_QUERY'
export const SET_DEVOTIONS_SEARCH_QUERY = 'SET_DEVOTIONS_SEARCH_QUERY'
export const SET_INFINITE_LOADING_REF_DEVOTIONS = 'SET_INFINITE_LOADING_REF_DEVOTIONS'
export const SET_NOT_FOUND_DEVOTIONS = 'SET_NOT_FOUND_DEVOTIONS'
export const PUSH_NEW_POST_DATA = 'PUSH_NEW_POST_DATA'
export const SET_ACTIVE_POST = 'SET_ACTIVE_POST'
export const SET_NO_DATA_DEVOTIONS_POST = 'SET_NO_DATA_DEVOTIONS_POST'
export const PUSH_DEVOTIONS_POST_DATA = 'PUSH_DEVOTIONS_POST_DATA'
export const SET_DEVOTIONS_ACTIVE_POST = 'SET_DEVOTIONS_ACTIVE_POST'
export const PRESET_DEVOTIONS_IMAGE_UPDATE = 'PRESET_DEVOTIONS_IMAGE_UPDATE'
// export const SET_PRINT_STATUS = 'SET_PRINT_STATUS'
