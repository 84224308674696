import * as types from '../../mutations-types'

const state = {
  tabsBibleLoadingState: false,
  tabsChatLoadingState: false
}

const getters = {
  tabsBibleLoadingState: state => state.tabsBibleLoadingState,
  tabsChatLoadingState: state => state.tabsChatLoadingState
}
const actions = {
  enableTabBibleState ({ commit }) {
    commit(types.ENABLE_TAB_BIBLE_STATE)
  },
  enableTabChatState ({ commit }) {
    commit(types.ENABLE_TAB_CHAT_STATE)
  }
}
const mutations = {
  [types.ENABLE_TAB_BIBLE_STATE] (state) {
    state.tabsBibleLoadingState = true
  },
  [types.ENABLE_TAB_CHAT_STATE] (state) {
    state.tabsChatLoadingState = true
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
