import * as types from '../../constants/store/mutations-types'

const state = {
  sermonsPostListData: [],
  sermonsActivePost: 0
  // printStatus: false
}

const getters = {
  sermonsPostListData: state => state.sermonsPostListData,
  sermonsActivePost: state => state.sermonsActivePost
}
const actions = {
  pushSermonsPostData ({ commit }, data) {
    commit(types.PUSH_SERMONS_POST_DATA, { data: data })
  },
  setSermonsActivePost ({ commit }, data) {
    commit(types.SET_SERMONS_ACTIVE_POST, data)
  }
}
const mutations = {
  [types.PUSH_SERMONS_POST_DATA] (state, { data }) {
    state.sermonsPostListData.push(data)
    state.sermonsActivePost = state.sermonsPostListData.length - 1
  },
  [types.SET_SERMONS_ACTIVE_POST] (state, data) {
    state.sermonsActivePost = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
