import * as types from './../../constants/store/mutations-types'
import * as statuses from './../../constants/store/status-types'
import authentificate from './../../api/authentificate'
import _ from 'lodash'
import widgetsApi from './../../api/widgets'
import VueCookie from 'vue-cookie'
import * as modals from './../../constants/store/modal-types'
import { newRegisterState } from '@@@/components/modals/userAuth/registration/constants/newRegisterState'

const state = {
  userData: [],
  authorizeStatus: statuses.STATUS_FAIL_AUTH,
  linksStatus: false,
  registerObj: {},
  registerState: newRegisterState,
  userRegMailTo: '',
  userMailToSendMeALink: ''
}

const getters = {
  userData: state => state.userData,
  authorizeStatus: state => state.authorizeStatus,
  registerObj: state => state.registerObj,
  registerState: state => state.registerState,
  userRegMailTo: state => state.userRegMailTo,
  userMailToSendMeALink: state => state.userMailToSendMeALink,
  linksStatus: state => state.linksStatus
}
const actions = {
  dataUser ({ commit }) {
    authentificate.getUserData((response) => {
      commit(types.SET_USER_DATA, { response: response })
      // thisEl.dispatch('setIfLoader', true)
      if (response.data.member !== undefined) {
        commit(types.AUTHORIZATION, { authorizeStatus: statuses.STATUS_SUCCES_AUTH })
        commit('mutationUserMailSendMeALink', { data: response.data.member.email })
      } else {
        commit(types.AUTHORIZATION, { authorizeStatus: statuses.STATUS_FAIL_AUTH })
      }
      if (response.data.member !== undefined) {
        widgetsApi.personalSettings((resp) => {
          this.dispatch('updatePersonalSettings', resp.data)
          let el = _.find(resp.data, function (obj) {
            return obj.name === 'closed_captions_lang'
          })
          if (el !== undefined) this.dispatch('setTranscriptLang', el.value)
          else this.dispatch('setTranscriptLang', el)
          el = _.find(resp.data, function (obj) {
            return obj.name === 'closed_captions'
          })
          if (el !== undefined) this.dispatch('updatePlayerCaptions', el.value)
          else this.dispatch('updatePlayerCaptions', el)
        })
        this.dispatch('setAppLang', response.data.member.language)
      } else {
        this.dispatch('setTranscriptLang', VueCookie.get('transcriptLang'))
        this.dispatch('updatePlayerCaptions', VueCookie.get('captionsState'))
        this.dispatch('setAppLang', VueCookie.get('appLang'))
      }
      if (VueCookie.get('firstInit') === null) {
        this.dispatch('toogleModal', modals.MODAL_SELECT_LANG)
        VueCookie.set('firstInit', true, { expiries: '1Y' })
      }
    })
  },
  logout ({ commit }) {
    commit(types.LOGOUT)
  },
  setUserState ({ commit }, data) {
    commit(types.REGISTER_STATE, { newState: data })
  },
  setUserData ({ commit }, { response }) {
    commit(types.SET_USER_DATA, { response: response })
  },
  updateUserDataSb ({ commit }, { response }) {
    commit(types.UPDATE_USER_DATA_SB, { response: response })
  },
  setUserRegisterObj ({ commit }, data) {
    commit(types.SET_USER_REGISTER_OBJ, { data: data })
  },
  regUser ({ commit }) {
    commit(types.SET_USER_REGISTER_MAIL)
    commit(types.SET_USER_REGISTER_OBJ, { data: [] })
  },
  changeUserMailToSendMeALink ({ commit }, data) {
    commit('mutationUserMailSendMeALink', { data: data })
  }
}
const mutations = {
  [types.AUTHORIZATION] (state, { authorizeStatus }) {
    if (state.userData.member !== undefined) {
      state.authorizeStatus = authorizeStatus
      state.linksStatus = !authorizeStatus
    } else {
      state.linksStatus = !authorizeStatus
    }
  },
  [types.SET_USER_DATA] (state, { response }) {
    state.userData = response.data
    if (state.userData.member !== undefined) {
      delete state.userData.member
    }
  },
  [types.UPDATE_USER_DATA_SB] (state, { response }) {
    if (state.userData.member !== undefined) {
      state.registerObj.first_name = response.firstname
      state.registerObj.last_name = response.lastname
      state.registerObj.email = response.email
      state.registerObj.phone_mobile = response.phoneMobile ? response.phoneMobile : ''
      state.registerObj.street = response.location.addressStreet ? response.location.addressStreet : ''
      state.registerObj.city = response.location.city ? response.location.city : ''
      state.registerObj.zip = response.location.zipCode ? response.location.zipCode : ''
      state.registerObj.state = response.location.state ? response.location.state : ''
      state.userData.id = response.mid
      state.userData.member = {
        fname: response.firstname,
        lname: response.lastname,
        email: response.email,
        phone_mobile: response.phoneMobile ? response.phoneMobile : '',
        street: response.location.addressStreet ? response.location.addressStreet : '',
        city: response.location.city ? response.location.city : '',
        zip: response.location.zipCode ? response.location.zipCode : '',
        state: response.location.state ? response.location.state : '',
        country: response.location.country ? response.location.country : ''
      }
    } else {
      state.userData.id = response.mid
      state.userData.member = {
        fname: response.firstname,
        lname: response.lastname,
        email: response.email,
        phone_mobile: response.phoneMobile ? response.phoneMobile : '',
        street: response.location.addressStreet ? response.location.addressStreet : '',
        city: response.location.city ? response.location.city : '',
        zip: response.location.zipCode ? response.location.zipCode : '',
        state: response.location.state ? response.location.state : '',
        country: response.location.country ? response.location.country : ''
      }
      state.registerObj = {
        first_name: response.firstname,
        last_name: response.lastname,
        email: response.email,
        phone_mobile: response.phoneMobile ? response.phoneMobile : '',
        street: response.location.addressStreet ? response.location.addressStreet : '',
        city: response.location.city ? response.location.city : '',
        zip: response.location.zipCode ? response.location.zipCode : '',
        state: response.location.state ? response.location.state : ''
      }
    }
  },
  [types.LOGOUT] (state) {
    state.authorizeStatus = statuses.STATUS_FAIL_AUTH
    state.linksStatus = !statuses.STATUS_FAIL_AUTH
  },
  [types.REGISTER_STATE] (state, { newState }) {
    state.registerState = newState
  },
  [types.SET_USER_REGISTER_OBJ] (state, { data }) {
    state.registerObj = data
  },
  [types.SET_USER_REGISTER_MAIL] (state) {
    state.userRegMailTo = state.registerObj.email
  },
  mutationUserMailSendMeALink (state, { data }) {
    state.userMailToSendMeALink = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
