import * as types from './../../constants/store/mutations-types'
import _ from 'lodash'

const state = {
  readingPlanHorizontalListAll: [],
  currentReadingPlanHorizontalCount: 1,
  readingPlanHorizontalFilterQuery: '',
  readingPlanHorizontalSearchQuery: '',
  infiniteLoadingRefReadingPlanHorizontal: false,
  notFoundReadingPlanHorizontal: false,
  noDataReadingPlanHorizontalPost: false,
  presetReadingPlanHorizontalImg: false,
  readingPlanHorizontalListSetTimeoutId: false
  // printStatus: false
}

const getters = {
  readingPlanHorizontalListAll: state => state.readingPlanHorizontalListAll,
  currentReadingPlanHorizontalCount: state => state.currentReadingPlanHorizontalCount,
  readingPlanHorizontalFilterQuery: state => state.readingPlanHorizontalFilterQuery,
  readingPlanHorizontalSearchQuery: state => state.readingPlanHorizontalSearchQuery,
  infiniteLoadingRefReadingPlanHorizontal: state => state.infiniteLoadingRefReadingPlanHorizontal,
  notFoundReadingPlanHorizontal: state => state.notFoundReadingPlanHorizontal,
  noDataReadingPlanHorizontalPost: state => state.noDataReadingPlanHorizontalPost,
  presetReadingPlanHorizontalImg: state => state.presetReadingPlanHorizontalImg
  // printStatus: state => state.printStatus
}
const actions = {
  setAllReadingPlanHorizontalData ({ commit }, data) {
    commit(types.SET_ALL_READING_PLAN_HORIZONTAL_DATA, { data: data })
  },
  pushNewReadingPlanHorizontalData ({ commit }, data) {
    commit(types.PUSH_NEW_READING_PLAN_HORIZONTAL_DATA, { data: data })
  },
  setCurrentReadingPlanHorizontalCount ({ commit }, data) {
    commit(types.SET_CURRENT_READING_PLAN_HORIZONTAL_COUNT, { data: data })
  },
  setReadingPlanHorizontalFilterQuery ({ commit }, data) {
    commit(types.SET_READING_PLAN_HORIZONTAL_FILTER_QUERY, data)
  },
  setReadingPlanHorizontalSearchQuery ({ commit }, data) {
    commit(types.SET_READING_PLAN_HORIZONTAL_SEARCH_QUERY, data)
  },
  setInfiniteLoadingRefReadingPlanHorizontal ({ commit }, data) {
    commit(types.SET_INFINITE_LOADING_REF_READING_PLAN_HORIZONTAL, data)
  },
  setNotFoundReadingPlanHorizontal ({ commit }, data) {
    commit(types.SET_NOT_FOUND_READING_PLAN_HORIZONTAL, data)
  },
  setNoDataReadingPlanHorizontalPost ({ commit }, data) {
    commit(types.SET_NO_DATA_READING_PLAN_HORIZONTAL_POST, data)
  },
  presetReadingPlanHorizontalImageUpdate ({ commit }, data) {
    commit(types.PRESET_READING_PLAN_HORIZONTAL_IMAGE_UPDATE, data)
  }
  // setPrintStatus ({commit}, data) {
  //   commit(types.SET_PRINT_STATUS, data)
  // }
}
const mutations = {
  [types.SET_ALL_READING_PLAN_HORIZONTAL_DATA] (state, { data }) {
    if (data.length > 0) {
      state.readingPlanHorizontalListAll = data.items
    } else {
      state.readingPlanHorizontalListAll = []
    }
  },
  [types.PUSH_NEW_READING_PLAN_HORIZONTAL_DATA] (state, { data }) {
    clearTimeout(state.readingPlanHorizontalListSetTimeoutId)
    for (let i = 0; i < data.items.length; i++) {
      if (_.find(state.readingPlanHorizontalListAll, function (obj) {
        return obj.id === data.items[i].id
      }) === undefined) {
        if (data.items[i].description === undefined) {
          data.items[i].description = ''
        }
        state.readingPlanHorizontalListAll.push(data.items[i])
      }
    }
  },
  [types.SET_CURRENT_READING_PLAN_HORIZONTAL_COUNT] (state, { data }) {
    state.currentReadingPlanHorizontalCount = data
  },
  [types.SET_READING_PLAN_HORIZONTAL_FILTER_QUERY] (state, data) {
    state.readingPlanHorizontalFilterQuery = data
  },
  [types.SET_READING_PLAN_HORIZONTAL_SEARCH_QUERY] (state, data) {
    state.readingPlanHorizontalSearchQuery = data
  },
  [types.SET_INFINITE_LOADING_REF_READING_PLAN_HORIZONTAL] (state, data) {
    state.infiniteLoadingRefReadingPlanHorizontal = data
  },
  [types.SET_NOT_FOUND_READING_PLAN_HORIZONTAL] (state, data) {
    state.notFoundReadingPlanHorizontal = data
    state.notFoundReadingPlanHorizontal = data
  },
  [types.SET_NO_DATA_READING_PLAN_HORIZONTAL_POST] (state, data) {
    state.noDataReadingPlanHorizontalPost = data
  },
  [types.PRESET_READING_PLAN_HORIZONTAL_IMAGE_UPDATE] (state, data) {
    state.presetReadingPlanHorizontalImg = data
  }
  // [types.SET_PRINT_STATUS] (state, data) {
  //   state.printStatus = data
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
