export default {
  title: 'Untitled',
  name: 'Untitled',
  type: 'address',
  group: 'advanced',
  properties: {
    options: {
      values: [
        {
          id: 'optional',
          title: 'Optional Field'
        },
        {
          id: 'required',
          title: 'Required Field'
        }
      ],
      selected: {
        id: ['optional']
      }
    },
    visibility: {
      values: [
        {
          id: 'everyone',
          title: 'Everyone',
          description: 'Every person could fill your form'
        },
        {
          id: 'admin',
          title: 'Admin Only'
        },
        {
          id: 'registered',
          title: 'Registered Users Only'
        },
        {
          id: 'custom_category',
          title: 'Custom Category Only',
          description: 'Visible only to this category of user'
        }
      ],
      selected: {
        id: 'everyone',
        categories: []
      }
    },
    predefined: {
      selected: {
        streetAddress: null,
        addressLine2: null,
        city: null,
        region: null,
        postcode: null,
        country: 'United States'
      }
    },
    placeholder: {
      selected: {
        streetAddress: 'Street Address',
        addressLine2: 'Street Line 2',
        city: 'City',
        postcode: 'Postal / ZIP Code',
        region: 'State / Province / Region'
      }
    },
    instructions: {
      selected: ''
    },
    system: {
      selected: false
    }
  }
}
