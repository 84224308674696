import * as types from '../../mutations-types'
import * as filters from '../../../constants/filters/filterList'
import _ from 'lodash'

const state = {
  sortString: '&sorting[created_at]=desc',
  filterList: filters.FILTER_LIST,
  filterListSeries: filters.FILTER_LIST_SERIES,
  presetImg: false,
  presetImgSeries: false,
  presetImageFroMostViewed: true,
  filterChecker: false,
  filterSeriesChecker: false,
  seriesSortString: ''
}

const getters = {
  sortString: state => state.sortString,
  seriesSortString: state => state.seriesSortString,
  filterList: state => state.filterList,
  filterListSeries: state => state.filterListSeries,
  presetImg: state => state.presetImg,
  presetImgSeries: state => state.presetImgSeries,
  presetImageFroMostViewed: state => state.presetImageFroMostViewed,
  filterChecker: state => state.filterChecker,
  filterSeriesChecker: state => state.filterSeriesChecker
}
const actions = {
  setSortingString ({ commit }, data) {
    commit(types.SET_SORTING_STRING, data)
  },
  setSeriesSortingString ({ commit }, data) {
    commit(types.SET_SERIES_SORTING_STRING, data)
  },
  setSermonsFilter ({ commit }, { item, subMenu }) {
    commit(types.SET_FILTER_SERMONS, { item, subMenu })
  },
  setSeriesFilter ({ commit }, { item, subMenu }) {
    commit(types.SET_FILTER_SERIES, { item, subMenu })
  },
  presetImageUpdate ({ commit }, data) {
    commit(types.PRESET_IMAGE, data)
  },
  presetImageSeriesUpdate ({ commit }, data) {
    commit(types.PRESET_IMAGE_SERIES, data)
  },
  presetImageMWUpdate ({ commit }, data) {
    commit(types.PRESET_IMAGE_MW, data)
  },
  presetCampuses ({ commit }, data) {
    commit(types.PRESET_CAMPUSES, data)
  }
}
const mutations = {
  [types.SET_SORTING_STRING] (state, data) {
    state.sortString = data
  },
  [types.SET_SERIES_SORTING_STRING] (state, data) {
    state.seriesSortString = data
  },
  [types.PRESET_IMAGE] (state, data) {
    state.presetImg = data
  },
  [types.PRESET_IMAGE_SERIES] (state, data) {
    state.presetImgSeries = data
  },
  [types.PRESET_IMAGE_MW] (state, data) {
    state.presetImageFroMostViewed = false
  },
  [types.PRESET_CAMPUSES] (state, data) {
    for (var i = 0; i < state.filterList.length; i++) {
      if (state.filterList[i].filtervalue === 'campus') {
        if (state.filterList[i].submenu.sort.length === 0) {
          for (var j = 0; j < data.length; j++) {
            state.filterList[i].submenu.sort.push({
              title: data[j].title,
              id: data[j].id,
              query: '',
              active: false
            })
            state.filterList[i].submenu.query = '&department_id='
          }
        }
      }
    }
    for (i = 0; i < state.filterListSeries.length; i++) {
      if (state.filterListSeries[i].filtervalue === 'campus') {
        if (state.filterListSeries[i].submenu.sort.length === 0) {
          for (j = 0; j < data.length; j++) {
            state.filterListSeries[i].submenu.sort.push({
              title: data[j].title,
              id: data[j].id,
              query: '',
              active: false
            })
            state.filterListSeries[i].submenu.query = '&department_id='
          }
        }
      }
    }
    state.presetImg = data
  },
  [types.SET_FILTER_SERMONS] (state, { item, subMenu }) {
    state.filterChecker = !state.filterChecker
    if (item.radioName === 'depend') {
      for (var i = 0; i < state.filterList.length; i++) {
        if (state.filterList[i].radioName === 'depend') {
          state.filterList[i].isActive = false
          for (var j = 0; j < state.filterList[i].submenu.sort.length; j++) {
            state.filterList[i].submenu.sort[j].active = false
          }
        }
      }
    } else {
      for (i = 0; i < state.filterList.length; i++) {
        if (state.filterList[i].radioName !== 'depend' && state.filterList[i].id === item.id) {
          for (j = 0; j < state.filterList[i].submenu.sort.length; j++) {
            if (state.filterList[i].submenu.type !== 'checkbox') {
              state.filterList[i].isActive = false
              state.filterList[i].submenu.sort[j].active = false
            }
          }
        }
      }
    }
    const needItem = _.find(state.filterList, function (obj) { return obj.id === item.id })
    if (needItem.submenu.type === 'checkbox') {
      _.find(needItem.submenu.sort, function (obj) { return obj.id === subMenu.id }).active = !_.find(needItem.submenu.sort, function (obj) { return obj.id === subMenu.id }).active
      var count = 0
      for (var k = 0; k < needItem.submenu.sort.length; k++) {
        if (needItem.submenu.sort[k].active) count++
      }
      if (count > 0) needItem.isActive = true
      else needItem.isActive = false
    } else {
      needItem.isActive = true
      _.find(needItem.submenu.sort, function (obj) { return obj.id === subMenu.id }).active = true
    }
    let query = ''
    for (i = 0; i < state.filterList.length; i++) {
      if (state.filterList[i].submenu.type === 'checkbox') {
        count = 0
        for (k = 0; k < state.filterList[i].submenu.sort.length; k++) {
          if (state.filterList[i].submenu.sort[k].active) count++
        }
        if (count > 0) {
          query = query + state.filterList[i].submenu.query
          for (j = 0; j < state.filterList[i].submenu.sort.length; j++) {
            if (state.filterList[i].submenu.sort[j].active === true) query = query + state.filterList[i].submenu.sort[j].id + ','
          }
          if (query[query.length - 1].indexOf(',') === 0) {
            query = query.substring(0, query.length - 1)
          }
        }
      } else {
        for (j = 0; j < state.filterList[i].submenu.sort.length; j++) {
          if (state.filterList[i].submenu.sort[j].active === true) query = query + state.filterList[i].submenu.sort[j].query
        }
      }
    }
    state.sortString = query
  },
  [types.SET_FILTER_SERIES] (state, { item, subMenu }) {
    state.filterSeriesChecker = !state.filterSeriesChecker
    if (item.radioName === 'depend') {
      for (var i = 0; i < state.filterListSeries.length; i++) {
        if (state.filterListSeries[i].radioName === 'depend') {
          state.filterListSeries[i].isActive = false
          for (var j = 0; j < state.filterListSeries[i].submenu.sort.length; j++) {
            state.filterListSeries[i].submenu.sort[j].active = false
          }
        }
      }
    } else {
      for (i = 0; i < state.filterListSeries.length; i++) {
        if (state.filterListSeries[i].radioName !== 'depend' && state.filterListSeries[i].id === item.id) {
          for (j = 0; j < state.filterListSeries[i].submenu.sort.length; j++) {
            if (state.filterListSeries[i].submenu.type !== 'checkbox') {
              state.filterListSeries[i].isActive = false
              state.filterListSeries[i].submenu.sort[j].active = false
            }
          }
        }
      }
    }
    const needItem = _.find(state.filterListSeries, function (obj) { return obj.id === item.id })
    if (needItem.submenu.type === 'checkbox') {
      _.find(needItem.submenu.sort, function (obj) { return obj.id === subMenu.id }).active = !_.find(needItem.submenu.sort, function (obj) { return obj.id === subMenu.id }).active
      var count = 0
      for (var k = 0; k < needItem.submenu.sort.length; k++) {
        if (needItem.submenu.sort[k].active) count++
      }
      if (count > 0) needItem.isActive = true
      else needItem.isActive = false
    } else {
      needItem.isActive = true
      _.find(needItem.submenu.sort, function (obj) { return obj.id === subMenu.id }).active = true
    }
    let query = ''
    for (i = 0; i < state.filterListSeries.length; i++) {
      if (state.filterListSeries[i].submenu.type === 'checkbox') {
        count = 0
        for (k = 0; k < state.filterListSeries[i].submenu.sort.length; k++) {
          if (state.filterListSeries[i].submenu.sort[k].active) count++
        }
        if (count > 0) {
          query = query + state.filterListSeries[i].submenu.query
          for (j = 0; j < state.filterListSeries[i].submenu.sort.length; j++) {
            if (state.filterListSeries[i].submenu.sort[j].active === true) query = query + state.filterListSeries[i].submenu.sort[j].id + ','
          }
          if (query[query.length - 1].indexOf(',') === 0) {
            query = query.substring(0, query.length - 1)
          }
        }
      } else {
        for (j = 0; j < state.filterListSeries[i].submenu.sort.length; j++) {
          if (state.filterListSeries[i].submenu.sort[j].active === true) query = query + state.filterListSeries[i].submenu.sort[j].query
        }
      }
    }
    state.seriesSortString = query
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
