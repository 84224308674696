<template>
  <div class="modal-template-dialog">
    <VimeoTemplate
      :id="id"
    />
  </div>
</template>

<script>
import VimeoTemplate from '@@@/components/elements/vimeoTemplate.vue'

export default {
  name: 'ModalTemplateDialog',
  components: {
    VimeoTemplate
  },
  props: ['id']
}
</script>

<style lang="scss" scoped>
  .modal-template-dialog {
    margin: 20px;

    @media screen and (max-width: 540px) {
      margin: 25px 10px 10px;
    }
  }
</style>
