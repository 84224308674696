import * as types from '../../mutations-types'

const state = {
  ifNotEmbed: true
}

const getters = {
  ifNotEmbed: state => state.ifNotEmbed
}
const actions = {
  setEmbed ({ commit }) {
    commit(types.SET_EMBED)
  },
  setNotEmbed ({ commit }) {
    commit(types.SET_NOT_EMBED)
  }
}
const mutations = {
  [types.SET_EMBED] (state) {
    state.ifNotEmbed = false
  },
  [types.SET_NOT_EMBED] (state) {
    state.ifNotEmbed = true
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
