import {
  SUB_TYPE_BLOG_WIDGET, SUB_TYPE_DEVOTIONS_WIDGET,
  SUB_TYPE_EVENTS_WIDGET,
  SUB_TYPE_NEWS_WIDGET, SUB_TYPE_PHOTO_WIDGET, SUB_TYPE_READING_PLAN_WIDGET,
  SUB_TYPE_SERMONS_WIDGET, SUB_TYPE_STAFF_WIDGET
} from '@@@/preset/elements/listSubTypes'

export const widgetsToRoutes = {
  [SUB_TYPE_BLOG_WIDGET]: [{
    presetUrl: '/blog/:id',
    name: 'viewBlogPage'
  }],
  [SUB_TYPE_DEVOTIONS_WIDGET]: [{
    presetUrl: '/devotion/:id',
    name: 'viewDevotionPage'
  }],
  [SUB_TYPE_EVENTS_WIDGET]: [{
    presetUrl: '/event/:id',
    name: 'viewEventPage'
  }],
  [SUB_TYPE_NEWS_WIDGET]: [{
    presetUrl: '/news/:id',
    name: 'viewNewsPage'
  }],
  [SUB_TYPE_PHOTO_WIDGET]: [{
    presetUrl: '/album/:id',
    name: 'viewAlbumPage'
  }, {
    presetUrl: '/album/:id/photo/:idPhoto',
    name: 'viewPhotoPage'
  }],
  [SUB_TYPE_READING_PLAN_WIDGET]: [{
    presetUrl: '/plan/:id',
    name: 'viewReadingPlanPage'
  }],
  [SUB_TYPE_SERMONS_WIDGET]: [{
    presetUrl: '/video/:id',
    name: 'viewVideoPage'
  }],
  [SUB_TYPE_STAFF_WIDGET]: [{
    presetUrl: '/staff/:id',
    name: 'viewStaffPage'
  }]
}
