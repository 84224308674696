import * as types from './../../constants/store/mutations-types'

const state = {
  postListData: [],
  activePost: 0
  // printStatus: false
}

const getters = {
  postListData: state => state.postListData,
  activePost: state => state.activePost
}
const actions = {
  pushNewPostData ({ commit }, data) {
    commit(types.PUSH_NEW_POST_DATA, { data: data })
  },
  setActivePost ({ commit }, data) {
    commit(types.SET_ACTIVE_POST, data)
  }
}
const mutations = {
  [types.PUSH_NEW_POST_DATA] (state, { data }) {
    state.postListData.push(data)
    state.activePost = state.postListData.length - 1
  },
  [types.SET_ACTIVE_POST] (state, data) {
    state.activePost = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
