import * as domains from 'API/organization/domains'
import VueCookie from 'vue-cookie'
const axios = require('axios')

export default {
  getOrganizationSettings: function () {
    const instWithCred = axios.create({
      baseURL: domains.ORGANIZATIONS_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid')
      }
    })
    return instWithCred.get('settings')
  },
  getCorpAction: function () {
    const instWithCred = axios.create({
      baseURL: domains.ACTION_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid')
      }
    })
    return instWithCred.get('epicRegLink')
  },
  getOrganizationText: function (data) {
    const instWithCred = axios.create({
      baseURL: domains.ORGANIZATIONS_DOMAIN
    })
    return instWithCred.get('text/' + data)
  },
  // getGeneralSettings: function () {
  //   const instWithCred = axios.create({
  //     baseURL: domains.CURRENT_SERVER,
  //     withCredentials: true
  //   })
  //   return instWithCred.get('iphone.php/sermons/getGeneralSettings')
  // },
  setAction: function (action, data) {
    const instWithCred = axios.create({
      baseURL: domains.CORP_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid')
      }
    })
    let postData
    if (action === 'setDesign') {
      postData = 'design'
    } else {
      postData = 'data'
    }
    return instWithCred.post('' + action, { [postData]: data })
  },
  getOrganizationPaymentProfile: function () {
    const instWithCred = axios.create({
      baseURL: domains.CORP_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid')
      }
    })
    return instWithCred.get('paymentProfile')
  },
  getCorpOrganizationSettings: function () {
    const instWithCred = axios.create({
      baseURL: domains.CORP_ORGANIZATIONS_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid')
      }
    })
    return instWithCred.get('')
  },
  getApplicationConfiguration: function () {
    const instWithCred = axios.create({
      baseURL: domains.APPLICATION_CONFIGURATION_DOMAIN
    })
    return instWithCred.get('configuration')
  },
  updateCorpOrganizationSettings: function (data) {
    const instWithCred = axios.create({
      baseURL: domains.CORP_ORGANIZATIONS_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid')
      }
    })
    return instWithCred.patch('', { data })
  },
  setModule: function (moduleName, data) {
    const instWithCred = axios.create({
      baseURL: domains.APPLICATION_ADMIN_CONFIGURATION_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid')
      }
    })
    console.log(moduleName)
    console.log(data)
    return instWithCred.patch('' + moduleName, { data })
  },
  updateOrganizationModule: function (data) {
    const instWithCred = axios.create({
      baseURL: domains.ORGANIZATIONS_CONFIG_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid')
      }
    })
    return instWithCred.patch('', { data: data })
  },
  getMinistriesDataWithPagination: function () {
    const instWithCred = axios.create({
      baseURL: domains.MINISTRIES_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid')
      }
    })
    // let queryCheck = (query.length === 0) ? '?' : '&'
    return instWithCred.get('?page=1&onpage=999')
  },
  getDesignationDataWithPagination: function () {
    const instWithCred = axios.create({
      baseURL: domains.DESIGNATION_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid')
      }
    })
    // let queryCheck = (query.length === 0) ? '?' : '&'
    return instWithCred.get('?page=1&onpage=999')
  }
  // getConfiguration: function () {
  //   const instWithCred = axios.create({
  //     baseURL: domains.ORGANIZATIONS_DOMAIN,
  //     headers: {
  //       'X-Custom-Auth': VueCookie.get('mid')
  //     }
  //   })
  //   return instWithCred.get('settings')
  // }
}
