import * as types from '../../mutations-types'
import _ from 'lodash'
import bibleReadBook from '../../../api/bibleReadBook'

const state = {
  currentBookText: [],
  bookMarksForBooks: [],
  notesForBooks: [],
  markersForBooks: [],
  translationsList: [],
  currentBookVersion: 7,
  currentBookId: 6,
  currentBookVerBookId: 1,
  currentBookVerBookName: 'Genesis',
  currentBookChapterId: 1,
  currentBookChapterNum: 1,
  animationVersesId: 0,
  editNotesStatus: false,
  editedNote: {},
  bibleScrollPosition: 1,
  chatScrollPosition: 0,
  ifLoaderState: false,
  compareBookVersion: 0,
  compareBookText: [],
  compareBookNotFoundPlaceholder: false,
  compareChapterNotFoundPlaceholder: false
}

const getters = {
  currentBookText: state => state.currentBookText,
  bookMarksForBooks: state => state.bookMarksForBooks,
  notesForBooks: state => state.notesForBooks,
  markersForBooks: state => state.markersForBooks,
  translationsList: state => state.translationsList,
  currentBookId: state => state.currentBookId,
  currentBookVersion: state => state.currentBookVersion,
  currentBookVerBookId: state => state.currentBookVerBookId,
  currentBookVerBookName: state => state.currentBookVerBookName,
  currentBookChapterId: state => state.currentBookChapterId,
  currentBookChapterNum: state => state.currentBookChapterNum,
  animationVersesId: state => state.animationVersesId,
  editNotesStatus: state => state.editNotesStatus,
  editedNote: state => state.editedNote,
  bibleScrollPosition: state => state.bibleScrollPosition,
  chatScrollPosition: state => state.chatScrollPosition,
  ifLoaderState: state => state.ifLoaderState,
  compareBookVersion: state => state.compareBookVersion,
  compareBookText: state => state.compareBookText,
  compareBookNotFoundPlaceholder: state => state.compareBookNotFoundPlaceholder,
  compareChapterNotFoundPlaceholder: state => state.compareChapterNotFoundPlaceholder
}
const actions = {
  setCurrentBookBookmark ({ commit }, content) {
    commit(types.SET_BOOK_BOOKMARK, { content: content })
  },
  setCurrentBookNotes ({ commit }, content) {
    commit(types.SET_BOOK_NOTES, { content: content })
  },
  setCurrentBookMarkers ({ commit }, content) {
    commit(types.SET_BOOK_MARKERS, { content: content })
  },
  setTranslationsList ({ commit }, content) {
    commit(types.SET_TRANSLATIONS_LIST, { content: content })
  },
  initBookText ({ commit }) {
    commit(types.INIT_BOOK_TEXT)
  },
  setCurrentBookVersion ({ commit }, content) {
    commit(types.SET_CURRENT_BOOK_CONTENT, { content: content })
  },
  setChaptersForVer ({ commit }, { content, ver }) {
    commit(types.SET_CHAPTERS_FOR_VER, { content: content, ver: ver })
  },
  setTextForChapters ({ commit }, { content, verChapter, version }) {
    commit(types.SET_TEXT_FOR_CHAPTERS, { content: content, verChapter: verChapter, version: version })
  },
  setScrollData ({ commit }, { verId, chapterId }) {
    commit(types.SET_SCROLL_DATA, { verId: verId, chapterId: chapterId })
  },
  setAnimationVersesId ({ commit }, data) {
    commit(types.SET_ANIMATION_ID, { id: data })
  },
  setCurrentBookShortName ({ commit }, data) {
    commit(types.SET_CURRENT_BOOK_SHORT_NAME, { name: data })
  },
  setEditNotesStatus ({ commit }, data) {
    commit(types.SET_EDIT_NOTES_STATUS, { status: data })
  },
  setEditNote ({ commit }, data) {
    commit(types.SET_EDIT_NOTE, { data: data })
  },
  setBibleScrollPosition ({ commit }, data) {
    commit(types.SET_BIBLE_SCROLL_POSITION, { data: data })
  },
  setChatScrollPosition ({ commit }, data) {
    commit(types.SET_CHAT_SCROLL_POSITION, { data: data })
  },
  setIfLoader ({ commit }, data) {
    commit(types.SET_IF_LOADER, { data: data })
  },
  setCompareData ({ commit }) {
    commit(types.SET_COMPARE_DATA)
  },
  setCompareVersion ({ commit }, data) {
    commit(types.SET_COMPARE_VERSION, { data: data })
  }
}
const mutations = {
  [types.SET_SCROLL_DATA] (state, { verId, chapterId }) {
    state.currentBookVerBookId = parseInt(verId)
    state.currentBookChapterId = parseInt(chapterId)
    const version = _.find(state.currentBookText, function (obj) { return parseInt(obj.id) === parseInt(verId) })
    if (version === undefined) {
      state.currentBookVerBookName = ''
      state.currentBookChapterNum = ''
    } else {
      state.currentBookVerBookName = version.name
      const indexVersion = _.indexOf(state.currentBookText, version)
      if (state.currentBookText[indexVersion].chapters === undefined) state.currentBookChapterNum = ''
      else state.currentBookChapterNum = _.find(state.currentBookText[indexVersion].chapters, function (obj) { return parseInt(obj.id) === parseInt(chapterId) }).num
    }
  },
  [types.SET_COMPARE_DATA] (state) {
    state.compareBookNotFoundPlaceholder = false
    state.compareChapterNotFoundPlaceholder = false
    state.ifLoaderState = true
    if (state.compareBookVersion === 0) {
      state.compareBookText = state.currentBookText
      state.compareBookVersion = state.currentBookVersion
      state.ifLoaderState = false
    } else if (state.compareBookVersion !== state.currentBookVersion) {
      bibleReadBook.getBooksVersions(state.compareBookVersion, (response) => {
        state.compareBookText = response.data
        const currentBook = _.find(state.currentBookText, function (obj) {
          return parseInt(obj.id) === parseInt(state.currentBookVerBookId)
        })
        let firstVersion = 0
        firstVersion = _.find(response.data, function (obj) { return parseInt(obj.index) === parseInt(currentBook.index) })
        if (firstVersion === undefined) {
          state.compareBookNotFoundPlaceholder = true
          state.ifLoaderState = false
          return
        }
        firstVersion = firstVersion.id
        bibleReadBook.getBooksChapterByVersion({ bookVer: state.compareBookVersion, verForChapter: firstVersion }, (response) => {
          const elem = _.find(state.compareBookText, function (obj) { return parseInt(obj.id) === parseInt(firstVersion) })
          const indexElem = _.indexOf(state.compareBookText, elem)
          state.compareBookText[indexElem].chapters = response.data
          const newElement = state.compareBookText[indexElem]
          state.compareBookText.splice(indexElem, 1, newElement)
          let firstChapter = 0
          const chapter = _.find(response.data, function (obj) { return parseInt(obj.num) === parseInt(state.currentBookChapterNum) })
          if (chapter === undefined) {
            state.compareChapterNotFoundPlaceholder = true
            state.ifLoaderState = false
            return
          }
          firstChapter = chapter.id
          bibleReadBook.getBooksVersesByChapter({ bookVer: state.compareBookVersion, verForChapterText: firstChapter }, (response) => {
            const elem = _.find(state.compareBookText, function (obj) { return parseInt(obj.id) === parseInt(firstVersion) })
            const elemIndex = _.indexOf(state.compareBookText, elem)
            const secElem = _.find(elem.chapters, function (obj) { return parseInt(obj.id) === parseInt(firstChapter) })
            const secIndex = _.indexOf(elem.chapters, secElem)
            state.compareBookText[elemIndex].chapters[secIndex].chapterText = response.data
            const newElement = state.compareBookText[elemIndex]
            state.compareBookText.splice(elemIndex, 1, newElement)
            state.ifLoaderState = false
          })
        })
      })
    } else {
      state.compareBookText = state.currentBookText
      state.compareBookVersion = state.currentBookVersion
      state.ifLoaderState = false
    }
  },
  [types.SET_ANIMATION_ID] (state, { id }) {
    state.animationVersesId = id
  },
  [types.SET_CHAT_SCROLL_POSITION] (state, { data }) {
    state.chatScrollPosition = data
  },
  [types.SET_COMPARE_VERSION] (state, { data }) {
    state.compareBookVersion = data
    state.compareBookText = []
  },
  [types.SET_IF_LOADER] (state, { data }) {
    state.ifLoaderState = data
  },
  [types.SET_BIBLE_SCROLL_POSITION] (state, { data }) {
    state.bibleScrollPosition = data
  },
  [types.SET_EDIT_NOTES_STATUS] (state, { status }) {
    state.editNotesStatus = status
  },
  [types.SET_EDIT_NOTE] (state, { data }) {
    state.editedNote = data
  },
  [types.SET_CURRENT_BOOK_SHORT_NAME] (state, { name }) {
    state.currentBookVersion = name
    state.currentBookId = _.find(state.translationsList, function (obj) {
      return parseInt(obj.version) === parseInt(name)
    }).id
  },
  [types.INIT_BOOK_TEXT] (state) {
    state.currentBookText = []
  },
  [types.SET_CURRENT_BOOK_CONTENT] (state, { content }) {
    state.currentBookText = content
  },
  [types.SET_CHAPTERS_FOR_VER] (state, { content, ver }) {
    const elem = _.find(state.currentBookText, function (obj) { return parseInt(obj.id) === parseInt(ver) })
    const indexElem = _.findIndex(state.currentBookText, elem)
    state.currentBookText[indexElem].chapters = content
    const newElement = state.currentBookText[indexElem]
    state.currentBookText.splice(indexElem, 1, newElement)
  },
  [types.SET_TEXT_FOR_CHAPTERS] (state, { content, verChapter, version }) {
    const elem = _.find(state.currentBookText, function (obj) { return parseInt(obj.id) === parseInt(version) })
    const elemIndex = _.findIndex(state.currentBookText, elem)
    const secElem = _.find(elem.chapters, function (obj) { return parseInt(obj.id) === parseInt(verChapter) })
    const secIndex = _.findIndex(elem.chapters, secElem)
    state.currentBookText[elemIndex].chapters[secIndex].chapterText = content
    const newElement = state.currentBookText[elemIndex]
    state.currentBookText.splice(elemIndex, 1, newElement)
  },
  [types.SET_BOOK_BOOKMARK] (state, { content }) {
    state.bookMarksForBooks = []
    state.bookMarksForBooks.push(...content)
  },
  [types.SET_BOOK_NOTES] (state, { content }) {
    state.notesForBooks = []
    for (let i = 0; i < content.length; i++) {
      content[i].info = JSON.parse(content[i].info)
      state.notesForBooks.push(content[i])
    }
  },
  [types.SET_BOOK_MARKERS] (state, { content }) {
    state.markersForBooks = []
    // for (var i = 0; i < content.length; i++) {
    //   state.markersForBooks.push(content[i])
    // }
    state.markersForBooks.push(...content)
  },
  [types.SET_TRANSLATIONS_LIST] (state, { content }) {
    state.translationsList = content
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
