export const newRegisterState = [
  {
    name: 'first_name',
    type: 'text',
    label: 'First Name',
    validator: {
      required: true,
      max_length: 100,
      messages: {
        required: 'The First Name is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: true
  },
  {
    name: 'last_name',
    type: 'text',
    label: 'Last Name',
    validator: {
      required: true,
      max_length: 100,
      messages: {
        required: 'The Last Name is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: true
  },
  {
    name: 'email',
    type: 'text',
    label: 'Email',
    validator: {
      required: true,
      max_length: 128,
      must_match: true,
      pattern: '/^([^@\\s]+)@((?:[-a-z0-9]+\\.)+[a-z]{2,})$/i',
      messages: {
        required: 'The Email is required.',
        invalid: 'The e-mail address you have entered is incorrect.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: true,
    keyboard: 'email'
  },
  {
    name: 'password',
    type: 'password',
    label: 'Password',
    validator: {
      required: true,
      min_length: 7,
      messages: {
        required: 'The Password is required.',
        invalid: "The password you've entered is incorrect. It must be more than 7 characters long and hold both letters and digits.",
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: true
  },
  {
    name: 'marital_status',
    type: 'select',
    label: 'Marital Status',
    validator: {
      required: false,
      multiple: false,
      messages: {
        required: 'Required.',
        invalid: 'Invalid.',
        min: 'At least %min% values must be selected (%count% values selected).',
        max: 'At most %max% values must be selected (%count% values selected).'
      }
    },
    required: false,
    default: 0,
    values: [
      {
        id: 0,
        value: 'Optional'
      },
      {
        id: 1,
        value: 'Child/Yth'
      },
      {
        id: 2,
        value: 'Married'
      },
      {
        id: 3,
        value: 'Divorced'
      },
      {
        id: 4,
        value: 'Separated'
      },
      {
        id: 5,
        value: 'Single'
      },
      {
        id: 6,
        value: 'Widow/Widower'
      },
      {
        id: 7,
        value: 'Engaged'
      }
    ]
  },
  {
    name: 'gender',
    type: 'select',
    label: 'Gender',
    validator: {
      required: false,
      multiple: false,
      messages: {
        required: 'Required.',
        invalid: 'Invalid.',
        min: 'At least %min% values must be selected (%count% values selected).',
        max: 'At most %max% values must be selected (%count% values selected).'
      }
    },
    required: false,
    default: 10,
    values: [
      {
        id: 10,
        value: 'Optional'
      },
      {
        id: 0,
        value: 'Female'
      },
      {
        id: 1,
        value: 'Male'
      }
    ]
  },
  {
    name: 'date_of_birth',
    type: 'date',
    label: 'Birthday',
    validator: {
      required: false,
      with_time: false,
      date_output: 'Y-m-d',
      datetime_output: 'Y-m-d H:i:s',
      date_format_error: 'YYYY-MM-DD',
      date_format_range_error: 'd/m/Y H:i:s',
      messages: {
        required: 'Required.',
        invalid: 'Invalid.',
        bad_format: '"%value%" does not match the date format (%date_format%).',
        max: 'The date must be before %max%.',
        min: 'The date must be after %min%.'
      }
    },
    required: false
  },
  {
    name: 'street',
    type: 'text',
    label: 'Street',
    validator: {
      required: true,
      max_length: 128,
      messages: {
        required: 'The Street is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: true
  },
  {
    name: 'city',
    type: 'text',
    label: 'City',
    validator: {
      required: true,
      max_length: 64,
      messages: {
        required: 'The City is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: true
  },
  {
    name: 'state',
    type: 'text',
    label: 'State',
    validator: {
      required: false,
      max_length: 64,
      messages: {
        required: 'The State is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: false,
    multivalue: {
      US: {
        AL: 'Alabama',
        AK: 'Alaska',
        AS: 'American Samoa',
        AZ: 'Arizona',
        AR: 'Arkansas',
        CA: 'California',
        CO: 'Colorado',
        CT: 'Connecticut',
        DE: 'Delaware',
        DC: 'District Of Columbia',
        FM: 'Federated States Of Micronesia',
        FL: 'Florida',
        GA: 'Georgia',
        GU: 'Guam',
        HI: 'Hawaii',
        ID: 'Idaho',
        IL: 'Illinois',
        IN: 'Indiana',
        IA: 'Iowa',
        KS: 'Kansas',
        KY: 'Kentucky',
        LA: 'Louisiana',
        ME: 'Maine',
        MH: 'Marshall Islands',
        MD: 'Maryland',
        MA: 'Massachusetts',
        MI: 'Michigan',
        MN: 'Minnesota',
        MS: 'Mississippi',
        MO: 'Missouri',
        MT: 'Montana',
        NE: 'Nebraska',
        NV: 'Nevada',
        NH: 'New Hampshire',
        NJ: 'New Jersey',
        NM: 'New Mexico',
        NY: 'New York',
        NC: 'North Carolina',
        ND: 'North Dakota',
        MP: 'Northern Mariana Islands',
        OH: 'Ohio',
        OK: 'Oklahoma',
        OR: 'Oregon',
        PW: 'Palau',
        PA: 'Pennsylvania',
        PR: 'Puerto Rico',
        RI: 'Rhode Island',
        SC: 'South Carolina',
        SD: 'South Dakota',
        TN: 'Tennessee',
        TX: 'Texas',
        UT: 'Utah',
        VT: 'Vermont',
        VI: 'Virgin Islands',
        VA: 'Virginia',
        WA: 'Washington',
        WV: 'West Virginia',
        WI: 'Wisconsin',
        WY: 'Wyoming'
      }
    }
  },
  {
    name: 'country',
    type: 'select',
    label: 'Country',
    validator: {
      required: 'The Country is required.',
      multiple: false,
      messages: {
        required: 'Required.',
        invalid: 'Invalid.',
        min: 'At least %min% values must be selected (%count% values selected).',
        max: 'At most %max% values must be selected (%count% values selected).'
      }
    },
    required: 'The Country is required.',
    default: 'US',
    values: [
      {
        id: 'US',
        value: 'United States'
      },
      {
        id: 'AF',
        value: 'Afghanistan'
      },
      {
        id: 'AL',
        value: 'Albania'
      },
      {
        id: 'DZ',
        value: 'Algeria'
      },
      {
        id: 'AS',
        value: 'American Samoa'
      },
      {
        id: 'AD',
        value: 'Andorra'
      },
      {
        id: 'AO',
        value: 'Angola'
      },
      {
        id: 'AI',
        value: 'Anguilla'
      },
      {
        id: 'AQ',
        value: 'Antarctica'
      },
      {
        id: 'AG',
        value: 'Antigua and Barbuda'
      },
      {
        id: 'AR',
        value: 'Argentina'
      },
      {
        id: 'AM',
        value: 'Armenia'
      },
      {
        id: 'AW',
        value: 'Aruba'
      },
      {
        id: 'AU',
        value: 'Australia'
      },
      {
        id: 'AT',
        value: 'Austria'
      },
      {
        id: 'AZ',
        value: 'Azerbaijan'
      },
      {
        id: 'BS',
        value: 'Bahamas'
      },
      {
        id: 'BH',
        value: 'Bahrain'
      },
      {
        id: 'BD',
        value: 'Bangladesh'
      },
      {
        id: 'BB',
        value: 'Barbados'
      },
      {
        id: 'BY',
        value: 'Belarus'
      },
      {
        id: 'BE',
        value: 'Belgium'
      },
      {
        id: 'BZ',
        value: 'Belize'
      },
      {
        id: 'BJ',
        value: 'Benin'
      },
      {
        id: 'BM',
        value: 'Bermuda'
      },
      {
        id: 'BT',
        value: 'Bhutan'
      },
      {
        id: 'BO',
        value: 'Bolivia'
      },
      {
        id: 'BQ',
        value: 'Bonaire'
      },
      {
        id: 'BA',
        value: 'Bosnia and Herzegovina'
      },
      {
        id: 'BW',
        value: 'Botswana'
      },
      {
        id: 'BV',
        value: 'Bouvet Island'
      },
      {
        id: 'BR',
        value: 'Brazil'
      },
      {
        id: 'IO',
        value: 'British Indian Ocean Territory'
      },
      {
        id: 'BN',
        value: 'Brunei Darussalam'
      },
      {
        id: 'BG',
        value: 'Bulgaria'
      },
      {
        id: 'BF',
        value: 'Burkina Faso'
      },
      {
        id: 'BI',
        value: 'Burundi'
      },
      {
        id: 'KH',
        value: 'Cambodia'
      },
      {
        id: 'CM',
        value: 'Cameroon'
      },
      {
        id: 'CA',
        value: 'Canada'
      },
      {
        id: 'CV',
        value: 'Cape Verde'
      },
      {
        id: 'KY',
        value: 'Cayman Islands'
      },
      {
        id: 'CF',
        value: 'Central African Republic'
      },
      {
        id: 'TD',
        value: 'Chad'
      },
      {
        id: 'CL',
        value: 'Chile'
      },
      {
        id: 'CN',
        value: 'China'
      },
      {
        id: 'CX',
        value: 'Christmas Island'
      },
      {
        id: 'CC',
        value: 'Cocos (Keeling) Islands'
      },
      {
        id: 'CO',
        value: 'Colombia'
      },
      {
        id: 'KM',
        value: 'Comoros'
      },
      {
        id: 'CG',
        value: 'Congo'
      },
      {
        id: 'CD',
        value: 'Democratic Republic of the Congo'
      },
      {
        id: 'CK',
        value: 'Cook Islands'
      },
      {
        id: 'CR',
        value: 'Costa Rica'
      },
      {
        id: 'HR',
        value: 'Croatia'
      },
      {
        id: 'CU',
        value: 'Cuba'
      },
      {
        id: 'CY',
        value: 'Cyprus'
      },
      {
        id: 'CZ',
        value: 'Czech Republic'
      },
      {
        id: 'CI',
        value: "Cote d'Ivoire"
      },
      {
        id: 'DK',
        value: 'Denmark'
      },
      {
        id: 'DJ',
        value: 'Djibouti'
      },
      {
        id: 'DM',
        value: 'Dominica'
      },
      {
        id: 'DO',
        value: 'Dominican Republic'
      },
      {
        id: 'EC',
        value: 'Ecuador'
      },
      {
        id: 'EG',
        value: 'Egypt'
      },
      {
        id: 'SV',
        value: 'El Salvador'
      },
      {
        id: 'GQ',
        value: 'Equatorial Guinea'
      },
      {
        id: 'ER',
        value: 'Eritrea'
      },
      {
        id: 'EE',
        value: 'Estonia'
      },
      {
        id: 'ET',
        value: 'Ethiopia'
      },
      {
        id: 'FK',
        value: 'Falkland Islands (Malvinas)'
      },
      {
        id: 'FO',
        value: 'Faroe Islands'
      },
      {
        id: 'FJ',
        value: 'Fiji'
      },
      {
        id: 'FI',
        value: 'Finland'
      },
      {
        id: 'FR',
        value: 'France'
      },
      {
        id: 'GF',
        value: 'French Guiana'
      },
      {
        id: 'PF',
        value: 'French Polynesia'
      },
      {
        id: 'TF',
        value: 'French Southern Territories'
      },
      {
        id: 'GA',
        value: 'Gabon'
      },
      {
        id: 'GM',
        value: 'Gambia'
      },
      {
        id: 'GE',
        value: 'Georgia'
      },
      {
        id: 'DE',
        value: 'Germany'
      },
      {
        id: 'GH',
        value: 'Ghana'
      },
      {
        id: 'GI',
        value: 'Gibraltar'
      },
      {
        id: 'GR',
        value: 'Greece'
      },
      {
        id: 'GL',
        value: 'Greenland'
      },
      {
        id: 'GD',
        value: 'Grenada'
      },
      {
        id: 'GP',
        value: 'Guadeloupe'
      },
      {
        id: 'GU',
        value: 'Guam'
      },
      {
        id: 'GT',
        value: 'Guatemala'
      },
      {
        id: 'GG',
        value: 'Guernsey'
      },
      {
        id: 'GN',
        value: 'Guinea'
      },
      {
        id: 'GW',
        value: 'Guinea-Bissau'
      },
      {
        id: 'GY',
        value: 'Guyana'
      },
      {
        id: 'HT',
        value: 'Haiti'
      },
      {
        id: 'HM',
        value: 'Heard Island and McDonald Islands'
      },
      {
        id: 'VA',
        value: 'Holy See (Vatican City State)'
      },
      {
        id: 'HN',
        value: 'Honduras'
      },
      {
        id: 'HK',
        value: 'Hong Kong'
      },
      {
        id: 'HU',
        value: 'Hungary'
      },
      {
        id: 'IS',
        value: 'Iceland'
      },
      {
        id: 'IN',
        value: 'India'
      },
      {
        id: 'ID',
        value: 'Indonesia'
      },
      {
        id: 'IR',
        value: 'Iran'
      },
      {
        id: 'IQ',
        value: 'Iraq'
      },
      {
        id: 'IE',
        value: 'Ireland'
      },
      {
        id: 'IM',
        value: 'Isle of Man'
      },
      {
        id: 'IL',
        value: 'Israel'
      },
      {
        id: 'IT',
        value: 'Italy'
      },
      {
        id: 'JM',
        value: 'Jamaica'
      },
      {
        id: 'JP',
        value: 'Japan'
      },
      {
        id: 'JE',
        value: 'Jersey'
      },
      {
        id: 'JO',
        value: 'Jordan'
      },
      {
        id: 'KZ',
        value: 'Kazakhstan'
      },
      {
        id: 'KE',
        value: 'Kenya'
      },
      {
        id: 'KI',
        value: 'Kiribati'
      },
      {
        id: 'KP',
        value: "Democratic People's Republic of Korea"
      },
      {
        id: 'KR',
        value: 'Republic of Korea'
      },
      {
        id: 'KW',
        value: 'Kuwait'
      },
      {
        id: 'KG',
        value: 'Kyrgyzstan'
      },
      {
        id: 'LA',
        value: 'Lao'
      },
      {
        id: 'LV',
        value: 'Latvia'
      },
      {
        id: 'LB',
        value: 'Lebanon'
      },
      {
        id: 'LS',
        value: 'Lesotho'
      },
      {
        id: 'LR',
        value: 'Liberia'
      },
      {
        id: 'LY',
        value: 'Libya'
      },
      {
        id: 'LI',
        value: 'Liechtenstein'
      },
      {
        id: 'LT',
        value: 'Lithuania'
      },
      {
        id: 'LU',
        value: 'Luxembourg'
      },
      {
        id: 'MO',
        value: 'Macao'
      },
      {
        id: 'MK',
        value: 'Macedonia'
      },
      {
        id: 'MG',
        value: 'Madagascar'
      },
      {
        id: 'MW',
        value: 'Malawi'
      },
      {
        id: 'MY',
        value: 'Malaysia'
      },
      {
        id: 'MV',
        value: 'Maldives'
      },
      {
        id: 'ML',
        value: 'Mali'
      },
      {
        id: 'MT',
        value: 'Malta'
      },
      {
        id: 'MH',
        value: 'Marshall Islands'
      },
      {
        id: 'MQ',
        value: 'Martinique'
      },
      {
        id: 'MR',
        value: 'Mauritania'
      },
      {
        id: 'MU',
        value: 'Mauritius'
      },
      {
        id: 'YT',
        value: 'Mayotte'
      },
      {
        id: 'MX',
        value: 'Mexico'
      },
      {
        id: 'FM',
        value: 'Micronesia'
      },
      {
        id: 'MD',
        value: 'Moldova'
      },
      {
        id: 'MC',
        value: 'Monaco'
      },
      {
        id: 'MN',
        value: 'Mongolia'
      },
      {
        id: 'ME',
        value: 'Montenegro'
      },
      {
        id: 'MS',
        value: 'Montserrat'
      },
      {
        id: 'MA',
        value: 'Morocco'
      },
      {
        id: 'MZ',
        value: 'Mozambique'
      },
      {
        id: 'MM',
        value: 'Myanmar'
      },
      {
        id: 'NA',
        value: 'Namibia'
      },
      {
        id: 'NR',
        value: 'Nauru'
      },
      {
        id: 'NP',
        value: 'Nepal'
      },
      {
        id: 'NL',
        value: 'Netherlands'
      },
      {
        id: 'NC',
        value: 'New Caledonia'
      },
      {
        id: 'NZ',
        value: 'New Zealand'
      },
      {
        id: 'NI',
        value: 'Nicaragua'
      },
      {
        id: 'NE',
        value: 'Niger'
      },
      {
        id: 'NG',
        value: 'Nigeria'
      },
      {
        id: 'NU',
        value: 'Niue'
      },
      {
        id: 'NF',
        value: 'Norfolk Island'
      },
      {
        id: 'MP',
        value: 'Northern Mariana Islands'
      },
      {
        id: 'NO',
        value: 'Norway'
      },
      {
        id: 'OM',
        value: 'Oman'
      },
      {
        id: 'PK',
        value: 'Pakistan'
      },
      {
        id: 'PW',
        value: 'Palau'
      },
      {
        id: 'PS',
        value: 'Palestine'
      },
      {
        id: 'PA',
        value: 'Panama'
      },
      {
        id: 'PG',
        value: 'Papua New Guinea'
      },
      {
        id: 'PY',
        value: 'Paraguay'
      },
      {
        id: 'PE',
        value: 'Peru'
      },
      {
        id: 'PH',
        value: 'Philippines'
      },
      {
        id: 'PN',
        value: 'Pitcairn'
      },
      {
        id: 'PL',
        value: 'Poland'
      },
      {
        id: 'PT',
        value: 'Portugal'
      },
      {
        id: 'PR',
        value: 'Puerto Rico'
      },
      {
        id: 'QA',
        value: 'Qatar'
      },
      {
        id: 'RO',
        value: 'Romania'
      },
      {
        id: 'RU',
        value: 'Russian Federation'
      },
      {
        id: 'RW',
        value: 'Rwanda'
      },
      {
        id: 'RE',
        value: 'Reunion'
      },
      {
        id: 'BL',
        value: 'Saint Bartholemy'
      },
      {
        id: 'KN',
        value: 'Saint Kitts and Nevis'
      },
      {
        id: 'LC',
        value: 'Saint Lucia'
      },
      {
        id: 'MF',
        value: 'Saint Martin (French part)'
      },
      {
        id: 'PM',
        value: 'Saint Pierre and Miquelon'
      },
      {
        id: 'VC',
        value: 'Saint Vincent and the Grenadines'
      },
      {
        id: 'WS',
        value: 'Samoa'
      },
      {
        id: 'SM',
        value: 'San Marino'
      },
      {
        id: 'ST',
        value: 'Sao Tome and Principe'
      },
      {
        id: 'SA',
        value: 'Saudi Arabia'
      },
      {
        id: 'SN',
        value: 'Senegal'
      },
      {
        id: 'RS',
        value: 'Serbia'
      },
      {
        id: 'SC',
        value: 'Seychelles'
      },
      {
        id: 'SL',
        value: 'Sierra Leone'
      },
      {
        id: 'SG',
        value: 'Singapore'
      },
      {
        id: 'SX',
        value: 'Sint Maarten (Dutch part)'
      },
      {
        id: 'SK',
        value: 'Slovakia'
      },
      {
        id: 'SI',
        value: 'Slovenia'
      },
      {
        id: 'SB',
        value: 'Solomon Islands'
      },
      {
        id: 'SO',
        value: 'Somalia'
      },
      {
        id: 'ZA',
        value: 'South Africa'
      },
      {
        id: 'GS',
        value: 'South Georgia and the South Sandwich Islands'
      },
      {
        id: 'SS',
        value: 'South Sudan'
      },
      {
        id: 'ES',
        value: 'Spain'
      },
      {
        id: 'LK',
        value: 'Sri Lanka'
      },
      {
        id: 'SD',
        value: 'Sudan'
      },
      {
        id: 'SR',
        value: 'Suriname'
      },
      {
        id: 'SJ',
        value: 'Svalbard and Jan Mayen'
      },
      {
        id: 'SZ',
        value: 'Swaziland'
      },
      {
        id: 'SE',
        value: 'Sweden'
      },
      {
        id: 'CH',
        value: 'Switzerland'
      },
      {
        id: 'SY',
        value: 'Syrian Arab Republic'
      },
      {
        id: 'TW',
        value: 'Taiwan'
      },
      {
        id: 'TJ',
        value: 'Tajikistan'
      },
      {
        id: 'TZ',
        value: 'Tanzania'
      },
      {
        id: 'TH',
        value: 'Thailand'
      },
      {
        id: 'TL',
        value: 'Timor-Leste'
      },
      {
        id: 'TG',
        value: 'Togo'
      },
      {
        id: 'TK',
        value: 'Tokelau'
      },
      {
        id: 'TO',
        value: 'Tonga'
      },
      {
        id: 'TT',
        value: 'Trinidad and Tobago'
      },
      {
        id: 'TN',
        value: 'Tunisia'
      },
      {
        id: 'TR',
        value: 'Turkey'
      },
      {
        id: 'TM',
        value: 'Turkmenistan'
      },
      {
        id: 'TC',
        value: 'Turks and Caicos Islands'
      },
      {
        id: 'TV',
        value: 'Tuvalu'
      },
      {
        id: 'UG',
        value: 'Uganda'
      },
      {
        id: 'UA',
        value: 'Ukraine'
      },
      {
        id: 'AE',
        value: 'United Arab Emirates'
      },
      {
        id: 'GB',
        value: 'United Kingdom'
      },
      {
        id: 'UY',
        value: 'Uruguay'
      },
      {
        id: 'UZ',
        value: 'Uzbekistan'
      },
      {
        id: 'VU',
        value: 'Vanuatu'
      },
      {
        id: 'VE',
        value: 'Venezuela'
      },
      {
        id: 'VN',
        value: 'Viet Nam'
      },
      {
        id: 'VG',
        value: 'Virgin Islands, British'
      },
      {
        id: 'VI',
        value: 'Virgin Islands, U.S.'
      },
      {
        id: 'WF',
        value: 'Wallis and Futuna'
      },
      {
        id: 'EH',
        value: 'Western Sahara'
      },
      {
        id: 'YE',
        value: 'Yemen'
      },
      {
        id: 'ZM',
        value: 'Zambia'
      },
      {
        id: 'ZW',
        value: 'Zimbabwe'
      }
    ]
  },
  {
    name: 'zip',
    type: 'text',
    label: 'Postal Code',
    validator: {
      required: true,
      max_length: 32,
      messages: {
        required: 'The Postal Code is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: true
  },
  {
    name: 'phone_mobile',
    type: 'text',
    label: 'Mobile Phone',
    validator: {
      required: false,
      max_length: 30,
      messages: {
        required: 'The Mobile Phone is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: false,
    keyboard: 'numbers'
  },
  {
    name: 'bio',
    type: 'text',
    label: 'Bio',
    validator: {
      required: false,
      messages: {
        required: 'The Bio is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: false
  },
  {
    name: 'facebook',
    type: 'text',
    label: 'Facebook',
    validator: {
      required: false,
      messages: {
        required: 'The Facebook is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: false
  },
  {
    name: 'twitter',
    type: 'text',
    label: 'Twitter',
    validator: {
      required: false,
      messages: {
        required: 'The Twitter is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: false
  },
  {
    name: 'instagram',
    type: 'text',
    label: 'Instagram',
    validator: {
      required: false,
      messages: {
        required: 'The Instagram is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: false
  },
  {
    name: 'linkedin',
    type: 'text',
    label: 'Linkedin',
    validator: {
      required: false,
      messages: {
        required: 'The Linkedin is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: false
  },
  {
    name: 'snapchat',
    type: 'text',
    label: 'Snapchat',
    validator: {
      required: false,
      messages: {
        required: 'The Snapchat is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: false
  },
  {
    name: 'website',
    type: 'text',
    label: 'Website',
    validator: {
      required: false,
      must_match: true,
      protocols: [
        'http',
        'https',
        'ftp',
        'ftps'
      ],
      pattern: {},
      messages: {
        required: 'The Website is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: false
  },
  {
    name: 'google_plus',
    type: 'text',
    label: 'Google plus',
    validator: {
      required: false,
      messages: {
        required: 'The Google plus is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: false
  },
  {
    name: 'youtube',
    type: 'text',
    label: 'Youtube',
    validator: {
      required: false,
      must_match: true,
      protocols: [
        'http',
        'https',
        'ftp',
        'ftps'
      ],
      pattern: {},
      messages: {
        required: 'The Youtube is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: false
  },
  {
    name: 'telegram',
    type: 'text',
    label: 'Telegram',
    validator: {
      required: false,
      messages: {
        required: 'The Telegram is required.',
        invalid: 'Invalid.',
        max_length: '"%value%" is too long (%max_length% characters max).',
        min_length: '"%value%" is too short (%min_length% characters min).'
      }
    },
    required: false
  }
]
