// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
// require('./apiLoad')
import Vue from 'vue'
import VueYoutube from 'vue-youtube'
import App from './App.vue'
import store from './store/index'
import { router } from './router'
// import VeeValidate from 'vee-validate'
import VueCookie from 'vue-cookie'
import VueLocalStorage from 'vue-localstorage'
import * as VueGoogleMaps from 'vue2-google-maps'
import { VueMasonryPlugin } from 'vue-masonry'
// import VLazyImage from 'v-lazy-image/v2'
import SweetModal from 'sweet-modal-vue/src/plugin'
import VTooltip from 'v-tooltip'
import { widgetsToRoutes } from './constants/widgetToRouter'
import MobileDetect from 'mobile-detect'
import { MODE_MOBILE, MODE_PC, MODE_TABLET } from '../siteBuilder/src/store/constant/modeViewNames'
import { VLazyImagePlugin } from '@@@/assets/modules/v-lazy-image'
import VueLazyBackgroundImage from 'vue-lazy-background-images/VueLazyBackgroundImage'

Vue.use(VLazyImagePlugin)
Vue.component('LazyBackground', VueLazyBackgroundImage)

const index = () => import('@@@@/components/index.vue')

Vue.config.productionTip = false
// const page404 = () => import('@@@@/components/notFoundPage.vue')

Vue.use(VueCookie)
Vue.use(VueYoutube)
Vue.use(VTooltip)
Vue.use(SweetModal)
Vue.use(VueLocalStorage)

require('intersection-observer')

// Vue.use(VLazyImage)
// Vue.use(VeeValidate)
Vue.use(VueCookie)

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCHD0K1v6ceapYfL7wyY4Org7h-vSPXsk4',
    libraries: 'places',
    language: 'en'
  }
})
Vue.use(require('vue-shortkey'))
Vue.use(VueMasonryPlugin)
function handleClick (el, vnode, binding, ev) {
  if (el !== undefined) {
    if (!el.contains(ev.target)) {
      if (Boolean(binding.value) !== false) {
        const els = document.getElementsByClassName(binding.value)
        let check = false
        for (let i = 0; i < els.length; i++) {
          if (els[i].contains(ev.target)) check = true
        }
        if (!check) vnode.context[binding.arg] = false
      } else {
        vnode.context[binding.arg] = false
      }
    }
  }
}

Vue.directive('click-outside', {
  inserted: function (el, binding, vnode) {
    document.addEventListener('click', handleClick.bind('event', el, vnode, binding))
  },
  unbind: function () {
    document.removeEventListener('click', handleClick)
  }
})

Vue.directive('focus', {
  inserted: function (el) {
    el.focus()
  }
})
const routes = []
new Vue({
  store,
  router,
  components: { App },
  mounted: function () {
    this.$router.addRoutes(routes)
  },
  beforeCreate: async function () {
    const md = new MobileDetect(window.navigator.userAgent)
    const mobile = md.mobile()
    const tablet = md.tablet()
    const adaptiveMode = tablet ? MODE_TABLET : MODE_MOBILE
    const mode = !(mobile || tablet) ? MODE_PC : adaptiveMode
    this.$store.dispatch('updateViewMode', mode)
    const ticketsData = this.$localStorage.get('ticketsData')
    const eventsData = this.$localStorage.get('eventsData')
    if (ticketsData !== undefined && ticketsData !== null) {
      this.$store.dispatch('setTicketsCount', JSON.parse(ticketsData))
    }
    if (eventsData !== undefined && eventsData !== null) {
      this.$store.dispatch('updateShoppingCartEventsData', JSON.parse(eventsData))
    }
    const script = document.createElement('script')
    script.setAttribute('src', 'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit')
    script.setAttribute('async', '')
    script.setAttribute('defer', '')
    document.getElementsByTagName('head')[0].appendChild(script)
    const scripts = document.getElementsByTagName('script')
    let needed = ''
    let sitePages = []
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].dataset) {
        if (scripts[i].dataset.type === 'tree') needed = scripts[i]
        if (scripts[i].dataset.type === 'pages') sitePages = JSON.parse(scripts[i].textContent)
      }
    }
    const site = JSON.parse(needed.textContent)
    // function initSeoSettings (pageTitle, pageDesc, pageMeta) {
    //   document.title = (pageTitle === '') ? site.siteSettings.webSiteTitle : pageTitle
    //   let desc = (pageDesc === '') ? site.siteSettings.webSiteDescription : pageDesc
    //   let keywords = (pageMeta === '') ? site.siteSettings.metaKeyWords : pageMeta
    //   document.querySelector('meta[name="description"]').setAttribute('content', desc)
    //   document.querySelector('meta[name="keywords"]').setAttribute('content', keywords)
    // }
    this.$store.dispatch('updateGlobalFont', site.siteSettings.fontFamily)
    // })
    for (const i in sitePages) {
      if (sitePages[i].route !== '') {
        const url = sitePages[i].route[0] === '/' ? '' + sitePages[i].route : '/' + sitePages[i].route
        const name = '' + sitePages[i].route
        routes.push({
          path: url,
          name: name,
          component: index,
          beforeEnter: (to, from, next) => {
            // initSeoSettings(site.pages[i].pageSettings.pageTitle, site.pages[i].pageSettings.pageDescription, site.pages[i].pageSettings.metaKeyWords)
            next()
          }
        })
      }
    }
    const byDefaultPage = sitePages.find((obj) => obj.byDefault)
    const indexPage = sitePages.find((obj) => obj.route === 'index' || obj.route === '/')
    const [fistPage] = sitePages
    const def = byDefaultPage || indexPage || fistPage
    for (const i in sitePages) {
      if (sitePages[i].active && sitePages[i].widgets) {
        if (sitePages[i].widgets === '[\'500\']') {
          sitePages[i].widgets = '["500"]'
        } else if (sitePages[i].widgets === '[\'501\']') {
          sitePages[i].widgets = '["501"]'
        } else if (sitePages[i].widgets === '[\'403\']') {
          sitePages[i].widgets = '["403"]'
        } else if (sitePages[i].widgets === '[\'404\']') {
          sitePages[i].widgets = '["404"]'
        }
        const widgets = JSON.parse(sitePages[i].widgets)
        for (const j in widgets) {
          const presetRouteObject = widgetsToRoutes[widgets[j]]
          if (presetRouteObject !== undefined) {
            for (const j in presetRouteObject) {
              const url = `/${sitePages[i].route}${presetRouteObject[j].presetUrl}`
              const routeObject = {
                path: url,
                name: presetRouteObject[j].name,
                component: index,
                beforeEnter: (to, from, next) => {
                  // initSeoSettings(sitePages[i].pageSettings.pageTitle, sitePages[i].pageSettings.pageDescription, sitePages[i].pageSettings.metaKeyWords)
                  next()
                }
              }
              if (routeObject.path !== undefined) {
                routes.push(routeObject)
              }
            }
          }
        }
      }
    }
    routes.push({
      path: '/',
      name: `${def.route}Def`,
      component: index,
      beforeEnter: (to, from, next) => {
        // initSeoSettings(def.pageSettings.pageTitle, def.pageSettings.pageDescription, def.pageSettings.metaKeyWords)
        next()
      }
    })

    // routes.push()

    // routes.push({
    //   path: '*',
    //   component: index,
    //   name: 'page404',
    //   beforeEnter: (to, from, next) => {
    //     // initSeoSettings('404 ' + site.siteSettings.webSiteTitle, site.siteSettings.webSiteDescription, site.siteSettings.metaKeyWords)
    //     next()
    //   }
    // })
    this.$store.dispatch('setCurrentSite', site)
    site.pages = sitePages
  },
  template: '<App/>'
}).$mount('#app')
