export const SET_ALL_NEWS_DATA = 'SET_ALL_NEWS_DATA'
export const PUSH_NEW_NEWS_DATA = 'PUSH_NEW_NEWS_DATA'
export const SET_EVENT_DATA = 'SET_EVENT_DATA'
export const SET_CURRENT_NEWS_COUNT = 'SET_CURRENT_NEWS_COUNT'
export const PRESET_CAMPUSES = 'PRESET_CAMPUSES'
export const SET_NEWS_FILTER_QUERY = 'SET_NEWS_FILTER_QUERY'
export const SET_NEWS_SEARCH_QUERY = 'SET_NEWS_SEARCH_QUERY'
export const SET_INFINITE_LOADING_REF_NEWS = 'SET_INFINITE_LOADING_REF_NEWS'
export const SET_NOT_FOUND_NEWS = 'SET_NOT_FOUND_NEWS'
export const PUSH_NEW_POST_DATA = 'PUSH_NEW_POST_DATA'
export const SET_ACTIVE_POST = 'SET_ACTIVE_POST'
export const SET_NO_DATA_NEWS_POST = 'SET_NO_DATA_NEWS_POST'
export const PUSH_NEWS_POST_DATA = 'PUSH_NEWS_POST_DATA'
export const SET_NEWS_ACTIVE_POST = 'SET_NEWS_ACTIVE_POST'
export const PRESET_NEWS_IMAGE_UPDATE = 'PRESET_NEWS_IMAGE_UPDATE'
// export const SET_PRINT_STATUS = 'SET_PRINT_STATUS'
