import * as types from '@@@/store/constant/mutationsTypes'

const state = {
  setModalMediaContent: false,
  openModalSaveSmartTemplate: false,
  smartTemplateSection: {},
  imageUrlModal: ''
}

const getters = {
  setModalMediaContent: state => state.setModalMediaContent,
  imageUrlModal: state => state.imageUrlModal,
  openModalSaveSmartTemplate: state => state.openModalSaveSmartTemplate,
  smartTemplateSection: state => state.smartTemplateSection
}

const actions = {
  updateModalMediaContent ({ commit }, data) {
    commit(types.SET_MODAL_MEDIA_CONTENT, data)
  },
  updateImageUrlModal ({ commit }, data) {
    commit(types.SET_URL_MEDIA_CONTENT, data)
  },
  updateModalSaveSmartTemplate ({ commit }, data) {
    commit(types.UPDATE_MODAL_SAVE_SMART_TEMPLATE, data)
  },
  updateSmartTemplateSection ({ commit }, data) {
    commit(types.UPDATE_SMART_TEMPLATE_SECTION, data)
  }
}

const mutations = {
  [types.SET_MODAL_MEDIA_CONTENT] (state, data) {
    state.setModalMediaContent = data
  },
  [types.SET_URL_MEDIA_CONTENT] (state, data) {
    state.imageUrlModal = data
  },
  [types.UPDATE_MODAL_SAVE_SMART_TEMPLATE] (state, data) {
    state.openModalSaveSmartTemplate = data
  },
  [types.UPDATE_SMART_TEMPLATE_SECTION] (state, data) {
    state.smartTemplateSection = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
