const state = {
  userFormInfo: {},
  authStatus: false,
  savedForm: []
}

const getters = {
  userFormInfo: state => state.userFormInfo,
  authStatus: state => state.authStatus,
  savedForm: state => state.savedForm
}
const actions = {
  setAuthStatus ({ commit }, data) {
    commit('setAuthStatus', data)
  },
  setUserFormInfo ({ commit }, data) {
    commit('setUserFormInfo', data)
  },
  setSavedForm ({ commit }, data) {
    commit('setSavedForm', data)
  }
}
const mutations = {
  setAuthStatus (state, data) {
    state.authStatus = data
  },
  setUserFormInfo (state, data) {
    state.userFormInfo = data
  },
  setSavedForm (state, data) {
    state.savedForm = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
