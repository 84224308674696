import currentTimeZone from './modules/currentTimeZone'
// import generalSettingsWidgetPublic from './modules/generalSettingsWidgetPublic'
// import ifLoader from './modules/ifLoader'
import modals from './modules/modals'
import organizationSettings from './modules/organizationSettings'
import profilePhoto from './modules/profilePhoto'
import socialAuth from './modules/socialAuth'
import userPanel from './modules/userPanel'

const modules = {
  currentTimeZone,
  // generalSettingsWidgetPublic,
  // ifLoader,
  modals,
  organizationSettings,
  profilePhoto,
  socialAuth,
  userPanel
}

export default modules
