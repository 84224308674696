import * as types from './../../constants/store/mutations-types'
import _ from 'lodash'

const state = {
  readingPlanListAll: [],
  currentReadingPlanCount: 1,
  readingPlanFilterQuery: '',
  readingPlanSearchQuery: '',
  infiniteLoadingRefReadingPlan: false,
  notFoundReadingPlan: false,
  noDataReadingPlanPost: false,
  presetReadingPlanImg: false,
  readinPlanListSetTimeoutId: false
  // printStatus: false
}

const getters = {
  readingPlanListAll: state => state.readingPlanListAll,
  currentReadingPlanCount: state => state.currentReadingPlanCount,
  readingPlanFilterQuery: state => state.readingPlanFilterQuery,
  readingPlanSearchQuery: state => state.readingPlanSearchQuery,
  infiniteLoadingRefReadingPlan: state => state.infiniteLoadingRefReadingPlan,
  notFoundReadingPlan: state => state.notFoundReadingPlan,
  noDataReadingPlanPost: state => state.noDataReadingPlanPost,
  presetReadingPlanImg: state => state.presetReadingPlanImg
  // printStatus: state => state.printStatus
}
const actions = {
  setAllReadingPlanData ({ commit }, data) {
    commit(types.SET_ALL_READING_PLAN_DATA, { data: data })
  },
  pushNewReadingPlanData ({ commit }, data) {
    commit(types.PUSH_NEW_READING_PLAN_DATA, { data: data })
  },
  setCurrentReadingPlanCount ({ commit }, data) {
    commit(types.SET_CURRENT_READING_PLAN_COUNT, { data: data })
  },
  setReadingPlanFilterQuery ({ commit }, data) {
    commit(types.SET_READING_PLAN_FILTER_QUERY, data)
  },
  setReadingPlanSearchQuery ({ commit }, data) {
    commit(types.SET_READING_PLAN_SEARCH_QUERY, data)
  },
  setInfiniteLoadingRefReadingPlan ({ commit }, data) {
    commit(types.SET_INFINITE_LOADING_REF_READING_PLAN, data)
  },
  setNotFoundReadingPlan ({ commit }, data) {
    commit(types.SET_NOT_FOUND_READING_PLAN, data)
  },
  setNoDataReadingPlanPost ({ commit }, data) {
    commit(types.SET_NO_DATA_READING_PLAN_POST, data)
  },
  presetReadingPlanImageUpdate ({ commit }, data) {
    commit(types.PRESET_READING_PLAN_IMAGE_UPDATE, data)
  }
  // setPrintStatus ({commit}, data) {
  //   commit(types.SET_PRINT_STATUS, data)
  // }
}
const mutations = {
  [types.SET_ALL_READING_PLAN_DATA] (state, { data }) {
    if (data.length > 0) {
      state.readingPlanListAll = data.items
    } else {
      state.readingPlanListAll = []
    }
  },
  [types.PUSH_NEW_READING_PLAN_DATA] (state, { data }) {
    clearTimeout(state.readinPlanListSetTimeoutId)
    for (let i = 0; i < data.items.length; i++) {
      if (_.find(state.readingPlanListAll, function (obj) {
        return obj.id === data.items[i].id
      }) === undefined) {
        if (data.items[i].description === undefined) {
          data.items[i].description = ''
        }
        state.readinPlanListSetTimeoutId = setTimeout(function () {
          state.readingPlanListAll.push(data.items[i])
        }, i * 100)
      }
    }
  },
  [types.SET_CURRENT_READING_PLAN_COUNT] (state, { data }) {
    state.currentReadingPlanCount = data
  },
  [types.SET_READING_PLAN_FILTER_QUERY] (state, data) {
    state.readingPlanFilterQuery = data
  },
  [types.SET_READING_PLAN_SEARCH_QUERY] (state, data) {
    state.readingPlanSearchQuery = data
  },
  [types.SET_INFINITE_LOADING_REF_READING_PLAN] (state, data) {
    state.infiniteLoadingRefReadingPlan = data
  },
  [types.SET_NOT_FOUND_READING_PLAN] (state, data) {
    state.notFoundReadingPlan = data
  },
  [types.SET_NO_DATA_READING_PLAN_POST] (state, data) {
    state.noDataReadingPlanPost = data
  },
  [types.PRESET_READING_PLAN_IMAGE_UPDATE] (state, data) {
    state.presetReadingPlanImg = data
  }
  // [types.SET_PRINT_STATUS] (state, data) {
  //   state.printStatus = data
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
