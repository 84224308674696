import { CHECKBOX } from '../../../types/fieldsTypes'

export default {
  title: 'Untitled',
  name: 'Untitled',
  type: CHECKBOX,
  group: 'regular',
  properties: {
    options: {
      values: [
        {
          id: 'optional',
          title: 'Optional Field'
        },
        {
          id: 'required',
          title: 'Required Field'
        },
        {
          id: 'randomize',
          title: 'Randomize',
          description: '...'
        },
        {
          id: 'allow_other',
          title: 'Allow Other',
          description: '...'
        },
        {
          id: 'max_quantity',
          title: 'Max Quantities',
          description: 'Only this number of checks is available'
        }
      ],
      selected: {
        id: [
          'optional'
        ]
      }
    },
    visibility: {
      values: [
        {
          id: 'everyone',
          title: 'Everyone',
          description: 'Every person could fill your form'
        },
        {
          id: 'admin',
          title: 'Admin Only'
        },
        {
          id: 'registered',
          title: 'Registered Users Only'
        },
        {
          id: 'custom_category',
          title: 'Custom Category Only',
          description: 'Visible only to this category of users'
        }
      ],
      selected: {
        id: 'everyone',
        categories: []
      }
    },
    layout: {
      description: '...',
      values: [
        {
          id: 'one_column',
          title: 'One Column'
        },
        {
          id: 'two_column',
          title: 'Two Columns'
        },
        {
          id: 'three_column',
          title: 'Three Columns'
        },
        {
          id: 'side_by_side',
          title: 'Side by Side'
        }
      ],
      selected: {
        id: 'one_column'
      }
    },
    choices: {
      description: '...',
      selected: [
        {
          maxQuantity: null,
          label: 'Option 1',
          default: false
        },
        {
          maxQuantity: null,
          label: 'Option 2',
          default: false
        },
        {
          maxQuantity: null,
          label: 'Option 3',
          default: false
        }
      ]
    },
    instructions: {
      selected: ' '
    },
    system: {
      selected: false
    }
  }
}
