import * as types from '../constant/mutationsTypes'

const state = {
  currentSite: {},
  currentPage: {},
  routeLeave: false,
  siteData: false,
  googleFonts: []
}

const getters = {
  currentSite: state => state.currentSite,
  routeLeave: state => state.routeLeave,
  currentPage: state => state.currentPage,
  siteData: state => state.siteData,
  googleFonts: state => state.googleFonts
}

const actions = {
  setCurrentSite ({ commit }, data) {
    commit(types.SET_CURRENT_SITE, data)
  },
  setRouteLeave ({ commit }, data) {
    commit(types.SET_ROUTE_LEAVE, data)
  },
  setCurrentPage ({ commit }, data) {
    commit(types.SET_CURRENT_PAGE, data)
  },
  setSiteData ({ commit }, data) {
    commit(types.SET_SITE_DATA, data)
  },
  setGoogleFonts ({ commit }, data) {
    commit(types.SET_GOOGLE_FONTS, { data })
  }
}

const mutations = {
  [types.SET_CURRENT_SITE] (state, data) {
    console.log('data', data)
    state.currentSite = data
  },
  [types.SET_ROUTE_LEAVE] (state, data) {
    state.routeLeave = data
  },
  [types.SET_CURRENT_PAGE] (state, data) {
    state.currentPage = data
  },
  [types.SET_SITE_DATA] (state, data) {
    state.siteData = data
  },
  [types.SET_GOOGLE_FONTS] (state, { data }) {
    state.googleFonts = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
