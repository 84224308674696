export default {
  title: 'Untitled',
  type: 'date',
  name: 'Untitled',
  group: 'advanced',
  properties: {
    options: {
      values: [
        {
          id: 'optional',
          title: 'Optional Field'
        },
        {
          id: 'required',
          title: 'Required Field'
        },
        {
          id: 'no_duplicates',
          title: 'No Duplicates',
          description: 'No duplicates are available'
        }
      ],
      selected: {
        id: ['optional']
      }
    },
    visibility: {
      values: [
        {
          id: 'everyone',
          title: 'Everyone',
          description: 'Every person could fill your form'
        },
        {
          id: 'admin',
          title: 'Admin Only'
        },
        {
          id: 'registered',
          title: 'Registered Users Only'
        },
        {
          id: 'custom_category',
          title: 'Custom Category Only',
          description: 'Visible only to this category of users'
        }
      ],
      selected: {
        id: 'everyone',
        categories: []
      }
    },
    format: {
      description: '...',
      values: [
        {
          id: 'ddmmyyyy',
          title: 'DD / MM / YYYY'
        },
        {
          id: 'mmddyyyy',
          title: 'MM / DD / YYYY'
        }
      ],
      selected: {
        id: 'mmddyyyy'
      }
    },
    predefined: {
      selected: ''
    },
    instructions: {
      selected: ''
    },
    system: {
      selected: false
    }
  }
}
