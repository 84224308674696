export const MODE_TABLET = 'MODE_TABLET'
export const MODE_MOBILE = 'MODE_MOBILE'
export const MODE_PC = 'MODE_PC'

export const LIST_MODES = [
  {
    mode: MODE_PC,
    icon: 'icon-media-desktop'
  },
  {
    mode: MODE_TABLET,
    icon: 'icon-media-tablet'
  },
  {
    mode: MODE_MOBILE,
    icon: 'icon-media-mobile'
  }]
