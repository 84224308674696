import * as types from '../mutations-types'
import _ from 'lodash'
const moment = require('moment-timezone')

const state = {
  scheduleList: [],
  confirmationDisplayed: false,
  currentLiveChannelsCount: 0,
  itemChekedList: [],
  currentItemId: 0,
  chekedList: [],
  currentTimeZoneNameSermon: moment.tz.zone(moment.tz.guess()).name,
  currentTimeZoneSermon: -moment.tz.zone(moment.tz.guess()).parse(new Date()) / 60,
  firstLoadingSchedule: false,
  fullLoadingSchedule: false
}

const getters = {
  scheduleList: state => state.scheduleList,
  confirmationDisplayed: state => state.confirmationDisplayed,
  currentLiveChannelsCount: state => state.currentLiveChannelsCount,
  itemChekedList: state => state.itemChekedList,
  chekedList: state => state.chekedList,
  currentTimeZoneSermon: state => state.currentTimeZoneSermon,
  currentTimeZoneNameSermon: state => state.currentTimeZoneNameSermon,
  firstLoadingSchedule: state => state.firstLoadingSchedule,
  fullLoadingSchedule: state => state.fullLoadingSchedule
}
const actions = {
  loadDataSchedule ({ commit }, data) {
    commit(types.LOAD_DATA_SCHEDULE, { data: data })
  },
  confirmationDisplayedToggle ({ commit }, { status, item }) {
    commit(types.CONFIRMATION_DISPLAYED_TOGGLE, { status: status, item: item })
  },
  confirmationDisplayedSave ({ commit }, { status, item }) {
    commit(types.CONFIRMATION_DISPLAYED_SAVE, { status: status, item: item })
  },
  editTimeZoneSermon ({ commit }, { name, offset }) {
    commit(types.EDIT_TIME_ZONE_SERMON, { name: name, offset: offset })
  },
  setFirstLoadingSchedule ({ commit }) {
    commit(types.SET_FIRST_LOADING_SCHEDULE)
  },
  setFullLoadingSchedule ({ commit }, data) {
    commit(types.SET_FULL_LOADING_SCHEDULE, data)
  }
}
const mutations = {
  [types.LOAD_DATA_SCHEDULE] (state, { data }) {
    state.currentLiveChannelsCount = state.currentLiveChannelsCount + data.length
    state.scheduleList = data
  },
  [types.CONFIRMATION_DISPLAYED_TOGGLE] (state, { status, item }) {
    state.confirmationDisplayed = status
    if (status === true) {
      if (state.itemChekedList.indexOf(item) < 0) state.itemChekedList.push(item)
    }
    state.currentItemId = item.id
  },
  [types.CONFIRMATION_DISPLAYED_SAVE] (state, { status, item }) {
    state.confirmationDisplayed = status
    const itemListId = _.find(state.itemChekedList, function (obj) {
      return obj.id === state.currentItemId
    })
    if (state.chekedList.indexOf(item) < 0) state.chekedList.push({ id: itemListId.id })
  },
  [types.EDIT_TIME_ZONE_SERMON] (state, { name, offset }) {
    state.currentTimeZoneSermon = offset
    state.currentTimeZoneNameSermon = name
  },
  [types.SET_FIRST_LOADING_SCHEDULE] (state) {
    state.firstLoadingSchedule = true
  },
  [types.SET_FULL_LOADING_SCHEDULE] (state, data) {
    state.fullLoadingSchedule = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
