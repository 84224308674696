export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const TOGGLE_LOADER = 'TOGGLE_LOADER'
export const AUTHORIZATION = 'AUTHORIZATION'
export const SET_USER_DATA = 'SET_USER_DATA'
export const LOGOUT = 'LOGOUT'
export const REGISTER_STATE = 'REGISTER_STATE'
export const SET_USER_REGISTER_OBJ = 'SET_USER_REGISTER_OBJ'
export const SET_USER_REGISTER_MAIL = 'SET_USER_REGISTER_MAIL'
export const TOGGLE_TAB = 'TOGGLE_TAB'
export const REGISTER_USER = 'REGISTER_USER'
export const CHANGE_PHOTO = 'CHANGE_PHOTO'
export const TABS_CONTENT = 'TABS_CONTENT'
export const TABS_CONTENT_SCHEDULE = 'TABS_CONTENT_SCHEDULE'
export const LOAD_VIDEO_LIST = 'LOAD_VIDEO_LIST'
export const PROGRAM_LIST = 'PROGRAM_LIST'
export const PROGRAM_TOTAL = 'PROGRAM_TOTAL'
export const RELOAD_VIDEO_LIST = 'RELOAD_VIDEO_LIST'
export const RELOAD_SERIES_LIST = 'RELOAD_SERIES_LIST'
export const SAVE_PLAYBACK_AUDIO = 'SAVE_PLAYBACK_AUDIO'
export const SET_LOVER_LIGHT = 'SET_LOVER_LIGHT'
export const LOAD_SLIDER_VIDEO_LIST = 'LOAD_SLIDER_VIDEO_LIST'
export const LOAD_ONE_VIDEO_ITEM = 'LOAD_ONE_VIDEO_ITEM'
export const PUSH_NEW_VIDEO_DATA = 'PUSH_NEW_VIDEO_DATA'
export const VIDEO_CONTENT = 'VIDEO_CONTENT'
export const LOAD_FAVORITES_DATA = 'LOAD_FAVORITES_DATA'
export const ADD_FAVORITES_DATA = 'ADD_FAVORITES_DATA'
export const REMOVE_FAVORITES_DATA = 'REMOVE_FAVORITES_DATA'
export const SET_ACTIVE_TAB_LEFT_SIDEBAR = 'SET_ACTIVE_TAB_LEFT_SIDEBAR'
export const SET_FROM_PLAYER_STATE = 'SET_FROM_PLAYER_STATE'
export const START_PAGE_GO_OUT = 'START_PAGE_GO_OUT'
export const VIEW_PAGE_GO_OUT = 'VIEW_PAGE_GO_OUT'
export const START_PAGE_IN = 'START_PAGE_IN'
export const VIEW_PAGE_IN = 'VIEW_PAGE_IN'
export const START_PAGE_EMPTY = 'START_PAGE_EMPTY'
export const VIEW_PAGE_EMPTY = 'VIEW_PAGE_EMPTY'
export const PUSH_NEW_SERIES = 'PUSH_NEW_SERIES'
export const SET_SERIES_LIST = 'SET_SERIES_LIST'
export const SET_VIEW_MODEL = 'SET_VIEW_MODEL'
export const SET_SERMONS_FOR_SERIE = 'SET_SERMONS_FOR_SERIE'
export const SET_VIEW_ACTIVE_MODEL = 'SET_VIEW_ACTIVE_MODEL'
export const UPDATE_GENERAL_SETTINGS = 'UPDATE_GENERAL_SETTINGS'
export const UPDATE_LOGO = 'UPDATE_LOGO'
export const SET_EMBED = 'SET_EMBED'
export const SET_NOT_EMBED = 'SET_NOT_EMBED'
export const HARD_MODAL = 'HARD_MODAL'
export const CHANGE_PLAYER_SHOW = 'CHANGE_PLAYER_SHOW'
export const CHANGE_POPOUT_STATUS = 'CHANGE_POPOUT_STATUS'
export const SET_ACTIVE_IDS = 'SET_ACTIVE_IDS'
export const SET_DOMEN = 'SET_DOMEN'
export const SAVE_PLAYBACK = 'SET_DOMEN'
export const SET_SORTING_STRING = 'SET_SORTING_STRING'
export const SET_SERIES_SORTING_STRING = 'SET_SORTING_STRING'
export const SET_FILTER_SERMONS = 'SET_FILTER_SERMONS'
export const SET_FILTER_SERIES = 'SET_FILTER_SERIES'
export const PRESET_IMAGE = 'PRESET_IMAGE'
export const PRESET_IMAGE_SERIES = 'PRESET_IMAGE_SERIES'
export const PRESET_IMAGE_MW = 'PRESET_IMAGE_MW'
export const PRESET_CAMPUSES = 'PRESET_CAMPUSES'
export const SET_MOST_VIEWED = 'SET_MOST_VIEWED'
export const SET_PLAYER_ENTITY = 'SET_PLAYER_ENTITY'
export const LOAD_BOOKMARKS_DATA = 'LOAD_BOOKMARKS_DATA'
export const LOAD_ALL_BOOKMARKS_DATA = 'LOAD_ALL_BOOKMARKS_DATA'
export const ADD_BOOKMARKS_DATA = 'ADD_BOOKMARKS_DATA'
export const REMOVE_BOOKMARKS_DATA = 'REMOVE_BOOKMARKS_DATA'
export const SET_PLAY_ALL_BOOKMARKS = 'SET_PLAY_ALL_BOOKMARKS'
export const VIEW_PAGE_RELATED_SERMONS = 'VIEW_PAGE_RELATED_SERMONS'
export const SET_BOOKMARK_CLICKED = 'SET_BOOKMARK_CLICKED'
export const TOGGLE_CHECK_VIDEO_PLAY = 'TOGGLE_CHECK_VIDEO_PLAY'
export const TOGGLE_ADD_BOOKMARKS = 'TOGGLE_ADD_BOOKMARKS'
export const SETTINGS_BOOKMARKS_TIME = 'SETTINGS_BOOKMARKS_TIME'
export const LOAD_DATA_SCHEDULE = 'LOAD_DATA_SCHEDULE'
export const CONFIRMATION_DISPLAYED_TOGGLE = 'CONFIRMATION_DISPLAYED_TOGGLE'
export const CONFIRMATION_DISPLAYED_SAVE = 'CONFIRMATION_DISPLAYED_SAVE'
export const EDIT_TIME_ZONE_SERMON = 'EDIT_TIME_ZONE_SERMON'
export const VIEW_BOOKMARKS_TYPE = 'VIEW_BOOKMARKS_TYPE'
export const CLEAR_LIST_ALL_BOOKMARKS = 'CLEAR_LIST_ALL_BOOKMARKS'
export const FIRST_PLAY_TIMER_COUNT = 'FIRST_PLAY_TIMER_COUNT'
export const TOOGLE_TAB_CONTROLS = 'TOOGLE_TAB_CONTROLS'
export const SET_CURRENT_BOOK_CONTENT = 'SET_CURRENT_BOOK_CONTENT'
export const SET_BOOK_BOOKMARK = 'SET_BOOK_BOOKMARK'
export const SET_BOOK_NOTES = 'SET_BOOK_NOTES'
export const SET_BOOK_MARKERS = 'SET_BOOK_MARKERS'
export const SET_TRANSLATIONS_LIST = 'SET_TRANSLATIONS_LIST'
export const INIT_BOOK_TEXT = 'INIT_BOOK_TEXT'
export const SET_CHAPTERS_FOR_VER = 'SET_CHAPTERS_FOR_VER'
export const SET_TEXT_FOR_CHAPTERS = 'SET_TEXT_FOR_CHAPTERS'
export const SET_SCROLL_DATA = 'SET_SCROLL_DATA'
export const SET_ANIMATION_ID = 'SET_ANIMATION_ID'
export const ENABLE_TAB_BIBLE_STATE = 'ENABLE_TAB_BIBLE_STATE'
export const ENABLE_TAB_CHAT_STATE = 'ENABLE_TAB_CHAT_STATE'
export const SET_ACTIVE_BOOKMARK_ID = 'SET_ACTIVE_BOOKMARK_ID'
export const SET_ACTIVE_NOTE_ID = 'SET_ACTIVE_NOTE_ID'
export const SET_CURRENT_BOOK_SHORT_NAME = 'SET_CURRENT_BOOK_SHORT_NAME'
export const SET_EDIT_NOTES_STATUS = 'SET_EDIT_NOTES_STATUS'
export const SET_EDIT_NOTE = 'SET_EDIT_NOTE'
export const SET_BIBLE_SCROLL_POSITION = 'SET_BIBLE_SCROLL_POSITION'
export const SET_IF_LOADER = 'SET_IF_LOADER'
export const SET_COMPARE_DATA = 'SET_COMPARE_DATA'
export const SET_COMPARE_VERSION = 'SET_COMPARE_VERSION'
export const SET_CHAT_SCROLL_POSITION = 'SET_CHAT_SCROLL_POSITION'
export const SET_FIRST_LOADING_SCHEDULE = 'SET_FIRST_LOADING_SCHEDULE'
export const SET_FULL_LOADING_SCHEDULE = 'SET_FULL_LOADING_SCHEDULE'
export const SET_FIRST_LOADING_MAP = 'SET_FIRST_LOADING_MAP'
export const UPDATE_PLAYER_SETTINGS = 'UPDATE_PLAYER_SETTINGS'
export const UPDATE_PLAYER_CAPTIONS = 'UPDATE_PLAYER_CAPTIONS'
export const UPDATE_PERSONAL_SETTINGS = 'UPDATE_PERSONAL_SETTINGS'
export const SET_CURRENT_TRANSCRIPT = 'SET_CURRENT_TRANSCRIPT'
export const SET_TRANSCRIPT_LANG = 'SET_TRANSCRIPT_LANG'
export const SET_APP_LANG = 'SET_APP_LANG'
export const SET_CURRENT_VIDEO_TRANSCRIPT_LANG = 'SET_CURRENT_VIDEO_TRANSCRIPT_LANG'
export const SET_FAVORITES_LIST_DATA = 'SET_FAVORITES_LIST_DATA'
export const UPDATE_GENERAL_SETTINGS_SB = 'UPDATE_GENERAL_SETTINGS_SB'
export const SET_GENERAL_SETTINGS_SB = 'SET_GENERAL_SETTINGS_SB'
export const SET_GLOBAL_COLORS_MAIN = 'SET_GLOBAL_COLORS_MAIN'
export const UPDATE_CURRENT_MODE_VIEW_PAGE = 'UPDATE_CURRENT_MODE_VIEW_PAGE'
export const UPDATE_VIEW_MODE = 'UPDATE_VIEW_MODE'
