export const SET_ALL_NEWS_HORIZONTAL_DATA = 'SET_ALL_NEWS_HORIZONTAL_DATA'
export const PUSH_NEW_NEWS_HORIZONTAL_DATA = 'PUSH_NEW_NEWS_HORIZONTAL_DATA'
export const SET_EVENT_DATA = 'SET_EVENT_DATA'
export const SET_CURRENT_NEWS_HORIZONTAL_COUNT = 'SET_CURRENT_NEWS_HORIZONTAL_COUNT'
export const PRESET_CAMPUSES = 'PRESET_CAMPUSES'
export const SET_NEWS_HORIZONTAL_FILTER_QUERY = 'SET_NEWS_HORIZONTAL_FILTER_QUERY'
export const SET_NEWS_HORIZONTAL_SEARCH_QUERY = 'SET_NEWS_HORIZONTAL_SEARCH_QUERY'
export const SET_INFINITE_LOADING_REF_NEWS_HORIZONTAL = 'SET_INFINITE_LOADING_REF_NEWS_HORIZONTAL'
export const SET_NOT_FOUND_NEWS_HORIZONTAL = 'SET_NOT_FOUND_NEWS_HORIZONTAL'
export const PUSH_NEW_POST_DATA = 'PUSH_NEW_POST_DATA'
export const SET_ACTIVE_POST = 'SET_ACTIVE_POST'
export const SET_NO_DATA_NEWS_HORIZONTAL_POST = 'SET_NO_DATA_NEWS_HORIZONTAL_POST'
export const PUSH_NEWS_HORIZONTAL_POST_DATA = 'PUSH_NEWS_HORIZONTAL_POST_DATA'
export const SET_NEWS_HORIZONTAL_ACTIVE_POST = 'SET_NEWS_HORIZONTAL_ACTIVE_POST'
export const PRESET_NEWS_HORIZONTAL_IMAGE_UPDATE = 'PRESET_NEWS_HORIZONTAL_IMAGE_UPDATE'
export const SET_ALL_BLOG_HORIZONTAL_DATA = 'SET_ALL_BLOG_HORIZONTAL_DATA'
export const PUSH_NEW_BLOG_HORIZONTAL_DATA = 'PUSH_NEW_BLOG_HORIZONTAL_DATA'
export const SET_CURRENT_BLOG_HORIZONTAL_COUNT = 'SET_CURRENT_BLOG_HORIZONTAL_COUNT'
export const SET_BLOG_HORIZONTAL_FILTER_QUERY = 'SET_BLOG_HORIZONTAL_FILTER_QUERY'
export const SET_BLOG_HORIZONTAL_SEARCH_QUERY = 'SET_BLOG_HORIZONTAL_SEARCH_QUERY'
export const SET_INFINITE_LOADING_REF_BLOG_HORIZONTAL = 'SET_INFINITE_LOADING_REF_BLOG_HORIZONTAL'
export const SET_NOT_FOUND_BLOG_HORIZONTAL = 'SET_NOT_FOUND_BLOG_HORIZONTAL'
export const SET_NO_DATA_BLOG_HORIZONTAL_POST = 'SET_NO_DATA_BLOG_HORIZONTAL_POST'
export const PUSH_BLOG_HORIZONTAL_POST_DATA = 'PUSH_BLOG_HORIZONTAL_POST_DATA'
export const SET_BLOG_HORIZONTAL_ACTIVE_POST = 'SET_BLOG_HORIZONTAL_ACTIVE_POST'
export const PRESET_BLOG_HORIZONTAL_IMAGE_UPDATE = 'PRESET_BLOG_HORIZONTAL_IMAGE_UPDATE'
export const SET_ALL_SERMONS_HORIZONTAL_DATA = 'SET_ALL_SERMONS_HORIZONTAL_DATA'
export const PUSH_NEW_SERMONS_HORIZONTAL_DATA = 'PUSH_NEW_SERMONS_HORIZONTAL_DATA'
export const SET_CURRENT_SERMONS_HORIZONTAL_COUNT = 'SET_CURRENT_SERMONS_HORIZONTAL_COUNT'
export const SET_SERMONS_HORIZONTAL_FILTER_QUERY = 'SET_SERMONS_HORIZONTAL_FILTER_QUERY'
export const SET_SERMONS_HORIZONTAL_SEARCH_QUERY = 'SET_SERMONS_HORIZONTAL_SEARCH_QUERY'
export const SET_INFINITE_LOADING_REF_SERMONS_HORIZONTAL = 'SET_INFINITE_LOADING_REF_SERMONS_HORIZONTAL'
export const SET_NOT_FOUND_SERMONS_HORIZONTAL = 'SET_NOT_FOUND_SERMONS_HORIZONTAL'
export const SET_NO_DATA_SERMONS_HORIZONTAL_POST = 'SET_NO_DATA_SERMONS_HORIZONTAL_POST'
export const PUSH_SERMONS_HORIZONTAL_POST_DATA = 'PUSH_SERMONS_HORIZONTAL_POST_DATA'
export const SET_SERMONS_HORIZONTAL_ACTIVE_POST = 'SET_SERMONS_HORIZONTAL_ACTIVE_POST'
export const PRESET_SERMONS_HORIZONTAL_IMAGE_UPDATE = 'PRESET_SERMONS_HORIZONTAL_IMAGE_UPDATE'
export const SET_ACTIVE_SERMON_FILTER = 'SET_ACTIVE_SERMON_FILTER'
export const SET_ALL_MINISTRIES_HORIZONTAL_DATA = 'SET_ALL_MINISTRIES_HORIZONTAL_DATA'
export const PUSH_NEW_MINISTRIES_HORIZONTAL_DATA = 'PUSH_NEW_MINISTRIES_HORIZONTAL_DATA'
export const SET_CURRENT_MINISTRIES_HORIZONTAL_COUNT = 'SET_CURRENT_MINISTRIES_HORIZONTAL_COUNT'
export const SET_MINISTRIES_HORIZONTAL_FILTER_QUERY = 'SET_MINISTRIES_HORIZONTAL_FILTER_QUERY'
export const SET_MINISTRIES_HORIZONTAL_SEARCH_QUERY = 'SET_MINISTRIES_HORIZONTAL_SEARCH_QUERY'
export const SET_INFINITE_LOADING_REF_MINISTRIES_HORIZONTAL = 'SET_INFINITE_LOADING_REF_MINISTRIES_HORIZONTAL'
export const SET_NOT_FOUND_MINISTRIES_HORIZONTAL = 'SET_NOT_FOUND_MINISTRIES_HORIZONTAL'
export const SET_NO_DATA_MINISTRIES_HORIZONTAL_POST = 'SET_NO_DATA_MINISTRIES_HORIZONTAL_POST'
export const PUSH_MINISTRIES_HORIZONTAL_POST_DATA = 'PUSH_MINISTRIES_HORIZONTAL_POST_DATA'
export const SET_MINISTRIES_HORIZONTAL_ACTIVE_POST = 'SET_MINISTRIES_HORIZONTAL_ACTIVE_POST'
export const PRESET_MINISTRIES_HORIZONTAL_IMAGE_UPDATE = 'PRESET_MINISTRIES_HORIZONTAL_IMAGE_UPDATE'
export const SET_ALL_EVENTS_HORIZONTAL_DATA = 'SET_ALL_EVENTS_HORIZONTAL_DATA'
export const PUSH_NEW_EVENTS_HORIZONTAL_DATA = 'PUSH_NEW_EVENTS_HORIZONTAL_DATA'
export const SET_CURRENT_EVENTS_HORIZONTAL_COUNT = 'SET_CURRENT_EVENTS_HORIZONTAL_COUNT'
export const SET_EVENTS_HORIZONTAL_FILTER_QUERY = 'SET_EVENTS_HORIZONTAL_FILTER_QUERY'
export const SET_EVENTS_HORIZONTAL_SEARCH_QUERY = 'SET_EVENTS_HORIZONTAL_SEARCH_QUERY'
export const SET_INFINITE_LOADING_REF_EVENTS_HORIZONTAL = 'SET_INFINITE_LOADING_REF_EVENTS_HORIZONTAL'
export const SET_NOT_FOUND_EVENTS_HORIZONTAL = 'SET_NOT_FOUND_EVENTS_HORIZONTAL'
export const SET_NO_DATA_EVENTS_HORIZONTAL_POST = 'SET_NO_DATA_EVENTS_HORIZONTAL_POST'
export const PUSH_EVENTS_HORIZONTAL_POST_DATA = 'PUSH_EVENTS_HORIZONTAL_POST_DATA'
export const SET_EVENTS_HORIZONTAL_ACTIVE_POST = 'SET_EVENTS_HORIZONTAL_ACTIVE_POST'
export const PRESET_EVENTS_HORIZONTAL_IMAGE_UPDATE = 'PRESET_EVENTS_HORIZONTAL_IMAGE_UPDATE'
export const SET_ALL_READING_PLAN_HORIZONTAL_DATA = 'SET_ALL_EVENTS_HORIZONTAL_DATA'
export const PUSH_NEW_READING_PLAN_HORIZONTAL_DATA = 'PUSH_NEW_READING_PLAN_HORIZONTAL_DATA'
export const SET_CURRENT_READING_PLAN_HORIZONTAL_COUNT = 'SET_CURRENT_READING_PLAN_HORIZONTAL_COUNT'
export const SET_READING_PLAN_HORIZONTAL_FILTER_QUERY = 'SET_READING_PLAN_HORIZONTAL_FILTER_QUERY'
export const SET_READING_PLAN_HORIZONTAL_SEARCH_QUERY = 'SET_READING_PLAN_HORIZONTAL_SEARCH_QUERY'
export const SET_INFINITE_LOADING_REF_READING_PLAN_HORIZONTAL = 'SET_INFINITE_LOADING_REF_READING_PLAN_HORIZONTAL'
export const SET_NOT_FOUND_READING_PLAN_HORIZONTAL = 'SET_NOT_FOUND_READING_PLAN_HORIZONTAL'
export const SET_NO_DATA_READING_PLAN_HORIZONTAL_POST = 'SET_NO_DATA_READING_PLAN_HORIZONTAL_POST'
export const PUSH_READING_PLAN_HORIZONTAL_POST_DATA = 'PUSH_READING_PLAN_HORIZONTAL_POST_DATA'
export const SET_READING_PLAN_HORIZONTAL_ACTIVE_POST = 'SET_READING_PLAN_HORIZONTAL_ACTIVE_POST'
export const PRESET_READING_PLAN_HORIZONTAL_IMAGE_UPDATE = 'PRESET_READING_PLAN_HORIZONTAL_IMAGE_UPDATE'
// export const SET_PRINT_STATUS = 'SET_PRINT_STATUS'
