export const SET_ALL_BLOG_DATA = 'SET_ALL_BLOG_DATA'
export const PUSH_NEW_BLOG_DATA = 'PUSH_NEW_BLOG_DATA'
export const SET_EVENT_DATA = 'SET_EVENT_DATA'
export const SET_CURRENT_BLOG_COUNT = 'SET_CURRENT_BLOG_COUNT'
export const PRESET_CAMPUSES = 'PRESET_CAMPUSES'
export const SET_BLOG_FILTER_QUERY = 'SET_BLOG_FILTER_QUERY'
export const SET_BLOG_SEARCH_QUERY = 'SET_BLOG_SEARCH_QUERY'
export const SET_INFINITE_LOADING_REF_BLOG = 'SET_INFINITE_LOADING_REF_BLOG'
export const SET_NOT_FOUND_BLOG = 'SET_NOT_FOUND_BLOG'
export const PUSH_NEW_POST_DATA = 'PUSH_NEW_POST_DATA'
export const SET_ACTIVE_POST = 'SET_ACTIVE_POST'
export const SET_NO_DATA_BLOG_POST = 'SET_NO_DATA_BLOG_POST'
export const PRESET_BLOG_IMAGE_UPDATE = 'PRESET_BLOG_IMAGE_UPDATE'
// export const SET_PRINT_STATUS = 'SET_PRINT_STATUS'
