export default {
  getForm: state => {
    return JSON.parse(JSON.stringify(state.form))
  },
  customFields: state => state.customFields,
  getField: state => {
    const field = state.form.fields[state.activeFieldIndex]
    if (field) {
      return JSON.parse(JSON.stringify(field))
    }
  },
  formId: state => state.formId,
  ifLoaderForm: state => state.ifLoaderForm
}
