const layout = () => import('@@@/components/fields/settings/Layout.vue')
const variables = () => import('@@@/components/fields/settings/Variables.vue')
const animation = () => import('@@@/components/fields/settings/Animation.vue')
const bg = () => import('@@@/components/fields/settings/Background.vue')
const typography = () => import('@@@/components/fields/settings/Typography.vue')
const headerSettings = () => import('@@@/components/fields/settings/HeaderSettings.vue')
const footerSettings = () => import('@@@/components/fields/settings/FooterSettings')
const shape = () => import('@@@/components/fields/settings/Shape.vue')
const general = () => import('@@@/components/fields/settings/General.vue')
const linkTo = () => import('@@@/components/fields/settings/LinkTo.vue')
const linkToFw = () => import('@@@/components/fields/settings/LinkToFw.vue')
const social = () => import('@@@/components/fields/settings/SocialSettings.vue')
const slider = () => import('@@@/components/fields/settings/SliderSettings.vue')
const slidersSettings = () => import('@@@/components/fields/settings/slidersSettings.vue')
const menu = () => import('@@@/components/fields/settings/MenuSettings.vue')
const menuStyle = () => import('@@@/components/fields/settings/MenuStyle.vue')
const tableSettings = () => import('@@@/components/fields/settings/TableSettings.vue')
const saveAsTemplateSettings = () => import('@@@/components/fields/settings/SaveAsTemplate.vue')
const formTemplate = () => import('@@@/components/fields/settings/FormSettings')
const eventsWidgetSettings = () => import('@@@/components/fields/settings/widgets/EventsWidgetSettings')
const layoutWidgetSettings = () => import('@@@/components/fields/settings/widgets/layoutWidgetSettings')
const blogWidgetSettings = () => import('@@@/components/fields/settings/widgets/BlogWidgetSettings')
const blogCompactWidgetSettings = () => import('@@@/components/fields/settings/widgets/BlogCompactWidgetSettings')
const eventsCompactWidgetSettings = () => import('@@@/components/fields/settings/widgets/eventsCompactWidgetSettings')
const newsWidgetSettings = () => import('@@@/components/fields/settings/widgets/NewsWidgetSettings')
const newsCompactWidgetSettings = () => import('@@@/components/fields/settings/widgets/NewsCompactWidgetSettings')
const devotionsWidgetSettings = () => import('@@@/components/fields/settings/widgets/DevotionsWidgetSettings')
const devotionsCompactWidgetSettings = () => import('@@@/components/fields/settings/widgets/DevotionsCompactWidgetSettings')
const readingPlanWidgetSettings = () => import('@@@/components/fields/settings/widgets/ReadingPlanWidgetSettings')
const readingPlanCompactWidgetSettings = () => import('@@@/components/fields/settings/widgets/ReadingPlanCompactWidgetSettings')
const photoWidgetSettings = () => import('@@@/components/fields/settings/widgets/PhotoWidgetSettings')
const photoCompactWidgetSettings = () => import('@@@/components/fields/settings/widgets/PhotoCompactWidgetSettings')
const visitorWidgetSettings = () => import('@@@/components/fields/settings/widgets/visitorWidgetSettings')
const watchUsOnTvWidgetSettings = () => import('@@@/components/fields/settings/widgets/watchUsOnTvWidgetSettings')
const subscribeWidgetSettings = () => import('@@@/components/fields/settings/widgets/visitorWidgetSettings')
const welcomeWidgetSettings = () => import('@@@/components/fields/settings/widgets/welcomeWidgetSettings')
const mobileAppWidgetSettings = () => import('@@@/components/fields/settings/widgets/appWidgetSettings')
const tvAppWidgetSettings = () => import('@@@/components/fields/settings/widgets/appWidgetSettings')
const appPageWidgetSettings = () => import('@@@/components/fields/settings/widgets/appWidgetSettings')
const giveWidgetSettings = () => import('@@@/components/fields/settings/widgets/standardWidgetTemplateSettings')
const contentItemSettings = () => import('@@@/components/fields/settings/widgets/standardWidgetTemplateSettings')
const streamWidgetSettings = () => import('@@@/components/fields/settings/widgets/standardWidgetTemplateSettings')
const sermonsWidgetSettings = () => import('@@@/components/fields/settings/widgets/standardListWidgetTemplateSettings')
const latestWidgetSettings = () => import('@@@/components/fields/settings/widgets/latestWidgetTemplateSettings')
const generalHeaderSettings = () => import('@@@/components/fields/settings/GeneralHeaderSettings')
const generalInputsSettings = () => import('@@@/components/fields/settings/GeneralInputs')
const generalButtonFwSettings = () => import('@@@/components/fields/settings/GeneralButtonFwSettings')
const contactUsWidgetSettings = () => import('@@@/components/fields/settings/widgets/ContactUsWidgetSettings')
const contactUsWidgetStyleSettings = () => import('@@@/components/fields/settings/widgets/styleSettings/styleContactUsWidget')
const aboutUsWidgetSettings = () => import('@@@/components/fields/settings/widgets/AboutUsWidgetSettings')
const aboutUsWidgetStyleSettings = () => import('@@@/components/fields/settings/widgets/styleSettings/styleAboutUsWidget')
const ministriesWidgetSettings = () => import('@@@/components/fields/settings/widgets/MinistriesWidgetSettings')
const ministriesWidgetStyleSettings = () => import('@@@/components/fields/settings/widgets/styleSettings/styleMinistriesWidget')
const staffWidgetSettings = () => import('@@@/components/fields/settings/widgets/StaffWidgetSettings')
const staffWidgetStyleSettings = () => import('@@@/components/fields/settings/widgets/styleSettings/styleStaffWidget')
const accordionSettings = () => import('@@@/components/fields/settings/AccordionSettings')
const fwPresetSettings = () => import('@@@/components/fields/settings/frameworks/fwPresetSettings')
const generalTabsSettings = () => import('@@@/components/fields/settings/GeneralTabsSettings')
const iframeSettings = () => import('@@@/components/fields/settings/IframeSettings')
const sectionTemplateSettings = () => import('@@@/components/fields/settings/sectionTemplateSettings')
const privacyPolicyWidgetSettings = () => import('@@@/components/fields/settings/widgets/privacyPolicyWidgetSettings')
const privacyPolicyWidgetStyleSettings = () => import('@@@/components/fields/settings/widgets/styleSettings/stylePrivacyPolicyWidget')
const eventsCompactSubSettings = () => import('@@@/components/fields/settings/widgets/styleSettings/eventsCompactSubSettings')
const dropdownSettings = () => import('@@@/components/fields/settings/DropDownSettings')
const mapSettings = () => import('@@@/components/fields/settings/mapSettings')
const visibleAdaptiveSettings = () => import('@@@/components/fields/settings/visibleAdaptiveSettings')
const widgetStyleSettings = () => import('@@@/components/fields/settings/widgets/styleSettings/styleWidget')
const widgetStyle = () => import('@@@/components/fields/settings/widgets/styleSettings/widgetStyle')
const standardHorizontalWidgetSettings = () => import('@@@/components/fields/settings/widgets/standardHorizontalWidgetSettings')
const setCustomClass = () => import('@@@/components/fields/settings/setCustomClass')
const filters = () => import('@@@/components/fields/settings/filters')
const colorOverlay = () => import('@@@/components/fields/settings/colorOverlay')
const smartAssistant = () => import('@@@/components/fields/settings/SmartAssistant')
const sectionBackground = () => import('@@@/components/fields/settings/sectionBackground')
const blockTemplateSettings = () => import('@@@/components/fields/settings/blockTemplateSettings')
const latestWidgetStyleSettings = () => import('@@@/components/fields/settings/latestWidgetStyleSettings')
const widgetSettings = () => import('@@@/components/fields/settings/widgets/widgetSettings')
const nightModeSettings = () => import('@@@/components/fields/settings/widgets/NightModeSettings')
const analyticSettings = () => import('@@@/components/fields/settings/widgets/AnalyticSettings')

const CTAWidgetSettings = () => import('@@@/components/fields/settings/widgets/CTAWidgetSettings')
const QRSettings = () => import('@@@/components/fields/settings/widgets/styleSettings/styleQRElement')
const WidgetElementSettings = () => import('@@@/components/fields/settings/widgets/styleSettings/styleWidgetImageElement')

export const NIGHT_MODE_SETTINGS = 'NIGHT_MODE_SETTINGS'
export const ANALYTIC_SETTINGS = 'ANALYTIC_SETTINGS'
export const LAYOUT_SETTINGS = 'LAYOUT_SETTINGS'
export const VARIABLES_SETTINGS = 'VARIABLES_SETTINGS'
export const ANIMATION_SETTINGS = 'ANIMATION_SETTINGS'
export const BACKGROUND_SETTINGS = 'BACKGROUND_SETTINGS'
export const TYPOGRAPHY_SETTINGS = 'TYPOGRAPHY_SETTINGS'
export const HEADER_SETTINGS = 'HEADER_SETTINGS'
export const FOOTER_SETTINGS = 'FOOTER_SETTINGS'
export const SHAPE_SETTINGS = 'SHAPE_SETTINGS'
export const GENERAL_SETTINGS = 'GENERAL_SETTINGS'
export const FORM_SETTINGS = 'FORM_SETTINGS'
export const LINK_TO_SETTINGS = 'LINK_TO_SETTINGS'
export const LINK_TO_FW_SETTINGS = 'LINK_TO_FW_SETTINGS'
export const SOCIAL_SETTINGS = 'SOCIAL_SETTINGS'
export const SUB_TYPE_SOCIAL_PANEL = 'SUB_TYPE_SOCIAL_PANEL'
export const SLIDER_SETTINGS = 'SLIDER_SETTINGS'
export const SLIDERS_SETTINGS = 'SLIDERS_SETTINGS'
export const MENU_SETTINGS = 'MENU_SETTINGS'
export const EVENTS_WIDGET_SETTINGS = 'EVENTS_WIDGET_SETTINGS'
export const MENU_STYLE = 'MENU_STYLE'
export const TABLE_SETTINGS = 'TABLE_SETTINGS'
export const SAVE_TEMPLATE_SETTINGS = 'SAVE_TEMPLATE_SETTINGS'
export const SECTION_TEMPLATE_SETTINGS = 'SECTION_TEMPLATE_SETTINGS'
export const LAYOUT_WIDGET_SETTINGS = 'LAYOUT_WIDGET_SETTINGS'
export const BLOG_WIDGET_SETTINGS = 'BLOG_WIDGET_SETTINGS'
export const BLOG_WIDGET_STYLE_SETTINGS = 'BLOG_WIDGET_STYLE_SETTINGS'
export const BLOG_COMPACT_WIDGET_SETTINGS = 'BLOG_COMPACT_WIDGET_SETTINGS'
export const BLOG_COMPACT_WIDGET_STYLE_SETTINGS = 'BLOG_COMPACT_WIDGET_STYLE_SETTINGS'
export const EVENTS_WIDGET_STYLE_SETTINGS = 'EVENTS_WIDGET_STYLE_SETTINGS'
export const EVENTS_COMPACT_WIDGET_STYLE_SETTINGS = 'EVENTS_COMPACT_WIDGET_STYLE_SETTINGS'
export const EVENTS_COMPACT_WIDGET_SETTINGS = 'EVENTS_COMPACT_WIDGET_SETTINGS'
export const ACCORDION_SETTINGS = 'ACCORDION_SETTINGS'
export const NEWS_WIDGET_SETTINGS = 'NEWS_WIDGET_SETTINGS'
export const NEWS_WIDGET_STYLE_SETTINGS = 'NEWS_WIDGET_STYLE_SETTINGS'
export const NEWS_COMPACT_WIDGET_SETTINGS = 'NEWS_COMPACT_WIDGET_SETTINGS'
export const NEWS_COMPACT_WIDGET_STYLE_SETTINGS = 'NEWS_COMPACT_WIDGET_STYLE_SETTINGS'
export const DEVOTIONS_WIDGET_SETTINGS = 'DEVOTIONS_WIDGET_SETTINGS'
export const DEVOTIONS_WIDGET_STYLE_SETTINGS = 'DEVOTIONS_WIDGET_STYLE_SETTINGS'
export const DEVOTIONS_COMPACT_WIDGET_SETTINGS = 'DEVOTIONS_COMPACT_WIDGET_SETTINGS'
export const DEVOTIONS_COMPACT_WIDGET_STYLE_SETTINGS = 'DEVOTIONS_COMPACT_WIDGET_STYLE_SETTINGS'
export const READING_PLAN_WIDGET_SETTINGS = 'READING_PLAN_WIDGET_SETTINGS'
export const READING_PLAN_WIDGET_STYLE_SETTINGS = 'READING_PLAN_WIDGET_STYLE_SETTINGS'
export const READING_PLAN_COMPACT_WIDGET_SETTINGS = 'READING_PLAN_COMPACT_WIDGET_SETTINGS'
export const READING_PLAN_COMPACT_WIDGET_STYLE_SETTINGS = 'READING_PLAN_COMPACT_WIDGET_STYLE_SETTINGS'
export const PHOTO_WIDGET_SETTINGS = 'PHOTO_WIDGET_SETTINGS'
export const PHOTO_WIDGET_STYLE_SETTINGS = 'PHOTO_WIDGET_STYLE_SETTINGS'
export const PHOTO_COMPACT_WIDGET_SETTINGS = 'PHOTO_COMPACT_WIDGET_SETTINGS'
export const PHOTO_COMPACT_WIDGET_STYLE_SETTINGS = 'PHOTO_COMPACT_WIDGET_STYLE_SETTINGS'
export const VISITOR_WIDGET_SETTINGS = 'VISITOR_WIDGET_SETTINGS'
export const VISITOR_WIDGET_STYLE_SETTINGS = 'VISITOR_WIDGET_STYLE_SETTINGS'
export const WATCH_US_ON_TV_WIDGET_SETTINGS = 'WATCH_US_ON_TV_WIDGET_SETTINGS'
export const WATCH_US_ON_TV_WIDGET_STYLE_SETTINGS = 'WATCH_US_ON_TV_WIDGET_STYLE_SETTINGS'
export const SUBSCRIBE_WIDGET_SETTINGS = 'SUBSCRIBE_WIDGET_SETTINGS'
export const SUBSCRIBE_WIDGET_STYLE_SETTINGS = 'SUBSCRIBE_WIDGET_STYLE_SETTINGS'
export const WELCOME_WIDGET_SETTINGS = 'WELCOME_WIDGET_SETTINGS'
export const WELCOME_WIDGET_STYLE_SETTINGS = 'WELCOME_WIDGET_STYLE_SETTINGS'
export const MOBILE_APP_WIDGET_SETTINGS = 'MOBILE_APP_WIDGET_SETTINGS'
export const MOBILE_APP_WIDGET_STYLE_SETTINGS = 'MOBILE_APP_WIDGET_STYLE_SETTINGS'
export const TV_APP_WIDGET_SETTINGS = 'TV_APP_WIDGET_SETTINGS'
export const TV_APP_WIDGET_STYLE_SETTINGS = 'TV_APP_WIDGET_STYLE_SETTINGS'
export const APP_PAGE_WIDGET_SETTINGS = 'APP_PAGE_WIDGET_SETTINGS'
export const APP_PAGE_WIDGET_STYLE_SETTINGS = 'APP_PAGE_WIDGET_STYLE_SETTINGS'
export const GIVE_WIDGET_SETTINGS = 'GIVE_WIDGET_SETTINGS'
export const GIVE_WIDGET_STYLE_SETTINGS = 'GIVE_WIDGET_STYLE_SETTINGS'
export const STREAM_WIDGET_SETTINGS = 'STREAM_WIDGET_SETTINGS'
export const STREAM_WIDGET_STYLE_SETTINGS = 'STREAM_WIDGET_STYLE_SETTINGS'
export const SERMONS_WIDGET_SETTINGS = 'SERMONS_WIDGET_SETTINGS'
export const LATEST_WIDGET_SETTINGS = 'LATEST_WIDGET_SETTINGS'
export const SERMONS_WIDGET_STYLE_SETTINGS = 'SERMONS_WIDGET_STYLE_SETTINGS'
export const GENERAL_INPUTS_SETTINGS = 'GENERAL_INPUTS_SETTINGS'
export const GENERAL_HEADER_SETTINGS = 'GENERAL_HEADER_SETTINGS'
export const GENERAL_FW_BUTTON_SETTINGS = 'GENERAL_FW_BUTTON_SETTINGS'
export const CONTACT_US_WIDGET_SETTINGS = 'CONTACT_US_WIDGET_SETTINGS'
export const CONTACT_US_WIDGET_STYLE_SETTINGS = 'CONTACT_US_WIDGET_STYLE_SETTINGS'
export const ABOUT_US_WIDGET_SETTINGS = 'ABOUT_US_WIDGET_SETTINGS'
export const ABOUT_US_WIDGET_STYLE_SETTINGS = 'ABOUT_US_WIDGET_STYLE_SETTINGS'
export const MINISTRIES_WIDGET_SETTINGS = 'MINISTRIES_WIDGET_SETTINGS'
export const MINISTRIES_WIDGET_STYLE_SETTINGS = 'MINISTRIES_WIDGET_STYLE_SETTINGS'
export const STAFF_WIDGET_SETTINGS = 'STAFF_WIDGET_SETTINGS'
export const STAFF_WIDGET_STYLE_SETTINGS = 'STAFF_WIDGET_STYLE_SETTINGS'
export const FW_API_PRESET_SETTINGS = 'FW_API_PRESET_SETTINGS'
export const GENERAL_TABS_SETTINGS = 'GENERAL_TABS_SETTINGS'
export const IFRAME_SETTINGS = 'IFRAME_SETTINGS'
export const PRIVACY_POLICY_WIDGET_SETTINGS = 'PRIVACY_POLICY_WIDGET_SETTINGS'
export const PRIVACY_POLICY_WIDGET_STYLE_SETTINGS = 'PRIVACY_POLICY_WIDGET_STYLE_SETTINGS'
export const EVENTS_COMPACT_SUB_SETTINGS = 'EVENTS_COMPACT_SUB_SETTINGS'
export const DROPDOWN_SETTINGS = 'DROPDOWN_SETTINGS'
export const MAP_SETTINGS = 'MAP_SETTINGS'
export const VISIBLE_ADAPTIVE_SETTINGS = 'VISIBLE_ADAPTIVE_SETTINGS'
export const WIDGET_STYLE_SETTINGS = 'WIDGET_STYLE_SETTINGS'
export const STANDARD_HORIZONTAL_WIDGET_SETTINGS = 'STANDARD_HORIZONTAL_WIDGET_SETTINGS'
export const SET_CUSTOM_CLASS = 'SET_CUSTOM_CLASS'
export const FILTERS = 'FILTERS'
export const COLOR_OVERLAY = 'COLOR_OVERLAY'
export const POSITION = 'POSITION'
export const SMART_ASSISTANT = 'SMART_ASSISTANT'
export const SECTION_BACKGROUND = 'SECTION_BACKGROUND'
export const BLOCK_TEMPLATE_SETTINGS = 'BLOCK_TEMPLATE_SETTINGS'
export const LATEST_WIDGET_TITLE_SETTINGS = 'LATEST_WIDGET_TITLE_SETTINGS'
export const LATEST_WIDGET_TEXT_SETTINGS = 'LATEST_WIDGET_TEXT_SETTINGS'
export const IMAGGA_OPTIONS = 'IMAGGA_OPTIONS'
export const CONTENT_ITEM_WIDGET_SETTINGS = 'CONTENT_ITEM_WIDGET_SETTINGS'
export const WIDGET_STYLE = 'WIDGET_STYLE'
export const WIDGET_SETTINGS = 'WIDGET_SETTINGS'
export const CTA_WIDGET_SETTINGS = 'CTA_WIDGET_SETTINGS'
export const QR_SETTINGS = 'QR_SETTINGS'
export const WIDGET_IMAGE_SETTINGS = 'WIDGET_IMAGE_SETTINGS'

export const LIST_STYLES = {
  [SECTION_BACKGROUND]: sectionBackground,
  [SLIDERS_SETTINGS]: slidersSettings,
  [BACKGROUND_SETTINGS]: bg,
  [LAYOUT_SETTINGS]: layout,
  [NIGHT_MODE_SETTINGS]: nightModeSettings,
  [ANALYTIC_SETTINGS]: analyticSettings,
  [VARIABLES_SETTINGS]: variables,
  [ANIMATION_SETTINGS]: animation,
  [TYPOGRAPHY_SETTINGS]: typography,
  [HEADER_SETTINGS]: headerSettings,
  [SHAPE_SETTINGS]: shape,
  [GENERAL_SETTINGS]: general,
  [IFRAME_SETTINGS]: iframeSettings,
  [FORM_SETTINGS]: formTemplate,
  [LINK_TO_SETTINGS]: linkTo,
  [SOCIAL_SETTINGS]: social,
  [SUB_TYPE_SOCIAL_PANEL]: social,
  [SLIDER_SETTINGS]: slider,
  [MENU_SETTINGS]: menu,
  [MENU_STYLE]: menuStyle,
  [TABLE_SETTINGS]: tableSettings,
  [ACCORDION_SETTINGS]: accordionSettings,
  [GENERAL_HEADER_SETTINGS]: generalHeaderSettings,
  [GENERAL_INPUTS_SETTINGS]: generalInputsSettings,
  [CONTACT_US_WIDGET_STYLE_SETTINGS]: contactUsWidgetStyleSettings,
  [ABOUT_US_WIDGET_STYLE_SETTINGS]: aboutUsWidgetStyleSettings,
  [MINISTRIES_WIDGET_STYLE_SETTINGS]: ministriesWidgetStyleSettings,
  [STAFF_WIDGET_STYLE_SETTINGS]: staffWidgetStyleSettings,
  [LINK_TO_FW_SETTINGS]: linkToFw,
  [PRIVACY_POLICY_WIDGET_STYLE_SETTINGS]: privacyPolicyWidgetStyleSettings,
  [EVENTS_COMPACT_SUB_SETTINGS]: eventsCompactSubSettings,
  [DROPDOWN_SETTINGS]: dropdownSettings,
  [MAP_SETTINGS]: mapSettings,
  [VISIBLE_ADAPTIVE_SETTINGS]: visibleAdaptiveSettings,
  [WIDGET_STYLE_SETTINGS]: widgetStyleSettings,
  [WIDGET_STYLE]: widgetStyle,
  [SET_CUSTOM_CLASS]: setCustomClass,
  [FILTERS]: filters,
  [COLOR_OVERLAY]: colorOverlay,
  [SMART_ASSISTANT]: smartAssistant,
  [LATEST_WIDGET_TITLE_SETTINGS]: latestWidgetStyleSettings,
  [LATEST_WIDGET_TEXT_SETTINGS]: latestWidgetStyleSettings,
  [QR_SETTINGS]: QRSettings,
  [WIDGET_IMAGE_SETTINGS]: WidgetElementSettings
}
export const LIST_SETTINGS = {
  [MENU_SETTINGS]: menu,
  [SHAPE_SETTINGS]: shape,
  [MENU_STYLE]: menuStyle,
  [HEADER_SETTINGS]: headerSettings,
  [FOOTER_SETTINGS]: footerSettings,
  [SAVE_TEMPLATE_SETTINGS]: saveAsTemplateSettings,
  [EVENTS_WIDGET_SETTINGS]: eventsWidgetSettings,
  [BLOG_WIDGET_SETTINGS]: blogWidgetSettings,
  [BLOG_COMPACT_WIDGET_SETTINGS]: blogCompactWidgetSettings,
  [EVENTS_COMPACT_WIDGET_SETTINGS]: eventsCompactWidgetSettings,
  [LAYOUT_WIDGET_SETTINGS]: layoutWidgetSettings,
  [NEWS_WIDGET_SETTINGS]: newsWidgetSettings,
  [NEWS_COMPACT_WIDGET_SETTINGS]: newsCompactWidgetSettings,
  [DEVOTIONS_WIDGET_SETTINGS]: devotionsWidgetSettings,
  [DEVOTIONS_COMPACT_WIDGET_SETTINGS]: devotionsCompactWidgetSettings,
  [READING_PLAN_WIDGET_SETTINGS]: readingPlanWidgetSettings,
  [READING_PLAN_COMPACT_WIDGET_SETTINGS]: readingPlanCompactWidgetSettings,
  [PHOTO_WIDGET_SETTINGS]: photoWidgetSettings,
  [PHOTO_COMPACT_WIDGET_SETTINGS]: photoCompactWidgetSettings,
  [GENERAL_FW_BUTTON_SETTINGS]: generalButtonFwSettings,
  [VISITOR_WIDGET_SETTINGS]: visitorWidgetSettings,
  [WATCH_US_ON_TV_WIDGET_SETTINGS]: watchUsOnTvWidgetSettings,
  [SUBSCRIBE_WIDGET_SETTINGS]: subscribeWidgetSettings,
  [WELCOME_WIDGET_SETTINGS]: welcomeWidgetSettings,
  [MOBILE_APP_WIDGET_SETTINGS]: mobileAppWidgetSettings,
  [TV_APP_WIDGET_SETTINGS]: tvAppWidgetSettings,
  [APP_PAGE_WIDGET_SETTINGS]: appPageWidgetSettings,
  [GIVE_WIDGET_SETTINGS]: giveWidgetSettings,
  [CONTENT_ITEM_WIDGET_SETTINGS]: contentItemSettings,
  [STREAM_WIDGET_SETTINGS]: streamWidgetSettings,
  [SERMONS_WIDGET_SETTINGS]: sermonsWidgetSettings,
  [LATEST_WIDGET_SETTINGS]: latestWidgetSettings,
  [CONTACT_US_WIDGET_SETTINGS]: contactUsWidgetSettings,
  [ABOUT_US_WIDGET_SETTINGS]: aboutUsWidgetSettings,
  [MINISTRIES_WIDGET_SETTINGS]: ministriesWidgetSettings,
  [STAFF_WIDGET_SETTINGS]: staffWidgetSettings,
  [FW_API_PRESET_SETTINGS]: fwPresetSettings,
  [GENERAL_TABS_SETTINGS]: generalTabsSettings,
  [SECTION_TEMPLATE_SETTINGS]: sectionTemplateSettings,
  [PRIVACY_POLICY_WIDGET_SETTINGS]: privacyPolicyWidgetSettings,
  [STANDARD_HORIZONTAL_WIDGET_SETTINGS]: standardHorizontalWidgetSettings,
  [BLOCK_TEMPLATE_SETTINGS]: blockTemplateSettings,
  [WIDGET_SETTINGS]: widgetSettings,
  [CTA_WIDGET_SETTINGS]: CTAWidgetSettings
}

export const SETTINGS = {
  ...LIST_STYLES,
  ...LIST_SETTINGS
}
