import * as types from './../../constants/store/mutations-types'

const state = {
  readingPlanPostListData: [],
  readingPlanActivePost: 0
  // printStatus: false
}

const getters = {
  readingPlanPostListData: state => state.readingPlanPostListData,
  readingPlanActivePost: state => state.readingPlanActivePost
}
const actions = {
  pushReadingPlanPostData ({ commit }, data) {
    commit(types.PUSH_READING_PLAN_POST_DATA, { data: data })
  },
  setReadingPlanActivePost ({ commit }, data) {
    commit(types.SET_READING_PLAN_ACTIVE_POST, data)
  }
}
const mutations = {
  [types.PUSH_READING_PLAN_POST_DATA] (state, { data }) {
    state.readingPlanPostListData.push(data)
    state.readingPlanActivePost = state.readingPlanPostListData.length - 1
  },
  [types.SET_READING_PLAN_ACTIVE_POST] (state, data) {
    state.readingPlanActivePost = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
