export const API_VERSION = '1.0'
export const CURRENT_DOMEN = window.API_PATH
// export const CURRENT_DOMEN = 'https://jmmi.impactfactors.net/'
// export const CURRENT_DOMEN = 'https://gracechurchii.impactfactors.net/'
// export const CURRENT_DOMEN = 'https://ccv2.impactfactors.net/'
// export const CURRENT_DOMEN = 'https://concordia2.impactfactors.net/'
// export const DEMO_DOMEN = 'https://demo.impactfactors.net/'
export const DEMO_DOMEN = window.API_PATH
export const SUBTITLES_DOMEN = window.API_PATH
export const BIBLE_DOMAIN = `${CURRENT_DOMEN}api/bible/${API_VERSION}/`
export const SERMON_DOMAIN = `${CURRENT_DOMEN}api/media/${API_VERSION}/`
export const SERIES_DOMAIN = `${CURRENT_DOMEN}api/media/serie/${API_VERSION}/`
export const SETTINGS_DOMAIN = `${CURRENT_DOMEN}api/settings/${API_VERSION}/personal/`
export const PROGRAMS_GET_DOMAIN = `${CURRENT_DOMEN}api/v1/media/`
export const USERS_SERMON_GET_DOMAIN = `${CURRENT_DOMEN}api/v1/users/`
export const BOOKMARKS_SERMON_GET_DOMAIN = `${CURRENT_DOMEN}api/v1/media/`
export const MEMBER_DOMEN = `${CURRENT_DOMEN}api/members/${API_VERSION}/`
export const MEDIA_DOMEN = `${CURRENT_DOMEN}api/media/${API_VERSION}/`
