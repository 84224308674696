import * as domens from '../constants/domens'
import VueCookie from 'vue-cookie'
const axios = require('axios')

export const SERMON_WIDGET_GROUP = 'SERMON_WIDGET_GROUP'

export default {
  getBooksBookmarks: function (callback) {
    const instWithCred = axios.create({
      baseURL: domens.SETTINGS_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid') || localStorage.mid
      }
    })
    return instWithCred.get(`${SERMON_WIDGET_GROUP}/books-bookmarks`)
  },
  saveBooksBookmarks: function (obj) {
    const instWithCred = axios.create({
      baseURL: domens.SETTINGS_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid') || localStorage.mid
      }
    })
    return instWithCred.post(`${SERMON_WIDGET_GROUP}/books-bookmarks`, { data: { value: obj } })
  },
  getBooksNotes: function () {
    const instWithCred = axios.create({
      baseURL: domens.SETTINGS_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid') || localStorage.mid
      }
    })
    return instWithCred.get(`${SERMON_WIDGET_GROUP}/books-notes`)
  },
  saveBooksNotes: function (obj) {
    const instWithCred = axios.create({
      baseURL: domens.SETTINGS_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid') || localStorage.mid
      }
    })
    return instWithCred.post(`${SERMON_WIDGET_GROUP}/books-notes`, { data: { value: obj } })
  },
  saveBooksMarkers: function (obj) {
    const instWithCred = axios.create({
      baseURL: domens.SETTINGS_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid') || localStorage.mid
      }
    })
    return instWithCred.post(`${SERMON_WIDGET_GROUP}/books-markers`, { data: { value: obj } })
  },
  getBooksMarkers: function () {
    const instWithCred = axios.create({
      baseURL: domens.SETTINGS_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid') || localStorage.mid
      }
    })
    return instWithCred.get(`${SERMON_WIDGET_GROUP}/books-markers`)
  },
  getBooksTranslations: function (callback) {
    const instWithCred = axios.create({
      baseURL: domens.BIBLE_DOMAIN
    })
    instWithCred.get('')
      .then(function (response) {
        callback(response)
      })
  },
  getBooksVersions: function (bookVer, callback) {
    const instWithCred = axios.create({
      baseURL: domens.BIBLE_DOMAIN
    })
    instWithCred.get('books?ver=' + bookVer)
      .then(function (response) {
        callback(response)
      })
  },
  getBooksChapterByVersion: function ({ bookVer, verForChapter }, callback) {
    const instWithCred = axios.create({
      baseURL: domens.BIBLE_DOMAIN
    })
    instWithCred.get(`book/${verForChapter}/chapters?ver=${bookVer}`)
      .then(function (response) {
        callback(response)
      })
  },
  getBooksVersesByChapter: function ({ bookVer, verForChapterText }, callback) {
    const instWithCred = axios.create({
      baseURL: domens.BIBLE_DOMAIN
    })
    instWithCred.get(`/book/chapter/${verForChapterText}/verses?ver=${bookVer}`)
      .then(function (response) {
        callback(response)
      })
  },
  saveBiblePosition: function (obj) {
    const instWithCred = axios.create({
      baseURL: domens.SETTINGS_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid') || localStorage.mid
      }
    })
    return instWithCred.post(`${SERMON_WIDGET_GROUP}/bible-position`, { data: { value: obj } })
  },
  getBiblePosition: function () {
    const instWithCred = axios.create({
      baseURL: domens.SETTINGS_DOMAIN,
      headers: {
        'X-Custom-Auth': VueCookie.get('mid') || localStorage.mid
      }
    })
    return instWithCred.get(`${SERMON_WIDGET_GROUP}/bible-position`)
  }
}
