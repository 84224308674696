import * as types from '../mutations-types'
import * as TabsItemList from '../../constants/tabs/tabsList'
const state = {
  showTabPanel: TabsItemList.tabsNameViewPage.TABS_SUMMARY,
  videoPageTabsItem: TabsItemList.TABS_VIDEO,
  tabsScheduleItem: TabsItemList.TABS_SCHEDULE,
  showTabPanelSchedule: TabsItemList.tabsNameSchedule.TABS_EVENTS,
  currentTranscript: {}
}

const getters = {
  showTabPanel: state => state.showTabPanel,
  videoPageTabsItemList: state => state.videoPageTabsItem,
  tabsScheduleItem: state => state.tabsScheduleItem,
  showTabPanelSchedule: state => state.showTabPanelSchedule,
  currentTranscript: state => state.currentTranscript
}
const actions = {
  setActive ({ commit }, route) {
    commit(types.TABS_CONTENT, { route: route })
  },
  setActiveSchedule ({ commit }, route) {
    commit(types.TABS_CONTENT_SCHEDULE, { route: route })
  },
  setCurrentTranscript ({ commit }, data) {
    commit(types.SET_CURRENT_TRANSCRIPT, data)
  }
}
const mutations = {
  [types.TABS_CONTENT] (state, { route }) {
    state.showTabPanel = route
    state.videoPageTabsItem.forEach(function (item) {
      if (item.tabsName !== route) {
        item.isActive = false
      } else item.isActive = true
    })
  },
  [types.TABS_CONTENT_SCHEDULE] (state, { route }) {
    state.showTabPanelSchedule = route
    state.tabsScheduleItem.forEach(function (item) {
      if (item.tabsName !== route) {
        item.isActive = false
      } else item.isActive = true
    })
  },
  [types.SET_CURRENT_TRANSCRIPT] (state, data) {
    state.currentTranscript = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
