import * as types from '../../mutations-types'
import * as tabs from '../bible/tabConstants'

const state = {
  tabControlsPanelName: tabs.BIBLE_READ,
  activeBookmarkId: 0,
  activeNoteId: 0
  // tabControlsHeaderName: tabs.BIBLE_HEADER_READ
}

const getters = {
  tabControlsPanelName: state => state.tabControlsPanelName,
  activeBookmarkId: state => state.activeBookmarkId,
  activeNoteId: state => state.activeNoteId
  // tabControlsHeaderName: state => state.tabControlsHeaderName
}
const actions = {
  toggleTabControlsPanel ({ commit }, tab) {
    commit(types.TOOGLE_TAB_CONTROLS, { tab: tab })
  },
  setActiveBookmarkId ({ commit }, id) {
    commit(types.SET_ACTIVE_BOOKMARK_ID, { id: id })
  },
  setActiveNoteId ({ commit }, id) {
    commit(types.SET_ACTIVE_NOTE_ID, { id: id })
  }
}
const mutations = {
  [types.TOOGLE_TAB_CONTROLS] (state, { tab }) {
    state.tabControlsPanelName = tab
  },
  [types.SET_ACTIVE_BOOKMARK_ID] (state, { id }) {
    state.activeBookmarkId = id
  },
  [types.SET_ACTIVE_NOTE_ID] (state, { id }) {
    state.activeNoteId = id
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
