export const STATUS_SAVED = 'STATUS_SAVED'
export const STATUS_SAVING = 'STATUS_SAVING'
export const STATUS_SAVING_BTN = 'STATUS_SAVING_BTN'
export const STATUS_LOADED = 'STATUS_LOADED'
export const STATUS_ERROR = 'STATUS_ERROR'
export const STATUS_PUBLISH_SAVING = 'STATUS_PUBLISH_SAVING'

export const LIST_STATUSES = {
  [STATUS_LOADED]: '',
  [STATUS_SAVED]: 'saved!',
  [STATUS_SAVING]: 'No worries! We auto-save your work “Internal Preview”.',
  [STATUS_SAVING_BTN]: 'Saving your work',
  [STATUS_ERROR]: 'Something went wrong! Please reload Page',
  [STATUS_PUBLISH_SAVING]: 'Save your work.'
}
