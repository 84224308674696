<template>
  <div class="template-dialog">
    <div
      class="template-dialog__container"
      :class="{'template-dialog__container_open-menu': showMenu}"
    >
      <template>
        <div class="template-dialog__logo">
          <img
            src="@@@/assets/images/elements/impactFactors.svg"
            alt=""
          >
        </div>
        <div class="template-dialog-main">
          <img
            class="swiper-button-prev template-dialog-slider__arrow"
            src="@@@/assets/images/elements/caret.svg"
            alt=""
          >
          <swiper
            ref="swiper"
            class="template-dialog-slider"
            :options="sliderOption"
            @click.native="openVideo"
          >
            <swiper-slide
              v-for="(item, index) in itemList"
              :key="item.id"
              class="template-dialog-item"
            >
              <div class="template-dialog-item__img">
                <img
                  src="@@@/assets/images/elements/play.svg"
                  alt=""
                >
              </div>
              <div>
                <div class="template-dialog-item__title">
                  {{ item.title }}
                </div>
                <div class="template-dialog-item__description">
                  {{ item.description }}
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <img
            class="swiper-button-next"
            src="@@@/assets/images/elements/caret.svg"
            alt=""
          >
        </div>
        <div class="template-dialog-main-mobile">
          <div
            class="template-dialog-item"
            @click="openVideo($event,itemList[0].url)"
          >
            <div class="template-dialog-item__img">
              <img
                src="@@@/assets/images/elements/play.svg"
                alt=""
              >
            </div>
            <div>
              <div class="template-dialog-item__title">
                {{ itemList[0].title }}
              </div>
              <div class="template-dialog-item__description">
                {{ itemList[0].description }}
              </div>
            </div>
          </div>
          <img
            class="swiper-button-next"
            :class="{'swiper-button-next_open-menu': showMenu}"
            src="@@@/assets/images/elements/caret.svg"
            @click="showMenu = !showMenu"
          >
        </div>
        <div class="template-dialog-right">
          <a
            class="template-dialog-right__btn template-dialog-right__btn_primary"
            href="#"
          >
            <span class="template-dialog-right__btn_hide">Schedule а</span>Demo
          </a>
          <a
            class="template-dialog-right__btn template-dialog-right__btn_second"
            href="#"
          >
            Sign Up
          </a>
        </div>
      </template>
      <transition name="fade-widgets">
        <div
          v-if="showMenu"
          class="template-dialog-mobile"
        >
          <div
            v-for="(item, index) in itemList"
            v-if="index > 0"
            :key="item.id"
            class="template-dialog-item"
            @click="openVideo($event,item.url)"
          >
            <div class="template-dialog-item__img">
              <img
                src="@@@/assets/images/elements/play.svg"
                alt=""
              >
            </div>
            <div>
              <div class="template-dialog-item__title">
                {{ item.title }}
              </div>
              <div class="template-dialog-item__description">
                {{ item.description }}
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <v-teleport
      v-if="openModal"
      to="modal"
      :disabled="false"
    >
      <w-modal
        :close-icon="true"
        :class-appendix="'size-1220-padding'"
        :icon-class="'sb-icon-cancel'"
        @close="openModal = false"
      >
        <ModalTemplateDialog :id="vimeoId" />
      </w-modal>
    </v-teleport>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { MODE_MOBILE } from '@@@/store/constant/modeViewNames'
import ModalTemplateDialog from '@@@/components/elements/templateDialog/modalTemplateDialog'
import { vTeleport } from '@desislavsd/vue-teleport'
import WModal from '@@/components/modal/w-modal'

export default {
  name: 'TemplateDialog',
  components: {
    Swiper,
    SwiperSlide,
    vTeleport,
    WModal,
    ModalTemplateDialog
  },
  data: () => ({
    itemList: [
      { id: 1, title: 'Why we are #1', description: '15-seconds video', url: '' },
      { id: 2, title: 'Live in 30 mins!', description: '15-seconds video', url: '' },
      { id: 3, title: 'How easy to customize', description: '15-seconds video', url: '' },
      { id: 4, title: 'Automated', description: '15-seconds video', url: '' },
      { id: 5, title: 'Integrated', description: '15-seconds video', url: '' }
    ],
    sliderOption: {
      slidesPerView: 'auto',
      spaceBetween: 18,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      }
    },
    showMenu: false,
    openModal: false,
    vimeoId: ''
  }),
  computed: {
    viewMode: function () {
      return this.$store.getters.viewMode
    },
    swiper () {
      return this.$refs.swiper.$swiper
    }
  },
  methods: {
    openVideo (event, url) {
      this.openModal = !this.openModal
      if (MODE_MOBILE === this.viewMode) {
        this.vimeoId = url.substring(url.indexOf('.com/')).substring(5)
        return
      }
      if (
        !event.target.classList.contains('swiper-button-next') &&
        !event.target.classList.contains('swiper-button-prev')
      ) {
        const { url } = this.itemList[this.swiper.realIndex]
        this.vimeoId = url.substring(url.indexOf('.com/')).substring(5)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../sass/variables";

  .template-dialog {
    z-index: 2000;
    top: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 20px;
    background: $color-white;
    font-family: Muller;

    &__container {
      max-width: 1440px;
      padding: 23px 48px;
      max-height: 97px;
      width: 100%;
      display: grid;
      grid-template-columns: 19% 1fr 35%;
      align-items: center;
    }

    &__logo {
      max-width: 226px;

      img {
        width: 100%;
      }
    }

    &-main {
      position: relative;
      margin-left: 9%;
      margin-right: 2%;
    }

    &-main-mobile {
      display: none;
    }

    &-right {
      display: flex;
      justify-content: flex-end;

      &__btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5% 1%;
        text-decoration: none;
        max-width: 168px;
        max-height: 48px;
        width: 100%;
        height: 100%;
        border-radius: 24px;
        font-size: 16px;
        font-weight: 600;
        color: $color-bright-turquoise;
        transition: all 0.3s;
        position: relative;
        overflow: hidden;
        z-index: 1;
        border: 1px solid;
        text-align: center;

        &_primary {
          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 100%;
            background-color: $color-bright-turquoise;
            transition: all 0.3s;
            border-radius: 24px;
            z-index: -1;
          }

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $color-white;
            border-radius: 24px;
            z-index: -2;
          }

          @media (min-width: 541px) {
            &:hover {
              color: $color-white;

              &:before {
                width: 100%;
              }
            }
          }
        }

        &_second {
          color: $color-white;
          background: $color-bright-turquoise;

          &:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 0;
            height: 100%;
            background-color: $color-white;
            transition: all 0.3s;
            border-radius: 24px;
            z-index: -1;
          }

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $color-bright-turquoise;
            border-radius: 24px;
            z-index: -2;
          }

          @media (min-width: 541px) {
            &:hover {
              color: $color-bright-turquoise;

              &:before {
                width: 100%;
              }
            }
          }
        }

        &_hide {
          margin-right: 4px;
        }

        & + & {
          margin-left: 16px;
        }
      }
    }

    &-slider {
      max-width: 100%;
      width: 29vw;
    }

    .swiper-slide {
      width: auto !important;
    }

    .swiper-button-prev, .swiper-button-next {
      background-image: none !important;
      width: 16px;
    }

    .swiper-button-prev {
      left: -34px;
    }

    .swiper-button-next {
      right: -30px;
      transform: rotate(180deg);
    }

    .swiper-button-disabled {
      opacity: 0;
    }
  }

  .template-dialog-item {
      display: flex;
      font-size: 16px;
      cursor: pointer;

      &__img {
        margin-right: 7px;
        max-width: 24px;
        min-width: 20px;

        img {
          width: 100%;
        }
      }

      &__title {
        font-weight: 600;
        color: $color-shark;
        position: relative;
        top: 2px;
      }

      &__description {
        color: $color-silver;
        line-height: 24px;
        position: relative;
        top: 4px;
      }
    }

@media (max-width: 1200px) {
  .template-dialog {
    &-main {
      .swiper-button-next {
        right: 0;
      }
    }
  }
}

@media (max-width: 1024px) {
  .template-dialog {
    &__container {
      padding: 20px 24px;
    }

    &-main {
      .swiper-button-prev {
        left: 0;
      }
    }
  }
}

@media (max-width: 992px) {
  .template-dialog-right__btn_hide {
    display: none;
  }
}

@media (max-width: 540px) {
  .template-dialog {

    &__logo {
      display: none;
    }

    &__container {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 47px 1fr;
      align-items: flex-start;
      padding: 8px 24px;
      border-bottom: 1px solid $color-gallery;

      &_open-menu {
        max-height: 100%;
        height: 100vh;
        border-bottom: 0;
      }
    }

    &-main {
      display: none;
    }

    &-main-mobile {
      display: block;
      position: relative;

      .swiper-button-next {
        right: 5px;
        top: 19px;
        transform: rotate(180deg);
        transition: all 0.3s ease-out;

        &_open-menu {
          transform: rotate(270deg);
        }
      }
    }

    &-right {

      &__btn {
        max-width: 88px;
        min-width: 88px;
        max-height: 36px;
        min-height: 36px;
        font-size: 14px;
        border: 0;

        &:after {display:none}
        &:before {display:none}

        & + & {
          margin-left: -12px;
        }
      }
    }
  }

  .template-dialog-item {
    display: flex;
    font-size: 14px;
    margin-top: 4px;
    margin-bottom: 9px;

    &__img {
      margin-right: 7px;
      max-width: 18px;
      min-width: 18px;

      img {
        width: 100%;
      }
    }

    &__title {
      font-weight: 600;
      color: $color-shark;
      position: relative;
      top: 2px;
    }

    &__description {
      color: $color-silver;
      line-height: 24px;
      position: relative;
      top: 1px;
    }
  }

  .template-dialog-mobile {
    grid-column: 1 / 3;
    border-top: 1px solid $color-gallery;
    border-bottom: 1px solid $color-gallery;
    margin: 8px -24px;
    box-sizing: border-box;
    padding: 15px 24px 15px;
  }
}

</style>

<style lang="scss">
@import "../../../sass/swiper";

</style>
