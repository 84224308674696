import { fonts } from '../constants/fonts'
import { FONT_FAMILIES_LIST } from '@@@/store/constant/fontFamiliesList.js'
import _ from 'lodash'

export const getGoogleFontList = (allFonts) => {
  if (!allFonts) return
  const allSortFonts = allFonts.map(({ family, files }) => {
    return Object.entries(files).map((font) => {
      const [key, value] = font
      const findFont = fonts.find((font) => font.key === key)
      return {
        key: findFont.value ? `${family}-${findFont.value}` : family,
        text: findFont.value ? `${family} ${findFont.value}` : family,
        file: value,
        weight: key,
        family
      }
    })
  }
  ).flat()

  const mergedFonts = _.uniq([...FONT_FAMILIES_LIST, ...allSortFonts], 'text')

  return _.sortBy(mergedFonts, 'text')
}

export const setFontOnSite = (key, file) => {
  if (file) {
    const correctFile = file.replace('http', 'https')
    const newFont = new FontFace(key, `url(${correctFile})`)
    newFont.display = 'block'
    newFont.load().then(function (loadedFace) {
      document.fonts.add(loadedFace)
    }).catch(function (error) {
      console.error(error)
    })
  }
}
