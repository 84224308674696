export default {
  title: 'Person',
  type: 'person',
  name: 'Untitled',
  group: 'advanced',
  properties: {
    options: {
      values: [{
        id: 'optional',
        title: 'Optional Field'
      },
      {
        id: 'required',
        title: 'Required Field'
      }],
      selected: {
        id: ['optional']
      }
    },
    visibility: {
      values: [{
        id: 'everyone',
        title: 'Everyone',
        description: 'Every person could fill your form'
      },
      {
        id: 'admin',
        title: 'Admin Only'
      },
      {
        id: 'registered',
        title: 'Registered Users Only'
      },
      {
        id: 'custom_category',
        title: 'Custom Category Only',
        description: 'Visible only to this category of users'
      }
      ],
      selected: {
        id: 'everyone',
        categories: []
      }
    },
    predefined: {
      selected: null,
      optional: true
    },
    placeholder: {
      selected: {
        firstName: null,
        lastName: null,
        email: null
      },
      optional: true
    },
    instructions: {
      selected: null,
      optional: true
    },
    system: {
      selected: false
    }
  }
}
