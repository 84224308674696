// import * as statuses from './../constants/store/status-types'
import * as domens from '../constants/domains'
const axios = require('axios')
const instWithCred = axios.create({
  baseURL: domens.CURRENT_DOMAIN,
  withCredentials: true
})
const instWithCredAndMultipart = axios.create({
  baseURL: domens.CURRENT_DOMAIN,
  withCredentials: true,
  headers: { 'Content-Type': 'multipart/form-data' }
})
const inst = axios.create({
  baseURL: domens.CURRENT_DOMAIN,
  withCredentials: true
})
export default {
  // auth: function (login, password, callback, errCallback) {
  //   instWithCred.post('widget.php/auth/login', {u: login, p: password})
  //     .then(function (response) {
  //       if (response.data.result === 1) {
  //         callback(statuses.STATUS_SUCCES_AUTH)
  //       } else if (response.data.result === 0) {
  //         errCallback(response.data.error)
  //       }
  //     })
  // },
  auth: function (login, password, callback, errCallback) {
    const instWithCred = axios.create({
      baseURL: domens.MEMBER_DOMAIN
    })
    instWithCred.post('login', { email: login, password: password })
      .then(function (response) {
        // if (response.data.result === 1) {
        //   callback()
        // } else if (response.data.result === 0) {
        //   errCallback(response.data.error)
        // }
        callback(response)
      })
      .catch(function () {
        errCallback()
      })
  },
  // getUserData: function (callback) {
  //   instWithCred.get('widget.php/index/userInfo')
  //     .then(function (response) {
  //       callback(response)
  //     })
  // },
  logout: function (callback) {
    instWithCred.get('widget.php/auth/logout')
      .then(function (response) {
        callback(response)
      })
  },
  getNewRegisterState: function (callback) {
    inst.get('widget.php/registration/getFormFields')
      .then(function (response) {
        callback(response)
      })
  },
  registerUser: function (data, callback, errCallback) {
    const postData = {}
    for (const key in data) {
      postData[key] = data[key]
    }
    instWithCred.post('widget.php/registration/save', postData)
      .then(function (response) {
        if (response.data.result === 0) {
          errCallback(response.data.error)
        } else if (response.data.result === 1) {
          callback()
        }
      })
  },
  resetPassword: function (login, callback, errCallback) {
    inst.get('widget.php/auth/resetPwd?email=' + login)
      .then(function (response) {
        if (response.data.result === 1) {
          callback()
        } else if (response.data.result === 0) {
          errCallback(response.data.error)
        }
      })
  },
  checkEmail: function (login, callback, errCallback) {
    inst.get('widget.php/registration/checkEmail?email=' + login)
      .then(function (response) {
        if (response.data.result === true) {
          errCallback()
        } else if (response.data.result === false) {
          callback()
        }
      })
  },
  getLocation: function (callback) {
    instWithCred.get('widget.php/registration/getLocation')
      .then(function (response) {
        callback(response)
      })
  },
  uploadFoto: function (data, callback, errCallback) {
    instWithCredAndMultipart.post('iphone.php/member/uploadPhoto', data)
      .then(function (response) {
        callback()
      })
  }
}
