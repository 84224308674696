export const TABS_RIGHT =
  [
    {
      tabsName: 'Most Viewed',
      isActive: true,
      id: 0
    }
  ]

export const TABS_CENTER =
  [
    {
      tabsName: 'Programs',
      isActive: false,
      route: 'program',
      id: 1,
      enabled: true
    },
    {
      tabsName: 'Series',
      isActive: false,
      route: 'serie',
      id: 2,
      enabled: true
    },
    {
      tabsName: 'Sermons',
      isActive: true,
      route: 'sermon',
      id: 3,
      enabled: true
    }
  ]

export const RIGHT_BAR_TABS =
  [
    {
      tabsName: 'Bible',
      id: 'tab_bible',
      isActive: true,
      key: 1
    },
    {
      tabsName: 'Give',
      id: 'tab_give',
      isActive: false,
      key: 2
    },
    {
      tabsName: 'Chat',
      id: 'tab_chat',
      isActive: false,
      key: 3
    },
    {
      tabsName: 'Map',
      id: 'tab_map',
      isActive: false,
      key: 4
    },
    {
      tabsName: 'Schedule',
      id: 'tab_shedule',
      isActive: false,
      key: 5
    }
  ]

export const tabsNameSchedule =
  {
    TABS_EVENTS: 'Events',
    TABS_CONFERENCES: 'Conferences',
    TABS_OUTREACHES: 'Outreaches'
  }

export const TABS_SCHEDULE =
  [
    {
      tabsName: tabsNameSchedule.TABS_EVENTS,
      isActive: true,
      route: tabsNameSchedule.TABS_EVENTS,
      id: 0
    },
    {
      tabsName: tabsNameSchedule.TABS_CONFERENCES,
      isActive: false,
      route: tabsNameSchedule.TABS_CONFERENCES,
      id: 1
    },
    {
      tabsName: tabsNameSchedule.TABS_OUTREACHES,
      isActive: false,
      route: tabsNameSchedule.TABS_OUTREACHES,
      id: 2
    }
  ]

export const tabsNameViewPage =
  {
    TABS_SUMMARY: 'Summary',
    TABS_BOOKMARKS: 'Bookmarks',
    TABS_FAVORITES: 'Favorites',
    TABS_CONNECT: 'Connect'
  }

export const TABS_VIDEO =
  [
    {
      tabsName: tabsNameViewPage.TABS_SUMMARY,
      icon: 'sermon-icon-summary',
      iconActive: 'sermon-icon-summary-active',
      isActive: true,
      route: tabsNameViewPage.TABS_SUMMARY,
      authorize: false,
      id: 0
    },
    // {
    //   tabsName: 'Notes',
    //   icon: 'icon-notes',
    //   iconActive: 'icon-notes-active',
    //   isActive: false,
    //   route: 'notes',
    //   authorize: false,
    //   id: 1
    // },
    // {
    //   tabsName: 'Comments',
    //   icon: 'icon-comments-tab',
    //   iconActive: 'icon-comments-active',
    //   isActive: false,
    //   authorize: false,
    //   route: 'comments',
    //   id: 2
    // },
    {
      tabsName: tabsNameViewPage.TABS_BOOKMARKS,
      icon: 'sermon-icon-bookmark',
      iconActive: 'sermon-icon-bookmark-active',
      isActive: false,
      route: tabsNameViewPage.TABS_BOOKMARKS,
      authorize: true,
      id: 3
    },
    {
      tabsName: tabsNameViewPage.TABS_FAVORITES,
      icon: 'sermon-icon-favorites',
      iconActive: 'sermon-icon-love',
      isActive: false,
      authorize: true,
      route: tabsNameViewPage.TABS_FAVORITES,
      id: 4
    },
    {
      tabsName: tabsNameViewPage.TABS_CONNECT,
      icon: 'sermon-icon-share-1',
      iconActive: 'sermon-icon-share-1-active',
      isActive: false,
      authorize: false,
      route: tabsNameViewPage.TABS_CONNECT,
      id: 5
    }
  ]
