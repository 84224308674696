import * as types from './../../constants/store/mutations-types'

const state = {
  userProfilePhoto: ''
}

const getters = {
  userProfilePhoto: state => state.userProfilePhoto
}
const actions = {
  changePhoto ({ commit }, photo) {
    commit(types.CHANGE_PHOTO, { photo: photo })
  }
}
const mutations = {
  [types.CHANGE_PHOTO] (state, { photo }) {
    state.userProfilePhoto = photo
  }

}

export default {
  state,
  getters,
  actions,
  mutations
}
