import * as types from './../../constants/store/mutations-types'
import _ from 'lodash'

const state = {
  blogListAll: [],
  currentBlogCount: 1,
  blogFilterQuery: '',
  blogSearchQuery: '',
  infiniteLoadingRefBlog: false,
  notFoundBlog: false,
  noDataBlogPost: false,
  presetBlogImg: false,
  blogListSetTimeoutId: false
  // printStatus: false
}

const getters = {
  blogListAll: state => state.blogListAll,
  currentBlogCount: state => state.currentBlogCount,
  blogFilterQuery: state => state.blogFilterQuery,
  blogSearchQuery: state => state.blogSearchQuery,
  infiniteLoadingRefBlog: state => state.infiniteLoadingRefBlog,
  notFoundBlog: state => state.notFoundBlog,
  noDataBlogPost: state => state.noDataBlogPost,
  presetBlogImg: state => state.presetBlogImg,
  blogListSetTimeoutId: state => state.blogListSetTimeoutId
  // printStatus: state => state.printStatus
}
const actions = {
  setAllBlogData ({ commit }, data) {
    commit(types.SET_ALL_BLOG_DATA, { data: data })
  },
  pushNewBlogData ({ commit }, data) {
    commit(types.PUSH_NEW_BLOG_DATA, { data: data })
  },
  setCurrentBlogCount ({ commit }, data) {
    commit(types.SET_CURRENT_BLOG_COUNT, { data: data })
  },
  setBlogFilterQuery ({ commit }, data) {
    commit(types.SET_BLOG_FILTER_QUERY, data)
  },
  setBlogSearchQuery ({ commit }, data) {
    commit(types.SET_BLOG_SEARCH_QUERY, data)
  },
  setInfiniteLoadingRefBlog ({ commit }, data) {
    commit(types.SET_INFINITE_LOADING_REF_BLOG, data)
  },
  setNotFoundBlog ({ commit }, data) {
    commit(types.SET_NOT_FOUND_BLOG, data)
  },
  setNoDataBlogPost ({ commit }, data) {
    commit(types.SET_NO_DATA_BLOG_POST, data)
  },
  presetBlogImageUpdate ({ commit }, data) {
    commit(types.PRESET_BLOG_IMAGE_UPDATE, data)
  }
  // setPrintStatus ({commit}, data) {
  //   commit(types.SET_PRINT_STATUS, data)
  // }
}
const mutations = {
  [types.SET_ALL_BLOG_DATA] (state, { data }) {
    if (data.length > 0) {
      state.blogListAll = data.items
    } else {
      state.blogListAll = []
    }
  },
  [types.PUSH_NEW_BLOG_DATA] (state, { data }) {
    clearTimeout(state.blogListSetTimeoutId)
    for (let i = 0; i < data.items.length; i++) {
      if (_.find(state.blogListAll, function (obj) {
        return obj.id === data.items[i].id
      }) === undefined) {
        if (data.items[i].description === undefined) {
          data.items[i].description = ''
        }
        state.blogListSetTimeoutId = setTimeout(function () {
          state.blogListAll.push(data.items[i])
        }, i * 100)
      }
    }
  },
  [types.SET_CURRENT_BLOG_COUNT] (state, { data }) {
    state.currentBlogCount = data
  },
  [types.SET_BLOG_FILTER_QUERY] (state, data) {
    state.blogFilterQuery = data
  },
  [types.SET_BLOG_SEARCH_QUERY] (state, data) {
    state.blogSearchQuery = data
  },
  [types.SET_INFINITE_LOADING_REF_BLOG] (state, data) {
    state.infiniteLoadingRefBlog = data
  },
  [types.SET_NOT_FOUND_BLOG] (state, data) {
    state.notFoundBlog = data
  },
  [types.SET_NO_DATA_BLOG_POST] (state, data) {
    state.noDataBlogPost = data
  },
  [types.PRESET_BLOG_IMAGE_UPDATE] (state, data) {
    state.presetBlogImg = data
  }
  // [types.SET_PRINT_STATUS] (state, data) {
  //   state.printStatus = data
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
