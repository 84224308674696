import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)
const scripts = document.getElementsByTagName('script')
let needed = ''
for (let i = 0; i < scripts.length; i++) {
  if (scripts[i].dataset) {
    if (scripts[i].dataset.type === 'tree') needed = scripts[i]
  }
}
const site = JSON.parse(needed.textContent)
const siteName = site.siteSettings.subDomain
const path = window.location.pathname
// let newPath = window.location.pathname.replace(path, '')
let BASE_PATH = false
if (path.indexOf('website') > -1) {
  BASE_PATH = `/widget/website/${siteName}`
}
if (path.indexOf('website-dev') > -1) {
  BASE_PATH = `/widget/website-dev/${siteName}`
}

if (path.indexOf('website-v2') > -1) {
  BASE_PATH = `/widget/website-v2/${siteName}`
}

export const router = BASE_PATH !== false ? new Router({
  mode: 'history',
  base: BASE_PATH,
  scrollBehavior (to, from, savedPosition) {
    if (to.name !== from.name) {
      return { x: 0, y: 0 }
    }
  }
}) : new Router({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (to.name !== from.name) {
      return { x: 0, y: 0 }
    }
  }
})
