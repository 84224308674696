<template>
  <div
    id="app"
    class="render-website"
  >
    <transition name="fade-cookie-policy">
      <cookie-policy
        v-if="showCookiePolicy && siteData"
        :show-cookie-policy.sync="showCookiePolicy"
      />
    </transition>
    <TemplateDialog v-if="snowTemplateDialog" />
    <div class="layout-app-content">
      <transition name="fade-el">
        <template v-if="siteNavigation !== false && siteData && showHeader">
          <div
            class="site-builder-header"
            :class="{
              'site-builder-header_float': floatHeader,
              'site-builder-header_mobile': isMobile
            }"
          >
            <Section
              :key="siteNavigation.id"
              :ref="sectionRef"
              :section="siteNavigation"
              :page="currentPage"
              :render-status="true"
            />
          </div>
        </template>
      </transition>
      <router-view />
    </div>
    <transition name="fade-footer">
      <template v-if="currentSite.siteFooter && showPageFooter">
        <div
          v-if="currentSite.siteFooter.length && siteData"
          class="layout-app-footer"
        >
          <template v-for="(item, index) in currentSite.siteFooter">
            <footer-template
              v-if="item.sub === footerTemplate"
              :key="item.id"
              :ref="`${sectionRef}-footer`"
              :item="item"
              :page="currentPage"
              :render-status="true"
            />
            <footer-small-template
              v-else-if="item.sub === footerSmallTemplate"
              :key="item.id"
              :ref="`${sectionRef}-footer`"
              :item="item"
              :page="currentPage"
              :render-status="true"
            />
            <footer-one-template
              v-else-if="item.sub === footerOneTemplate || item.sub === footerFiveTemplate"
              :key="item.id"
              :ref="`${sectionRef}-footer`"
              :item="item"
              :page="currentPage"
              :render-status="true"
            />
            <footer-two-template
              v-else-if="item.sub === footerTwoTemplate"
              :key="item.id"
              :ref="`${sectionRef}-footer`"
              :item="item"
              :page="currentPage"
              :render-status="true"
            />
            <footer-three-template
              v-else-if="item.sub === footerThreeTemplate"
              :key="item.id"
              :ref="`${sectionRef}-footer`"
              :item="item"
              :page="currentPage"
              :render-status="true"
            />
            <footer-eight-template
              v-else-if="item.sub === footerEightTemplate"
              :key="item.id"
              :ref="`${sectionRef}-footer`"
              :item="item"
              :page="currentPage"
              :render-status="true"
            />
            <footer-four-template
              v-else-if="item.sub === footerFourTemplate"
              :key="item.id"
              :ref="`${sectionRef}-footer`"
              :item="item"
              :page="currentPage"
              :render-status="true"
            />
            <footer-six-template
              v-else-if="item.sub === footerSixTemplate"
              :key="item.id"
              :ref="`${sectionRef}-footer`"
              :item="item"
              :page="currentPage"
              :render-status="true"
            />
            <footer-seven-template
              v-else-if="item.sub === footerSevenTemplate"
              :key="item.id"
              :ref="`${sectionRef}-footer`"
              :item="item"
              :page="currentPage"
              :render-status="true"
            />
          </template>
        </div>
      </template>
    </transition>
  </div>
</template>

<script>
// import * as modes from '@@@/store/constant/modeViewNames'
import {
  SUB_TYPE_BLOG_WIDGET, SUB_TYPE_DEVOTIONS_WIDGET,
  SUB_TYPE_EVENTS_WIDGET,
  SUB_TYPE_NEWS_WIDGET, SUB_TYPE_PHOTO_WIDGET, SUB_TYPE_READING_PLAN_WIDGET,
  SUB_TYPE_SERMONS_WIDGET, SUB_TYPE_STAFF_WIDGET, SUB_TYPE_FOOTER, SUB_TYPE_FOOTER_SMALL,
  SUB_TYPE_FOOTER_ONE, SUB_TYPE_FOOTER_TWO, SUB_TYPE_FOOTER_THREE, SUB_TYPE_FOOTER_FOUR,
  SUB_TYPE_FOOTER_EIGHT,
  SUB_TYPE_FOOTER_FIVE, SUB_TYPE_FOOTER_SIX, SUB_TYPE_FOOTER_SEVEN
} from '@@@/preset/elements/listSubTypes'
import { get } from 'lodash'
import { getUniqueId } from '@@@/mixin/uniqueId'
import { SECTION_TEMPLATE_SETTINGS } from '@@@/preset/settings/listElements'
// import settingsApi from '@@@/api/generalSettings'
// import organizationSettings from '@@@/components/widgets/public/api/organizationSettings'
// import websiteApi from 'API/USER/website/websiteApi'
import organizationApi from 'API/organization/organizationsApi'
import fontsApi from 'API/fonts/fontsApi'
import { MODE_MOBILE } from '@@@/store/constant/modeViewNames'
import TemplateDialog from '@@@/components/elements/templateDialog/templateDialog'
import { widgetsToRoutes } from '@@@@/constants/widgetToRouter'
import { setFontOnSite, getGoogleFontList } from '@@@/utils/common'
const FooterTemplate = () => import(/* webpackChunkName: "FooterTemplate" */ '@@@/components/elements/footerTemplate')
const FooterSmallTemplate = () => import(/* webpackChunkName: "FooterSmallTemplate" */ '@@@/components/elements/footerSmallTemplate')
const FooterOneTemplate = () => import(/* webpackChunkName: "FooterOneTemplate" */ '@@@/components/elements/footerOneTemplate')
const FooterTwoTemplate = () => import(/* webpackChunkName: "FooterTwoTemplate" */ '@@@/components/elements/footerTwoTemplate')
const FooterFourTemplate = () => import(/* webpackChunkName: "FooterFourTemplate" */ '@@@/components/elements/footerFourTemplate')
const FooterThreeTemplate = () => import(/* webpackChunkName: "FooterThreeTemplate" */ '@@@/components/elements/footerThreeTemplate')
const FooterEightTemplate = () => import(/* webpackChunkName: "FooterEightTemplate" */ '@@@/components/elements/footerEightTemplate')
const FooterSixTemplate = () => import(/* webpackChunkName: "FooterSixTemplate" */ '@@@/components/elements/footerSixTemplate')
const FooterSevenTemplate = () => import(/* webpackChunkName: "FooterSevenTemplate" */ '@@@/components/elements/footerSevenTemplate')
const CookiePolicy = () => import(/* webpackChunkName: "CookiePolicy" */ '@@@/components/elements/cookiePolicy')
const Section = () => import(/* webpackChunkName: "Section" */ '@@@/components/grid/Section/SectionRender')

export default {
  name: 'App',
  components: {
    Section,
    CookiePolicy,
    FooterTemplate,
    FooterSmallTemplate,
    FooterOneTemplate,
    FooterTwoTemplate,
    FooterFourTemplate,
    FooterThreeTemplate,
    FooterEightTemplate,
    FooterSixTemplate,
    FooterSevenTemplate,
    TemplateDialog
  },
  data: function () {
    return {
      redirectList: [],
      pageIndex: -1,
      sectionRef: getUniqueId(),
      sectionRef2: getUniqueId(),
      showCookiePolicy: false,
      footerTemplate: SUB_TYPE_FOOTER,
      footerSmallTemplate: SUB_TYPE_FOOTER_SMALL,
      footerOneTemplate: SUB_TYPE_FOOTER_ONE,
      footerTwoTemplate: SUB_TYPE_FOOTER_TWO,
      footerThreeTemplate: SUB_TYPE_FOOTER_THREE,
      footerEightTemplate: SUB_TYPE_FOOTER_EIGHT,
      footerFourTemplate: SUB_TYPE_FOOTER_FOUR,
      footerFiveTemplate: SUB_TYPE_FOOTER_FIVE,
      footerSixTemplate: SUB_TYPE_FOOTER_SIX,
      footerSevenTemplate: SUB_TYPE_FOOTER_SEVEN,
      objRoute: {
        viewVideoPage: SUB_TYPE_SERMONS_WIDGET,
        viewEventPage: SUB_TYPE_EVENTS_WIDGET,
        viewNewsPage: SUB_TYPE_NEWS_WIDGET,
        viewBlogPage: SUB_TYPE_BLOG_WIDGET,
        viewDevotionPage: SUB_TYPE_DEVOTIONS_WIDGET,
        viewReadingPlanPage: SUB_TYPE_READING_PLAN_WIDGET,
        viewStaffPage: SUB_TYPE_STAFF_WIDGET,
        viewAlbumPage: SUB_TYPE_PHOTO_WIDGET,
        viewPhotoPage: SUB_TYPE_PHOTO_WIDGET
      }
    }
  },
  computed: {
    language: {
      get: function () {
        return this.$store.getters.language
      },
      set: function (data) {
        this.$store.dispatch('setLanguage', data)
      }
    },
    viewMode () {
      return this.$store.getters.viewMode
    },
    isMobile () {
      return this.viewMode === MODE_MOBILE
    },
    snowTemplateDialog () {
      const { siteSettings = {} } = this.currentSite
      const { snowTemplateDialog = false } = siteSettings
      return snowTemplateDialog
    },
    showHeader () {
      if (!this.currentPage || Object.keys(this.$store.getters.generalSettingsSb).length === 0) return false
      const pageSettings = get(this.currentPage, 'jsonStructure.pageSettings')
      if (!pageSettings) return false
      if (pageSettings.showHeader === undefined) {
        this.$set(pageSettings, 'showHeader', true)
      }
      return pageSettings.showHeader
    },
    floatHeader () {
      if (!this.currentPage) return false
      const pageSettings = get(this.currentPage, 'jsonStructure.pageSettings')
      if (!pageSettings) return false
      if (pageSettings.floatHeader === undefined) {
        this.$set(pageSettings, 'floatHeader', false)
      }
      return pageSettings.floatHeader
    },
    showPageFooter () {
      if (!this.currentPage || Object.keys(this.$store.getters.generalSettingsSb).length === 0) return false
      const pageSettings = get(this.currentPage, 'jsonStructure.pageSettings')
      if (!pageSettings) return false
      if (pageSettings.showFooter === undefined) {
        this.$set(pageSettings, 'showFooter', true)
      }
      return pageSettings.showFooter
    },
    siteData: function () {
      return this.$store.getters.siteData
    },
    page: {
      get: function () {
        return this.$store.getters.currentPage
      },
      set: function (data) {
        this.$store.dispatch('setCurrentPage', data)
      }
    },
    showFooter: function () {
      return this.$store.getters.routeLeave
    },
    currentSite: function () {
      return this.$store.getters.currentSite
    },
    currentPage: function () {
      if (this.pageIndex !== -1) {
        return this.currentSite.pages[this.pageIndex]
      } else {
        return false
      }
    },
    navigationSectionItem: function () {
      if (!this.currentPage) return false
      const { sections } = this.currentPage
      const navSection = sections.filter((obj) => {
        try {
          if (obj.elementSettings[SECTION_TEMPLATE_SETTINGS].header === true) {
            return true
          }
        } catch (e) {}
        if (obj.endToEndId !== undefined) {
          return true
        }
        return false
      })
      if (navSection.length !== 0) {
        const sections = []
        for (let i = 0; i < navSection.length; i++) {
          // eslint-disable-next-line no-empty
          if (navSection[i].endToEndId !== undefined) {
            console.log('navSection[i].endToEndId', navSection[i])
          } else {
            sections.push(navSection[i])
          }
        }
        return sections.length > 0 ? sections : false
      } else {
        return false
      }
    },
    siteNavigation: function () {
      const { siteNav = [], activeSiteNavId } = this.currentSite
      const activeSiteNav = siteNav.find(obj => obj.id === activeSiteNavId)
      return activeSiteNav || false
    },
    generalSettingsSb: function () {
      return this.$store.getters.generalSettingsSb
    },
    googleFonts: {
      get: function () {
        return getGoogleFontList(this.$store.getters.googleFonts)
      },
      set: function (data) {
        this.$store.dispatch('setGoogleFonts', data)
      }
    }
  },
  watch: {
    $route: {
      deep: true,
      handler: function () {
        this.checkRoute()
      }
    },
    pageIndex: function (newVal) {
      if (newVal !== -1) {
        this.page = this.currentPage
      }
    }
  },
  beforeMount: async function () {
    const scriptsNodeList = document.getElementsByTagName('script')
    const scriptsArr = [...scriptsNodeList]
    const scripts = scriptsArr.filter(obj => obj.dataset)
    const pagesRedirectScript = scripts.find(obj => obj.dataset.type === 'pagesRedirect')
    if (pagesRedirectScript !== undefined) {
      this.redirectList = JSON.parse(pagesRedirectScript.textContent)
    }
    // organizationSettings.getOrganizationSettings({userMid: this.$cookie.get('mid')}, (response) => {
    //   console.log('getOrganizationSettings', response.data)
    //   this.$store.dispatch('setOrganizationSettings', response.data)
    // })
    const { navigator } = window
    const language = navigator ? (navigator.language || navigator.systemLanguage || navigator.userLanguage) : 'en'
    this.language = language.substr(0, 2).toLowerCase() === 'ru' ? 'ru' : 'en'

    const organizationSettingsStore = localStorage.getItem('organizationSettings')
    const organizationSettings = organizationSettingsStore ? JSON.parse(organizationSettingsStore) : {}
    const { response, exp = 0 } = organizationSettings
    const defExp = new Date().getTime() - exp
    if (!response || defExp > 86400000) {
      this.getOrganizationSettings()
    } else {
      this.setOrganizationSettings(response)
      this.getOrganizationSettings()
    }
    const isCustomStyle = document.getElementById('customStyle') !== null
    const style = isCustomStyle
      ? document.getElementById('customStyle')
      : document.createElement('style')
    style.innerHTML = ''
    let innerStyle = ''
    try {
      innerStyle = document.createTextNode(this.currentSite.siteSettings.customCssCode)
    } catch (e) {
      innerStyle = document.createTextNode('')
    }
    await this.getAllFonts()
    try {
      const { fontFamily } = this.currentSite.siteSettings || {}
      const findFont = this.googleFonts.find(({ key }) => key === fontFamily)
      if (findFont) setFontOnSite(findFont.key, findFont.file)
    } catch (e) {
    }
    style.appendChild(innerStyle)
    if (!isCustomStyle) {
      style.setAttribute('id', 'customStyle')
      document.body.appendChild(style)
    }
    if (Object.keys(this.currentSite).length !== 0) {
      const { siteSettings: { favicons } } = this.currentSite
      const { organization = {} } = this.generalSettingsSb
      const { logo = {} } = organization
      if (favicons !== undefined) {
        const faviconBig = favicons.find((el) => el.size === 260)
        favicons.forEach((item) => {
          const { favicon, size } = item
          let faviconUrl
          if (size === 260) {
            faviconUrl = favicon || logo.svg
          } else {
            faviconUrl = favicon || this.getUrl(faviconBig, size) || logo.svg
          }
          const currentFavicon = document.createElement('link')
          currentFavicon.setAttribute('rel', 'shortcut icon')
          currentFavicon.setAttribute('href', faviconUrl)
          currentFavicon.setAttribute('type', 'image/png')
          currentFavicon.setAttribute('sizes', `${size}x${size}`)
          document.head.appendChild(currentFavicon)
        })
      }
    }
  },
  methods: {
    async getAllFonts () {
      const { data: { items } } = await fontsApi.getListFonts()
      this.googleFonts = items
    },
    getUrl ({ favicon }, size) {
      if (!favicon) return ''
      const link = favicon.split('/')
      const lastItem = link[link.length - 1]
      const url = favicon.slice(0, favicon.length - lastItem.length)
      return `${url}image/${size}x${size}/${lastItem}`
    },
    checkPage: function (routeName, modeView, ifStatement) {
      let page = {}
      const { pages } = this.currentSite
      for (const i in pages) {
        if (pages[i].pageSettings.visibility) {
          for (const j in pages[i].sections) {
            if (pages[i].sections[j].sub === this.objRoute[routeName] && (pages[i].modeView === modeView || (pages[i].modeView === undefined && ifStatement))) {
              page = pages[i]
            }
          }
        }
      }
      return page
    },
    checkRoute: async function () {
      let page = {}
      const redirectLink = this.redirectList.find(obj =>
        (obj.websiteUri === this.$route.fullPath || obj.websiteUri === this.$route.fullPath + '/') && obj.active)
      if (redirectLink === undefined) {
        const { pages = [] } = this.currentSite
        if (this.$route.fullPath === '/') {
          const byDefaultPage = pages.find((obj) => obj.byDefault)
          const indexPage = pages.find((obj) => obj.route === 'index' || obj.route === '/')
          page = byDefaultPage || indexPage
        } else {
          const needed = this.$route.name
          if (needed === 'page404') {
            page = pages.find(obj => obj.widgets && obj.widgets.indexOf('404') > -1)
            if (page === undefined) {
              this.$router.push('/')
            }
          } else {
            page = pages.find((obj) => obj.route === needed)
            if (!page) {
              const widgetName = this.$route.name
              // widgetsToRoutes.forEach((widget) =)
              for (const i in widgetsToRoutes) {
                if (widgetsToRoutes[i].find(obj => obj.name === widgetName) !== undefined) {
                  page = pages.find(obj => obj.widgets && obj.widgets.indexOf(i) > -1 && this.$route.fullPath.indexOf(obj.route) > -1)
                }
              }
            } else {
              const { active } = page
              if (!active) {
                page = pages.find(obj => obj.widgets && obj.widgets.indexOf('404') > -1)
                if (page === undefined) {
                  this.$router.push('/')
                }
              }
            }
          }
        }
        this.pageIndex = pages.indexOf(page)
      } else {
        window.open(redirectLink.redirectUrl, '_self')
      }
    },
    getOrganizationSettings () {
      organizationApi.getApplicationConfiguration().then((response) => {
        const organizationSettings = {
          response: {
            data: response.data
          },
          exp: new Date().getTime()
        }
        localStorage.setItem('organizationSettings', JSON.stringify(organizationSettings))
        this.setOrganizationSettings(response)
      })
    },
    setOrganizationSettings (response) {
      this.$store.dispatch('updateGeneralSettingsSb', { response })
      const { organization } = response.data
      const settings = {
        ...organization
      }
      this.$store.dispatch('setOrganizationSettings', settings)
      const templates = get(response, 'data.organization.templates', {})
      const { cookieNotice, privacyPolicy } = templates
      this.$store.dispatch('setCookiePolicy', cookieNotice)
      this.$store.dispatch('setPrivacyPolicy', privacyPolicy)
      this.showCookiePolicy = this.$cookie.get('show') !== 'false'
    }
  }
}
</script>

<style lang="scss">
@import "./sass/render";

.render-website {
  .ql-tooltip-editor-custom {
    display: none!important;
  }

  .site-builder {
    overflow: hidden;
  }
}

.fade-section-enter-active, .fade-section-leave-active {
  transition: opacity 200ms ease-in;
}

.fade-section-enter, .fade-section-leave-to {
  opacity: 0;
}

.fade-section-leave, .fade-section-enter-to {
  opacity: 1;
}

.tns-fade-route-enter-active {
  transition: opacity 100ms ease-out;
}

.tns-fade-route-enter, .tns-fade-route-leave-to {
  opacity: 0;
}

.fade-footer-enter-active {
  transition: opacity 1000ms ease-in;
}
.fade-footer-leave-active {
  transition: opacity 0ms ease-in;
}

.fade-footer-enter, .fade-footer-leave-to {
  opacity: 0;
}

.fade-footer-leave, .fade-footer-enter-to {
  opacity: 1;
}

.fade-cookie-policy-enter-active, .fade-cookie-policy-leave-active {
  transition: opacity 200ms linear;
}

.fade-cookie-policy-enter, .fade-cookie-policy-leave-to {
  opacity: 0;
}

.fade-el-leave, .fade-el-enter-to {
  opacity: 1;
}

</style>
<style lang="scss" scoped>
#app {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  //overflow: auto;
}

.layout-app-footer {
  /*padding-top: 15px;*/
  margin-top: auto;
}

.site-builder-header {
  position: relative;
  z-index: 101;
  top: 0;

  &_mobile {
    position: sticky;
    z-index: 101;
    box-shadow: 0 0 14px rgba(25, 25, 39, 0.1);
  }

}

.layout-app-content {
  position: relative;
  left: 0;
  transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1) 0s
}

</style>
