import * as types from './../../../constants/store/mutationTypes'
// const moment = require('moment')
const state = {
  ticketsDataForEvent: [],
  selectedTickets: [],
  ticketCount: {},
  phonesCodes: [],
  countryList: [],
  disableMinus: {},
  disablePlus: {},
  ticketTypeUserInfo: {},
  modalLoginShow: false,
  cardValidator: {},
  cardDataObject: {
    cardHolder: '',
    cardNumber: '',
    month: '',
    year: '',
    cvv: ''
  },
  achValidator: {},
  achDataObject: {
    ach_first_name: '',
    ach_last_name: '',
    ach_routing: '',
    ach_account: ''
  },
  ticketStep: 1,
  buyTicketEventStatus: false,
  ticketListId: '',
  userMid: ''
}
const getters = {
  ticketsDataForEvent: state => state.ticketsDataForEvent,
  ticketTypeUserInfo: state => state.ticketTypeUserInfo,
  ticketCount: state => state.ticketCount,
  selectedTickets: state => state.selectedTickets,
  phonesCodes: state => state.phonesCodes,
  countryList: state => state.countryList,
  disableMinus: state => state.disableMinus,
  disablePlus: state => state.disablePlus,
  cardValidator: state => state.cardValidator,
  cardDataObject: state => state.cardDataObject,
  modalLoginShow: state => state.modalLoginShow,
  ticketStep: state => state.ticketStep,
  buyTicketEventStatus: state => state.buyTicketEventStatus,
  achValidator: state => state.achValidator,
  achDataObject: state => state.achDataObject,
  ticketListId: state => state.ticketListId,
  userMid: state => state.userMid

}
const actions = {
  setTicketsDataForEvent ({ commit }, data) {
    commit(types.SET_TICKETS_DATA_FOR_EVENT, { data: data })
  },
  setSelectedTicketsForEvent ({ commit }, data) {
    commit(types.SET_SELECTED_TICKETS, { data: data })
  },
  setDisableMinus ({ commit }, data) {
    commit(types.SET_DISABLE_MINUS, data)
  },
  setDisablePlus ({ commit }, { data }) {
    commit(types.SET_DISABLE_PLUS, data)
  },
  setPhonesCodes ({ commit }, data) {
    commit(types.SET_PHONES_CODES, { data: data })
  },
  setCountryList ({ commit }, data) {
    commit(types.SET_COUNTRY_LIST, { data: data })
  },
  setTicketsCount ({ commit }, data) {
    commit(types.SET_TICKETS_COUNT, { data: data })
  },
  setTicketTypeUserInfo ({ commit }, data) {
    commit(types.SET_TICKETS_USER_INFO, { data: data })
  },
  setModalLoginShow ({ commit }, data) {
    commit(types.SET_MODAL_LOGIN_SHOW, data)
  },
  setCardValidator ({ commit }, data) {
    commit(types.SET_CARD_VALIDATOR, data)
  },
  setAchValidator ({ commit }, data) {
    commit(types.SET_ACH_VALIDATOR, data)
  },
  setCardDataObject ({ commit }, data) {
    commit(types.SET_CARD_DATA_OBJECT, data)
  },
  setAchDataObject ({ commit }, data) {
    commit(types.SET_ACH_DATA_OBJECT, data)
  },
  setTicketStep ({ commit }, data) {
    commit(types.SET_TICKET_STEP, data)
  },
  setBuyTicketEventStatus ({ commit }, data) {
    commit(types.SET_BUY_TICKET_EVENT_STATUS, data)
  },
  setTicketListId ({ commit }, data) {
    commit(types.SET_TICKET_LIST_ID, data)
  },
  setUserMid ({ commit }, data) {
    commit(types.SET_USER_MID, data)
  }
}
const mutations = {
  [types.SET_TICKETS_DATA_FOR_EVENT] (state, { data }) {
    data.forEach((item) => {
      if (item.price === undefined) {
        item.price = 0
      }
      item.specialPrice = {
        value: item.price,
        currency: item.currency || 'USD'
      }
    })
    state.ticketsDataForEvent = data
  },
  [types.SET_TICKETS_USER_INFO] (state, { data }) {
    state.ticketTypeUserInfo = data
  },
  [types.SET_TICKETS_COUNT] (state, { data }) {
    state.ticketCount = data
  },
  [types.SET_SELECTED_TICKETS] (state, { data }) {
    state.selectedTickets = data
  },
  [types.SET_PHONES_CODES] (state, { data }) {
    state.phonesCodes = data
  },
  [types.SET_COUNTRY_LIST] (state, { data }) {
    state.countryList = data
  },
  [types.SET_DISABLE_MINUS] (state, data) {
    state.disableMinus = data
  },
  [types.SET_DISABLE_PLUS] (state, data) {
    state.disablePlus = data
  },
  [types.SET_MODAL_LOGIN_SHOW] (state, data) {
    state.modalLoginShow = data
  },
  [types.SET_CARD_VALIDATOR] (state, data) {
    state.cardValidator = data
  },
  [types.SET_ACH_VALIDATOR] (state, data) {
    state.achValidator = data
  },
  [types.SET_CARD_DATA_OBJECT] (state, data) {
    state.cardDataObject = data
  },
  [types.SET_ACH_DATA_OBJECT] (state, data) {
    state.achDataObject = data
  },
  [types.SET_TICKET_STEP] (state, data) {
    state.ticketStep = data
  },
  [types.SET_BUY_TICKET_EVENT_STATUS] (state, data) {
    state.buyTicketEventStatus = data
  },
  [types.SET_TICKET_LIST_ID] (state, data) {
    state.ticketListId = data
  },
  [types.SET_USER_MID] (state, data) {
    state.userMid = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
