import * as types from '../mutations-types'

const state = {
  videoContent: 'sermon'
}

const getters = {
  videoContent: state => state.videoContent
}
const actions = {
  setActiveVideo ({ commit }, route) {
    commit(types.VIDEO_CONTENT, { route: route })
  }
}
const mutations = {
  [types.VIDEO_CONTENT] (state, { route }) {
    state.videoContent = route
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
