import { DROPDOWN_SELECT } from '../../../types/fieldsTypes'

export default {
  name: 'Untitled',
  title: 'Untitled',
  type: DROPDOWN_SELECT,
  group: 'regular',
  properties: {
    options: {
      values: [
        {
          id: 'optional',
          title: 'Optional Field'
        },
        {
          id: 'required',
          title: 'Required Field'
        },
        {
          id: 'max_quantity',
          title: 'Max Quantities',
          description: 'Only this number of checks is available'
        }
      ],
      selected: {
        id: ['optional']
      }
    },
    visibility: {
      values: [
        {
          id: 'everyone',
          title: 'Everyone',
          description: 'Every person could fill your form'
        },
        {
          id: 'admin',
          title: 'Admin Only'
        },
        {
          id: 'registered',
          title: 'Registered Users Only'
        },
        {
          id: 'custom_category',
          title: 'Custom Category Only',
          description: 'Visible only to this category of users'
        }
      ],
      selected: {
        id: 'everyone',
        categories: []
      }
    },
    size: {
      description: 'Display size',
      values: [
        {
          id: 'small',
          title: 'Small'
        },
        {
          id: 'medium',
          title: 'Medium'
        },
        {
          id: 'large',
          title: 'Large'
        }
      ],
      selected: {
        id: 'large'
      }
    },
    choices: {
      description: '...',
      selected: [
        {
          maxQuantity: null,
          label: 'Option 1',
          default: false
        },
        {
          maxQuantity: null,
          label: 'Option 2',
          default: false
        },
        {
          maxQuantity: null,
          label: 'Option 3',
          default: false
        }
      ]
    },
    instructions: {
      optional: true,
      selected: ' '
    },
    system: {
      selected: false
    }
  }
  // size: 'small',
  // options: 'options',
  // showTo: 'everyone',
  // selections: [{
  //   value: 'your_selection',
  //   label: 'Your Selection'
  // }],
  // predefined: '',
  // placeholder: 'Placeholder Text',
  // instructions: ''
}
