import * as types from './../../constants/store/mutations-types'
import _ from 'lodash'

const state = {
  photoListAll: [],
  photoCompactListAll: [],
  currentPhotoCount: 1,
  photoSortQuery: '',
  photoSearchQuery: '',
  infiniteLoadingRefPhoto: false,
  notFoundPhoto: false,
  noDataPhotoPost: false,
  notFoundPhotoCompact: false,
  presetPhotoImg: false,
  countAlbums: null,
  photoListSetTimeoutId: false,
  photoCompactListSetTimeoutId: -1
  // printStatus: false
}

const getters = {
  photoListAll: state => state.photoListAll,
  photoCompactListAll: state => state.photoCompactListAll,
  currentPhotoCount: state => state.currentPhotoCount,
  photoSortQuery: state => state.photoSortQuery,
  photoSearchQuery: state => state.photoSearchQuery,
  infiniteLoadingRefPhoto: state => state.infiniteLoadingRefPhoto,
  notFoundPhoto: state => state.notFoundPhoto,
  notFoundPhotoCompact: state => state.notFoundPhotoCompact,
  noDataPhotoPost: state => state.noDataPhotoPost,
  presetPhotoImg: state => state.presetPhotoImg,
  countAlbums: state => state.countAlbums
  // printStatus: state => state.printStatus
}
const actions = {
  setAllPhotoData ({ commit }, data) {
    commit(types.SET_ALL_PHOTO_DATA, { data: data })
  },
  pushNewPhotoData ({ commit }, data) {
    commit(types.PUSH_NEW_PHOTO_DATA, { data: data })
  },
  pushNewPhotoCompactData ({ commit }, data) {
    commit(types.PUSH_NEW_PHOTO_COMPACT_DATA, { data: data })
  },
  setCurrentPhotoCount ({ commit }, data) {
    commit(types.SET_CURRENT_PHOTO_COUNT, { data: data })
  },
  setPhotoSortQuery ({ commit }, data) {
    commit(types.SET_PHOTO_SORT_QUERY, data)
  },
  setPhotoSearchQuery ({ commit }, data) {
    commit(types.SET_PHOTO_SEARCH_QUERY, data)
  },
  setInfiniteLoadingRefPhoto ({ commit }, data) {
    commit(types.SET_INFINITE_LOADING_REF_PHOTO, data)
  },
  setNotFoundPhoto ({ commit }, data) {
    commit(types.SET_NOT_FOUND_PHOTO, data)
  },
  setNotFoundPhotoCompact ({ commit }, data) {
    commit(types.SET_NOT_FOUND_PHOTO_COMPACT, data)
  },
  setNoDataPhotoPost ({ commit }, data) {
    commit(types.SET_NO_DATA_PHOTO_POST, data)
  },
  presetPhotoImageUpdate ({ commit }, data) {
    commit(types.PRESET_PHOTO_IMAGE_UPDATE, data)
  }
  // setPrintStatus ({commit}, data) {
  //   commit(types.SET_PRINT_STATUS, data)
  // }
}
const mutations = {
  [types.SET_ALL_PHOTO_DATA] (state, { data }) {
    if (data.length > 0) {
      state.photoListAll = data.items
    } else {
      state.photoListAll = []
    }
  },
  [types.PUSH_NEW_PHOTO_DATA] (state, { data }) {
    clearTimeout(state.photoListSetTimeoutId)
    state.countAlbums = data.totalCount
    for (let i = 0; i < data.items.length; i++) {
      if (_.find(state.photoListAll, function (obj) {
        return obj.id === data.items[i].id
      }) === undefined) {
        if (data.items[i].description === undefined) {
          data.items[i].description = ''
        }
        state.photoListSetTimeoutId = setTimeout(function () {
          if (_.find(state.photoListAll, function (obj) {
            return obj.id === data.items[i].id
          }) === undefined) {
            state.photoListAll.push(data.items[i])
          }
        }, i * 200)
      }
    }
  },
  [types.PUSH_NEW_PHOTO_COMPACT_DATA] (state, { data }) {
    clearTimeout(state.photoCompactListSetTimeoutId)
    // state.countAlbums = data.total_results
    for (let i = 0; i < data.items.length; i++) {
      if (_.find(state.photoCompactListAll, function (obj) {
        return obj.id === data.items[i].id
      }) === undefined) {
        if (data.items[i].description === undefined) {
          data.items[i].description = ''
        }
        state.photoCompactListSetTimeoutId = setTimeout(function () {
          if (_.find(state.photoCompactListAll, function (obj) {
            return obj.id === data.items[i].id
          }) === undefined) {
            state.photoCompactListAll.push(data.items[i])
          }
        }, i * 200)
      }
    }
  },
  [types.SET_CURRENT_PHOTO_COUNT] (state, { data }) {
    state.currentPhotoCount = data
  },
  [types.SET_PHOTO_SORT_QUERY] (state, data) {
    state.photoSortQuery = data
    state.currentPhotoCount = 1
  },
  [types.SET_PHOTO_SEARCH_QUERY] (state, data) {
    state.photoSearchQuery = data
    state.currentPhotoCount = 1
  },
  [types.SET_INFINITE_LOADING_REF_PHOTO] (state, data) {
    state.infiniteLoadingRefPhoto = data
  },
  [types.SET_NOT_FOUND_PHOTO] (state, data) {
    state.notFoundPhoto = data
  },
  [types.SET_NOT_FOUND_PHOTO_COMPACT] (state, data) {
    state.notFoundPhotoCompact = data
  },
  [types.SET_NO_DATA_PHOTO_POST] (state, data) {
    state.noDataPhotoPost = data
  },
  [types.PRESET_PHOTO_IMAGE_UPDATE] (state, data) {
    state.presetPhotoImg = data
  }
  // [types.SET_PRINT_STATUS] (state, data) {
  //   state.printStatus = data
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
