import * as types from './../../constants/store/mutations-types'
import * as TabsEventList from '../../../../public/constants/tabs/tabsListWidgets'
import _ from 'lodash'

const state = {
  eventDataArray: [],
  activeEventId: false
  // videoListSlider: [],
  // videoListMostViewed: [],
  // oneVideoItem: {},
  // seriesList: [],
  // massForCheck: [],
  // massForCheckSeries: []
}

const getters = {
  eventDataArray: state => state.eventDataArray,
  activeEventId: state => state.activeEventId
  // videoListSlider: state => state.videoListSlider,
  // videoListMostViewed: state => state.videoListMostViewed,
  // oneVideoItem: state => state.oneVideoItem,
  // seriesList: state => state.seriesList,
  // massForCheck: state => state.massForCheck,
  // massForCheckSeries: state => state.massForCheckSeries
}
const actions = {
  setEventData ({ commit }, data) {
    commit(types.SET_EVENT_DATA, { data: data })
  }
}
const mutations = {
  [types.SET_EVENT_DATA] (state, { data }) {
    const activeEvent = _.find(state.eventDataArray, function (obj) {
      return obj.active === true
    })
    if (activeEvent !== undefined) {
      activeEvent.active = false
    }
    if (_.find(state.eventDataArray, function (obj) {
      return obj.id === data.id
    }) === undefined) {
      data.active = true
      data.activeTab = TabsEventList.TABS_NAME_EVENT.TABS_ABOUT
      state.eventDataArray.push(data)
    }
    state.activeEventId = data.id
    // state.eventsListAll = data.items
  }
  // [types.SET_MOST_VIEWED] (state, {data}) {
  //   state.videoListMostViewed = data
  // },
  // [types.RELOAD_VIDEO_LIST] (state) {
  //   state.massForCheck = []
  //   state.eventsListAll = []
  // },
  // [types.RELOAD_SERIES_LIST] (state) {
  //   state.massForCheckSeries = []
  //   state.seriesList = []
  // },
  // [types.SAVE_PLAYBACK] (state, {id, pb}) {
  //   if (state.eventsListAll.length !== 0) {
  //     if (_.find(state.eventsListAll, function (obj) { return obj.video_id === id }) !== undefined) {
  //       state.eventsListAll[_.indexOf(state.eventsListAll, _.find(state.eventsListAll, function (obj) { return obj.video_id === id }))]['video_played'] = pb
  //     }
  //   }
  //   if (this.getters.sermonsListForSerie.length !== 0) {
  //     if (_.find(this.getters.sermonsListForSerie, function (obj) { return obj.video_id === id }) !== undefined) {
  //       this.getters.sermonsListForSerie[_.indexOf(this.getters.sermonsListForSerie, _.find(this.getters.sermonsListForSerie, function (obj) { return obj.video_id === id }))]['video_played'] = pb
  //     }
  //   }
  // },
  // [types.SAVE_PLAYBACK_AUDIO] (state, {id, pb}) {
  //   if (state.eventsListAll.length !== 0) {
  //     if (_.find(state.eventsListAll, function (obj) { return obj.audio_id === id }) !== undefined) {
  //       state.eventsListAll[_.indexOf(state.eventsListAll, _.find(state.eventsListAll, function (obj) { return obj.audio_id === id }))]['audio_played'] = pb
  //     }
  //   }
  // },
  // [types.LOAD_SLIDER_VIDEO_LIST] (state, {data}) {
  //   if (state.videoListSlider.length < 2) {
  //     for (var i = 0; i < 3; i++) {
  //       state.videoListSlider.push(data[i])
  //     }
  //   }
  // },
  // [types.LOAD_ONE_VIDEO_ITEM] (state, {data}) {
  //   state.oneVideoItem = data
  // },
  // [types.PUSH_NEW_VIDEO_DATA] (state, {data, query}) {
  //   for (var i = 0; i < data.length; i++) {
  //     if (_.find(state.massForCheck, function (obj) {
  //       return obj.id === data[i].id
  //     }) === undefined) {
  //       if (query === this.getters.sortString) {
  //         state.massForCheck.push(data[i])
  //       }
  //       setTimeout(addd, i * 100, data[i], query, this)
  //     }
  //   }
  //   function addd (item, query, owner) {
  //     if (query === owner.getters.sortString) {
  //       state.eventsListAll.push(item)
  //     }
  //   }
  // },
  // [types.PUSH_NEW_SERIES] (state, {data, query}) {
  //   for (var i = 0; i < data.length; i++) {
  //     if (_.find(state.massForCheckSeries, function (obj) {
  //       return obj.id === data[i].id
  //     }) === undefined) {
  //       if (query === this.getters.seriesSortString) {
  //         state.massForCheckSeries.push(data[i])
  //       }
  //       setTimeout(addd, i * 70, data[i], query, this)
  //     }
  //   }
  //   function addd (item, query, owner) {
  //     if (query === owner.getters.seriesSortString) {
  //       state.seriesList.push(item)
  //     }
  //   }
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
