import {
  SINGLE_LINE_TEXT, MULTI_LINE_TEXT, DROPDOWN_SELECT, NUMBER, SINGLE_CHECKBOX,
  CHECKBOX, RADIO, TIME, NAME, DATE, PHONE, ADDRESS, ACTION_BUTTON, PAYMENT, PERSON, UPLOAD_BUTTON
} from '../types/fieldsTypes'
import singleLineText from '../defaults/fields/regular/singleLineText'
import multiLineText from '../defaults/fields/regular/multiLineText'
import number from '../defaults/fields/regular/number'
import dropdownSelect from '../defaults/fields/regular/dropdownSelect'
import singleCheckbox from '../defaults/fields/regular/singleCheckbox'
import multipleCheckbox from '../defaults/fields/regular/multipleCheckbox'
import radioSelect from '../defaults/fields/regular/radioSelect'
import payment from '../defaults/fields/regular/payment'
import uploadButton from '../defaults/fields/advanced/uploadButton'

import time from '../defaults/fields/advanced/time'
import phone from '../defaults/fields/advanced/phone'
import name from '../defaults/fields/advanced/name'
import date from '../defaults/fields/advanced/date'
import address from '../defaults/fields/advanced/address'
import person from '../defaults/fields/advanced/person'
import actionButton from '../defaults/fields/advanced/actionButton'

export default {
  [SINGLE_LINE_TEXT]: singleLineText,
  [MULTI_LINE_TEXT]: multiLineText,
  [DROPDOWN_SELECT]: dropdownSelect,
  [NUMBER]: number,
  [SINGLE_CHECKBOX]: singleCheckbox,
  [CHECKBOX]: multipleCheckbox,
  [RADIO]: radioSelect,

  [TIME]: time,
  [PHONE]: phone,
  [NAME]: name,
  [DATE]: date,
  [ADDRESS]: address,
  [PERSON]: person,
  [ACTION_BUTTON]: actionButton,
  [UPLOAD_BUTTON]: uploadButton,
  [PAYMENT]: payment
}
