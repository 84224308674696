import * as types from '../../mutations-types'

const state = {
  viewModelId: 0,
  activeViewModelId: 0,
  sermonsListForSerie: [],
  activeIds: 0
}

const getters = {
  viewModelId: state => state.viewModelId,
  activeViewModelId: state => state.activeViewModelId,
  sermonsListForSerie: state => state.sermonsListForSerie,
  activeIds: state => state.activeIds
}
const actions = {
  setViewModel ({ commit }, data) {
    commit(types.SET_VIEW_MODEL, { data: data })
  },
  setViewActiveModelId ({ commit }, id) {
    commit(types.SET_VIEW_ACTIVE_MODEL, { id: id })
  },
  setListSermonsForSerie ({ commit }, data) {
    commit(types.SET_SERMONS_FOR_SERIE, { data: data })
  },
  setActiveIds ({ commit }, data) {
    commit(types.SET_ACTIVE_IDS, { data: data })
  }

}
const mutations = {
  [types.SET_VIEW_MODEL] (state, { data }) {
    state.viewModelId = parseInt(data)
  },
  [types.SET_SERMONS_FOR_SERIE] (state, { data }) {
    state.sermonsListForSerie = []
    for (let i = 0; i < data.length; i++) {
      setTimeout(addd, i * 100, data[i])
    }
    function addd (item) {
      state.sermonsListForSerie.push(item)
    }
  },
  [types.SET_VIEW_ACTIVE_MODEL] (state, { id }) {
    state.activeViewModelId = parseInt(id)
  },
  [types.SET_ACTIVE_IDS] (state, { data }) {
    state.activeIds = parseInt(data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
