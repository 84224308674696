import * as types from './../../constants/store/mutations-types'
import _ from 'lodash'
const state = {
  photoAlbumListData: [],
  photoAlbumsActive: -1,
  currentPhotoAlbumsPage: [],
  albumPhotoAllData: false,
  photoInAlbumSortQuery: '',
  photoInAlbumSearchQuery: '',
  infiniteLoadingRefPhotoInAlbum: false,
  notFoundPhotoInAlbum: false,
  presetAlbumPhotoImg: false,
  photoAlbumListListSetTimeoutId: false
  // printStatus: false
}

const getters = {
  photoAlbumListData: state => state.photoAlbumListData,
  photoAlbumsActive: state => state.photoAlbumsActive,
  currentPhotoAlbumsPage: state => state.currentPhotoAlbumsPage,
  albumPhotoAllData: state => state.albumPhotoAllData,
  photoInAlbumSortQuery: state => state.photoInAlbumSortQuery,
  photoInAlbumSearchQuery: state => state.photoInAlbumSearchQuery,
  infiniteLoadingRefPhotoInAlbum: state => state.infiniteLoadingRefPhotoInAlbum,
  notFoundPhotoInAlbum: state => state.notFoundPhotoInAlbum,
  presetAlbumPhotoImg: state => state.presetAlbumPhotoImg
}
const actions = {
  pushPhotoAlbumData ({ commit }, data) {
    commit(types.PUSH_PHOTO_ALBUM_DATA, { data: data })
  },
  setPhotoAlbumActive ({ commit }, data) {
    commit(types.SET_PHOTO_ALBUM_ACTIVE, data)
  },
  clearPhotoAlbumData ({ commit }, data) {
    commit(types.CLEAR_PHOTO_ALBUM_DATA, data)
  },
  setCurrentPhotoAlbumsPage ({ commit }, data) {
    commit(types.SET_CURRENT_PHOTO_ALBUMS_PAGE, data)
  },
  setAlbumPhotoAllData ({ commit }, data) {
    commit(types.SET_ALBUM_PHOTO_ALL_DATA, data)
  },
  setPhotoInAlbumSortQuery ({ commit }, data) {
    commit(types.SET_PHOTO_IN_ALBUM_SORT_QUERY, data)
  },
  setPhotoInAlbumSearchQuery ({ commit }, data) {
    commit(types.SET_PHOTO_IN_ALBUM_SEARCH_QUERY, data)
  },
  setInfiniteLoadingRefPhotoInAlbum ({ commit }, data) {
    commit(types.SET_INFINITE_LOADING_REF_PHOTO_IN_ALBUM, data)
  },
  setNotFoundPhotoInAlbum ({ commit }, data) {
    commit(types.SET_NOT_FOUND_PHOTO_IN_ALBUM, data)
  },
  setPresetAlbumPhotoImg ({ commit }, data) {
    commit(types.SET_PRESET_ALBUM_PHOTO_IMG, data)
  }
}
const mutations = {
  [types.PUSH_PHOTO_ALBUM_DATA] (state, { data }) {
    const currentPhotoAlbumIndex = _.indexOf(state.photoAlbumListData, _.find(state.photoAlbumListData, function (obj) {
      return obj.id === state.photoAlbumsActive
    }))
    if (currentPhotoAlbumIndex === -1) {
      const dataObj = {
        id: state.photoAlbumsActive,
        data: [],
        totalPhoto: data.totalCount
      }
      state.photoAlbumListData.push(dataObj)
      clearTimeout(state.photoAlbumListListSetTimeoutId)
      for (let i = 0; i <= data.items.length; i++) {
        state.photoAlbumListListSetTimeoutId = setTimeout(function () {
          if (_.find(dataObj.data, obj => obj.id === data.items[i].id) === undefined) {
            dataObj.data.push(data.items[i])
          }
        }, i * 200)
      }
    } else {
      clearTimeout(state.photoAlbumListListSetTimeoutId)
      for (let i = 0; i <= data.items.length; i++) {
        state.photoAlbumListListSetTimeoutId = setTimeout(function () {
          if (_.find(state.photoAlbumListData[currentPhotoAlbumIndex].data, obj => obj.id === data.items[i].id) === undefined) {
            state.photoAlbumListData[currentPhotoAlbumIndex].data.push(data.items[i])
          }
        }, i * 200)
      }
      state.photoAlbumListData[currentPhotoAlbumIndex].totalPhoto = data.totalCount
    }
  },
  [types.SET_PHOTO_ALBUM_ACTIVE] (state, data) {
    state.photoAlbumsActive = data
  },
  [types.SET_CURRENT_PHOTO_ALBUMS_PAGE] (state, data) {
    const currentPhotoAlbum = _.find(state.currentPhotoAlbumsPage, function (obj) {
      return obj.id === state.photoAlbumsActive
    })
    if (currentPhotoAlbum === undefined) {
      state.currentPhotoAlbumsPage.push({
        id: state.photoAlbumsActive,
        page: data
      })
    } else {
      state.currentPhotoAlbumsPage[state.currentPhotoAlbumsPage.indexOf(currentPhotoAlbum)].page = data
    }
  },
  [types.SET_ALBUM_PHOTO_ALL_DATA] (state, data) {
    state.albumPhotoAllData = data
  },
  [types.SET_PHOTO_IN_ALBUM_SORT_QUERY] (state, data) {
    state.photoInAlbumSortQuery = data
  },
  [types.SET_PHOTO_IN_ALBUM_SEARCH_QUERY] (state, data) {
    state.photoInAlbumSearchQuery = data
  },
  [types.CLEAR_PHOTO_ALBUM_DATA] (state, data) {
    const currentPhotoAlbumIndex = _.indexOf(state.photoAlbumListData, _.find(state.photoAlbumListData, function (obj) {
      return obj.id === state.photoAlbumsActive
    }))
    state.photoAlbumListData[currentPhotoAlbumIndex].data = []
    const currentPhotoAlbum = _.find(state.currentPhotoAlbumsPage, function (obj) {
      return obj.id === state.photoAlbumsActive
    })
    state.currentPhotoAlbumsPage[state.currentPhotoAlbumsPage.indexOf(currentPhotoAlbum)].page = 1
  },
  [types.SET_INFINITE_LOADING_REF_PHOTO_IN_ALBUM] (state, data) {
    state.infiniteLoadingRefPhotoInAlbum = data
  },
  [types.SET_NOT_FOUND_PHOTO_IN_ALBUM] (state, data) {
    state.notFoundPhotoInAlbum = data
  },
  [types.SET_PRESET_ALBUM_PHOTO_IMG] (state, data) {
    state.presetAlbumPhotoImg = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
