import * as types from '@@@/store/constant/mutationsTypes'

const state = {
  cookiePolicy: '',
  privacyPolicy: ''
}

const getters = {
  cookiePolicy: state => state.cookiePolicy,
  privacyPolicy: state => state.privacyPolicy
}

const actions = {
  setCookiePolicy ({ commit }, data) {
    commit(types.SET_COOKIE_POLICY, data)
  },
  setPrivacyPolicy ({ commit }, data) {
    commit(types.SET_PRIVACY_POLICY, data)
  }
}

const mutations = {
  [types.SET_COOKIE_POLICY] (state, data) {
    const { text } = data
    state.cookiePolicy = text || data
  },
  [types.SET_PRIVACY_POLICY] (state, data) {
    const { text } = data
    state.privacyPolicy = text || data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
