import * as types from './../../constants/store/mutations-types'
import _ from 'lodash'

const state = {
  newsListAll: [],
  currentNewsCount: 1,
  newsFilterQuery: '',
  newsSearchQuery: '',
  infiniteLoadingRefNews: false,
  notFoundNews: false,
  noDataNewsPost: false,
  presetNewsImg: false,
  newsListSetTimeoutId: false
  // printStatus: false
}

const getters = {
  newsListAll: state => state.newsListAll,
  currentNewsCount: state => state.currentNewsCount,
  newsFilterQuery: state => state.newsFilterQuery,
  newsSearchQuery: state => state.newsSearchQuery,
  infiniteLoadingRefNews: state => state.infiniteLoadingRefNews,
  notFoundNews: state => state.notFoundNews,
  noDataNewsPost: state => state.noDataNewsPost,
  presetNewsImg: state => state.presetNewsImg
  // printStatus: state => state.printStatus
}
const actions = {
  setAllNewsData ({ commit }, data) {
    commit(types.SET_ALL_NEWS_DATA, { data: data })
  },
  pushNewNewsData ({ commit }, data) {
    commit(types.PUSH_NEW_NEWS_DATA, { data: data })
  },
  setCurrentNewsCount ({ commit }, data) {
    commit(types.SET_CURRENT_NEWS_COUNT, { data: data })
  },
  setNewsFilterQuery ({ commit }, data) {
    commit(types.SET_NEWS_FILTER_QUERY, data)
  },
  setNewsSearchQuery ({ commit }, data) {
    commit(types.SET_NEWS_SEARCH_QUERY, data)
  },
  setInfiniteLoadingRefNews ({ commit }, data) {
    commit(types.SET_INFINITE_LOADING_REF_NEWS, data)
  },
  setNotFoundNews ({ commit }, data) {
    commit(types.SET_NOT_FOUND_NEWS, data)
  },
  setNoDataNewsPost ({ commit }, data) {
    commit(types.SET_NO_DATA_NEWS_POST, data)
  },
  presetNewsImageUpdate ({ commit }, data) {
    commit(types.PRESET_NEWS_IMAGE_UPDATE, data)
  }
  // setPrintStatus ({commit}, data) {
  //   commit(types.SET_PRINT_STATUS, data)
  // }
}
const mutations = {
  [types.SET_ALL_NEWS_DATA] (state, { data }) {
    if (data.length > 0) {
      state.newsListAll = data
    } else {
      state.newsListAll = []
    }
  },
  [types.PUSH_NEW_NEWS_DATA] (state, { data }) {
    clearTimeout(state.newsListSetTimeoutId)
    for (let i = 0; i < data.items.length; i++) {
      if (_.find(state.newsListAll, function (obj) {
        return obj.id === data.items[i].id
      }) === undefined) {
        if (data.items[i].description === undefined) {
          data.items[i].description = ''
        }
        state.newsListSetTimeoutId = setTimeout(function () {
          state.newsListAll.push(data.items[i])
        }, i * 100)
      }
    }
  },
  [types.SET_CURRENT_NEWS_COUNT] (state, { data }) {
    state.currentNewsCount = data
  },
  [types.SET_NEWS_FILTER_QUERY] (state, data) {
    state.newsFilterQuery = data
  },
  [types.SET_NEWS_SEARCH_QUERY] (state, data) {
    state.newsSearchQuery = data
  },
  [types.SET_INFINITE_LOADING_REF_NEWS] (state, data) {
    state.infiniteLoadingRefNews = data
  },
  [types.SET_NOT_FOUND_NEWS] (state, data) {
    state.notFoundNews = data
  },
  [types.SET_NO_DATA_NEWS_POST] (state, data) {
    state.noDataNewsPost = data
  },
  [types.PRESET_NEWS_IMAGE_UPDATE] (state, data) {
    state.presetNewsImg = data
  }
  // [types.SET_PRINT_STATUS] (state, data) {
  //   state.printStatus = data
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
