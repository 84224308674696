import { getUniqueId } from '@@@/mixin/uniqueId'
import { SINGLE_LINE_TEXT } from '../../../types/fieldsTypes'

export default {
  title: 'Untitled',
  name: 'Untitled',
  type: SINGLE_LINE_TEXT,
  group: 'regular',
  id: getUniqueId(),
  syncCustomField: null,
  properties: {
    emailField: {
      optional: true,
      description: '',
      selected: false
    },
    size: {
      description: 'Display size',
      values: [
        {
          id: 'small',
          title: 'Small'
        },
        {
          id: 'medium',
          title: 'Medium'
        },
        {
          id: 'large',
          title: 'Large'
        }
      ],
      selected: {
        id: 'large'
      }
    },
    options: {
      values: [
        {
          id: 'optional',
          title: 'Optional Field'
        },
        {
          id: 'required',
          title: 'Required Field'
        },
        {
          id: 'no_duplicates',
          title: 'No Duplicates',
          description: 'No duplicates are available'
        },
        {
          id: 'encrypted',
          title: 'Encrypted',
          description: 'Field will be encrypted using AES256'
        }
      ],
      selected: {
        id: ['optional']
      }
    },
    visibility: {
      values: [
        {
          id: 'everyone',
          title: 'Everyone',
          description: 'Every person could fill your form'
        },
        {
          id: 'admin',
          title: 'Admin Only'
        },
        {
          id: 'registered',
          title: 'Registered Users Only'
        },
        {
          id: 'custom_category',
          title: 'Custom Category Only',
          description: 'Visible only to this category of users'
        }
      ],
      selected: {
        id: 'everyone',
        categories: []
      }
    },
    range: {
      description: 'Limit words of characters on this field',
      formats: [
        {
          id: 'character',
          title: 'Characters'
        },
        {
          id: 'word',
          title: 'Words'
        }
      ],
      selected: {
        min: 0,
        max: null,
        id: 'character'
      }
    },
    predefined: {
      optional: true,
      description: '',
      selected: null
    },
    placeholder: {
      optional: true,
      description: '',
      selected: null
    },
    instructions: {
      optional: true,
      selected: ' '
    },
    system: {
      selected: false
    }
  }
  // size: 'small',
  // options: 'options',
  // showTo: 'everyone',
  // range: {
  //   min: 0,
  //   max: 100,
  //   format: 'Characters'
  // },
  // predefined: '',
  // placeholder: 'Placeholder Text',
  // instructions: ''
}
