import * as types from '../../constants/store/mutations-types'
import _ from 'lodash'

const state = {
  sermonsListAll: [],
  currentSermonsCount: 1,
  sermonsFilterQuery: '',
  sermonsSearchQuery: '',
  infiniteLoadingRefSermons: false,
  notFoundSermons: false,
  noDataSermonsPost: false,
  presetSermonsImg: false,
  sermonsListSetTimeoutId: false
  // printStatus: false
}

const getters = {
  sermonsListAll: state => state.sermonsListAll,
  currentSermonsCount: state => state.currentSermonsCount,
  sermonsFilterQuery: state => state.sermonsFilterQuery,
  sermonsSearchQuery: state => state.sermonsSearchQuery,
  infiniteLoadingRefSermons: state => state.infiniteLoadingRefSermons,
  notFoundSermons: state => state.notFoundSermons,
  noDataSermonsPost: state => state.noDataSermonsPost,
  presetSermonsImg: state => state.presetSermonsImg
  // printStatus: state => state.printStatus
}
const actions = {
  setAllSermonsData ({ commit }, data) {
    commit(types.SET_ALL_SERMONS_DATA, { data: data })
  },
  pushNewSermonsData ({ commit }, data) {
    commit(types.PUSH_NEW_SERMONS_DATA, { data: data })
  },
  setCurrentSermonsCount ({ commit }, data) {
    commit(types.SET_CURRENT_SERMONS_COUNT, { data: data })
  },
  setSermonsFilterQuery ({ commit }, data) {
    commit(types.SET_SERMONS_FILTER_QUERY, data)
  },
  setSermonsSearchQuery ({ commit }, data) {
    commit(types.SET_SERMONS_SEARCH_QUERY, data)
  },
  setInfiniteLoadingRefSermons ({ commit }, data) {
    commit(types.SET_INFINITE_LOADING_REF_SERMONS, data)
  },
  setNotFoundSermons ({ commit }, data) {
    commit(types.SET_NOT_FOUND_SERMONS, data)
  },
  setNoDataSermonsPost ({ commit }, data) {
    commit(types.SET_NO_DATA_SERMONS_POST, data)
  },
  presetSermonsImageUpdate ({ commit }, data) {
    commit(types.PRESET_SERMONS_IMAGE_UPDATE, data)
  }
  // setPrintStatus ({commit}, data) {
  //   commit(types.SET_PRINT_STATUS, data)
  // }
}
const mutations = {
  [types.SET_ALL_SERMONS_DATA] (state, { data }) {
    if (data.length > 0) {
      state.sermonsListAll = data
    } else {
      state.sermonsListAll = []
    }
  },
  [types.PUSH_NEW_SERMONS_DATA] (state, { data }) {
    console.log(data)
    clearTimeout(state.sermonsListSetTimeoutId)
    for (let i = 0; i < data.items.length; i++) {
      if (_.find(state.sermonsListAll, function (obj) {
        return obj.id === data.items[i].id
      }) === undefined) {
        if (data.items[i].description === undefined) {
          data.items[i].description = ''
        }
        state.sermonsListSetTimeoutId = setTimeout(function () {
          state.sermonsListAll.push(data.items[i])
        }, i * 100)
      }
    }
  },
  [types.SET_CURRENT_SERMONS_COUNT] (state, { data }) {
    state.currentSermonsCount = data
  },
  [types.SET_SERMONS_FILTER_QUERY] (state, data) {
    state.sermonsFilterQuery = data
  },
  [types.SET_SERMONS_SEARCH_QUERY] (state, data) {
    state.sermonsSearchQuery = data
  },
  [types.SET_INFINITE_LOADING_REF_SERMONS] (state, data) {
    state.infiniteLoadingRefSermons = data
  },
  [types.SET_NOT_FOUND_SERMONS] (state, data) {
    state.notFoundSermons = data
  },
  [types.SET_NO_DATA_SERMONS_POST] (state, data) {
    state.noDataSermonsPost = data
  },
  [types.PRESET_SERMONS_IMAGE_UPDATE] (state, data) {
    state.presetSermonsImg = data
  }
  // [types.SET_PRINT_STATUS] (state, data) {
  //   state.printStatus = data
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
