import Vue from 'vue'
import { API_URL_FORMS } from '../constants/settings'
import { LOAD_FORM, CHANGE_FORM, CREATE_FORM, SET_FORM_ID, SET_IF_LOADER_FORM, SET_CUSTOM_FIELDS } from '../store/types'
import { FORM } from '../constants/defaults/form'
// import router from '@@@/router/index'
import { SET_FORM, UPDATE_LOADING_STATUS } from '@@@/store/constant/mutationsTypes'
import VueCookie from 'vue-cookie'

export default {
  [SET_CUSTOM_FIELDS] ({ commit }, data) {
    commit(SET_CUSTOM_FIELDS, data)
  },
  [LOAD_FORM] ({ commit, state }, data) {
    commit(UPDATE_LOADING_STATUS, true)
    Vue.http.get(`${API_URL_FORMS}/${data.formId}`,
      {
        headers: {
          'X-Custom-Auth': VueCookie.get('mid')
        }
      }
    ).then(response => {
      const { fields } = response.body.imp_form
      console.log('parse fields', typeof fields)
      if (response.body.imp_form.tags === undefined) {
        response.body.imp_form.tags = []
      }
      if (response.body.imp_form.useCaptcha === undefined) {
        response.body.imp_form.useCaptcha = true
      }
      // if (typeof fields === 'object') fields = JSON.stringify(fields)
      response.body.imp_form.fields = JSON.parse(fields)
      commit(UPDATE_LOADING_STATUS, false)
      commit({
        type: CHANGE_FORM,
        form: response.body.imp_form,
        withoutServerSaving: true
      })
      commit({
        type: SET_FORM_ID,
        formId: data.formId
      })
      commit(SET_FORM, response.body.imp_form)
      // this.$store.dispatch(types.SET_FORM, resp.data.imp_form)
    }, response => {
      // state.ifLoaderForm = false
      console.error('error') // error
      commit(UPDATE_LOADING_STATUS, false)
    })
  },
  [SET_FORM_ID] ({ commit }, data) {
    commit(SET_FORM_ID, { formId: data })
  },
  [SET_IF_LOADER_FORM] ({ commit }, data) {
    commit(SET_IF_LOADER_FORM, { data })
  },
  [CREATE_FORM] ({ commit, state }, formData) {
    commit(UPDATE_LOADING_STATUS, true)
    const data = (formData === undefined) ? FORM : formData
    const { fields, ...settings } = data
    const form = {
      ...settings,
      fields: JSON.stringify(fields)
    }
    Vue.http.post(API_URL_FORMS, JSON.stringify({ form }),
      {
        headers: {
          'X-Custom-Auth': VueCookie.get('mid')
        }
      }
    ).then(response => {
      // TODO скрыл router.push
      // router.push(`/forms/${response.body.imp_form.id}`)
      state.formId = response.body.imp_form.id
      commit(UPDATE_LOADING_STATUS, false)
      const formObj = {
        adminNotificationSend: response.body.imp_form.adminNotificationSend,
        authNeeded: response.body.imp_form.authNeeded,
        created_at: response.body.imp_form.created_at,
        description: response.body.imp_form.description,
        id: response.body.imp_form.id,
        title: response.body.imp_form.title,
        updated_at: response.body.imp_form.updated_at
      }
      commit('addFormFromSb', formObj)
    }, response => {
      commit(UPDATE_LOADING_STATUS, false)
    })
  }
}
