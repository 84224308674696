import widgetNewHorizontalList from './modules/widgetNewHorizontalList'
import widgetBlogHorizontalList from './modules/widgetBlogHorizontalList'
import widgetSermonsHorizontalList from './modules/widgetSermonsHorizontalList'
import widgetMinistriesHorizontalList from './modules/widgetMinistriesHorizontalList'
import widgetEventsHorizontalList from './modules/widgetEventsHorizontalList'
import widgetHorizontalPostList from './modules/widgetHorizontalPostList'
import widgetReadingPlanHorizontalList from './modules/widgetReadingPlanHorizontalList'

const modules = {
  widgetNewHorizontalList,
  widgetBlogHorizontalList,
  widgetSermonsHorizontalList,
  widgetMinistriesHorizontalList,
  widgetEventsHorizontalList,
  widgetHorizontalPostList,
  widgetReadingPlanHorizontalList
}

export default modules
