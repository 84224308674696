import * as types from './../../constants/store/mutations-types'
import _ from 'lodash'

const state = {
  blogHorizontalListAll: [],
  currentBlogHorizontalCount: 1,
  blogHorizontalFilterQuery: '',
  blogHorizontalSearchQuery: '',
  infiniteLoadingRefBlogHorizontal: false,
  notFoundBlogHorizontal: false,
  noDataBlogHorizontalPost: false,
  presetBlogHorizontalImg: false,
  blogHorizontalListSetTimeoutId: false
  // printStatus: false
}

const getters = {
  blogHorizontalListAll: state => state.blogHorizontalListAll,
  currentBlogHorizontalCount: state => state.currentBlogHorizontalCount,
  blogHorizontalFilterQuery: state => state.blogHorizontalFilterQuery,
  blogHorizontalSearchQuery: state => state.blogHorizontalSearchQuery,
  infiniteLoadingRefBlogHorizontal: state => state.infiniteLoadingRefBlogHorizontal,
  notFoundBlogHorizontal: state => state.notFoundBlogHorizontal,
  noDataBlogHorizontalPost: state => state.noDataBlogHorizontalPost,
  presetBlogHorizontalImg: state => state.presetBlogHorizontalImg
  // printStatus: state => state.printStatus
}
const actions = {
  setAllBlogHorizontalData ({ commit }, data) {
    commit(types.SET_ALL_BLOG_HORIZONTAL_DATA, { data: data })
  },
  pushNewBlogHorizontalData ({ commit }, data) {
    commit(types.PUSH_NEW_BLOG_HORIZONTAL_DATA, { data: data })
  },
  setCurrentBlogHorizontalCount ({ commit }, data) {
    commit(types.SET_CURRENT_BLOG_HORIZONTAL_COUNT, { data: data })
  },
  setBlogHorizontalFilterQuery ({ commit }, data) {
    commit(types.SET_BLOG_HORIZONTAL_FILTER_QUERY, data)
  },
  setBlogHorizontalSearchQuery ({ commit }, data) {
    commit(types.SET_BLOG_HORIZONTAL_SEARCH_QUERY, data)
  },
  setInfiniteLoadingRefBlogHorizontal ({ commit }, data) {
    commit(types.SET_INFINITE_LOADING_REF_BLOG_HORIZONTAL, data)
  },
  setNotFoundBlogHorizontal ({ commit }, data) {
    commit(types.SET_NOT_FOUND_BLOG_HORIZONTAL, data)
  },
  setNoDataBlogHorizontalPost ({ commit }, data) {
    commit(types.SET_NO_DATA_BLOG_HORIZONTAL_POST, data)
  },
  presetBlogHorizontalImageUpdate ({ commit }, data) {
    commit(types.PRESET_BLOG_HORIZONTAL_IMAGE_UPDATE, data)
  }
  // setPrintStatus ({commit}, data) {
  //   commit(types.SET_PRINT_STATUS, data)
  // }
}
const mutations = {
  [types.SET_ALL_BLOG_HORIZONTAL_DATA] (state, { data }) {
    if (data.length > 0) {
      state.blogHorizontalListAll = data.items
    } else {
      state.blogHorizontalListAll = []
    }
  },
  [types.PUSH_NEW_BLOG_HORIZONTAL_DATA] (state, { data }) {
    clearTimeout(state.blogHorizontalListSetTimeoutId)
    for (let i = 0; i < data.items.length; i++) {
      if (_.find(state.blogHorizontalListAll, function (obj) {
        return obj.id === data.items[i].id
      }) === undefined) {
        if (data.items[i].description === undefined) {
          data.items[i].description = ''
        }
        state.blogHorizontalListAll.push(data.items[i])
      }
    }
  },
  [types.SET_CURRENT_BLOG_HORIZONTAL_COUNT] (state, { data }) {
    state.currentBlogHorizontalCount = data
  },
  [types.SET_BLOG_HORIZONTAL_FILTER_QUERY] (state, data) {
    state.blogHorizontalFilterQuery = data
  },
  [types.SET_BLOG_HORIZONTAL_SEARCH_QUERY] (state, data) {
    state.blogHorizontalSearchQuery = data
  },
  [types.SET_INFINITE_LOADING_REF_BLOG_HORIZONTAL] (state, data) {
    state.infiniteLoadingRefBlogHorizontal = data
  },
  [types.SET_NOT_FOUND_BLOG_HORIZONTAL] (state, data) {
    state.notFoundBlogHorizontal = data
    state.notFoundBlogHorizontal = data
  },
  [types.SET_NO_DATA_BLOG_HORIZONTAL_POST] (state, data) {
    state.noDataBlogHorizontalPost = data
  },
  [types.PRESET_BLOG_HORIZONTAL_IMAGE_UPDATE] (state, data) {
    state.presetBlogHorizontalImg = data
  }
  // [types.SET_PRINT_STATUS] (state, data) {
  //   state.printStatus = data
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
