import * as types from './../../constants/store/mutations-types'
import _ from 'lodash'

const state = {
  eventsHorizontalListAll: [],
  currentEventsHorizontalCount: 1,
  eventsHorizontalFilterQuery: '',
  eventsHorizontalSearchQuery: '',
  infiniteLoadingRefEventsHorizontal: false,
  notFoundEventsHorizontal: false,
  noDataEventsHorizontalPost: false,
  presetEventsHorizontalImg: false,
  eventsHorizontalListSetTimeoutId: false
  // printStatus: false
}

const getters = {
  eventsHorizontalListAll: state => state.eventsHorizontalListAll,
  currentEventsHorizontalCount: state => state.currentEventsHorizontalCount,
  eventsHorizontalFilterQuery: state => state.eventsHorizontalFilterQuery,
  eventsHorizontalSearchQuery: state => state.eventsHorizontalSearchQuery,
  infiniteLoadingRefEventsHorizontal: state => state.infiniteLoadingRefEventsHorizontal,
  notFoundEventsHorizontal: state => state.notFoundEventsHorizontal,
  noDataEventsHorizontalPost: state => state.noDataEventsHorizontalPost,
  presetEventsHorizontalImg: state => state.presetEventsHorizontalImg
  // printStatus: state => state.printStatus
}
const actions = {
  setAllEventsHorizontalData ({ commit }, data) {
    commit(types.SET_ALL_EVENTS_HORIZONTAL_DATA, { data: data })
  },
  pushNewEventsHorizontalData ({ commit }, data) {
    commit(types.PUSH_NEW_EVENTS_HORIZONTAL_DATA, { data: data })
  },
  setCurrentEventsHorizontalCount ({ commit }, data) {
    commit(types.SET_CURRENT_EVENTS_HORIZONTAL_COUNT, { data: data })
  },
  setEventsHorizontalFilterQuery ({ commit }, data) {
    commit(types.SET_EVENTS_HORIZONTAL_FILTER_QUERY, data)
  },
  setEventsHorizontalSearchQuery ({ commit }, data) {
    commit(types.SET_EVENTS_HORIZONTAL_SEARCH_QUERY, data)
  },
  setInfiniteLoadingRefEventsHorizontal ({ commit }, data) {
    commit(types.SET_INFINITE_LOADING_REF_EVENTS_HORIZONTAL, data)
  },
  setNotFoundEventsHorizontal ({ commit }, data) {
    commit(types.SET_NOT_FOUND_EVENTS_HORIZONTAL, data)
  },
  setNoDataEventsHorizontalPost ({ commit }, data) {
    commit(types.SET_NO_DATA_EVENTS_HORIZONTAL_POST, data)
  },
  presetEventsHorizontalImageUpdate ({ commit }, data) {
    commit(types.PRESET_EVENTS_HORIZONTAL_IMAGE_UPDATE, data)
  }
  // setPrintStatus ({commit}, data) {
  //   commit(types.SET_PRINT_STATUS, data)
  // }
}
const mutations = {
  [types.SET_ALL_EVENTS_HORIZONTAL_DATA] (state, { data }) {
    if (data.length > 0) {
      state.eventsHorizontalListAll = data.items
    } else {
      state.eventsHorizontalListAll = []
    }
  },
  [types.PUSH_NEW_EVENTS_HORIZONTAL_DATA] (state, { data }) {
    clearTimeout(state.eventsHorizontalListSetTimeoutId)
    for (let i = 0; i < data.items.length; i++) {
      if (_.find(state.eventsHorizontalListAll, function (obj) {
        return obj.id === data.items[i].id
      }) === undefined) {
        if (data.items[i].description === undefined) {
          data.items[i].description = ''
        }
        state.eventsHorizontalListAll.push(data.items[i])
      }
    }
  },
  [types.SET_CURRENT_EVENTS_HORIZONTAL_COUNT] (state, { data }) {
    state.currentEventsHorizontalCount = data
  },
  [types.SET_EVENTS_HORIZONTAL_FILTER_QUERY] (state, data) {
    state.eventsHorizontalFilterQuery = data
  },
  [types.SET_EVENTS_HORIZONTAL_SEARCH_QUERY] (state, data) {
    state.eventsHorizontalSearchQuery = data
  },
  [types.SET_INFINITE_LOADING_REF_EVENTS_HORIZONTAL] (state, data) {
    state.infiniteLoadingRefEventsHorizontal = data
  },
  [types.SET_NOT_FOUND_EVENTS_HORIZONTAL] (state, data) {
    state.notFoundEventsHorizontal = data
    state.notFoundEventsHorizontal = data
  },
  [types.SET_NO_DATA_EVENTS_HORIZONTAL_POST] (state, data) {
    state.noDataEventsHorizontalPost = data
  },
  [types.PRESET_EVENTS_HORIZONTAL_IMAGE_UPDATE] (state, data) {
    state.presetEventsHorizontalImg = data
  }
  // [types.SET_PRINT_STATUS] (state, data) {
  //   state.printStatus = data
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
