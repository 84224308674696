import * as types from '@@@/store/constant/mutationsTypes'
const state = {
  templatesList: [],
  defaultPagesTemplate: [],
  templatesUserList: [],
  pageTemplatesList: [],
  pageTemplatesUserList: [],
  allTemplates: [],
  showThankYouModal: false,
  showPopup: false,
  location: '',
  darkModeBuilder: false,
  isShowGrid: false
}

const getters = {
  templatesList: state => state.templatesList,
  defaultPagesTemplate: state => state.defaultPagesTemplate,
  pageTemplatesList: state => state.pageTemplatesList,
  pageTemplatesUserList: state => state.pageTemplatesUserList,
  templatesUserList: state => state.templatesUserList,
  allTemplates: state => state.allTemplates,
  showThankYouModal: state => state.showThankYouModal,
  showPopup: state => state.showPopup,
  locationMap: state => state.locationMap,
  darkModeBuilder: state => state.darkModeBuilder,
  isShowGrid: state => state.isShowGrid
}

const actions = {
  updateAllTemplatesList ({ commit }, data) {
    commit(types.UPDATE_ALL_TEMPLATES_LIST, data)
  },
  updateDefaultPagesTemplate ({ commit }, data) {
    commit(types.UPDATE_DEFAULT_PAGES_TEMPLATES, data)
  },
  updateLocationMap ({ commit }, data) {
    commit(types.UPDATE_LOCATION_MAP, data)
  },
  updateShowThankYouModal ({ commit }, data) {
    commit(types.UPDATE_SHOW_THANK_YOU_MODAL, data)
  },
  updateShowPopup ({ commit }, data) {
    commit(types.UPDATE_SHOW_POPUP, data)
  },
  updateDarkModeBuilder ({ commit }, data) {
    commit(types.UPDATE_DARK_MODE_BUILDER, data)
  },
  updateTemplatesList ({ commit }, data) {
    commit(types.UPDATE_TEMPLATES_LIST, data)
  },
  updatePageTemplatesList ({ commit }, data) {
    commit(types.UPDATE_PAGE_TEMPLATES_LIST, data)
  },
  updatePageTemplatesUserList ({ commit }, data) {
    commit(types.UPDATE_PAGE_TEMPLATES_USER_LIST, data)
  },
  updateTemplatesUserList ({ commit }, data) {
    commit(types.UPDATE_TEMPLATES_USER_LIST, data)
  },
  updateShowGridBuilder ({ commit }, data) {
    commit(types.UPDATE_SHOW_GRID_BUILDER, data)
  }
}

const mutations = {
  [types.UPDATE_SHOW_THANK_YOU_MODAL] (state, data) {
    state.showThankYouModal = data
  },
  [types.UPDATE_SHOW_POPUP] (state, data) {
    state.showPopup = data
  },
  [types.UPDATE_DARK_MODE_BUILDER] (state, data) {
    state.darkModeBuilder = data
  },
  [types.UPDATE_LOCATION_MAP] (state, data) {
    state.locationMap = data
  },
  [types.UPDATE_ALL_TEMPLATES_LIST] (state, data) {
    state.allTemplates = data
  },
  [types.UPDATE_DEFAULT_PAGES_TEMPLATES] (state, data) {
    state.defaultPagesTemplate = data
  },
  [types.UPDATE_TEMPLATES_LIST] (state, data) {
    state.templatesList = data
  },
  [types.UPDATE_TEMPLATES_USER_LIST] (state, data) {
    state.templatesUserList = data
  },
  [types.UPDATE_PAGE_TEMPLATES_LIST] (state, data) {
    state.pageTemplatesList = data
  },
  [types.UPDATE_PAGE_TEMPLATES_USER_LIST] (state, data) {
    state.pageTemplatesUserList = data
  },
  [types.UPDATE_SHOW_GRID_BUILDER] (state, data) {
    state.isShowGrid = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
