import * as types from './../../constants/store/mutations-types'

const state = {
  devotionsPostListData: [],
  devotionsActivePost: 0
  // printStatus: false
}

const getters = {
  devotionsPostListData: state => state.devotionsPostListData,
  devotionsActivePost: state => state.devotionsActivePost
}
const actions = {
  pushDevotionsPostData ({ commit }, data) {
    commit(types.PUSH_DEVOTIONS_POST_DATA, { data: data })
  },
  setDevotionsActivePost ({ commit }, data) {
    commit(types.SET_DEVOTIONS_ACTIVE_POST, data)
  }
}
const mutations = {
  [types.PUSH_DEVOTIONS_POST_DATA] (state, { data }) {
    state.devotionsPostListData.push(data)
    state.devotionsActivePost = state.devotionsPostListData.length - 1
  },
  [types.SET_DEVOTIONS_ACTIVE_POST] (state, data) {
    state.devotionsActivePost = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
