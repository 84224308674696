export const PRAYER_WALL = 'PRAYER_WALL'
export const UPDATE_VIEW_MODE = 'UPDATE_VIEW_MODE'
export const UPDATE_CURRENT_MODE_VIEW_PAGE = 'UPDATE_CURRENT_MODE_VIEW_PAGE'
export const SET_GLOBAL_COLORS_MAIN = 'SET_GLOBAL_COLORS_MAIN'
export const SET_GLOBAL_COLORS_MAIN_DARK = 'SET_GLOBAL_COLORS_MAIN_DARK'
export const SET_REQUESTS = 'SET_REQUESTS'
export const SET_REPORTS = 'SET_REPORTS'
export const SET_CAMPUSES = 'SET_CAMPUSES'
export const SET_MY_POSTS = 'SET_MY_POSTS'
export const SET_PROFILE_DATA = 'SET_PROFILE_DATA'
export const SET_CATEGORIES = 'SET_CATEGORIES'
export const SET_QUERY_STRING_REQUESTS = 'SET_QUERY_STRING_REQUESTS'
export const SET_QUERY_STRING_REQUESTS_CATEGORY = 'SET_QUERY_STRING_REQUESTS_CATEGORY'
export const SET_QUERY_STRING_REPORTS = 'SET_QUERY_STRING_REPORTS'
export const SET_QUERY_STRING_REPORTS_CATEGORY = 'SET_QUERY_STRING_REPORTS_CATEGORY'
export const SET_QUERY_STRING_MY_POSTS = 'SET_QUERY_STRING_MY_POSTS'
export const SET_QUERY_STRING_MY_POSTS_CATEGORY = 'SET_QUERY_STRING_MY_POSTS_CATEGORY'
export const SET_CHECKBOXS_REQUESTS = 'SET_CHECKBOXS_REQUESTS'
export const SET_CHECKBOXS_REPORTS = 'SET_CHECKBOXS_REPORTS'
export const SET_CHECKBOXS_MY_POSTS = 'SET_CHECKBOXS_MY_POSTS'
export const SET_SEARCH_STRING_REQUESTS = 'SET_SEARCH_STRING_REQUESTS'
export const SET_SEARCH_STRING_REPORTS = 'SET_SEARCH_STRING_REPORTS'
export const SET_SEARCH_STRING_MY_POSTS = 'SET_SEARCH_STRING_MY_POSTS'
export const SET_CURRENT_POST_PAGE = 'SET_CURRENT_POST_PAGE'
export const CHANGE_CURRENT_POST_PAGE = 'CHANGE_CURRENT_POST_PAGE'
export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_LIKE = 'SET_LIKE'
export const SET_DISLIKE = 'SET_DISLIKE'
export const SET_ABUSE = 'SET_ABUSE'
export const SET_LIKE_COMMENT = 'SET_LIKE_COMMENT'
export const SET_DISLIKE_COMMENT = 'SET_DISLIKE_COMMENT'
export const SET_ABUSE_COMMENT = 'SET_ABUSE_COMMENT'
export const SET_GIVE_LOADER = 'SET_GIVE_LOADER'
export const SET_PROFILE_QUERY_LOADER = 'SET_PROFILE_QUERY_LOADER'
export const SET_PROFILE_QUERY_CALLBACK = 'SET_PROFILE_QUERY_CALLBACK'
export const SET_LOADER_AUTH = 'SET_LOADER_AUTH'
export const SET_ABUSE_TEXT = 'SET_ABUSE_TEXT'
export const UPDATE_USER_DATA_INFO = 'UPDATE_USER_DATA_INFO'
export const UPDATE_USER_TYPES_LIST = 'UPDATE_USER_TYPES_LIST'
export const UPDATE_USER_HIDE_TYPES = 'UPDATE_USER_HIDE_TYPES'
export const UPDATE_PRAY_LOGIN_CALLBACK = 'UPDATE_PRAY_LOGIN_CALLBACK'
export const UPDATE_CHURCH_FINDER_MARKERS_ITEMS = 'UPDATE_CHURCH_FINDER_MARKERS_ITEMS'
export const SET_COMMENTS_COUNT = 'SET_COMMENTS_COUNT'
export const SET_DELAY_REQUESTS_PRAYERS = 'SET_DELAY_REQUESTS_PRAYERS'
export const SET_DELAY_REPORTS_PRAYERS = 'SET_DELAY_REPORTS_PRAYERS'
export const SET_DELAY_PLAY = 'SET_DELAY_PLAY'
export const SET_LOCAL_DARK_MODE = 'SET_LOCAL_DARK_MODE'
export const SET_LOCAL_EXPANDED_POST = 'SET_LOCAL_EXPANDED_POST'
export const SET_TEXT_REQUEST = 'SET_TEXT_REQUEST'
export const SET_TEXT_REPORT = 'SET_TEXT_REPORT'
export const SET_TEXT_COMMENT = 'SET_TEXT_COMMENT'
export const SET_TEXT_SUBCOMMENT = 'SET_TEXT_SUBCOMMENT'
export const SET_TEXT_INTERFACE_CATEGORY_NAME = 'SET_TEXT_INTERFACE_CATEGORY_NAME'
export const SET_MODERATION_STATUS = 'SET_MODERATION_STATUS'
export const SET_GENERAL_SETTINGS = 'SET_GENERAL_SETTINGS'
export const SET_SHOW_CHIPS = 'SET_SHOW_CHIPS'
export const SET_FORCE_BACKGROUND = 'SET_FORCE_BACKGROUND'
export const SET_ALLOW_USER_TO_SHARE_POSTS = 'SET_ALLOW_USER_TO_SHARE_POSTS'
export const SET_SHOW_EMERGENCY_TEXT = 'SET_SHOW_EMERGENCY_TEXT'
export const SET_SHOW_COMMENTS = 'SET_SHOW_COMMENTS'
export const SET_SHOW_DISCLAIMER = 'SET_SHOW_DISCLAIMER'
export const SET_REMOVE_STEP_TWO = 'SET_REMOVE_STEP_TWO'
export const SET_IS_ACTIVE_FILTER = 'SET_IS_ACTIVE_FILTER'
export const SET_IS_ACTIVE_SORT = 'SET_IS_ACTIVE_SORT'
export const SET_POST_MAX_CHAR = 'SET_POST_MAX_CHAR'
export const SET_POST_MIN_CHAR = 'SET_POST_MIN_CHAR'
export const SET_SHOW_MODAL_LOGIN = 'SET_SHOW_MODAL_LOGIN'
export const SET_SHOW_MODAL_LOGOUT = 'SET_SHOW_MODAL_LOGOUT'
export const SET_EMERGENCY_BODY = 'SET_EMERGENCY_BODY'
export const SET_PRIVACY_BODY = 'SET_PRIVACY_BODY'
export const SET_SHOW_LAST_NAME = 'SET_SHOW_LAST_NAME'
export const SET_REGISTRATION_OPTIONS = 'SET_REGISTRATION_OPTIONS'
export const SET_REGISTER_FORM = 'SET_REGISTER_FORM'
export const SET_AUTH_TYPE_VALUE = 'SET_AUTH_TYPE_VALUE'
export const SET_LINKED_REQUEST_ID_TO_REPORT = 'SET_LINKED_REQUEST_ID_TO_REPORT'
export const SET_LINKED_CATEGORY_TO_REPORT = 'SET_LINKED_CATEGORY_TO_REPORT'
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB'
export const SET_CURRENT_LOCATION_DATA = 'SET_CURRENT_LOCATION_DATA'
export const SET_CARD_COLORS = 'SET_CARD_COLORS'
export const SET_TABS_NAMES = 'SET_TABS_NAMES'
export const SET_PRAY_BUTTON_LABELS = 'SET_PRAY_BUTTON_LABELS'
export const SET_SERVICE_SETTINGS = 'SET_SERVICE_SETTINGS'
export const SET_CHURCH_FINDER_QUERY_DATA = 'SET_CHURCH_FINDER_QUERY_DATA'
export const SET_UPDATE_POST_ID = 'SET_UPDATE_POST_ID'
export const UPDATE_FORM_VALIDATOR = 'UPDATE_FORM_VALIDATOR'
export const UPDATE_FORM_MODEL = 'UPDATE_FORM_MODEL'
