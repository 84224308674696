import * as types from './../../constants/store/mutations-types'
import { find } from 'lodash'

const state = {
  aboutUsList: [],
  currentAboutUsCount: 1
}

const getters = {
  aboutUsList: state => state.aboutUsList,
  currentAboutUsCount: state => state.currentAboutUsCount
}
const actions = {
  pushAboutUsData ({ commit }, data) {
    commit(types.PUSH_ABOUT_US_DATA, { data: data })
  },
  setCurrentAboutUsCount ({ commit }, data) {
    commit(types.SET_CURRENT_ABOUT_US_COUNT, { data: data })
  }
}
const mutations = {
  [types.PUSH_ABOUT_US_DATA] (state, { data }) {
    for (let i = 0; i < data.length; i++) {
      if (find(state.aboutUsList, function (obj) {
        return obj.id === data[i].id
      }) === undefined) {
        state.aboutUsList.push(data[i])
      }
    }
  },
  [types.SET_CURRENT_ABOUT_US_COUNT] (state, { data }) {
    state.currentAboutUsCount = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
