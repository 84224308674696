export const SET_TICKETS_DATA_FOR_EVENT = 'SET_TICKETS_DATA_FOR_EVENT'
export const SET_SELECTED_TICKETS = 'SET_SELECTED_TICKETS'
export const SET_USER_REGISTER_OBJ = 'SET_USER_REGISTER_OBJ'
export const TABS_ACTIVE_TICKETS_WIDGET_CONTENT = 'TABS_ACTIVE_TICKETS_WIDGET_CONTENT'
export const UPDATE_GENERAL_SETTINGS = 'UPDATE_GENERAL_SETTINGS'
export const SET_PHONES_CODES = 'SET_PHONES_CODES'
export const SET_COUNTRY_LIST = 'SET_COUNTRY_LIST'
export const SET_TICKETS_COUNT = 'SET_TICKETS_COUNT'
export const SET_DISABLE_MINUS = 'SET_DISABLE_MINUS'
export const SET_DISABLE_PLUS = 'SET_DISABLE_PLUS'
export const SET_TICKETS_USER_INFO = 'SET_TICKETS_USER_INFO'
export const SET_MODAL_LOGIN_SHOW = 'SET_MODAL_LOGIN_SHOW'
export const SET_CARD_VALIDATOR = 'SET_CARD_VALIDATOR'
export const SET_CARD_DATA_OBJECT = 'SET_CARD_DATA_OBJECT'
export const SET_TICKET_STEP = 'SET_TICKET_STEP'
export const SET_BUY_TICKET_EVENT_STATUS = 'SET_BUY_TICKET_EVENT_STATUS'
export const SET_ACH_DATA_OBJECT = 'SET_ACH_DATA_OBJECT'
export const SET_ACH_VALIDATOR = 'SET_ACH_VALIDATOR'
export const SET_TICKET_LIST_ID = 'SET_TICKET_LIST_ID'
export const SET_USER_MID = 'SET_USER_MID'
