export const FILTER_LIST =
  [
    {
      id: 0,
      filterName: 'Date',
      isActive: false,
      filtervalue: 'date',
      radioName: 'depend',
      auth: false,
      submenu: {
        type: 'normal',
        sort: [
          { title: 'Newest', query: '&sorting[created_at]=desc', active: true, id: '1' },
          { title: 'Oldest', query: '&sorting[created_at]=asc', active: false, id: '2' }
        ]
      }
    },
    {
      id: 1,
      filterName: 'Name',
      isActive: false,
      filtervalue: 'name',
      radioName: 'depend',
      auth: false,
      submenu: {
        type: 'normal',
        sort: [
          { title: 'A - Z', query: '&sorting[title]=asc', active: false, id: '1' },
          { title: 'Z - A', query: '&sorting[title]r=desc', active: false, id: '2' }
        ]
      }
    },
    {
      id: 2,
      filterName: 'Popularity',
      isActive: false,
      filtervalue: 'popularity',
      radioName: 'depend',
      auth: false,
      submenu: {
        type: 'normal',
        sort: [
          { title: 'Most Popular', query: '&sorting[popularity]=asc', active: false, id: '1' },
          { title: 'Less Popular', query: '&sorting[popularity]r=desc', active: false, id: '2' }
        ]
      }
    },
    {
      id: 3,
      filterName: 'Campus',
      isActive: false,
      filtervalue: 'campus',
      // NO CHANGE 'filtervalue' FOR GENERAL SETTINGS!!! BY LAVEN
      radioName: 'independ',
      auth: false,
      submenu: {
        type: 'checkbox',
        sort: [
          // {title: 'Campus Name #1', query: '', active: false, id: '1'},
          // {title: 'Campus Name #2', query: '', active: false, id: '2'},
          // {title: 'Campus Name #3', query: '', active: false, id: '3'}
        ]
      }
    },
    {
      id: 4,
      filterName: 'Unplayed',
      isActive: false,
      filtervalue: 'unplayed',
      radioName: 'independ',
      auth: true,
      submenu: {
        type: 'normal',
        sort: [
          { title: 'All', query: '', active: false, id: '1' },
          { title: 'Unplayed', query: '&unplayed=1', active: false, id: '2' }
        ]
      }
    }
  ]
export const FILTER_LIST_SERIES =
  [
    {
      id: 0,
      filterName: 'Date',
      isActive: false,
      filtervalue: 'date',
      radioName: 'depend',
      auth: false,
      submenu: {
        type: 'normal',
        sort: [
          { title: 'Newest', query: '&sorting[created_at]=desc', active: true, id: '1' },
          { title: 'Oldest', query: '&sorting[created_at]=asc', active: false, id: '2' }
        ]
      }
    },
    {
      id: 1,
      filterName: 'Name',
      isActive: false,
      filtervalue: 'name',
      radioName: 'depend',
      auth: false,
      submenu: {
        type: 'normal',
        sort: [
          { title: 'A - Z', query: '&sorting[title]=asc', active: false, id: '1' },
          { title: 'Z - A', query: '&sorting[title]=desc', active: false, id: '2' }
        ]
      }
    },
    {
      id: 2,
      filterName: 'Popularity',
      isActive: false,
      filtervalue: 'popularity',
      radioName: 'depend',
      auth: false,
      submenu: {
        type: 'normal',
        sort: [
          { title: 'Most Popular', query: '&sorting[popularity]=asc', active: false, id: '1' },
          { title: 'Less Popular', query: '&sorting[popularity]=desc', active: false, id: '2' }
        ]
      }
    },
    {
      id: 3,
      filterName: 'Campus',
      isActive: false,
      filtervalue: 'campus',
      // NO CHANGE 'filtervalue' FOR GENERAL SETTINGS!!! BY LAVEN
      radioName: 'independ',
      auth: false,
      submenu: {
        type: 'checkbox',
        sort: [
          // {title: 'Campus Name #1', query: '', active: false, id: '1'},
          // {title: 'Campus Name #2', query: '', active: false, id: '2'},
          // {title: 'Campus Name #3', query: '', active: false, id: '3'}
        ]
      }
    }
  ]
