import * as types from '@@@/store/constant/mutationsTypes'
import { SET_GOOGLE_FONTS } from '../constant/mutationsTypes'

const state = {
  userDataInfo: {},
  generalSettingsSb: {},
  generalSettingsSbLoadStatus: false,
  adminOrganizationSettings: false,
  currentFont: 'SourceSansPro-Regular',
  globalColorsMain: {},
  globalColorsMainDark: {},
  language: 'ru',
  googleFonts: []
  // globalBorderRadius: {}
}

const getters = {
  userDataInfo: state => state.userDataInfo,
  generalSettingsSb: state => state.generalSettingsSb,
  currentFont: state => state.currentFont,
  globalColorsMain: state => state.globalColorsMain,
  globalColorsMainDark: state => state.globalColorsMainDark,
  language: state => state.language,
  googleFonts: state => state.googleFonts,
  // globalBorderRadius: state => state.globalBorderRadius,
  adminOrganizationSettings: state => state.adminOrganizationSettings
}
const actions = {
  setGeneralSettingsSb ({ commit }, data) {
    commit(types.SET_GENERAL_SETTINGS_SB, { data: data })
  },
  setLanguage ({ commit }, data) {
    commit(types.SET_LANGUAGE, { data })
  },
  setGoogleFonts ({ commit }, data) {
    commit(types.SET_GOOGLE_FONTS, { data })
  },
  setAdminOrganizationSettings ({ commit }, data) {
    commit(types.SET_ADMIN_ORGANIZATION_SETTINGS, { data: data })
  },
  updateGeneralSettingsSb ({ commit }, { response }) {
    if (response !== undefined) {
      commit(types.UPDATE_GENERAL_SETTINGS_SB, { data: response.data })
    }
  },
  updateUserDataInfo ({ commit }, data) {
    commit(types.UPDATE_USER_DATA_INFO, data)
  },
  updateGlobalFont ({ commit }, data) {
    commit(types.UPDATE_GLOBAL_FONT, data)
  },
  updateGlobalColorsMain ({ commit }, data) {
    commit(types.SET_GLOBAL_COLORS_MAIN, { data: data })
  },
  updateGlobalColorsMainDark ({ commit }, data) {
    commit(types.SET_GLOBAL_COLORS_MAIN_DARK, { data: data })
  }
  // updateGlobalBorderRadius ({commit}, data) {
  //   commit(types.SET_GLOBAL_BORDER_RADIUS, {data: data})
  // }
}
const mutations = {
  [types.SET_ADMIN_ORGANIZATION_SETTINGS] (state, { data }) {
    state.adminOrganizationSettings = data
  },
  [types.SET_LANGUAGE] (state, { data }) {
    state.language = data
  },
  [types.SET_GOOGLE_FONTS] (state, { data }) {
    state.googleFonts = data
  },
  [types.SET_GENERAL_SETTINGS_SB] (state, { data }) {
    state.generalSettingsSb = data
  },
  [types.SET_GLOBAL_COLORS_MAIN] (state, { data }) {
    state.globalColorsMain = data
  },
  [types.SET_GLOBAL_COLORS_MAIN_DARK] (state, { data }) {
    state.globalColorsMainDark = data
  },
  // [types.SET_GLOBAL_BORDER_RADIUS] (state, { data }) {
  //   state.globalBorderRadius = data
  // },
  [types.UPDATE_USER_DATA_INFO] (state, data) {
    state.userDataInfo = data
  },
  [types.UPDATE_GLOBAL_FONT] (state, data) {
    state.currentFont = data
  },
  [types.UPDATE_GENERAL_SETTINGS_SB] (state, { data }) {
    const { organization = {} } = data
    const { location = {} } = organization
    const { zipCode, country, city, state: addressState, addressStreet } = location
    const address =
      `${addressStreet ? `${addressStreet}, ` : ''}${city ? `${city}, ` : ''}${addressState
        ? `${addressState}, ` : ''}${zipCode ? `${zipCode}, ` : ''}${country}`
    state.generalSettingsSbLoadStatus = true
    state.generalSettingsSb = {
      ...data,
      organization: {
        ...organization,
        address
      }
    }
    const style = document.createElement('style')
    const str = ':root {--accent-color-widgets: ' + data.organization.design.accentColor + '!important}'
    style.innerText = str
    const head = document.getElementsByTagName('head')[0]
    head.insertBefore(style, head.firstChild)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
