export const SET_SOUND_ENABLED = 'SET_SOUND_ENABLED'
export const SET_ACTIVE = 'SET_ACTIVE'
export const SET_ACCESS_MANAGER = 'SET_ACCESS_MANAGER'
export const SET_CHAT_CLIENT = 'SET_CHAT_CLIENT'
export const SET_CHAT_CHANNELS = 'SET_CHAT_CHANNELS'
export const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL'
export const SET_CURRENT_CHANNEL_READY = 'SET_CURRENT_CHANNEL_READY'
export const SET_CHANNEL_INVITED_TO = 'SET_CHANNEL_INVITED_TO'
export const SET_START_DISABLED = 'SET_START_DISABLED'
export const SET_CHANNEL_MESSAGES = 'SET_CHANNEL_MESSAGES'
export const SET_CHANNEL_UNREAD_MESSAGES_COUNT = 'SET_CHANNEL_UNREAD_MESSAGES_COUNT'
export const SET_CHANNEL_MESSAGES_ANCHOR = 'SET_CHANNEL_MESSAGES_ANCHOR'
export const SET_CHANNEL_USERS = 'SET_CHANNEL_USERS'
export const SET_USERS = 'SET_USERS'
export const SET_CHAT_USER_ID = 'SET_CHAT_USER_ID'
export const SET_ME = 'SET_ME'
export const SET_PROFILE_USER = 'SET_PROFILE_USER'
export const SET_PROFILE_USER_DATA = 'SET_PROFILE_USER_DATA'
export const SET_PROFILE_BACK_CALLBACK = 'SET_PROFILE_BACK_CALLBACK'
export const SET_PANEL = 'SET_PANEL'
export const SET_READY = 'SET_READY'
export const SET_MENU = 'SET_MENU'
export const SET_EDITABLE_MESSAGES = 'SET_EDITABLE_MESSAGES'
export const SET_EDITABLE_MESSAGES_TEXT = 'SET_EDITABLE_MESSAGES_TEXT'
export const SET_NEW_MESSAGES_TEXT = 'SET_NEW_MESSAGES_TEXT'
export const SET_NEW_CHANNEL = 'SET_NEW_CHANNEL'
export const SET_CONFIRMATION = 'SET_CONFIRMATION'
export const SET_ALERT = 'SET_ALERT'
export const SET_REPORT = 'SET_REPORT'
export const SET_CONTAINER_PS = 'SET_CONTAINER_PS'
export const SET_CURRENT_CHANNEL_FOR_WATCH = 'SET_CURRENT_CHANNEL_FOR_WATCH'
export const SET_ADD_MESSAGES_PS = 'SET_ADD_MESSAGES_PS'
export const SET_FIRST_LOAD_PS = 'SET_FIRST_LOAD_PS'
export const SET_SEND_MESSAGE_PS = 'SET_SEND_MESSAGE_PS'
export const SET_CAN_CHAT_WATCH_MESSAGES = 'SET_CAN_CHAT_WATCH_MESSAGES'
export const SET_CHANNEL_CHANGE = 'SET_CHANNEL_CHANGE'
export const SET_CHECKER_COUNT = 'SET_CHECKER_COUNT'
export const SET_NEW_LOADER_COUNTER_FOR_SCROLL = 'SET_NEW_LOADER_COUNTER_FOR_SCROLL'
export const SET_OFFSET_FOR_SCROLL = 'SET_OFFSET_FOR_SCROLL'
export const SET_PREV_MESSAGES_FOR_SCROLL = 'SET_PREV_MESSAGES_FOR_SCROLL'
export const SET_SHOW_TOOLTIP_CHANNEL_NAME = 'SET_SHOW_TOOLTIP_CHANNEL_NAME'
export const SET_I_AM_MODERATOR = 'SET_I_AM_MODERATOR'
export const SET_I_AM_BLOCKED = 'SET_I_AM_BLOCKED'
export const SET_INIT_TIME = 'SET_INIT_TIME'
export const SET_CHANNEL_STATUS_LOG = 'SET_CHANNEL_STATUS_LOG'
export const SET_ACCESS_KEY = 'SET_ACCESS_KEY'
export const SET_CHAT_LOADING_STATE = 'SET_CHAT_LOADING_STATE'
export const SET_CHAT_LOADING_CHANNELS_STATE = 'SET_CHAT_LOADING_CHANNELS_STATE'
