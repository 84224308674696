import * as types from '../../mutations-types'
import * as tabs from '../bible/tabConstants'

const state = {
  tabPanelName: tabs.BIBLE,
  fromPlayer: false
}

const getters = {
  tabPanelName: state => state.tabPanelName,
  fromPlayer: state => state.fromPlayer
}
const actions = {
  toggleTabPanel ({ commit }, tab) {
    commit(types.SET_FROM_PLAYER_STATE, { active: false })
    commit(types.SET_ACTIVE_TAB_LEFT_SIDEBAR, { tab: tab })
  },
  toggleFromPlayer ({ commit }, active) {
    commit(types.SET_FROM_PLAYER_STATE, { active: active })
  }
}
const mutations = {
  [types.SET_ACTIVE_TAB_LEFT_SIDEBAR] (state, { tab }) {
    state.tabPanelName = tab
  },
  [types.SET_FROM_PLAYER_STATE] (state, { active }) {
    state.fromPlayer = active
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
