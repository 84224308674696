export const FILTER_LIST =
  [
    {
      id: 1,
      filterName: 'Campus',
      isActive: false,
      filtervalue: 'campus',
      // NO CHANGE 'filtervalue' FOR GENERAL SETTINGS!!! BY LAVEN
      radioName: 'independ',
      auth: false,
      submenu: {
        type: 'checkbox',
        sort: [
          // {title: 'Campus Name #1', query: '', active: false, id: '1'},
          // {title: 'Campus Name #2', query: '', active: false, id: '2'},
          // {title: 'Campus Name #3', query: '', active: false, id: '3'}
        ]
      }
    }
  ]
