export const SINGLE_LINE_TEXT = 'singleLineText'
export const NUMBER = 'number'
export const MULTI_LINE_TEXT = 'multipleLineText'
export const DROPDOWN_SELECT = 'dropdownSelect'
// export const RADIO_SELECT = 'RADIO_SELECT'
export const PAYMENT = 'payment'
export const TOGGLE = 'TOGGLE'
export const SINGLE_CHECKBOX = 'singleCheckbox'
export const RADIO = 'radioSelect'
export const CHECKBOX = 'multipleCheckbox'
export const HEADER = 'HEADER'
export const COLORPICKER = 'COLORPICKER'
export const CHOICES_EDITOR = 'CHOICES_EDITOR'
export const CHOICES_EDITOR_TEXT = 'CHOICES_EDITOR_TEXT'
export const PAYMENT_EDITOR = 'PAYMENT_EDITOR'
export const SINGLE_CHOICE_EDITOR = 'SINGLE_CHOICE_EDITOR'
export const MULTI_FIELDS = 'MULTI_FIELDS'
export const MULTI_LINE_TEXT_QUILL = 'MULTI_LINE_TEXT_QUILL'

export const TIME = 'time'
export const PHONE = 'phone'
export const NAME = 'name'
export const DATE = 'date'
export const ADDRESS = 'address'
export const PERSON = 'person'
export const ACTION_BUTTON = 'actionButton'
export const UPLOAD_BUTTON = 'uploadButton'
export const MODALS_BUTTON = 'MODALS_BUTTON'
export const TAGS_BLOCK = 'TAGS_BLOCK'
