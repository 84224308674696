import * as types from '../constant/mutationsTypes'
import { SET_USER_MID } from '@@@/components/widgets/tickets-widget/src/constants/store/mutationTypes'
import { newRegisterState } from '@@@/components/modals/userAuth/registration/constants/newRegisterState'
const axios = require('axios')

const state = {
  activeMenuItem: '',
  sbLinksStatus: true,
  sbShowUserPanel: false,
  generalSettingsAuthUser: false,
  ifLoaderAuthUser: false,
  authUserInfo: {},
  logoAuth: '',
  logoHeader: '',
  userAuthProfilePhoto: '',
  registerAuthObj: {},
  registerAuthState: newRegisterState,
  userAuthRegMailTo: '',
  showLogInModalSbState: false,
  showModalAuthUserSbState: false,
  showModalForgotSbState: false,
  showModalForgotSuccessSbState: false,
  showModalSignUpSbState: false,
  registrationFirstStepSbState: false,
  registrationSecondStepSbState: false,
  registrationThanksSbState: false,
  eventShoppingCartModal: false,
  showModalTerms: false,
  termsEvent: false,
  termsSbState: false,
  activeMenuItemElementSelected: -1,
  forgotEmail: '',
  loginEmail: ''
}

const getters = {
  sbLinksStatus: state => state.sbLinksStatus,
  generalSettingsAuthUser: state => state.generalSettingsAuthUser,
  ifLoaderAuthUser: state => state.ifLoaderAuthUser,
  authUserInfo: state => state.authUserInfo,
  logoAuth: state => state.logoAuth,
  logoHeader: state => state.logoHeader,
  sbShowUserPanel: state => state.sbShowUserPanel,
  userAuthProfilePhoto: state => state.userAuthProfilePhoto,
  registerAuthObj: state => state.registerAuthObj,
  registerAuthState: state => state.registerAuthState,
  userAuthRegMailTo: state => state.userAuthRegMailTo,
  showLogInModalSbState: state => state.showLogInModalSbState,
  showModalAuthUserSbState: state => state.showModalAuthUserSbState,
  showModalForgotSbState: state => state.showModalForgotSbState,
  showModalForgotSuccessSbState: state => state.showModalForgotSuccessSbState,
  showModalSignUpSbState: state => state.showModalSignUpSbState,
  registrationFirstStepSbState: state => state.registrationFirstStepSbState,
  registrationSecondStepSbState: state => state.registrationSecondStepSbState,
  registrationThanksSbState: state => state.registrationThanksSbState,
  eventShoppingCartModal: state => state.eventShoppingCartModal,
  termsSbState: state => state.termsSbState,
  activeMenuItemElementSelected: state => state.activeMenuItemElementSelected,
  activeMenuItem: state => state.activeMenuItem,
  forgotEmail: state => state.forgotEmail,
  loginEmail: state => state.loginEmail,
  showModalTerms: state => state.showModalTerms,
  termsEvent: state => state.termsEvent
}
const actions = {
  changeActiveMenuItemElementSelected ({ commit }, data) {
    commit(types.CHANGE_ACTIVE_MENU_ITEM_ELEMENT_SELECTED, { data: data })
  },
  changeActiveMenuItem ({ commit }, data) {
    commit(types.CHANGE_ACTIVE_MENU_ITEM, { data: data })
  },
  changeAuthUserPhoto ({ commit }, photo) {
    commit(types.CHANGE_AUTH_USER_PHOTO, { photo: photo })
  },

  updateGeneralSettingsAuthUser ({ commit }, { response, color }) {
    commit(types.UPDATE_GENERAL_SETTINGS_AUTH_USER, { data: response.data, color: color })
  },
  updateLogoAuth ({ commit }, response) {
    commit(types.UPDATE_LOGO_AUTH, { data: response.data })
  },
  toggleLoaderAuthUser ({ commit }, status) {
    commit(types.TOGGLE_LOADER_AUTH_USER, { status: status })
  },
  setAuthUserInfo ({ commit }, data) {
    commit(types.SET_AUTH_USER_INFO, data)
    commit('setAuthStatus', true)
    commit(SET_USER_MID, data.mid)
  },
  setRegisterAuthState ({ commit }, data) {
    commit(types.SET_REGISTER_AUTH_STATE, data)
  },
  setRegisterAuthObj ({ commit }, data) {
    commit(types.SET_REGISTER_AUTH_OBJ, { data: data })
  },
  regAuthUser ({ commit }) {
    commit(types.SET_AUTH_USER_REGISTER_MAIL)
    commit(types.SET_REGISTER_AUTH_OBJ, { data: [] })
  },
  logoutAuth ({ commit }) {
    commit(types.LOGOUT_AUTH)
    commit('setAuthStatus', false)
    commit(SET_USER_MID, '')
  },
  setShowLogInModalSbState ({ commit }, data) {
    commit(types.SET_SHOW_LOGIN_MODAL_SB_STATE, { data: data })
  },
  setShowModalAuthUserSbState ({ commit }, data) {
    commit(types.SET_SHOW_MODAL_AUTH_USER_SB_STATE, { data: data })
  },
  setShowModalForgotSbState ({ commit }, data) {
    commit(types.SET_SHOW_MODAL_FORGOT_SB_STATE, { data: data })
  },
  setShowModalForgotSuccessSbState ({ commit }, data) {
    commit(types.SET_SHOW_MODAL_FORGOT_SUCCESS_SB_STATE, { data: data })
  },
  setShowModalSignUpSbState ({ commit }, data) {
    commit(types.SET_SHOW_MODAL_SIGNUP_SB_STATE, { data: data })
  },
  setRegistrationFirstStepSbState ({ commit }, data) {
    commit(types.SET_REGISTRATION_FIRST_STEP_SB_STATE, { data: data })
  },
  setRegistrationSecondStepSbState ({ commit }, data) {
    commit(types.SET_REGISTRATION_SECOND_STEP_SB_STATE, { data: data })
  },
  setRegistrationThanksSbState ({ commit }, data) {
    commit(types.SET_REGISTRATION_THANKS_SB_STATE, { data: data })
  },
  setEventShoppingCartModal ({ commit }, data) {
    commit(types.SET_EVENTS_SHOPPING_CART_MODAL_STATE, { data: data })
  },
  setTermsSbState ({ commit }, data) {
    commit(types.SET_TERMS_SB_STATE, { data: data })
  },
  setForgotEmail ({ commit }, data) {
    commit(types.SET_FORGOT_EMAIL, { data: data })
  },
  setLoginEmail ({ commit }, data) {
    commit(types.SET_LOGIN_EMAIL, { data: data })
  },
  setShowModalTerms ({ commit }, data) {
    commit(types.SET_SHOW_MODAL_TERMS, { data: data })
  },
  setTermsEvent ({ commit }, data) {
    commit(types.SET_TERMS_EVENT, { data: data })
  }
}
const mutations = {
  [types.UPDATE_GENERAL_SETTINGS_AUTH_USER] (state, { data, color }) {
    state.generalSettingsAuthUser = data
    state.generalSettingsAuthUser.organization.design.accentColor = color
    // let style = document.createElement('style')
    // let str = ':root {--accent-color-user-auth: ' + data.organization.design.accentColor + '!important}'
    // // let str = ':root {--accent-color-user-auth: #B82838!important}'
    // style.innerText = str
    // let head = document.getElementsByTagName('head')[0]
    // head.insertBefore(style, head.firstChild)
  },
  [types.TOGGLE_LOADER_AUTH_USER] (state, { status }) {
    state.ifLoaderAuthUser = status
  },
  [types.SET_EVENTS_SHOPPING_CART_MODAL_STATE] (state, { data }) {
    state.eventShoppingCartModal = data
  },
  [types.CHANGE_ACTIVE_MENU_ITEM_ELEMENT_SELECTED] (state, { data }) {
    state.activeMenuItemElementSelected = data
  },
  [types.CHANGE_ACTIVE_MENU_ITEM] (state, { data }) {
    state.activeMenuItem = data
  },
  [types.SET_AUTH_USER_INFO] (state, data) {
    state.authUserInfo = data
    state.sbLinksStatus = false
    state.sbShowUserPanel = true
  },
  [types.LOGOUT_AUTH] (state, data) {
    state.authUserInfo = {}
    state.sbLinksStatus = true
    state.sbShowUserPanel = false
  },
  [types.UPDATE_LOGO_AUTH] (state, { data }) {
    if (state.logoAuth !== data.logo) {
      const instWithCred = axios.create({
      })
      instWithCred.get(data.logo)
        .then(function (resp) {
          const fileNode = new DOMParser().parseFromString(resp.data, 'text/xml')
          const doc = fileNode.getElementsByTagName('svg')
          doc[0].setAttribute('width', doc[0].viewBox.baseVal.width + 'px')
          doc[0].setAttribute('width', doc[0].viewBox.baseVal.width + 'px')
          doc[0].setAttribute('height', doc[0].viewBox.baseVal.height + 'px')
          const path = fileNode.getElementsByTagName('path')
          path[0].setAttribute('fill', 'black')
          let xml1 = new XMLSerializer().serializeToString(fileNode.documentElement)
          let svg641 = window.btoa(xml1)
          let b64Start1 = 'data:image/svg+xml;base64,'
          let image641 = b64Start1 + svg641
          state.logoAuth = image641
          path[0].setAttribute('fill', data.organization.design.accentColor)
          xml1 = new XMLSerializer().serializeToString(fileNode.documentElement)
          svg641 = window.btoa(xml1)
          b64Start1 = 'data:image/svg+xml;base64,'
          image641 = b64Start1 + svg641
          state.logoHeader = image641
          path[0].setAttribute('fill', 'white')
          xml1 = new XMLSerializer().serializeToString(fileNode.documentElement)
          svg641 = window.btoa(xml1)
          b64Start1 = 'data:image/svg+xml;base64,'
          image641 = b64Start1 + svg641
          state.whiteLogo = image641
          // FAVICON
          path[0].setAttribute('fill', data.organization.design.accentColor)
          fileNode.documentElement.setAttribute('height', '16px')
          fileNode.documentElement.setAttribute('width', '16px')
          const img = document.createElement('img')
          const canvas = document.createElement('canvas')
          canvas.setAttribute('height', '16px')
          canvas.setAttribute('width', '16px')
          const xml = new XMLSerializer().serializeToString(fileNode.documentElement)
          const svg64 = window.btoa(xml)
          const b64Start = 'data:image/svg+xml;base64,'
          const image64 = b64Start + svg64
          img.onload = function () {
            canvas.getContext('2d').drawImage(img, 0, 0)
            const dt = canvas.toDataURL('image/png')
            const link = document.createElement('link')
            link.setAttribute('rel', 'icon')
            link.setAttribute('href', dt)
            const head = document.getElementsByTagName('head')[0]
            head.appendChild(link)
          }
          img.src = image64
        })
    }
  },
  [types.CHANGE_AUTH_USER_PHOTO] (state, { photo }) {
    state.userAuthProfilePhoto = photo
  },
  [types.SET_REGISTER_AUTH_STATE] (state, { data }) {
    state.registerAuthState = data
  },
  [types.SET_REGISTER_AUTH_OBJ] (state, { data }) {
    state.registerAuthObj = data
  },
  [types.SET_AUTH_USER_REGISTER_MAIL] (state) {
    state.userAuthRegMailTo = state.registerObj.email
  },
  [types.SET_SHOW_LOGIN_MODAL_SB_STATE] (state, { data }) {
    state.showLogInModalSbState = data
  },
  [types.SET_SHOW_MODAL_AUTH_USER_SB_STATE] (state, { data }) {
    state.showModalAuthUserSbState = data
  },
  [types.SET_SHOW_MODAL_FORGOT_SB_STATE] (state, { data }) {
    state.showModalForgotSbState = data
  },
  [types.SET_SHOW_MODAL_FORGOT_SUCCESS_SB_STATE] (state, { data }) {
    state.showModalForgotSuccessSbState = data
  },
  [types.SET_SHOW_MODAL_SIGNUP_SB_STATE] (state, { data }) {
    state.showModalSignUpSbState = data
  },
  [types.SET_REGISTRATION_FIRST_STEP_SB_STATE] (state, { data }) {
    state.registrationFirstStepSbState = data
  },
  [types.SET_REGISTRATION_SECOND_STEP_SB_STATE] (state, { data }) {
    state.registrationSecondStepSbState = data
  },
  [types.SET_REGISTRATION_THANKS_SB_STATE] (state, { data }) {
    state.registrationThanksSbState = data
  },
  [types.SET_TERMS_SB_STATE] (state, { data }) {
    state.termsSbState = data
  },
  [types.SET_FORGOT_EMAIL] (state, { data }) {
    state.forgotEmail = data
  },
  [types.SET_LOGIN_EMAIL] (state, { data }) {
    state.loginEmail = data
  },
  [types.SET_SHOW_MODAL_TERMS] (state, { data }) {
    state.showModalTerms = data
  },
  [types.SET_TERMS_EVENT] (state, { data }) {
    state.termsEvent = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
