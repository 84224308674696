import * as types from '@@@/store/constant/mutationsTypes'

const state = {
  scrollPosition: 0
}

const getters = {
  scrollPosition: state => state.scrollPosition
}

const actions = {
  updateScrollPosition ({ commit }, data) {
    commit(types.UPDATE_SCROLL_POSITION, data)
  }
}

const mutations = {
  [types.UPDATE_SCROLL_POSITION] (state, data) {
    state.scrollPosition = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
