// import generalSettings from './../../../public/store/modules/generalSettings'
// import ifLoader from './../../../public/store/modules/ifLoader'
// import currentTimeZone from './../../../public/store/modules/currentTimeZone'
import readingPlanList from './modules/readingPlanList'
import readingPlanPostList from './modules/readingPlanPostList'

const modules = {
  // generalSettings,
  // currentTimeZone,
  // ifLoader,
  readingPlanList,
  readingPlanPostList
}

export default modules
