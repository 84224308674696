export default {
  title: 'Payment',
  name: 'Payment',
  type: 'payment',
  group: 'regular',
  properties: {
    buttonColor: {
      selected: '#06CAF4'
    },
    labelColor: {
      selected: '#fff'
    },
    options: {
      values: [
        {
          id: 'optional',
          title: 'Optional Field'
        },
        {
          id: 'required',
          title: 'Required Field'
        },
        {
          id: 'no_duplicates',
          title: 'No Duplicates',
          description: 'No duplicates are available'
        }
      ],
      selected: {
        id: [
          'optional'
        ]
      }
    },
    visibility: {
      values: [
        {
          id: 'everyone',
          title: 'Everyone',
          description: 'Every person could fill your form'
        },
        {
          id: 'admin',
          title: 'Admin Only'
        },
        {
          id: 'registered',
          title: 'Registered Users Only'
        },
        {
          id: 'custom_category',
          title: 'Custom Category Only',
          description: 'Visible only to this category of users'
        }
      ],
      selected: {
        id: 'everyone',
        categories: []
      }
    },
    layout: {
      description: '...',
      values: [
        {
          id: 'one_column',
          title: 'One Column'
        },
        {
          id: 'two_column',
          title: 'Two Columns'
        },
        {
          id: 'three_column',
          title: 'Three Columns'
        },
        {
          id: 'side_by_side',
          title: 'Side by Side'
        }
      ],
      selected: {
        id: 'one_column'
      }
    },
    products: {
      settings: {
        receipt: {
          from: '',
          replyTo: '',
          subject: '',
          message: ''
        },
        refund: {
          from: '',
          replyTo: '',
          subject: '',
          message: ''
        },
        confirmationScreen: {
          title: '',
          message: ''
        }
      },
      selected: [
        {
          name: 'Name',
          price: 50,
          recurring: false,
          description: {
            content: '',
            show: false
          }
        }
      ]
    },
    instructions: {
      selected: null,
      optional: true
    },
    system: {
      selected: false
    }
  }
}
