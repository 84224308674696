export const API_VERSION = '1.0'
export const CURRENT_SERVER = window.API_PATH
// export const CURRENT_SERVER = 'https://gracechurchii.impactfactors.net/'
export const ACTION_DOMAIN = CURRENT_SERVER + 'api/corp/admin/action/' + API_VERSION + '/'
export const ORGANIZATIONS_DOMAIN = CURRENT_SERVER + 'api/organizations/' + API_VERSION + '/'
export const ORGANIZATIONS_CONFIG_DOMAIN = CURRENT_SERVER + 'api/organizations/admin/config/' + API_VERSION + '/'
export const CORP_DOMAIN = CURRENT_SERVER + 'api/corp/admin/action/' + API_VERSION + '/'
export const APPLICATION_ADMIN_CONFIGURATION_DOMAIN = CURRENT_SERVER + 'api/application/admin/config/' + API_VERSION + '/'
export const CORP_ORGANIZATIONS_DOMAIN = CURRENT_SERVER + 'api/corp/admin/organization/' + API_VERSION + '/'
export const APPLICATION_CONFIGURATION_DOMAIN = CURRENT_SERVER + 'api/application/' + API_VERSION + '/'
export const MINISTRIES_DOMAIN = CURRENT_SERVER + 'api/organizations/admin/ministry/' + API_VERSION + '/'
export const DESIGNATION_DOMAIN = CURRENT_SERVER + 'api/admin/designation/' + API_VERSION + '/'
