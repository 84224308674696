// import * as Proxima from './svg/Proxima'
// import * as Roboto from './svg/Roboto'
// import * as RobotoBold from './svg/RobotoBold'
// import * as GroceryHand from './svg/GroceryHand'
// import * as GroceryHandIt from './svg/GroceryHandIt'
// import * as JimmySansBld from './svg/JimmySansBld'
// import * as JimmySansRg from './svg/JimmySansRg'
// import * as JimmyScriptBld from './svg/JimmyScriptBld'
// import * as JimmyScriptLt from './svg/JimmyScriptLt'
// import * as JimmyScriptRg from './svg/JimmyScriptRg'
// import * as Jovial from './svg/Jovial'
// import * as JovialBld from './svg/JovialBld'
// import * as JovialLt from './svg/JovialLt'
// import * as JovialSans from './svg/JovialSans'
// import * as JovialSansBld from './svg/JovialSansBld'
// import * as JovialSansLt from './svg/JovialSansLt'
// import * as LanceRg from './svg/LanceRg'
// import * as LanceSansRg from './svg/LanceSansRg'
// import * as LiberalHandBld from './svg/LiberalHandBld'
// import * as LiberalHandRg from './svg/LiberalHandRg'
// import * as LiberalHandSansBld from './svg/LiberalHandSansBld'
// import * as LiberalHandSansRg from './svg/LiberalHandSansRg'
// import * as Magnite from './svg/Magnite'
// import * as MagniteAlt from './svg/MagniteAlt'
// import * as MagniteAltItalic from './svg/MagniteAltItalic'
// import * as MagniteItalic from './svg/MagniteItalic'
// import * as PetalBrush from './svg/PetalBrush'
// import * as RivinaTCBrush from './svg/RivinaTCBrush'
// import * as RivinaTCFill from './svg/RivinaTCFill'
// import * as RivinaTCOutline from './svg/RivinaTCOutline'
// import * as RivinaTCPen from './svg/RivinaTCPen'
// import * as RivinaTCPenCn from './svg/RivinaTCPenCn'
// import * as RivinaTCPenOutline from './svg/RivinaTCPenOutline'
// import * as RivinaTCPenOutlineCn from './svg/RivinaTCPenOutlineCn'
// import * as RockOutRg from './svg/RockOutRg'
// import * as RussellAlt from './svg/RussellAlt'
// import * as RussellAltIt from './svg/RussellAltIt'
// import * as RussellRg from './svg/RussellRg'
// import * as RussellRgIt from './svg/RussellRgIt'
// import * as TallAbbeyRg from './svg/TallAbbeyRg'
// import * as TallAbbeySansRg from './svg/TallAbbeySansRg'
// import * as TallowSansTCBrsh from './svg/TallowSansTCBrsh'
// import * as TallowSansTCPen from './svg/TallowSansTCPen'
// import * as TallowSansTCRg from './svg/TallowSansTCRg'
// import * as TallowSansTCRgh from './svg/TallowSansTCRgh'
// import * as TallowSansTCSktch from './svg/TallowSansTCSktch'
// import * as TallowTCBrsh from './svg/TallowTCBrsh'
// import * as TallowTCPen from './svg/TallowTCPen'
// import * as TallowTCRg from './svg/TallowTCRg'
// import * as TallowTCRgh from './svg/TallowTCRgh'
// import * as TallowTCSktch from './svg/TallowTCSktch'
// import * as TrueSketchIt from './svg/TrueSketchIt'
// import * as TrueSketchRg from './svg/TrueSketchRg'
// import * as WhickerBonusTC from './svg/WhickerBonusTC'
// import * as WhickerTC from './svg/WhickerTC'
// import * as GroceryBrush from './svg/GroceryBrush'
// import * as RobotoMedium from './svg/RobotoMedium'
// import * as PlayfairDisplayBold from './svg/PlayfairDisplayBold'
// import * as RobotoLight from './svg/RobotoLight'
// import * as MontserratExtraLight from './svg/MontserratExtraLight'
// import * as MontserratBold from './svg/MontserratBold'
// import * as MontserratMedium from './svg/MontserratMedium'
// import * as MontserratExtraBold from './svg/MontserratExtraBold'
// import * as Butler from './svg/Butler'
// import * as Montserrat from './svg/Montserrat'
// import * as MontserratLight from './svg/MontserratLight'
// import * as Butlerlight from './svg/Butlerlight'
// import * as SourceSansPro from './svg/SourceSansPro'
// import * as Anders from './svg/Anders'
// import * as OswaldRegular from './svg/OswaldRegular'
// import * as RalewayRegular from './svg/RalewayRegular'
// import * as RalewayBold from './svg/RalewayBold'
// import * as RalewayBlack from './svg/RalewayBlack'
// import * as RobotoCondensed from './svg/RobotoCondensed'
// import * as Alice from './svg/Alice'
// import * as MullerDemoExtraBold from './svg/MullerDemoExtraBold'
// import * as MullerNarrowDemoExtraBold from './svg/MullerNarrowDemoExtraBold'
// import * as CrimsonTextRegular from './svg/CrimsonTextRegular'
// import * as CrimsonTextBold from './svg/CrimsonTextBold'
// import * as OpenSans from './svg/OpenSans'
// import * as LatoRegular from './svg/LatoRegular'
// import * as LatoBold from './svg/LatoBold'
// import * as LatoLight from './svg/LatoLight'
// import * as SantEliaRoughAlt from './svg/SantEliaRoughAlt'
// import * as Guerrilla from './svg/Guerrilla'
// import * as Gagalin from './svg/Gagalin'
// import * as Elowen from './svg/Elowen'
// import * as BrushScriptStd from './svg/BrushScriptStd'
// import * as OswaldHeavy from './svg/OswaldHeavy'
// import * as Sreda from './svg/Sreda'
// import * as YoungSerif from './svg/YoungSerif'
// import * as BloggerSans from './svg/BloggerSans'
// import * as Faunetext from './svg/Faunetext'
// import * as PeaceSans from './svg/PeaceSans'
// import * as Westmeath from './svg/Westmeath'
// import * as Selima from './svg/Selima'
// import * as CanterBold from './svg/CanterBold'
// import * as Corduroy from './svg/Corduroy'
// import * as CanterBold3D from './svg/CanterBold3D'
// import * as CanterLight from './svg/CanterLight'
// import * as CastIronCondensed from './svg/CastIronCondensed'
// import * as Journalism from './svg/Journalism'
// import * as FrontageOutline from './svg/FrontageOutline'
// import * as Fontuna from './svg/Fontuna'
// import * as StrikeOne from './svg/StrikeOne'
// import * as Drina from './svg/Drina'
// import * as PlayfairDisplay from './svg/PlayfairDisplay'
// import * as RubikBold from './svg/RubikBold'
// import * as DeLaFuente from './svg/DeLaFuente'
// import * as Bangers from './svg/Bangers'
// import * as TkachenkoSketch4F from './svg/TkachenkoSketch4F'
// import * as MerriweatherBlackItalic from './svg/MerriweatherBlackItalic'
// import * as TitanOne from './svg/TitanOne'
// import * as BitterBold from './svg/BitterBold'
// import * as BitterItalic from './svg/BitterItalic'
// import * as AnsleyDisplay from './svg/AnsleyDisplay'
// import * as Exotic350 from './svg/Exotic350'
// import * as Anton from './svg/Anton'
// import * as ArturoHeavy from './svg/ArturoHeavy'
// import * as KelsonSans from './svg/KelsonSans'
// import * as FibonSans from './svg/FibonSans'
// import * as Relancer from './svg/Relancer'
// import * as Intro from './svg/Intro'
// import * as SantEliaScript from './svg/SantEliaScript'
// import * as Norwester from './svg/Norwester'
// import * as PoplarStd from './svg/PoplarStd'
// import * as ProximaMedium from './svg/ProximaMedium'f
// import * as Muller from './svg/Muller'
// import * as Gotham from './svg/Gotham'
// import * as TimesNew from './svg/TimesNew'
// import * as Arial from './svg/Arial'
// import * as ZingRustDemo from './svg/ZingRustDemo'
// import * as TrebuchetMS from './svg/TrebuchetMS'
// import * as AveraSansTc from './svg/AveraSansTc'
// import * as AveraSansTcBld from './svg/AveraSansTcBld'
// import * as AveraSansTcBrsh from './svg/AveraSansTcBrsh'
// import * as AveraSansTcLt from './svg/AveraSansTcLt'
// import * as AveraSansTcSktch from './svg/AveraSansTcSktch'
// import * as BobbyJones from './svg/BobbyJones'
// import * as BobbyJonesCondensed from './svg/BobbyJonesCondensed'
// import * as BobbyJonesOutline from './svg/BobbyJonesOutline'
// import * as BouncyCastleBonusBold from './svg/BouncyCastleBonusBold'
// import * as BobbyJonesSoft from './svg/BobbyJonesSoft'
// import * as BobbyJonesSoftCondensed from './svg/BobbyJonesSoftCondensed'
// import * as BobbyJonesSoftCondensedOutline from './svg/BobbyJonesSoftCondensedOutline'
// import * as BobbyJonesSoftOutline from './svg/BobbyJonesSoftOutline'
// import * as BobbyRough from './svg/BobbyRough'
// import * as BobbyRoughCondensed from './svg/BobbyRoughCondensed'
// import * as BobbyRoughCondensedOutline from './svg/BobbyRoughCondensedOutline'
// import * as BobbyRoughOutline from './svg/BobbyRoughOutline'
// import * as BobbyRoughSoft from './svg/BobbyRoughSoft'
// import * as BobbyRoughSoftCondensed from './svg/BobbyRoughSoftCondensed'
// import * as BobbyRoughSoftCondensedOutline from './svg/BobbyRoughSoftCondensedOutline'
// import * as BobbyRoughSoftOutline from './svg/BobbyRoughSoftOutline'
// import * as BouncyCastle from './svg/BouncyCastle'
// import * as BouncyCastleBonus from './svg/BouncyCastleBonus'
// import * as BouncyCastleSans from './svg/BouncyCastleSans'
// import * as BouncyCastleSS01 from './svg/BouncyCastleSS01'
// import * as BouncyCastleSS02 from './svg/BouncyCastleSS02'
// import * as BouncyCastleSS03 from './svg/BouncyCastleSS03'
// import * as BrixtonHand from './svg/BrixtonHand'
// import * as BrixtonHandExtras from './svg/BrixtonHandExtras'
// import * as BrixtonHandSans from './svg/BrixtonHandSans'
// import * as BrixtonHandWords from './svg/BrixtonHandWords'
// import * as BrixtonLight from './svg/BrixtonLight'
// import * as BrixtonBold from './svg/BrixtonBold'
// import * as BrixtonInRegular from './svg/BrixtonInRegular'
// import * as BrixtonInLight from './svg/BrixtonInLight'
// import * as BrixtonOutlineBold from './svg/BrixtonOutlineBold'
// import * as BrixtonOutlineLight from './svg/BrixtonOutlineLight'
// import * as BrixtonOutlineRegular from './svg/BrixtonOutlineRegular'
// import * as BrixtonRegular from './svg/BrixtonRegular'
// import * as BrixtonSansBld from './svg/BrixtonSansBld'
// import * as BrixtonSansLt from './svg/BrixtonSansLt'
// import * as BrixtonSansOutlineBld from './svg/BrixtonSansOutlineBld'
// import * as BrixtonSansOutlineLt from './svg/BrixtonSansOutlineLt'
// import * as BrixtonSansOutlineRg from './svg/BrixtonSansOutlineRg'
// import * as BuckwheatTCRg from './svg/BuckwheatTCRg'
// import * as BuckwheatTCRough from './svg/BuckwheatTCRough'
// import * as BuckwheatTCSansRg from './svg/BuckwheatTCSansRg'
// import * as BuckwheatTCSansRough from './svg/BuckwheatTCSansRough'
// import * as BuckwheatTCScriptRg from './svg/BuckwheatTCScriptRg'
// import * as BuckwheatTCScriptRough from './svg/BuckwheatTCScriptRough'

export const FONT_FAMILIES_LIST = [
  {
    text: 'BrixtonSansLt',
    key: 'BrixtonSansLt',
    icon: 'BrixtonSansLt'
  },
  {
    text: 'BrixtonSansOutlineRg',
    key: 'BrixtonSansOutlineRg',
    icon: 'BrixtonSansOutlineRg'
  },
  {
    text: 'BrixtonSansOutlineBld',
    key: 'BrixtonSansOutlineBld',
    icon: 'BrixtonSansOutlineBld'
  },
  {
    text: 'BrixtonSansOutlineLt',
    key: 'BrixtonSansOutlineLt',
    icon: 'BrixtonSansOutlineLt'
  },
  {
    text: 'BrixtonSansBld',
    key: 'BrixtonSansBld',
    icon: 'BrixtonSansBld'
  },
  {
    text: 'Brixton-Regular',
    key: 'Brixton-Regular',
    name: 'BrixtonRegular',
    icon: 'BrixtonRegular'
  },
  {
    text: 'Brixton-Outline-Light',
    key: 'Brixton-Outline-Light',
    name: 'BrixtonOutlineLight',
    icon: 'BrixtonOutlineLight'
  },
  {
    text: 'Brixton-Outline-Regular',
    key: 'Brixton-Outline-Regular',
    name: 'BrixtonOutlineRegular',
    icon: 'BrixtonOutlineRegular'
  },
  {
    text: 'Brixton-Outline-Bold',
    key: 'Brixton-Outline-Bold',
    name: 'BrixtonOutlineBold',
    icon: 'BrixtonOutlineBold'
  },
  {
    text: 'Buckwheat-TC-Rg',
    key: 'Buckwheat-TC-Rg',
    name: 'BuckwheatTCRg',
    icon: 'BuckwheatTCRg'
  },
  {
    text: 'Buckwheat-TC-Rough',
    key: 'Buckwheat-TC-Rough',
    name: 'BuckwheatTCRough',
    icon: 'BuckwheatTCRough'
  },
  {
    text: 'Buckwheat-TC-Sans-Rg',
    key: 'Buckwheat-TC-Sans-Rg',
    name: 'BuckwheatTCSansRg',
    icon: 'BuckwheatTCSansRg'
  },
  {
    text: 'Buckwheat-TC-Sans-Rough',
    key: 'Buckwheat-TC-Sans-Rough',
    name: 'BuckwheatTCSansRough',
    icon: 'BuckwheatTCSansRough'
  },
  {
    text: 'Buckwheat-TC-Script-Rough',
    key: 'Buckwheat-TC-Script-Rough',
    name: 'BuckwheatTCScriptRough',
    icon: 'BuckwheatTCScriptRough'
  },
  {
    text: 'Buckwheat-TC-Script-Rg',
    key: 'Buckwheat-TC-Script-Rg',
    name: 'BuckwheatTCScriptRg',
    icon: 'BuckwheatTCScriptRg'
  },
  {
    text: 'BrixtonHandWords',
    key: 'Brixton-Hand-Words',
    icon: 'BrixtonHandWords'
  },
  {
    text: 'Brixton-In-Light',
    key: 'Brixton-In-Light',
    name: 'BrixtonInLight',
    icon: 'BrixtonInLight'
  },
  {
    text: 'Brixton-In-Regular',
    key: 'Brixton-In-Regular',
    name: 'BrixtonInRegular',
    icon: 'BrixtonInRegular'
  },
  {
    text: 'Brixton-Bold',
    key: 'Brixton-Bold',
    name: 'BrixtonBold',
    icon: 'BrixtonBold'
  },
  {
    text: 'Brixton-Light',
    key: 'Brixton-Light',
    name: 'BrixtonLight',
    icon: 'BrixtonLight'
  },
  {
    text: 'Brixton-Hand-Sans',
    key: 'Brixton-Hand-Sans',
    name: 'BrixtonHandSans',
    icon: 'BrixtonHandSans'
  },
  {
    text: 'Brixton-Hand-Extras',
    key: 'Brixton-Hand-Extras',
    name: 'BrixtonHandExtras',
    icon: 'BrixtonHandExtras'
  },
  {
    text: 'BouncyCastleSans',
    key: 'Bouncy-Castle-Sans',
    icon: 'BouncyCastleSans'
  },
  {
    text: 'Brixton-Hand',
    key: 'Brixton-Hand',
    name: 'BrixtonHand',
    icon: 'BrixtonHand'
  },
  {
    text: 'Bouncy-Castle-SS01',
    key: 'Bouncy-Castle-SS01',
    name: 'BouncyCastleSS01',
    icon: 'BouncyCastleSS01'
  },
  {
    text: 'Bouncy-Castle-SS03',
    key: 'Bouncy-Castle-SS03',
    name: 'BouncyCastleSS03',
    icon: 'BouncyCastleSS03'
  },
  {
    text: 'Bouncy-Castle-SS02',
    key: 'Bouncy-Castle-SS02',
    name: 'BouncyCastleSS02',
    icon: 'BouncyCastleSS02'
  },
  {
    text: 'Bouncy-Castle-Bonus',
    key: 'Bouncy-Castle-Bonus',
    name: 'BouncyCastleBonus',
    icon: 'BouncyCastleBonus'
  },
  {
    text: 'Bobby-Rough-Soft-Condensed-Outline',
    key: 'Bobby-Rough-Soft-Condensed-Outline',
    name: 'BobbyRoughSoftCondensedOutline',
    icon: 'BobbyRoughSoftCondensedOutline'
  },
  {
    text: 'Bobby-Rough-Soft-Outline',
    key: 'Bobby-Rough-Soft-Outline',
    name: 'BobbyRoughSoftOutline',
    icon: 'BobbyRoughSoftOutline'
  },
  {
    text: 'BouncyCastle',
    key: 'Bouncy-Castle',
    icon: 'BouncyCastle'
  },
  {
    text: 'Bobby-Rough-Condensed',
    key: 'Bobby-Rough-Condensed',
    name: 'BobbyRoughCondensed',
    icon: 'BobbyRoughCondensed'
  },
  {
    text: 'Bobby-Rough-Soft-Condensed',
    key: 'Bobby-Rough-Soft-Condensed',
    name: 'BobbyRoughSoftCondensed',
    icon: 'BobbyRoughSoftCondensed'
  },
  {
    text: 'Bobby-Rough-Soft',
    key: 'Bobby-Rough-Soft',
    name: 'BobbyRoughSoft',
    icon: 'BobbyRoughSoft'
  },
  {
    text: 'Bobby-Rough-Outline',
    key: 'Bobby-Rough-Outline',
    name: 'BobbyRoughOutline',
    icon: 'BobbyRoughOutline'
  },
  {
    text: 'Bobby-Rough-Condensed-Outline',
    key: 'Bobby-Rough-Condensed-Outline',
    name: 'BobbyRoughCondensedOutline',
    icon: 'BobbyRoughCondensedOutline'
  },
  {
    text: 'Bobby-Jones-Soft-Condensed-Outline',
    key: 'Bobby-Jones-Soft-Condensed-Outline',
    name: 'BobbyJonesSoftCondensedOutline',
    icon: 'BobbyJonesSoftCondensedOutline'
  },
  {
    text: 'Bobby-Jones-Soft-Outline',
    key: 'Bobby-Jones-Soft-Outline',
    name: 'BobbyJonesSoftOutline',
    icon: 'BobbyJonesSoftOutline'
  },
  {
    text: 'BobbyRough',
    key: 'BobbyRough',
    icon: 'BobbyRough'
  },
  {
    text: 'Bobby-Jones-Soft-Condensed',
    key: 'Bobby-Jones-Soft-Condensed',
    name: 'BobbyJonesSoftCondensed',
    icon: 'BobbyJonesSoftCondensed'
  },
  {
    text: 'Bobby-Jones-Soft',
    key: 'Bobby-Jones-Soft',
    name: 'BobbyJonesSoft',
    icon: 'BobbyJonesSoft'
  },
  {
    text: 'Bobby-Jones-Outline',
    key: 'Bobby-Jones-Outline',
    name: 'BobbyJonesOutline',
    icon: 'BobbyJonesOutline'
  },
  {
    text: 'Bouncy Castle Bonus Bold',
    key: 'Bouncy Castle Bonus Bold',
    name: 'BouncyCastleBonusBold',
    icon: 'BouncyCastleBonusBold'
  },
  {
    text: 'Bobby-Jones-Soft',
    key: 'Bobby-Jones-Soft',
    name: 'BobbyJonesSoft',
    icon: 'BobbyJonesSoft'
  },
  {
    text: 'AveraSansTcBld',
    key: 'AveraSansTcBld',
    icon: 'AveraSansTcBld'
  },
  {
    text: 'AveraSansTcSktch',
    key: 'AveraSansTcSktch',
    icon: 'AveraSansTcSktch'
  },
  {
    text: 'Bobby-Jones-Condensed',
    key: 'Bobby-Jones-Condensed',
    name: 'BobbyJonesCondensed',
    icon: 'BobbyJonesCondensed'
  },
  {
    text: 'Bobby-Jones',
    key: 'Bobby-Jones',
    name: 'BobbyJones',
    icon: 'BobbyJones'
  },
  {
    text: 'AveraSansTcBrsh',
    key: 'AveraSansTcBrsh',
    icon: 'AveraSansTcBrsh'
  },
  {
    text: 'AveraSansTcLt',
    key: 'AveraSansTcLt',
    icon: 'AveraSansTcLt'
  },
  {
    text: 'Muller',
    key: 'Muller',
    icon: 'Muller'
  },
  {
    text: 'AveraSansTc',
    key: 'AveraSansTc',
    icon: 'AveraSansTc'
  },
  {
    text: 'ZingRustDemo',
    key: 'ZingRustDemo',
    icon: 'ZingRustDemo'
  },
  {
    text: 'Trebuchet MS',
    key: 'Trebuchet MS',
    name: 'TrebuchetMS',
    icon: 'TrebuchetMS'
  },
  {
    text: 'Arial',
    key: 'Arial',
    icon: 'Arial'
  },
  {
    text: 'Times New Roman',
    key: 'Times New Roman',
    name: 'TimesNew',
    icon: 'TimesNew'
  },
  {
    text: 'GothamPro',
    key: 'GothamPro',
    name: 'Gotham',
    icon: 'Gotham'
  },
  {
    text: 'Fraunces Wonky ExtraBold',
    key: 'Fraunces Wonky ExtraBold',
    name: 'Fraunces Wonky ExtraBold',
    icon: 'FrauncesWonkyExtraBold'
  },
  {
    text: 'Fraunces Wonky SemiBold',
    key: 'Fraunces Wonky SemiBold',
    name: 'Fraunces Wonky SemiBold',
    icon: 'FrauncesWonkySemiBold'
  },
  {
    text: 'Fraunces Wonky Black',
    key: 'Fraunces Wonky Black',
    name: 'Fraunces Wonky Black',
    icon: 'FrauncesWonkyBlack'
  },
  {
    text: 'Gotham Pro Medium',
    key: 'Gotham Pro Medium',
    name: 'GothamProMedium',
    icon: 'GothamProMedium'
  },
  {
    text: 'Fibon_Sans',
    key: 'Fibon_Sans',
    name: 'FibonSans',
    icon: 'FibonSans'
  },
  {
    text: 'PoplarStd',
    key: 'PoplarStd',
    icon: 'PoplarStd'
  },
  {
    text: 'Proxima-Medium',
    key: 'Proxima-Medium',
    name: 'ProximaMedium',
    icon: 'ProximaMedium'
  },
  {
    text: 'Norwester',
    key: 'Norwester',
    icon: 'Norwester'
  },
  {
    text: 'SantEliaScript',
    key: 'SantEliaScript',
    icon: 'SantEliaScript'
  },
  {
    text: 'Intro',
    key: 'Intro',
    icon: 'Intro'
  },
  {
    text: 'Relancer',
    key: 'Relancer',
    icon: 'Relancer'
  },
  {
    text: 'ArturoHeavy',
    key: 'Arturo-Heavy',
    icon: 'ArturoHeavy'
  },
  {
    text: 'Kelson-Sans',
    key: 'Kelson-Sans',
    name: 'KelsonSans',
    icon: 'KelsonSans'
  },
  {
    text: 'Exotic350',
    key: 'Exotic350',
    icon: 'Exotic350'
  },
  {
    text: 'Ansley-Display',
    key: 'Ansley-Display',
    name: 'AnsleyDisplay',
    icon: 'AnsleyDisplay'
  },
  {
    text: 'TkachenkoSketch4F',
    key: 'TkachenkoSketch4F',
    icon: 'TkachenkoSketch4F'
  },
  {
    text: 'DeLaFuente',
    key: 'DeLaFuente',
    icon: 'DeLaFuente'
  },
  {
    text: 'CastIron-Condensed',
    key: 'CastIron-Condensed',
    name: 'CastIronCondensed',
    icon: 'CastIronCondensed'
  },
  {
    text: 'Drina',
    key: 'Drina',
    icon: 'Drina'
  },
  {
    text: 'PlayfairDisplay',
    key: 'PlayfairDisplay',
    icon: 'PlayfairDisplay'
  },
  {
    text: 'Frontage-Outline',
    key: 'Frontage-Outline',
    name: 'FrontageOutline',
    icon: 'FrontageOutline'
  },
  {
    text: 'Fontuna',
    key: 'Fontuna',
    icon: 'Fontuna'
  },
  {
    text: 'StrikeOne',
    key: 'StrikeOne',
    icon: 'StrikeOne'
  },
  {
    text: 'Journalism',
    key: 'Journalism',
    icon: 'Journalism'
  },
  {
    text: 'Peace-Sans',
    key: 'Peace-Sans',
    name: 'PeaceSans',
    icon: 'PeaceSans'
  },
  {
    text: 'Westmeath',
    key: 'Westmeath',
    icon: 'Westmeath'
  },
  {
    text: 'Selima',
    key: 'Selima',
    icon: 'Selima'
  },
  {
    text: 'Canter-Bold-3D',
    key: 'Canter-Bold-3D',
    name: 'CanterBold3D',
    icon: 'CanterBold3D'
  },
  {
    text: 'Canter-Light',
    key: 'Canter-Light',
    name: 'CanterLight',
    icon: 'CanterLight'
  },
  {
    text: 'Canter-Bold',
    key: 'Canter-Bold',
    name: 'CanterBold',
    icon: 'CanterBold'
  },
  {
    text: 'Corduroy',
    key: 'Corduroy',
    icon: 'Corduroy'
  },
  {
    text: 'Elowen',
    key: 'Elowen',
    icon: 'Elowen'
  },
  {
    text: 'Faune-text',
    key: 'Faune-text',
    name: 'Faunetext',
    icon: 'Faunetext'
  },
  {
    text: 'BloggerSans',
    key: 'Blogger-Sans',
    icon: 'BloggerSans'
  },
  {
    text: 'YoungSerif',
    key: 'YoungSerif',
    icon: 'YoungSerif'
  },
  {
    text: 'Sreda',
    key: 'Sreda',
    icon: 'Sreda'
  },
  {
    text: 'BrushScriptStd',
    key: 'BrushScriptStd',
    icon: 'BrushScriptStd'
  },
  {
    text: 'Guerrilla',
    key: 'Guerrilla',
    icon: 'Guerrilla'
  },
  {
    text: 'Gagalin',
    key: 'Gagalin',
    icon: 'Gagalin'
  },
  {
    text: 'SantEliaRoughAlt',
    key: 'SantEliaRoughAlt',
    icon: 'SantEliaRoughAlt'
  },
  {
    text: 'MullerDemo-ExtraBold',
    key: 'MullerDemo-ExtraBold',
    name: 'MullerDemoExtraBold',
    icon: 'MullerDemoExtraBold'
  },
  {
    text: 'MullerNarrowDemo-ExtraBold',
    key: 'MullerNarrowDemo-ExtraBold',
    name: 'MullerNarrowDemoExtraBold',
    icon: 'MullerNarrowDemoExtraBold'
  },
  {
    text: 'Anders',
    key: 'Anders',
    icon: 'Anders'
  },
  {
    text: 'Butler',
    key: 'Butler',
    icon: 'Butler'
  },
  {
    text: 'Butlerlight',
    key: 'Butlerlight',
    icon: 'Butlerlight'
  },
  {
    text: 'Proxima',
    key: 'Proxima',
    icon: 'Proxima'
  },
  {
    text: 'GroceryHand',
    key: 'GroceryHand',
    icon: 'GroceryHand'
  },
  {
    text: 'GroceryHand_It',
    key: 'GroceryHand_It',
    name: 'GroceryHandIt',
    icon: 'GroceryHandIt'
  },
  {
    text: 'JimmySans-Bld',
    key: 'JimmySans-Bld',
    name: 'JimmySansBld',
    icon: 'JimmySansBld'
  },
  {
    text: 'JimmySans-Rg',
    key: 'JimmySans-Rg',
    name: 'JimmySansRg',
    icon: 'JimmySansRg'
  },
  {
    text: 'JimmyScript-Bld',
    key: 'JimmyScript-Bld',
    name: 'JimmyScriptBld',
    icon: 'JimmyScriptBld'
  },
  {
    text: 'JimmyScript-Lt',
    key: 'JimmyScript-Lt',
    name: 'JimmyScriptLt',
    icon: 'JimmyScriptLt'
  },
  {
    text: 'JimmyScript-Rg',
    key: 'JimmyScript-Rg',
    name: 'JimmyScriptRg',
    icon: 'JimmyScriptRg'
  },
  {
    text: 'Jovial',
    key: 'Jovial',
    icon: 'Jovial'
  },
  {
    text: 'JovialBld',
    key: 'JovialBld',
    icon: 'JovialBld'
  },
  {
    text: 'JovialLt',
    key: 'JovialLt',
    icon: 'JovialLt'
  },
  {
    text: 'JovialSans',
    key: 'JovialSans',
    icon: 'JovialSans'
  },
  {
    text: 'JovialSansBld',
    key: 'JovialSansBld',
    icon: 'JovialSansBld'
  },
  {
    text: 'JovialSansLt',
    key: 'JovialSansLt',
    icon: 'JovialSansLt'
  },
  {
    text: 'LanceRg',
    key: 'LanceRg',
    icon: 'LanceRg'
  },
  {
    text: 'LanceSansRg',
    key: 'LanceSansRg',
    icon: 'LanceSansRg'
  },
  {
    text: 'LiberalHand_Bld',
    key: 'LiberalHand_Bld',
    name: 'LiberalHandBld',
    icon: 'LiberalHandBld'
  },
  {
    text: 'LiberalHand_Rg',
    key: 'LiberalHand_Rg',
    name: 'LiberalHandRg',
    icon: 'LiberalHandRg'
  },
  {
    text: 'LiberalHandSans-Bld',
    key: 'LiberalHandSans-Bld',
    name: 'LiberalHandSansBld',
    icon: 'LiberalHandSansBld'
  },
  {
    text: 'LiberalHandSans-Rg',
    key: 'LiberalHandSans-Rg',
    name: 'LiberalHandSansRg',
    icon: 'LiberalHandSansRg'
  },
  {
    text: 'Magnite',
    key: 'Magnite',
    icon: 'Magnite'
  },
  {
    text: 'Magnite-Alt',
    key: 'Magnite-Alt',
    name: 'MagniteAlt',
    icon: 'MagniteAlt'
  },
  {
    text: 'Magnite-Alt-Italic',
    key: 'Magnite-Alt-Italic',
    name: 'MagniteAltItalic',
    icon: 'MagniteAltItalic'
  },
  {
    text: 'Magnite-Italic',
    key: 'Magnite-Italic',
    name: 'MagniteItalic',
    icon: 'MagniteItalic'
  },
  {
    text: 'PetalBrush',
    key: 'PetalBrush',
    icon: 'PetalBrush'
  },
  {
    text: 'RivinaTC-Brush',
    key: 'RivinaTC-Brush',
    name: 'RivinaTCBrush',
    icon: 'RivinaTCBrush'
  },
  {
    text: 'RivinaTC-Fill',
    key: 'RivinaTC-Fill',
    name: 'RivinaTCFill',
    icon: 'RivinaTCFill'
  },
  {
    text: 'RivinaTC-Outline',
    key: 'RivinaTC-Outline',
    name: 'RivinaTCOutline',
    icon: 'RivinaTCOutline'
  },
  {
    text: 'RivinaTC-Pen',
    key: 'RivinaTC-Pen',
    name: 'RivinaTCPen',
    icon: 'RivinaTCPen'
  },
  {
    text: 'RivinaTC-PenCn',
    key: 'RivinaTC-PenCn',
    name: 'RivinaTCPenCn',
    icon: 'RivinaTCPenCn'
  },
  {
    text: 'RivinaTC-PenOutline',
    key: 'RivinaTC-PenOutline',
    name: 'RivinaTCPenOutline',
    icon: 'RivinaTCPenOutline'
  },
  {
    text: 'RivinaTC-PenOutlineCn',
    key: 'RivinaTC-PenOutlineCn',
    name: 'RivinaTCPenOutlineCn',
    icon: 'RivinaTCPenOutlineCn'
  },
  {
    text: 'RockOut-Rg',
    key: 'RockOut-Rg',
    name: 'RockOutRg',
    icon: 'RockOutRg'
  },
  {
    text: 'RussellAlt',
    key: 'RussellAlt',
    icon: 'RussellAlt'
  },
  {
    text: 'RussellAltIt',
    key: 'RussellAltIt',
    icon: 'RussellAltIt'
  },
  {
    text: 'RussellRg',
    key: 'RussellRg',
    icon: 'RussellRg'
  },
  {
    text: 'RussellRgIt',
    key: 'RussellRgIt',
    icon: 'RussellRgIt'
  },
  {
    text: 'TallAbbeyRg',
    key: 'TallAbbeyRg',
    icon: 'TallAbbeyRg'
  },
  {
    text: 'TallAbbeySansRg',
    key: 'TallAbbeySansRg',
    icon: 'TallAbbeySansRg'
  },
  {
    text: 'TallowSansTC-Brsh',
    key: 'TallowSansTC-Brsh',
    name: 'TallowSansTCBrsh',
    icon: 'TallowSansTCBrsh'
  },
  {
    text: 'TallowSansTC-Pen',
    key: 'TallowSansTC-Pen',
    name: 'TallowSansTCPen',
    icon: 'TallowSansTCPen'
  },
  {
    text: 'TallowSansTC-Rg',
    key: 'TallowSansTC-Rg',
    name: 'TallowSansTCRg',
    icon: 'TallowSansTCRg'
  },
  {
    text: 'TallowSansTC-Rgh',
    key: 'TallowSansTC-Rgh',
    name: 'TallowSansTCRgh',
    icon: 'TallowSansTCRgh'
  },
  {
    text: 'TallowSansTC-Sktch',
    key: 'TallowSansTC-Sktch',
    name: 'TallowSansTCSktch',
    icon: 'TallowSansTCSktch'
  },
  {
    text: 'TallowTC-Brsh',
    key: 'TallowTC-Brsh',
    name: 'TallowTCBrsh',
    icon: 'TallowTCBrsh'
  },
  {
    text: 'TallowTC-Pen',
    key: 'TallowTC-Pen',
    name: 'TallowTCPen',
    icon: 'TallowTCPen'
  },
  {
    text: 'TallowTC-Rg',
    key: 'TallowTC-Rg',
    name: 'TallowTCRg',
    icon: 'TallowTCRg'
  },
  {
    text: 'TallowTC-Rgh',
    key: 'TallowTC-Rgh',
    name: 'TallowTCRgh',
    icon: 'TallowTCRgh'
  },
  {
    text: 'TallowTC-Sktch',
    key: 'TallowTC-Sktch',
    name: 'TallowTCSktch',
    icon: 'TallowTCSktch'
  },
  {
    text: 'TrueSketchIt',
    key: 'TrueSketchIt',
    icon: 'TrueSketchIt'
  },
  {
    text: 'TrueSketchRg',
    key: 'TrueSketchRg',
    icon: 'TrueSketchRg'
  },
  {
    text: 'WhickerBonusTC',
    key: 'WhickerBonusTC',
    icon: 'WhickerBonusTC'
  },
  {
    text: 'WhickerTC',
    key: 'WhickerTC',
    icon: 'WhickerTC'
  },
  {
    text: 'Grocery-Brush',
    key: 'Grocery-Brush',
    name: 'GroceryBrush',
    icon: 'GroceryBrush'
  },
  {
    text: 'Madrone',
    key: 'Madrone'
  },
  {
    text: 'Petal',
    key: 'Petal'
  },
  {
    text: 'ClarendonLTStd-Bold',
    key: 'ClarendonLTStd-Bold'
  },
  {
    text: 'SF Pro Display',
    key: 'SF Pro Display'
  },
  {
    text: 'HelveticaNeueLT-BlackExt',
    key: 'HelveticaNeueLT-BlackExt'
  },
  {
    text: 'MaverFree',
    key: 'MaverFree'
  },
  {
    text: 'BourbonGrotesque-Regular',
    key: 'BourbonGrotesque-Regular'
  },
  {
    text: 'Nexa-Bold',
    key: 'Nexa-Bold'
  },
  {
    text: 'Bebas-Regular',
    key: 'Bebas-Regular'
  }
  // {
  //   text: 'Poppins-Thin',
  //   key: 'Poppins-Thin'
  // },
  // {
  //   text: 'Poppins-Thin-Italic',
  //   key: 'Poppins-Thin-Italic'
  // },
  // {
  //   text: 'Poppins-ExtraLight',
  //   key: 'Poppins-ExtraLight'
  // },
  // {
  //   text: 'Poppins-ExtraLight-Italic',
  //   key: 'Poppins-ExtraLight-Italic'
  // },
  // {
  //   text: 'Poppins-Light',
  //   key: 'Poppins-Light'
  // },
  // {
  //   text: 'Poppins-Light-Italic',
  //   key: 'Poppins-Light-Italic'
  // },
  // {
  //   text: 'Poppins',
  //   key: 'Poppins'
  // },
  // {
  //   text: 'Poppins-Italic',
  //   key: 'Poppins-Italic'
  // },
  // {
  //   text: 'Poppins-Medium',
  //   key: 'Poppins-Medium'
  // },
  // {
  //   text: 'Poppins-Medium-Italic',
  //   key: 'Poppins-Medium-Italic'
  // },
  // {
  //   text: 'Poppins-SemiBold',
  //   key: 'Poppins-SemiBold'
  // },
  // {
  //   text: 'Poppins-SemiBold-Italic',
  //   key: 'Poppins-SemiBold-Italic'
  // },
  // {
  //   text: 'Poppins-Bold',
  //   key: 'Poppins-Bold'
  // },
  // {
  //   text: 'Poppins-Bold-Italic',
  //   key: 'Poppins-Bold-Italic'
  // },
  // {
  //   text: 'Poppins ExtraBold',
  //   key: 'Poppins-ExtraBold'
  // },
  // {
  //   text: 'Poppins ExtraBold Italic',
  //   key: 'Poppins-ExtraBold-Italic'
  // },
  // {
  //   text: 'Poppins Black',
  //   key: 'Poppins-Black'
  // },
  // {
  //   text: 'Poppins Black Italic',
  //   key: 'Poppins-Black-Italic'
  // }
]

export const FONT_FAMILIES_STYLE = [
  {
    text: 'Light',
    key: '300'
  },
  {
    text: 'Regular',
    key: '400'
  },
  {
    text: 'Medium',
    key: '500'
  },
  {
    text: 'Bold',
    key: '800'
  },
  {
    text: 'Black',
    key: '900'
  }]
