import * as types from './../../../../public/constants/store/mutations-types'
import * as filters from './../../constants/filters/filterList'

const state = {
  filterList: filters.FILTER_LIST
}

const getters = {
  filterList: state => state.filterList
}
const actions = {
  presetCampuses ({ commit }, data) {
    commit(types.PRESET_CAMPUSES, data)
  }
}
const mutations = {
  [types.PRESET_CAMPUSES] (state, data) {
    for (let i = 0; i < state.filterList.length; i++) {
      if (state.filterList[i].filtervalue === 'campus') {
        if (state.filterList[i].submenu.sort.length === 0) {
          for (let j = 0; j < data.length; j++) {
            state.filterList[i].submenu.sort.push({
              title: data[j].title,
              id: data[j].id,
              query: '',
              active: false
            })
            state.filterList[i].submenu.query = '&department_id='
          }
        }
      }
    }
    // state.presetImg = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
