export const CHANGE_SIDEBAR_TAB = 'CHANGE_SIDEBAR_TAB'

export const SET_CUSTOM_FIELDS = 'SET_CUSTOM_FIELDS'

export const ADD_NEW_FORM = 'ADD_NEW_FORM'
export const CHANGE_FORM = 'CHANGE_FORM'
export const SAVE_FORM = 'SAVE_FORM'
export const CANCEL_CHANGE_FORM = 'CANCEL_CHANGE_FORM'

export const ADD_NEW_FIELD = 'ADD_NEW_FIELD'
export const OPEN_FIELD_EDIT = 'OPEN_FIELD_EDIT'
export const CHANGE_FIELD = 'CHANGE_FIELD'
export const CHANGE_FIELD_TYPE = 'CHANGE_FIELD_TYPE'

export const COPY_FIELD = 'COPY_FIELD'
export const EDIT_FIELD = 'EDIT_FIELD'
export const DELETE_FIELD = 'DELETE_FIELD'
export const SET_FIELDS = 'SET_FIELDS'

export const LOAD_FORM = 'LOAD_FORM'
export const CREATE_FORM = 'CREATE_FORM'
export const SET_FORM_ID = 'SET_FORM_ID'

export const SET_NAV_STATUS = 'SET_NAV_STATUS'

export const CREATE_VERSION = 'CREATE_VERSION'
export const SET_VERSION = 'SET_VERSION'
export const SET_IF_LOADER_FORM = 'SET_IF_LOADER_FORM'
