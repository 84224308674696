// window.API_PATH = 'https://nbcchicago.impactfactors.net/'
// window.API_PATH = 'https://lifestylechristianity.impactfactors.net/'
import { CURRENT_DOMEN } from '@@@/components/widgets/sermonsWidget/constants/domens'

export const CURRENT_DOMAIN = window.API_PATH
export const CURRENT_GENERAL_DOMAIN = 'https://lifestylechristianity.impactfactors.net/'
// export const CURRENT_DOMAIN = 'https://lifestylechristianity.impactfactors.net/'
// export const CURRENT_DOMAIN = 'https://ccv2.impactfactors.net/'
export const API_VERSION = '1.0'
// export const CURRENT_SERVER = 'http://dfsf3-dev.impactfactors.net/app_dev.php/'
// export const CURRENT_SERVER = 'https://stage.impactfactors.net/'
// export const CURRENT_SERVER = 'https://nbcchicago.impactfactors.net/'
export const CURRENT_SERVER = window.API_PATH
export const ORGANIZATIONS_DOMAIN = CURRENT_SERVER + 'api/organizations/' + API_VERSION + '/'
// export const EVENTS_DOMAIN = CURRENT_SERVER + 'api/events/' + API_VERSION + '/'
export const EVENTS_DOMAIN = CURRENT_DOMAIN + 'api/events/' + API_VERSION + '/'
export const EVENTS_ADMIN_DOMAIN = CURRENT_DOMAIN + 'api/events/admin/' + API_VERSION + '/'
export const TICKETS_ADMIN_DOMAIN = CURRENT_DOMAIN + 'api/events/admin/tickets/' + API_VERSION + '/'
export const BLOG_DOMAIN = CURRENT_SERVER + 'api/blog/posts/' + API_VERSION + '/'
export const NEWS_DOMAIN = CURRENT_SERVER + 'api/news/' + API_VERSION + '/'
export const DEVOTIONS_DOMAIN = CURRENT_SERVER + 'api/devotions/' + API_VERSION + '/'
export const READING_PLAN_DOMAIN = CURRENT_SERVER + 'api/reading-plans/posts/' + API_VERSION + '/'
export const GALLERY_ALBUM_DOMAIN = CURRENT_DOMAIN + 'api/media/gallery/album/' + API_VERSION + '/'
export const GALLERY_IMAGE_DOMAIN = CURRENT_DOMAIN + 'api/media/gallery/image/' + API_VERSION + '/'
export const PHOTO_DOMAIN = CURRENT_DOMAIN + 'iphone.php/media/'
export const MINISTRY_DOMAIN = CURRENT_SERVER + 'api/organizations/ministry/' + API_VERSION + '/'
export const PAGE_DOMAIN = CURRENT_SERVER + 'api/cms/page/' + API_VERSION + '/'
// export const PAGE_DOMAIN = CURRENT_SERVER + 'iphone.php/church/blogs/'
export const STAFF_DOMAIN = CURRENT_SERVER + 'api/organizations/staff/' + API_VERSION + '/'
export const USER_DOMAIN = CURRENT_SERVER + 'api/members/' + API_VERSION + '/'
export const USER_DOMAIN_LCU = CURRENT_DOMAIN + 'api/members/' + API_VERSION + '/'
export const USER_DOMAIN_LCU_ADMIN = CURRENT_DOMAIN + 'api/members/admin/' + API_VERSION + '/'
export const APPLICATION_CONFIGURATION_DOMAIN = CURRENT_SERVER + 'api/application/' + API_VERSION + '/'
export const MEMBER_DOMAIN = CURRENT_DOMAIN + 'api/members/' + API_VERSION + '/'
export const NEW_VISITOR_DOMAIN = CURRENT_SERVER + 'api/members/new-visitor/' + API_VERSION + '/'
export const NEWSLETTER_DOMAIN = CURRENT_SERVER + 'api/newsletter/subscriber/' + API_VERSION + '/'
export const ADMIN_MEMBERS_PAYMENT_DATA = CURRENT_SERVER + 'api/admin/payment-way/' + API_VERSION + '/'
export const SERMON_DOMAIN = `${CURRENT_DOMEN}api/media/${API_VERSION}/`
