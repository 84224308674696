import * as tabsListTicket from '../../../../../public/constants/tabs/tabsListWidgets'
import * as types from './../../../constants/store/mutationTypes'
const state = {
  tabsTicketWidgetActive: tabsListTicket.TABS_ROUTE_TICKET.TABS_CREDIT_DEBIT_CARD
}
const getters = {
  tabsTicketWidgetActive: state => state.tabsTicketWidgetActive
}
const actions = {
  setActiveTabsTicketWidget ({ commit }, route) {
    commit(types.TABS_ACTIVE_TICKETS_WIDGET_CONTENT, { route: route })
  }
}
const mutations = {
  [types.TABS_ACTIVE_TICKETS_WIDGET_CONTENT] (state, { route }) {
    state.tabsTicketWidgetActive = route
  }
}
export default {
  state,
  getters,
  actions,
  mutations
}
