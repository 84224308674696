import * as types from './../../constants/store/mutations-types'
import _ from 'lodash'

const state = {
  newsHorizontalListAll: [],
  currentNewsHorizontalCount: 1,
  newsHorizontalFilterQuery: '',
  newsHorizontalSearchQuery: '',
  infiniteLoadingRefNewsHorizontal: false,
  notFoundNewsHorizontal: false,
  noDataNewsHorizontalPost: false,
  presetNewsHorizontalImg: false,
  newsHorizontalListSetTimeoutId: false
  // printStatus: false
}

const getters = {
  newsHorizontalListAll: state => state.newsHorizontalListAll,
  currentNewsHorizontalCount: state => state.currentNewsHorizontalCount,
  newsHorizontalFilterQuery: state => state.newsHorizontalFilterQuery,
  newsHorizontalSearchQuery: state => state.newsHorizontalSearchQuery,
  infiniteLoadingRefNewsHorizontal: state => state.infiniteLoadingRefNewsHorizontal,
  notFoundNewsHorizontal: state => state.notFoundNewsHorizontal,
  noDataNewsHorizontalPost: state => state.noDataNewsHorizontalPost,
  presetNewsHorizontalImg: state => state.presetNewsHorizontalImg
  // printStatus: state => state.printStatus
}
const actions = {
  setAllNewsHorizontalData ({ commit }, data) {
    commit(types.SET_ALL_NEWS_HORIZONTAL_DATA, { data: data })
  },
  pushNewNewsHorizontalData ({ commit }, data) {
    commit(types.PUSH_NEW_NEWS_HORIZONTAL_DATA, { data: data })
  },
  setCurrentNewsHorizontalCount ({ commit }, data) {
    commit(types.SET_CURRENT_NEWS_HORIZONTAL_COUNT, { data: data })
  },
  setNewsHorizontalFilterQuery ({ commit }, data) {
    commit(types.SET_NEWS_HORIZONTAL_FILTER_QUERY, data)
  },
  setNewsHorizontalSearchQuery ({ commit }, data) {
    commit(types.SET_NEWS_HORIZONTAL_SEARCH_QUERY, data)
  },
  setInfiniteLoadingRefNewsHorizontal ({ commit }, data) {
    commit(types.SET_INFINITE_LOADING_REF_NEWS_HORIZONTAL, data)
  },
  setNotFoundNewsHorizontal ({ commit }, data) {
    commit(types.SET_NOT_FOUND_NEWS_HORIZONTAL, data)
  },
  setNoDataNewsHorizontalPost ({ commit }, data) {
    commit(types.SET_NO_DATA_NEWS_HORIZONTAL_POST, data)
  },
  presetNewsHorizontalImageUpdate ({ commit }, data) {
    commit(types.PRESET_NEWS_HORIZONTAL_IMAGE_UPDATE, data)
  }
  // setPrintStatus ({commit}, data) {
  //   commit(types.SET_PRINT_STATUS, data)
  // }
}
const mutations = {
  [types.SET_ALL_NEWS_HORIZONTAL_DATA] (state, { data }) {
    if (data.length > 0) {
      state.newsHorizontalListAll = data.items
    } else {
      state.newsHorizontalListAll = []
    }
  },
  [types.PUSH_NEW_NEWS_HORIZONTAL_DATA] (state, { data }) {
    clearTimeout(state.newsHorizontalListSetTimeoutId)
    for (let i = 0; i < data.items.length; i++) {
      if (_.find(state.newsHorizontalListAll, function (obj) {
        return obj.id === data.items[i].id
      }) === undefined) {
        if (data.items[i].description === undefined) {
          data.items[i].description = ''
        }
        state.newsHorizontalListSetTimeoutId = setTimeout(function () {
          state.newsHorizontalListAll.push(data.items[i])
        }, i * 100)
      }
    }
  },
  [types.SET_CURRENT_NEWS_HORIZONTAL_COUNT] (state, { data }) {
    state.currentNewsHorizontalCount = data
  },
  [types.SET_NEWS_HORIZONTAL_FILTER_QUERY] (state, data) {
    state.newsHorizontalFilterQuery = data
  },
  [types.SET_NEWS_HORIZONTAL_SEARCH_QUERY] (state, data) {
    state.newsHorizontalSearchQuery = data
  },
  [types.SET_INFINITE_LOADING_REF_NEWS_HORIZONTAL] (state, data) {
    state.infiniteLoadingRefNewsHorizontal = data
  },
  [types.SET_NOT_FOUND_NEWS_HORIZONTAL] (state, data) {
    state.notFoundNewsHorizontal = data
  },
  [types.SET_NO_DATA_NEWS_HORIZONTAL_POST] (state, data) {
    state.noDataNewsHorizontalPost = data
  },
  [types.PRESET_NEWS_HORIZONTAL_IMAGE_UPDATE] (state, data) {
    state.presetNewsHorizontalImg = data
  }
  // [types.SET_PRINT_STATUS] (state, data) {
  //   state.printStatus = data
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
