export const MEDIA = 'MEDIA'
export const BIBLE = 'BIBLE'
export const GIVE = 'GIVE'
export const PRAYER_WALL = 'PRAYER_WALL'
export const CHAT = 'CHAT'
export const MAP = 'MAP'
export const SCHEDULE = 'SCHEDULE'
export const BIBLE_READ = 'BIBLE_READ'
export const BIBLE_BOOKMARKS = 'BIBLE_BOOKMARKS'
export const BIBLE_NOTES = 'BIBLE_NOTES'
export const BIBLE_SEARCH = 'BIBLE_SEARCH'
export const BIBLE_SEARCH_BOOKMARKS = 'BIBLE_SEARCH_BOOKMARKS'
export const BIBLE_SEARCH_NOTES = 'BIBLE_SEARCH_NOTES'
export const BIBLE_COMPARE = 'BIBLE_COMPARE'
export const BIBLE_TRANSLATIONS = 'BIBLE_TRANSLATIONS'
// export const BIBLE_HEADER_READ = 'BIBLE_HEADER_READ'
// export const BIBLE_HEADER_BOOKMARKS = 'BIBLE_HEADER_BOOKMARKS'
// export const BIBLE_HEADER_NOTES = 'BIBLE_HEADER_NOTES'
// export const BIBLE_HEADER_TRANSLATIONS = 'BIBLE_HEADER_TRANSLATIONS'
