import * as types from './../../constants/store/mutations-types'
const moment = require('moment-timezone')

const state = {
  organizationSettings: {
    campuses: [],
    departments: [],
    organizations: []
  },
  organizationSettingsLoadStatus: false
}

const getters = {
  organizationSettings: state => state.organizationSettings
}
const actions = {
  setOrganizationSettings ({ commit }, data) {
    console.log('setOrganizationSettings')
    commit(types.SET_ORGANIZATION_SETTINGS, { data: data })
    try {
      // console.log('data', data)
      let zoneName = ''
      if (data.organization) {
        zoneName = data.organization.timezone
      } else {
        zoneName = data.timezone
      }
      const thisTimezone = moment.tz.zone(zoneName)
      const thisTimezoneOffset = thisTimezone.parse(new Date())
      const zoneTime = -thisTimezoneOffset / 60
      commit(types.EDIT_TIME_ZONE, { name: zoneName, offset: zoneTime })
    } catch (e) {
      console.log(e)
    }
  }
}
const mutations = {
  [types.SET_ORGANIZATION_SETTINGS] (state, { data }) {
    state.organizationSettingsLoadStatus = true
    state.organizationSettings = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
