import * as types from './../../constants/store/mutations-types'
import _ from 'lodash'

const state = {
  ministriesHorizontalListAll: [],
  currentMinistriesHorizontalCount: 1,
  ministriesHorizontalFilterQuery: '',
  ministriesHorizontalSearchQuery: '',
  infiniteLoadingRefMinistriesHorizontal: false,
  notFoundMinistriesHorizontal: false,
  noDataMinistriesHorizontalPost: false,
  presetMinistriesHorizontalImg: false,
  ministriesHorizontalListSetTimeoutId: false
  // printStatus: false
}

const getters = {
  ministriesHorizontalListAll: state => state.ministriesHorizontalListAll,
  currentMinistriesHorizontalCount: state => state.currentMinistriesHorizontalCount,
  ministriesHorizontalFilterQuery: state => state.ministriesHorizontalFilterQuery,
  ministriesHorizontalSearchQuery: state => state.ministriesHorizontalSearchQuery,
  infiniteLoadingRefMinistriesHorizontal: state => state.infiniteLoadingRefMinistriesHorizontal,
  notFoundMinistriesHorizontal: state => state.notFoundMinistriesHorizontal,
  noDataMinistriesHorizontalPost: state => state.noDataMinistriesHorizontalPost,
  presetMinistriesHorizontalImg: state => state.presetMinistriesHorizontalImg
  // printStatus: state => state.printStatus
}
const actions = {
  setAllMinistriesHorizontalData ({ commit }, data) {
    commit(types.SET_ALL_MINISTRIES_HORIZONTAL_DATA, { data: data })
  },
  pushNewMinistriesHorizontalData ({ commit }, data) {
    commit(types.PUSH_NEW_MINISTRIES_HORIZONTAL_DATA, { data: data })
  },
  setCurrentMinistriesHorizontalCount ({ commit }, data) {
    commit(types.SET_CURRENT_MINISTRIES_HORIZONTAL_COUNT, { data: data })
  },
  setMinistriesHorizontalFilterQuery ({ commit }, data) {
    commit(types.SET_MINISTRIES_HORIZONTAL_FILTER_QUERY, data)
  },
  setMinistriesHorizontalSearchQuery ({ commit }, data) {
    commit(types.SET_MINISTRIES_HORIZONTAL_SEARCH_QUERY, data)
  },
  setInfiniteLoadingRefMinistriesHorizontal ({ commit }, data) {
    commit(types.SET_INFINITE_LOADING_REF_MINISTRIES_HORIZONTAL, data)
  },
  setNotFoundMinistriesHorizontal ({ commit }, data) {
    commit(types.SET_NOT_FOUND_MINISTRIES_HORIZONTAL, data)
  },
  setNoDataMinistriesHorizontalPost ({ commit }, data) {
    commit(types.SET_NO_DATA_MINISTRIES_HORIZONTAL_POST, data)
  },
  presetMinistriesHorizontalImageUpdate ({ commit }, data) {
    commit(types.PRESET_MINISTRIES_HORIZONTAL_IMAGE_UPDATE, data)
  }
  // setPrintStatus ({commit}, data) {
  //   commit(types.SET_PRINT_STATUS, data)
  // }
}
const mutations = {
  [types.SET_ALL_MINISTRIES_HORIZONTAL_DATA] (state, { data }) {
    if (data.length > 0) {
      state.ministriesHorizontalListAll = data.items
    } else {
      state.ministriesHorizontalListAll = []
    }
  },
  [types.PUSH_NEW_MINISTRIES_HORIZONTAL_DATA] (state, { data }) {
    clearTimeout(state.ministriesHorizontalListSetTimeoutId)
    for (let i = 0; i < data.items.length; i++) {
      if (_.find(state.ministriesHorizontalListAll, function (obj) {
        return obj.id === data.items[i].id
      }) === undefined) {
        if (data.items[i].description === undefined) {
          data.items[i].description = ''
        }
        state.ministriesHorizontalListAll.push(data.items[i])
      }
    }
  },
  [types.SET_CURRENT_MINISTRIES_HORIZONTAL_COUNT] (state, { data }) {
    state.currentMinistriesHorizontalCount = data
  },
  [types.SET_MINISTRIES_HORIZONTAL_FILTER_QUERY] (state, data) {
    state.ministriesHorizontalFilterQuery = data
  },
  [types.SET_MINISTRIES_HORIZONTAL_SEARCH_QUERY] (state, data) {
    state.ministriesHorizontalSearchQuery = data
  },
  [types.SET_INFINITE_LOADING_REF_MINISTRIES_HORIZONTAL] (state, data) {
    state.infiniteLoadingRefMinistriesHorizontal = data
  },
  [types.SET_NOT_FOUND_MINISTRIES_HORIZONTAL] (state, data) {
    state.notFoundMinistriesHorizontal = data
    state.notFoundMinistriesHorizontal = data
  },
  [types.SET_NO_DATA_MINISTRIES_HORIZONTAL_POST] (state, data) {
    state.noDataMinistriesHorizontalPost = data
  },
  [types.PRESET_MINISTRIES_HORIZONTAL_IMAGE_UPDATE] (state, data) {
    state.presetMinistriesHorizontalImg = data
  }
  // [types.SET_PRINT_STATUS] (state, data) {
  //   state.printStatus = data
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
