import { SINGLE_CHECKBOX } from '../../../types/fieldsTypes'

export default {
  title: 'Untitled',
  name: 'Untitled',
  type: SINGLE_CHECKBOX,
  group: 'regular',
  properties: {
    options: {
      values: [
        {
          id: 'optional',
          title: 'Optional Field'
        },
        {
          id: 'required',
          title: 'Required Field'
        },
        {
          id: 'max_quantity',
          title: 'Max Quantities',
          description: 'Only this number of checks is available'
        }
      ],
      selected: {
        id: [
          'optional'
        ]
      }
    },
    visibility: {
      values: [
        {
          id: 'everyone',
          title: 'Everyone',
          description: 'Every person could fill your form'
        },
        {
          id: 'admin',
          title: 'Admin Only'
        },
        {
          id: 'registered',
          title: 'Registered Users Only'
        },
        {
          id: 'custom_category',
          title: 'Custom Category Only',
          description: 'Visible only to this category of users'
        }
      ],
      selected: {
        id: 'everyone',
        categories: []
      }
    },
    choice: {
      selected: {
        maxQuantity: null,
        label: 'Option 1',
        default: false
      }
    },
    instructions: {
      selected: ' '
    },
    system: {
      selected: false
    }
  }
}
