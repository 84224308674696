export const SET_ALL_READING_PLAN_DATA = 'SET_ALL_READING_PLAN_DATA'
export const PUSH_NEW_READING_PLAN_DATA = 'PUSH_NEW_READING_PLAN_DATA'
export const SET_EVENT_DATA = 'SET_EVENT_DATA'
export const SET_CURRENT_READING_PLAN_COUNT = 'SET_CURRENT_READING_PLAN_COUNT'
export const PRESET_CAMPUSES = 'PRESET_CAMPUSES'
export const SET_READING_PLAN_FILTER_QUERY = 'SET_READING_PLAN_FILTER_QUERY'
export const SET_READING_PLAN_SEARCH_QUERY = 'SET_READING_PLAN_SEARCH_QUERY'
export const SET_INFINITE_LOADING_REF_READING_PLAN = 'SET_INFINITE_LOADING_REF_READING_PLAN'
export const SET_NOT_FOUND_READING_PLAN = 'SET_NOT_FOUND_READING_PLAN'
export const PUSH_NEW_POST_DATA = 'PUSH_NEW_POST_DATA'
export const SET_ACTIVE_POST = 'SET_ACTIVE_POST'
export const SET_NO_DATA_READING_PLAN_POST = 'SET_NO_DATA_READING_PLAN_POST'
export const PUSH_READING_PLAN_POST_DATA = 'PUSH_READING_PLAN_POST_DATA'
export const SET_READING_PLAN_ACTIVE_POST = 'SET_READING_PLAN_ACTIVE_POST'
export const PRESET_READING_PLAN_IMAGE_UPDATE = 'PRESET_READING_PLAN_IMAGE_UPDATE'
// export const SET_PRINT_STATUS = 'SET_PRINT_STATUS'
