<template>
  <transition :name="transition">
    <div
      class="w-modal"
      :style="{'z-index': zIndex}"
    >
      <div
        class="w-modal__bg w-modal__bg_show"
        :class="{
          'w-modal__bg_no-bg': noBackground,
          'w-modal__blur': blur,
        }"
        @click.stop="closeModal()"
      />
      <!--<div class="w-modal__container" :class="classAppendix">-->
      <!--<vue-perfect-scrollbar class="fix-container">-->

      <div
        class="w-modal__container"
        :class="[
          containerClass,
          {'w-modal__container_dark': darkMode},
          {'w-modal__container_mobile': isMobile},
          {'w-modal__container_no-padding': noPadding},
          {'w-modal__container_full': full}
        ]"
        :style="{
          maxHeight
        }"
      >
        <div
          class="w-modal__close"
          :class="closeIconClass"
          @click="closeModal"
        >
          <i :class="iconClass" />
        </div>
        <vue-perfect-scrollbar class="w-modal-content">
          <slot />
        </vue-perfect-scrollbar>
      </div>
      <!--</vue-perfect-scrollbar>-->
    </div>
    <!--</div>-->
  </transition>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { MODE_MOBILE } from '@@@/store/constant/modeViewNames'

export default {
  name: 'WModal',
  components: { VuePerfectScrollbar },
  props: {
    full: {
      type: Boolean,
      default: false
    },
    transition: {
      type: String,
      default: 'w-modal'
    },
    noBackground: {
      type: Boolean,
      default: false
    },
    blur: {
      type: Boolean,
      default: false
    },
    darkMode: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    closeIcon: {
      type: Boolean,
      default: false
    },
    iconClass: {
      type: String,
      default: 'icon-cancel'
    },
    darkModal: {
      type: Boolean,
      default: false
    },
    closeIconClass: {
      type: String,
      default: ''
    },
    classAppendix: {
      type: String,
      default: 'size-570',
      validator: (value) => {
        if (!value) {
          return false
        }
        return true
      }
    },
    zIndex: {
      type: Number,
      default: 10000,
      validator: (value) => {
        if (!value) {
          return false
        }
        return true
      }
    },
    maxHeight: {
      type: String
    }
  },
  computed: {
    viewMode: function () {
      return this.$store.getters.viewMode
    },
    isMobile () {
      return this.viewMode === MODE_MOBILE
    },
    containerClass () {
      return `w-modal__container_${this.classAppendix}`
    }
  },
  mounted: function () {
    document.addEventListener('keydown', this.keyup)
  },
  beforeDestroy: function () {
    document.removeEventListener('keydown', this.keyup)
  },
  methods: {
    closeModal: function () {
      this.$emit('close')
    },
    keyup: function (e) {
      const enterKey = 13
      if ((e.ctrlKey || e.metaKey) && e.keyCode === enterKey) {
        e.preventDefault()
        e.stopPropagation()
        this.$emit('apply')
      }
      const escKey = 27
      if (e.keyCode === escKey) {
        e.preventDefault()
        e.stopPropagation()
        this.closeModal()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../../siteBuilder/src/sass/variables";

  .w-modal-enter-active, .w-modal-leave-active {
    transition: opacity 200ms linear;
  }

  .w-modal-enter, .w-modal-leave-to {
    opacity: 0;
  }

  .w-modal-leave, .w-modal-enter-to {
    opacity: 1;
  }

  .w-modal-leave-active {
    display: none;
  }

  .w-modal-fast-enter-active, .w-modal-fast-leave-active {
    transition: opacity 0s ease-in;
  }

  //
  //.w-modal-fast-leave-active {
  //  display: none;
  //}

  .w-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 10000;
    cursor: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 991px) {
      width: 100%;
      height: 100%;
    }

    &__blur {
      filter: blur(8px);
    }

    &__bg {
      z-index: 1;
      position: absolute;
      left: 0;
      top: 0;
      transform: scale(0);
      opacity: 0;
      width: 100vw;
      height: 100vh;
      background: rgba($color-bunker, 0.4);
      transition: opacity 200ms ease-in;
      display: flex;
      align-items: center;
      justify-content: center;

      &_no-bg {
        background-color: transparent;
      }

      &_show {
        transform: scale(1);
        opacity: 1;
      }

      &_hide {
        transition: opacity 200ms ease-in, transform 1ms 200ms;
        transform: scale(0);
        opacity: 0;
      }
    }

    &__close {
      position: absolute;
      z-index: 100;
      font-size: 15px;
      color: $color-rolling-stone;
      transition: color .3s ease-in;
      top: 14px;
      right: 14px;
      cursor: pointer;

      &:hover {
        color: $color-turquoise;
      }
    }

    .w-modal-content {
      max-height: 100%;
      width: 100%;
    }

    &__container {
      z-index: 2;
      position: relative;
      /*top: 50%;*/
      /*left: 50%;*/
      /*<!--transform: translate(-50%, -50%);-->*/
      display: flex;
      height: auto;
      background-color: $color-white;
      border-radius: 4px;
      margin: auto;
      max-height: calc(100% - 140px);
      max-width: calc(100% - 60px);
      box-shadow: 0 8px 10px rgba($color-black, 0.2);
      padding: 20px;

      &_dark {
        background-color: $color-mirage;
      }

      &_no-padding {
        padding: 0;
      }

      &_mobile {
        max-height: calc(100% - 30px)!important;
        max-width: calc(100% - 30px)!important;
      }

      &_full {
        max-width: 100% !important;
        max-height: 100% !important;
        height: 100% !important;
      }

      &_height100 {
        height: 100%;
        display: flex;
        flex-direction: column;
      }

      &_size-440 {
        width: 440px;

        @media screen and (max-width: 500px) {
          width: 95%;
          max-width: 440px;
        }
      }

      &_size-1220 {
        width: 1200px;
      }

      &_size-1220-padding {
        width: 1200px;

        @media screen and (max-width: 540px) {
          padding: 0;
          max-width: calc(100% - 15px) !important;

          .w-modal__close {
            top: 5px;
            right: 5px;
          }
        }
      }

      &_size-1408 {
        max-width: 1408px;
        max-height: 1704px;
        height: calc(100% - 101px);
      }

      &_size-600 {
        width: 600px;
      }

      &_size-850 {
        max-width: 850px;
        width: 100%;
        z-index: 50;

        @media screen and (max-width: 900px) {
          width: 95%;
          max-width: 850px;
        }
      }

      &_size-850-padding {
        max-width: 850px;
        width: 100%;
        z-index: 50;
        padding: 15px;

        @media screen and (max-width: 900px) {
          width: 95%;
          max-width: 850px;
        }
      }

      &_size-515 {
        width: 516px;

        @media screen and (max-width: 560px) {
          width: 95%;
          max-width: 510px;
        }
      }

      &_size-570 {
        width: 570px;

        @media screen and (max-width: 600px) {
          width: 95%;
          max-width: 570px;
        }
      }

      &_size-full {
        max-width: 90vw;
      }

      &_size-auto {
        width: auto;
      }

      &_size-bigmodal {
        width: 90vw;
        height: 90vh;
      }

      &_size-medium-modal {
        max-width: 880px;
        width: 100%;
        margin: 0 15px;
        height: 90vh;
      }

      &_size-880 {
        max-width: 880px;
        width: 100%;
        margin: 0 15px;
        height: 90vh;
      }

      &_size-920 {
        max-width: 920px;
        width: 100%;
        margin: 0 15px;
        /*height: 90vh;*/
      }

      &_size-1200 {
        width: 100%;
        height: 90vh;
        max-width: 1200px;
      }

      &_size-1140 {
        width: 100%;
        height: 70vh;
        max-width: 1140px;
      }

      &_size-1450 {
        width: 100%;
        height: 90vh;
        max-height: 90vh;
        max-width: 1450px;
      }

      &_size-event-window {
        max-height: calc(100% - 70px);
        height: calc(100% - 70px);
        width: calc(100% - 70px);
        max-width: 1390px;

        @media (max-width: 700px) {
          max-height: calc(100% - 20px);
          height: calc(100% - 20px);
          width: calc(100% - 20px);

          .widget-modal_size-1390 {
            width: 100%;
          }

        }

        @media (max-width: 390px) {
          width: 100%;
          height: 100%;
          max-height: 100%;
          border-radius: 0;
        }

      }

      &_size-ministries {
        height: 100%;

        @media (max-width: 1600px) {
          height: auto;
        }

        @media (max-width: 560px) {
          height: 100%;
        }
      }

      &_has-close-icon {
        padding: 40px;
      }
    }

    &__scroll-container {
      max-height: 100%;
    }
  }
</style>
<style lang="scss">
  @import "../../../core/sass/_variables.scss";
  @import "../../../siteBuilder/src/sass/variables";

  .fix-container {
    max-height: calc(100vh - 140px);
  }

  .w-modal {
    &__close {
      position: absolute;
      z-index: 100;
      right: 20px;
      top: 15px;
      font-size: 15px;
      color: $color-rolling-stone;
      transition: color .3s ease-in;
      cursor: pointer;

      &:hover {
        color: $color-turquoise;
      }

      &_green {
        &:hover {
          color: $color-chateau-green-second !important;
        }
      }
    }

    &-loader {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 200;
      background: rgba(19, 24, 32, 0.4);

      &:after {
        content: '';
        position: absolute;
        z-index: 66;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -100px);
        color: $color-turquoise;
        font-size: 10px;
        margin: 100px auto;
        width: 1em;
        height: 1em;
        border-radius: 50%;
        text-indent: -9999em;
        animation: loader 1.3s infinite linear;
      }

    }
  }

  @keyframes loader {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
</style>
