import * as types from './../../constants/store/mutations-types'
const moment = require('moment-timezone')

const state = {
  currentTimeZoneName: moment.tz.zone(moment.tz.guess()).name,
  currentTimeZone: -moment.tz.zone(moment.tz.guess()).parse(new Date()) / 60
}

const getters = {
  currentTimeZone: state => {
    return state.currentTimeZoneName
  },
  currentTimeZoneName: state => state.currentTimeZoneName
}
const actions = {
  editTimeZone ({ commit }, { name, offset, key }) {
    commit(types.EDIT_TIME_ZONE, { name: name, offset: offset, key: key })
  }
}
const mutations = {
  [types.EDIT_TIME_ZONE] (state, { name, offset, key }) {
    state.currentTimeZone = offset
    state.currentTimeZoneName = name
    if (key) {
      state.currentTimeZoneName = key
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
