import * as types from './../../constants/store/mutations-types'
import * as modals from './../../constants/store/modal-types'

const state = {
  modalName: modals.EMPTY_MODAL,
  parentName: '',
  modalBg: false,
  ifLoader: false,
  hardModal: false
}

const getters = {
  modalName: state => state.modalName,
  ifLoader: state => state.ifLoader,
  modalBg: state => state.modalBg,
  hardModal: state => state.hardModal,
  parentName: state => state.parentName
}
const actions = {
  toogleModal ({ commit }, modalName) {
    commit(types.TOGGLE_MODAL, { modalName: modalName })
  },
  toggleLoader ({ commit }, status) {
    console.log('change status to ' + status)
    commit(types.TOGGLE_LOADER, { status: status })
  },
  setHardModal ({ commit }, status) {
    commit(types.HARD_MODAL, { status: status })
  }
}
const mutations = {
  [types.TOGGLE_MODAL] (state, { modalName }) {
    if (state.parentName !== '' && modalName !== modals.MODAL_EVENT_ITEM) {
      state.modalBg = true
      if (state.modalName === modals.EMPTY_MODAL && modalName === modals.EMPTY_MODAL) {
        modalName = modals.MODAL_EVENT_ITEM
      } else {
        if (state.modalName === modals.MODAL_EVENT_ITEM && modalName === modals.EMPTY_MODAL) {
          state.parentName = ''
          state.modalBg = !state.modalBg
        }
      }
    } else {
      state.modalBg = !state.modalBg
      if (state.modalName === modals.MODAL_EVENT_ITEM) {
        state.parentName = ''
      } else if (modalName === modals.MODAL_EVENT_ITEM && state.parentName === '') {
        state.parentName = modalName
      }
    }
    state.modalName = modalName
  },
  [types.TOGGLE_LOADER] (state, { status }) {
    state.ifLoader = status
  },
  [types.HARD_MODAL] (state, { status }) {
    state.hardModal = status
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
