import * as types from '@@@/store/constant/mutationsTypes'
const state = {
  formsList: []
}

const getters = {
  formsList: state => state.formsList
}

const actions = {
  updateFormsList ({ commit }, data) {
    commit(types.UPDATE_FORMS_LIST, data)
  }
}

const mutations = {
  [types.UPDATE_FORMS_LIST] (state, data) {
    state.formsList = data
  },
  addFormFromSb (state, data) {
    state.formsList.unshift(data)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
