// import generalSettings from './../../../public/store/modules/generalSettings'
// import ifLoader from './../../../public/store/modules/ifLoader'
// import currentTimeZone from './../../../public/store/modules/currentTimeZone'
import devotionsList from './modules/devotionsList'
import devotionsPostList from './modules/devotionsPostList'

const modules = {
  // generalSettings,
  // currentTimeZone,
  // ifLoader,
  devotionsList,
  devotionsPostList
}

export default modules
