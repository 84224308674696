import * as types from '../mutations-types'
const state = {
  currentVideoTranscript: {}
}

const getters = {
  currentVideoTranscript: state => state.currentVideoTranscript
}
const actions = {
  setCurrentVideoTranscript ({ commit }, data) {
    commit(types.SET_CURRENT_VIDEO_TRANSCRIPT_LANG, data)
  }
}
const mutations = {
  [types.SET_CURRENT_VIDEO_TRANSCRIPT_LANG] (state, data) {
    state.currentVideoTranscript = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
