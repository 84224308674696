import { SET_OPEN_MODAL_ID } from '../constant/mutationsTypes'

const state = {
  openModalId: -1
}

const getters = {
  openModalId: state => state.openModalId
}

const actions = {
  setOpenModalId ({ commit }, data) {
    commit(SET_OPEN_MODAL_ID, data)
  }
}

const mutations = {
  [SET_OPEN_MODAL_ID] (state, data) {
    state.openModalId = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
