import * as types from './../../constants/store/mutations-types'
import { find } from 'lodash'

const state = {
  staffList: [],
  currentStaffCount: 1,
  fullStaffData: false,
  infiniteLoadingRefStaff: false
}

const getters = {
  staffList: state => state.staffList,
  currentStaffCount: state => state.currentStaffCount,
  fullStaffData: state => state.fullStaffData,
  infiniteLoadingRefStaff: state => state.infiniteLoadingRefStaff
}
const actions = {
  pushStaffData ({ commit }, data) {
    commit(types.PUSH_STAFF_DATA, { data: data })
  },
  setCurrentStaffCount ({ commit }, data) {
    commit(types.SET_CURRENT_STAFF_COUNT, { data: data })
  },
  setFullStaffData ({ commit }, data) {
    commit(types.SET_FULL_STAFF_DATA, { data: data })
  },
  setInfiniteLoadingRefStaff ({ commit }, data) {
    commit(types.SET_INFINITE_LOADING_REF_STAFF, { data: data })
  }
}
const mutations = {
  [types.PUSH_STAFF_DATA] (state, { data }) {
    for (let i = 0; i < data.items.length; i++) {
      if (find(state.staffList, function (obj) {
        return obj.id === data.items[i].id
      }) === undefined) {
        state.staffList.push(data.items[i])
      }
    }
  },
  [types.SET_CURRENT_STAFF_COUNT] (state, { data }) {
    state.currentStaffCount = data
  },
  [types.SET_FULL_STAFF_DATA] (state, { data }) {
    state.fullStaffData = data
  },
  [types.SET_INFINITE_LOADING_REF_STAFF] (state, { data }) {
    state.infiniteLoadingRefStaff = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
