import * as types from './../../constants/store/mutations-types'

const state = {
  newsHorizontalPostListData: [],
  newsHorizontalActivePost: 0
  // printStatus: false
}

const getters = {
  newsHorizontalPostListData: state => state.newsHorizontalPostListData,
  newsHorizontalActivePost: state => state.newsHorizontalActivePost
}
const actions = {
  pushNewsHorizontalPostData ({ commit }, data) {
    commit(types.PUSH_NEWS_HORIZONTAL_POST_DATA, { data: data })
  },
  setNewsHorizontalActivePost ({ commit }, data) {
    commit(types.SET_NEWS_HORIZONTAL_ACTIVE_POST, data)
  }
}
const mutations = {
  [types.PUSH_NEWS_HORIZONTAL_POST_DATA] (state, { data }) {
    state.newsHorizontalPostListData.push(data)
    state.newsHorizontalActivePost = state.newsHorizontalPostListData.length - 1
  },
  [types.SET_NEWS_HORIZONTAL_ACTIVE_POST] (state, data) {
    state.newsHorizontalActivePost = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
