import modals from './../../../public/store/modules/modals'
import profilePhoto from './../../../public/store/modules/profilePhoto'
import socialAuth from './../../../public/store/modules/socialAuth'
import userPanel from './../../../public/store/modules/userPanel'
import eventsList from './modules/eventsList'
import modalEventData from './modules/modalEventData'
import tabsEvent from './modules/tabsEvent'
import filters from './modules/filters'
import organizationSettings from './../../../public/store/modules/organizationSettings'

const modules = {
  modals,
  profilePhoto,
  socialAuth,
  userPanel,
  eventsList,
  modalEventData,
  tabsEvent,
  filters,
  organizationSettings
}

export default modules
