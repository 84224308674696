import * as types from '../mutations-types'

const state = {
  playerEntity: {},
  showDown: true,
  showTop: false,
  playAllBookmarks: false,
  bookmarkClicked: false,
  checkVideoPlay: false,
  addBookmarks: false,
  popOutStatus: false,
  firstPlayTimer: 0
}

const getters = {
  playerEntity: state => state.playerEntity,
  firstPlayTimer: state => state.firstPlayTimer,
  showDown: state => state.showDown,
  showTop: state => state.showTop,
  playAllBookmarks: state => state.playAllBookmarks,
  bookmarkClicked: state => state.bookmarkClicked,
  checkVideoPlay: state => state.checkVideoPlay,
  addBookmarks: state => state.addBookmarks,
  popOutStatus: state => state.popOutStatus
}
const actions = {
  changePlayerShow ({ commit }) {
    commit(types.CHANGE_PLAYER_SHOW)
  },
  changePopOutStatus ({ commit }, data) {
    commit(types.CHANGE_POPOUT_STATUS, data)
  },
  setPlayerEntity ({ commit }, data) {
    commit(types.SET_PLAYER_ENTITY, data)
  },
  setPlayAllBookmarks ({ commit }, data) {
    commit(types.SET_PLAY_ALL_BOOKMARKS, data)
  },
  setBookmarkClicked ({ commit }, data) {
    commit(types.SET_BOOKMARK_CLICKED, data)
  },
  toggleCheckVideoPlay ({ commit }, data) {
    commit(types.TOGGLE_CHECK_VIDEO_PLAY, data)
  },
  toggleAddBookmarks ({ commit }, data) {
    commit(types.TOGGLE_ADD_BOOKMARKS, data)
  },
  firstPlayTimerCount ({ commit }, data) {
    commit(types.FIRST_PLAY_TIMER_COUNT, data)
  }
}
const mutations = {
  [types.CHANGE_PLAYER_SHOW] (state) {
    state.showDown = !state.showDown
    state.showTop = !state.showTop
  },
  [types.SET_PLAYER_ENTITY] (state, data) {
    state.playerEntity = data
  },
  [types.CHANGE_POPOUT_STATUS] (state, data) {
    state.popOutStatus = data
  },
  [types.SET_PLAY_ALL_BOOKMARKS] (state, data) {
    state.playAllBookmarks = data.data
  },
  [types.SET_BOOKMARK_CLICKED] (state, data) {
    state.bookmarkClicked = data
  },
  [types.TOGGLE_CHECK_VIDEO_PLAY] (state, data) {
    state.checkVideoPlay = data
  },
  [types.TOGGLE_ADD_BOOKMARKS] (state, data) {
    state.addBookmarks = data
  },
  [types.FIRST_PLAY_TIMER_COUNT] (state, data) {
    state.firstPlayTimer = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
