export const SET_ALL_EVENTS_DATA = 'SET_ALL_EVENTS_DATA'
export const UPDATE_SHOPPING_CART_EVENTS_DATA = 'UPDATE_SHOPPING_CART_EVENTS_DATA'
export const PUSH_NEW_EVENTS_DATA = 'PUSH_NEW_EVENTS_DATA'
export const SET_EVENT_DATA = 'SET_EVENT_DATA'
export const SET_CURRENT_EVENTS_COUNT = 'SET_CURRENT_EVENTS_COUNT'
export const SET_PAGE_VIEW_TYPE = 'SET_PAGE_VIEW_TYPE'
export const SET_CURRENT_DATE = 'SET_CURRENT_DATE'
export const SET_EVENTS_CALENDAR_DATA = 'SET_EVENTS_CALENDAR_DATA'
export const PUSH_NEW_EVENTS_CALENDAR_DATA = 'PUSH_NEW_EVENTS_CALENDAR_DATA'
export const SET_EVENTS_MONTH_DATA = 'SET_EVENTS_MONTH_DATA'
export const PRESET_CAMPUSES = 'PRESET_CAMPUSES'
export const SET_EVENTS_FILTER_QUERY = 'SET_EVENTS_FILTER_QUERY'
export const SET_EVENTS_SEARCH_QUERY = 'SET_EVENTS_SEARCH_QUERY'
export const SET_INFINITE_LOADING_REF = 'SET_INFINITE_LOADING_REF'
export const SET_NOT_FOUND_EVENTS = 'SET_NOT_FOUND_EVENTS'
export const SET_PRINT_STATUS = 'SET_PRINT_STATUS'
export const SET_IFLOADER_EVENTS = 'SET_IFLOADER_EVENTS'
export const SET_ACTUAL_EVENTS_WITH_TICKETS = 'SET_ACTUAL_EVENTS_WITH_TICKETS'
