export const TABS_NAME_EVENT =
  {
    TABS_ABOUT: 'About',
    TABS_SCHOOL_INFO: 'School Info',
    TABS_SCHEDULE: 'Schedule',
    TABS_LODGING: 'Lodging',
    TABS_FAQ: 'Faq'
  }

export const TABS_CONTENT_EVENT =
  {
    TABS_ABOUT: 'event-about-content',
    TABS_SCHOOL_INFO: 'event-school-info',
    TABS_SCHEDULE: 'event-schedule',
    TABS_LODGING: 'event-lodging',
    TABS_FAQ: 'event-faq'
  }

export const TABS_EVENT_LIST =
  [
    {
      tabsName: TABS_NAME_EVENT.TABS_ABOUT,
      icon: 'widgets-icon-list-view',
      iconActive: 'widgets-icon-list-view',
      isActive: true,
      route: TABS_NAME_EVENT.TABS_ABOUT,
      content: TABS_CONTENT_EVENT.TABS_ABOUT,
      id: 0,
      type: 'description'
    },
    // {
    //   tabsName: TABS_NAME_EVENT.TABS_SCHOOL_INFO,
    //   icon: 'widgets-icon-info',
    //   iconActive: 'widgets-icon-info',
    //   isActive: false,
    //   route: TABS_NAME_EVENT.TABS_SCHOOL_INFO,
    //   content: TABS_CONTENT_EVENT.TABS_SCHOOL_INFO,
    //   id: 1,
    //   type: 'text'
    // },
    {
      tabsName: TABS_NAME_EVENT.TABS_SCHEDULE,
      icon: 'widgets-icon-schedule',
      iconActive: 'widgets-icon-schedule',
      isActive: false,
      route: TABS_NAME_EVENT.TABS_SCHEDULE,
      content: TABS_CONTENT_EVENT.TABS_SCHEDULE,
      id: 2,
      type: 'sessions'
    },
    // {
    //   tabsName: TABS_NAME_EVENT.TABS_LODGING,
    //   icon: 'widgets-icon-lodging',
    //   iconActive: 'widgets-icon-lodging',
    //   isActive: false,
    //   route: TABS_NAME_EVENT.TABS_LODGING,
    //   content: TABS_CONTENT_EVENT.TABS_LODGING,
    //   id: 3,
    //   type: 'text'
    // },
    {
      tabsName: TABS_NAME_EVENT.TABS_FAQ,
      icon: 'widgets-icon-faq',
      iconActive: 'widgets-icon-faq',
      isActive: false,
      route: TABS_NAME_EVENT.TABS_FAQ,
      content: TABS_CONTENT_EVENT.TABS_FAQ,
      id: 4,
      type: 'faq'
    }
  ]

export const TABS_NAME_TICKET =
  {
    TABS_PREFERRED_PAYMENT_METHOD: 'Preferred payment method',
    TABS_CREDIT_DEBIT_CARD: 'CREDIT/DEBIT CARD',
    TABS_ACH_CHECK: 'ACH/CHECK'
  }

export const ADMIN_TABS_NAME_TICKET_USER_TYPE =
  {
    TABS_ADMIN: 'As an administrator',
    TABS_NEW_USER: 'As a new user',
    TABS_SELECTED_USER: 'As an existing user'
  }

export const TABS_ROUTE_TICKET =
  {
    TABS_PREFERRED_PAYMENT_METHOD: 'paymentMethodContent',
    TABS_CREDIT_DEBIT_CARD: 'creditCardContent',
    TABS_ACH_CHECK: 'achContent'
  }

export const TABS_TICKET =
  [
    {
      tabsName: TABS_NAME_TICKET.TABS_PREFERRED_PAYMENT_METHOD,
      isActive: false,
      route: TABS_ROUTE_TICKET.TABS_PREFERRED_PAYMENT_METHOD,
      id: 0,
      visible: false
    },
    {
      tabsName: TABS_NAME_TICKET.TABS_CREDIT_DEBIT_CARD,
      isActive: true,
      route: TABS_ROUTE_TICKET.TABS_CREDIT_DEBIT_CARD,
      id: 1,
      visible: true
    },
    {
      tabsName: TABS_NAME_TICKET.TABS_ACH_CHECK,
      isActive: false,
      route: TABS_ROUTE_TICKET.TABS_ACH_CHECK,
      id: 2,
      visible: true
    }
  ]

export const ADMIN_TABS_TICKET_USER_TYPE =
  [
    {
      tabsName: ADMIN_TABS_NAME_TICKET_USER_TYPE.TABS_NEW_USER,
      isActive: true,
      route: ADMIN_TABS_NAME_TICKET_USER_TYPE.TABS_NEW_USER,
      id: 0,
      visible: true
    },
    {
      tabsName: ADMIN_TABS_NAME_TICKET_USER_TYPE.TABS_SELECTED_USER,
      isActive: false,
      route: ADMIN_TABS_NAME_TICKET_USER_TYPE.TABS_SELECTED_USER,
      id: 1,
      visible: true
    },
    {
      tabsName: ADMIN_TABS_NAME_TICKET_USER_TYPE.TABS_ADMIN,
      isActive: false,
      route: ADMIN_TABS_NAME_TICKET_USER_TYPE.TABS_ADMIN,
      id: 2,
      visible: true
    }
  ]
