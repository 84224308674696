import * as types from '../../mutations-types'
const state = {
  firsLoadingMap: false
}

const getters = {
  firsLoadingMap: state => state.firsLoadingMap
}
const actions = {
  setFirstLoadingMap ({ commit }) {
    commit(types.SET_FIRST_LOADING_MAP)
  }
}
const mutations = {
  [types.SET_FIRST_LOADING_MAP] (state) {
    state.firsLoadingMap = true
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
