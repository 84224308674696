import * as types from './../../constants/store/mutations-types'

const state = {
  newsPostListData: [],
  newsActivePost: 0
  // printStatus: false
}

const getters = {
  newsPostListData: state => state.newsPostListData,
  newsActivePost: state => state.newsActivePost
}
const actions = {
  pushNewsPostData ({ commit }, data) {
    commit(types.PUSH_NEWS_POST_DATA, { data: data })
  },
  setNewsActivePost ({ commit }, data) {
    commit(types.SET_NEWS_ACTIVE_POST, data)
  }
}
const mutations = {
  [types.PUSH_NEWS_POST_DATA] (state, { data }) {
    state.newsPostListData.push(data)
    state.newsActivePost = state.newsPostListData.length - 1
  },
  [types.SET_NEWS_ACTIVE_POST] (state, data) {
    state.newsActivePost = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
