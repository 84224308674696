// import generalSettings from './../../../public/store/modules/generalSettings'
// import ifLoader from './../../../public/store/modules/ifLoader'
// import currentTimeZone from './../../../public/store/modules/currentTimeZone'
import widgetList from './modules/widgetList'
import widgetPostList from './modules/widgetPostList'

const modules = {
  // generalSettings,
  // currentTimeZone,
  // ifLoader,
  widgetList,
  widgetPostList
}

export default modules
