export const SET_ALL_SERMONS_DATA = 'SET_ALL_SERMONS_DATA'
export const PUSH_NEW_SERMONS_DATA = 'PUSH_NEW_SERMONS_DATA'
export const SET_EVENT_DATA = 'SET_EVENT_DATA'
export const SET_CURRENT_SERMONS_COUNT = 'SET_CURRENT_SERMONS_COUNT'
export const PRESET_CAMPUSES = 'PRESET_CAMPUSES'
export const SET_SERMONS_FILTER_QUERY = 'SET_SERMONS_FILTER_QUERY'
export const SET_SERMONS_SEARCH_QUERY = 'SET_SERMONS_SEARCH_QUERY'
export const SET_INFINITE_LOADING_REF_SERMONS = 'SET_INFINITE_LOADING_REF_SERMONS'
export const SET_NOT_FOUND_SERMONS = 'SET_NOT_FOUND_SERMONS'
export const PUSH_NEW_POST_DATA = 'PUSH_NEW_POST_DATA'
export const SET_ACTIVE_POST = 'SET_ACTIVE_POST'
export const SET_NO_DATA_SERMONS_POST = 'SET_NO_DATA_SERMONS_POST'
export const PUSH_SERMONS_POST_DATA = 'PUSH_SERMONS_POST_DATA'
export const SET_SERMONS_ACTIVE_POST = 'SET_SERMONS_ACTIVE_POST'
export const PRESET_SERMONS_IMAGE_UPDATE = 'PRESET_SERMONS_IMAGE_UPDATE'
// export const SET_PRINT_STATUS = 'SET_PRINT_STATUS'
