import * as types from '@@@/store/constant/mutationsTypes'

const state = {
  modalVideoContent: false,
  videoUrlModal: ''
}

const getters = {
  modalVideoContent: state => state.modalVideoContent,
  videoUrlModal: state => state.videoUrlModal
}

const actions = {
  updateModalVideoContent ({ commit }, data) {
    commit(types.SET_MODAL_VIDEO_CONTENT, data)
  },
  updateVideoUrlModal ({ commit }, data) {
    commit(types.SET_URL_VIDEO_CONTENT, data)
  }
}

const mutations = {
  [types.SET_MODAL_VIDEO_CONTENT] (state, data) {
    state.modalVideoContent = data
  },
  [types.SET_URL_VIDEO_CONTENT] (state, data) {
    state.videoUrlModal = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
