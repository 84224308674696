import * as types from '@@@/store/constant/mutationsTypes'
import { getUniqueId } from '@@@/mixin/uniqueId'
import _ from 'lodash'
const state = {
  forms: {},
  formModel: {},
  formValidator: {},
  formPaymentValidator: {},
  sbFormsList: [],
  tabSelectedFormEditor: 'formsList'
}

const getters = {
  forms: state => state.forms,
  formValidator: state => state.formValidator,
  getSiteForm: state => id => state.forms[id],
  getFormModel: state => id => state.formModel[id],
  formPaymentValidator: state => state.formPaymentValidator,
  sbFormsList: state => state.sbFormsList,
  tabSelectedFormEditor: state => state.tabSelectedFormEditor
}

const actions = {
  [types.SET_FORM] ({ commit }, data) {
    commit(types.SET_FORM, data)
  },
  updateFormPaymentValidator ({ commit }, data) {
    commit(types.UPDATE_FORM_PAYMENT_VALIDATOR, data)
  },
  updateFormModel ({ commit }, { id, form }) {
    commit(types.UPDATE_FORM_MODEL, { id, form })
  },
  updateFormValidator ({ commit }, data) {
    commit(types.UPDATE_FORM_VALIDATOR, data)
  },
  updateSbFormsList ({ commit }, data) {
    commit(types.UPDATE_SB_FORM_LIST, data)
  },
  setTabSelectedFormEditor ({ commit }, data) {
    commit(types.SET_TAB_SELECTED_FORM_EDITOR, data)
  }
}

const mutations = {
  [types.SET_FORM] (state, form) {
    state.forms = Object.assign({ [form.id]: form }, state.forms)
    const fields = typeof form.fields === 'string' ? JSON.parse(form.fields) : form.fields
    state.formModel = JSON.parse(JSON.stringify(Object.assign({ [form.id]: fields }, state.formModel)))
    state.formModel[form.id].forEach((item) => {
      item.id = getUniqueId()
    })
  },
  [types.UPDATE_FORM_MODEL] (state, { id, form }) {
    if (state.formModel[id] !== undefined) {
      for (let i = 0; i < state.formModel[id].length; i++) {
        const currentFields = _.find(form.fields, (obj) => {
          return obj.name === state.formModel[id][i].name
        })
        if (currentFields !== undefined) {
          state.formModel[id][i].value = currentFields.value
        }
      }
      const paymentFields = _.find(state.formModel[id], (obj) => {
        return obj.type === 'payment'
      })
      if (paymentFields !== undefined) {
        paymentFields.value = form.paid
      }
    }
  },
  [types.UPDATE_FORM_PAYMENT_VALIDATOR] (state, data) {
    state.formPaymentValidator = data
  },
  [types.UPDATE_FORM_VALIDATOR] (state, data) {
    state.formValidator = data
  },
  [types.UPDATE_SB_FORM_LIST] (state, data) {
    state.sbFormsList = data
  },
  [types.SET_TAB_SELECTED_FORM_EDITOR] (state, data) {
    state.tabSelectedFormEditor = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
