import * as types from '../mutations-types'
import _ from 'lodash'

const state = {
  listBookmarks: {},
  listAllBookmarks: [],
  bookmarksPlayTime: 5,
  viewBookmarksType: [],
  videoBookmarks: true,
  allBookmarks: false,
  countSermonsBookmarks: 0
}

const getters = {
  listBookmarks: state => state.listBookmarks,
  listAllBookmarks: state => state.listAllBookmarks,
  bookmarksPlayTime: state => state.bookmarksPlayTime,
  viewBookmarksType: state => state.viewBookmarksType,
  videoBookmarks: state => state.videoBookmarks,
  allBookmarks: state => state.allBookmarks,
  countSermonsBookmarks: state => state.countSermonsBookmarks
}
const actions = {
  loadDataBookmarks ({ commit }, data) {
    commit(types.LOAD_BOOKMARKS_DATA, { data: data })
  },
  loadAllDataBookmarks ({ commit }, data) {
    commit(types.LOAD_ALL_BOOKMARKS_DATA, { data: data })
  },
  addBookmarks ({ commit }, { data, title, author }) {
    commit(types.ADD_BOOKMARKS_DATA, { data: data, title: title, author: author })
  },
  removeBookmarks ({ commit }, data) {
    commit(types.REMOVE_BOOKMARKS_DATA, { data: data })
  },
  setBookmarksTime ({ commit }, data) {
    commit(types.SETTINGS_BOOKMARKS_TIME, { data: data })
  },
  setViewBookmarksType ({ commit }, { data, videoBookmarksChecked, allBookmarksChecked }) {
    commit(types.VIEW_BOOKMARKS_TYPE, { data: data, videoBookmarksChecked: videoBookmarksChecked, allBookmarksChecked: allBookmarksChecked })
  },
  clearListAllBookmarks ({ commit }) {
    commit(types.CLEAR_LIST_ALL_BOOKMARKS)
  }
}
const mutations = {
  [types.LOAD_BOOKMARKS_DATA] (state, { data }) {
    state.listBookmarks = data
  },
  [types.LOAD_ALL_BOOKMARKS_DATA] (state, { data }) {
    state.countSermonsBookmarks = state.countSermonsBookmarks + data.length
    state.listAllBookmarks = data
    // for (var i = 0; i < data.length; i++) {
    //   for (var j = 0; j < data[i].bookmarks.length; j++) {
    //     var bookmarksArray = {'sermon_id': data[i].sermon_id, 'author': data[i].author, 'title': data[i].title, 'created_at': data[i].bookmarks[j].created_at, 'id': data[i].bookmarks[j].id, 'sec': data[i].bookmarks[j].sec}
    //     state.listAllBookmarks.push(bookmarksArray)
    //   }
    // }
  },
  [types.ADD_BOOKMARKS_DATA] (state, { data, title, author }) {
    state.listBookmarks.my.unshift(data)
    const listAllBookmarksItem = { sec: data.sec, id: data.id, title: title, author: author }
    state.listAllBookmarks.unshift(listAllBookmarksItem)
  },
  [types.REMOVE_BOOKMARKS_DATA] (state, { data }) {
    const index = state.listBookmarks.my.indexOf(_.find(state.listBookmarks.my, function (obj) {
      return obj.id === data.id
    }))
    state.listBookmarks.my.splice(index, 1)
    const indexAll = state.listAllBookmarks.indexOf(_.find(state.listAllBookmarks, function (obj) {
      return obj.id === data.id
    }))
    state.listAllBookmarks.splice(indexAll, 1)
  },
  [types.SETTINGS_BOOKMARKS_TIME] (state, { data }) {
    state.bookmarksPlayTime = data
  },
  [types.VIEW_BOOKMARKS_TYPE] (state, { data, videoBookmarksChecked, allBookmarksChecked }) {
    state.viewBookmarksType = data
    state.videoBookmarks = videoBookmarksChecked
    state.allBookmarks = allBookmarksChecked
  },
  [types.CLEAR_LIST_ALL_BOOKMARKS] (state) {
    state.listAllBookmarks = []
    state.countSermonsBookmarks = 0
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
