import * as types from '../mutations-types'
import _ from 'lodash'

const state = {
  videoListAll: [],
  videoListSlider: [],
  videoListMostViewed: [],
  oneVideoItem: {},
  seriesList: [],
  programList: [],
  massForCheck: [],
  massForCheckSeries: [],
  programTotal: 1
}

const getters = {
  videoListAll: state => state.videoListAll,
  videoListSlider: state => state.videoListSlider,
  videoListMostViewed: state => state.videoListMostViewed,
  oneVideoItem: state => state.oneVideoItem,
  seriesList: state => state.seriesList,
  programList: state => state.programList,
  programTotal: state => state.programTotal,
  massForCheck: state => state.massForCheck,
  massForCheckSeries: state => state.massForCheckSeries
}
const actions = {
  setAllData ({ commit }, data) {
    commit(types.LOAD_VIDEO_LIST, { data: data })
  },
  setProgramList ({ commit }, data) {
    commit(types.PROGRAM_LIST, { data: data })
  },
  setProgramTotal ({ commit }, data) {
    commit(types.PROGRAM_TOTAL, { data: data })
  },
  resetAllData ({ commit }) {
    commit(types.RELOAD_VIDEO_LIST)
  },
  resetAllSeriesData ({ commit }) {
    commit(types.RELOAD_SERIES_LIST)
  },
  setOneItem ({ commit }, data) {
    commit(types.LOAD_ONE_VIDEO_ITEM, { data: data })
  },
  pushNewData ({ commit }, { data, query }) {
    commit(types.LOAD_SLIDER_VIDEO_LIST, { data: data })
    commit(types.PUSH_NEW_VIDEO_DATA, { data: data, query: query })
  },
  pushSerisList ({ commit }, { data, query }) {
    commit(types.PUSH_NEW_SERIES, { data: data, query: query })
  },
  savePlayBack ({ commit }, { id, playback }) {
    commit(types.SAVE_PLAYBACK, { id: id, pb: playback })
  },
  savePlayBackAudio ({ commit }, { id, playback }) {
    commit(types.SAVE_PLAYBACK_AUDIO, { id: id, pb: playback })
  },
  setMostViewedList ({ commit }, data) {
    commit(types.SET_MOST_VIEWED, { data: data })
  }
}
const mutations = {
  [types.LOAD_VIDEO_LIST] (state, { data }) {
    state.videoListAll = data
  },
  [types.PROGRAM_LIST] (state, { data }) {
    state.programList = data
  },
  [types.PROGRAM_TOTAL] (state, { data }) {
    state.programTotal = data
  },
  [types.SET_MOST_VIEWED] (state, { data }) {
    state.videoListMostViewed = data
  },
  [types.RELOAD_VIDEO_LIST] (state) {
    state.massForCheck = []
    state.videoListAll = []
  },
  [types.RELOAD_SERIES_LIST] (state) {
    state.massForCheckSeries = []
    state.seriesList = []
  },
  [types.SAVE_PLAYBACK] (state, { id, pb }) {
    if (state.videoListAll.length !== 0) {
      if (_.find(state.videoListAll, function (obj) { return obj.video_id === id }) !== undefined) {
        state.videoListAll[_.indexOf(state.videoListAll, _.find(state.videoListAll, function (obj) { return obj.video_id === id }))].video_played = pb
      }
    }
    if (this.getters.sermonsListForSerie.length !== 0) {
      if (_.find(this.getters.sermonsListForSerie, function (obj) { return obj.video_id === id }) !== undefined) {
        this.getters.sermonsListForSerie[_.indexOf(this.getters.sermonsListForSerie, _.find(this.getters.sermonsListForSerie, function (obj) { return obj.video_id === id }))].video_played = pb
      }
    }
  },
  [types.SAVE_PLAYBACK_AUDIO] (state, { id, pb }) {
    if (state.videoListAll.length !== 0) {
      if (_.find(state.videoListAll, function (obj) { return obj.audio_id === id }) !== undefined) {
        state.videoListAll[_.indexOf(state.videoListAll, _.find(state.videoListAll, function (obj) { return obj.audio_id === id }))].audio_played = pb
      }
    }
  },
  [types.LOAD_SLIDER_VIDEO_LIST] (state, { data }) {
    if (state.videoListSlider.length < 2) {
      for (let i = 0; i < 3; i++) {
        state.videoListSlider.push(data[i])
      }
    }
  },
  [types.LOAD_ONE_VIDEO_ITEM] (state, { data }) {
    state.oneVideoItem = data
  },
  [types.PUSH_NEW_VIDEO_DATA] (state, { data, query }) {
    for (var i = 0; i < data.length; i++) {
      if (_.find(state.massForCheck, function (obj) {
        return obj.id === data[i].id
      }) === undefined) {
        if (query === this.getters.sortString) {
          state.massForCheck.push(data[i])
        }
        setTimeout(addd, i * 100, data[i], query, this)
      }
    }
    function addd (item, query, owner) {
      if (query === owner.getters.sortString) {
        state.videoListAll.push(item)
      }
    }
  },
  [types.PUSH_NEW_SERIES] (state, { data, query }) {
    for (var i = 0; i < data.length; i++) {
      if (_.find(state.massForCheckSeries, function (obj) {
        return obj.id === data[i].id
      }) === undefined) {
        if (query === this.getters.seriesSortString) {
          state.massForCheckSeries.push(data[i])
        }
        setTimeout(addd, i * 70, data[i], query, this)
      }
    }
    function addd (item, query, owner) {
      if (query === owner.getters.seriesSortString) {
        state.seriesList.push(item)
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
