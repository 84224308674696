import * as types from './../../constants/store/mutations-types'
import _ from 'lodash'

const state = {
  devotionsListAll: [],
  currentDevotionsCount: 1,
  devotionsFilterQuery: '',
  devotionsSearchQuery: '',
  infiniteLoadingRefDevotions: false,
  notFoundDevotions: false,
  noDataDevotionsPost: false,
  presetDevotionsImg: false,
  devotionsListSetTimeoutId: false
  // printStatus: false
}

const getters = {
  devotionsListAll: state => state.devotionsListAll,
  currentDevotionsCount: state => state.currentDevotionsCount,
  devotionsFilterQuery: state => state.devotionsFilterQuery,
  devotionsSearchQuery: state => state.devotionsSearchQuery,
  infiniteLoadingRefDevotions: state => state.infiniteLoadingRefDevotions,
  notFoundDevotions: state => state.notFoundDevotions,
  noDataDevotionsPost: state => state.noDataDevotionsPost,
  presetDevotionsImg: state => state.presetDevotionsImg
  // printStatus: state => state.printStatus
}
const actions = {
  setAllDevotionsData ({ commit }, data) {
    commit(types.SET_ALL_DEVOTIONS_DATA, { data: data })
  },
  pushNewDevotionsData ({ commit }, data) {
    commit(types.PUSH_NEW_DEVOTIONS_DATA, { data: data })
  },
  setCurrentDevotionsCount ({ commit }, data) {
    commit(types.SET_CURRENT_DEVOTIONS_COUNT, { data: data })
  },
  setDevotionsFilterQuery ({ commit }, data) {
    commit(types.SET_DEVOTIONS_FILTER_QUERY, data)
  },
  setDevotionsSearchQuery ({ commit }, data) {
    commit(types.SET_DEVOTIONS_SEARCH_QUERY, data)
  },
  setInfiniteLoadingRefDevotions ({ commit }, data) {
    commit(types.SET_INFINITE_LOADING_REF_DEVOTIONS, data)
  },
  setNotFoundDevotions ({ commit }, data) {
    commit(types.SET_NOT_FOUND_DEVOTIONS, data)
  },
  setNoDataDevotionsPost ({ commit }, data) {
    commit(types.SET_NO_DATA_DEVOTIONS_POST, data)
  },
  presetDevotionsImageUpdate ({ commit }, data) {
    commit(types.PRESET_DEVOTIONS_IMAGE_UPDATE, data)
  }
  // setPrintStatus ({commit}, data) {
  //   commit(types.SET_PRINT_STATUS, data)
  // }
}
const mutations = {
  [types.SET_ALL_DEVOTIONS_DATA] (state, { data }) {
    if (data.length > 0) {
      state.devotionsListAll = data.items
    } else {
      state.devotionsListAll = []
    }
  },
  [types.PUSH_NEW_DEVOTIONS_DATA] (state, { data }) {
    clearTimeout(state.devotionsListSetTimeoutId)
    for (let i = 0; i < data.items.length; i++) {
      if (_.find(state.devotionsListAll, function (obj) {
        return obj.id === data.items[i].id
      }) === undefined) {
        if (data.items[i].description === undefined) {
          data.items[i].description = ''
        }
        const items = document.createElement('div')
        items.innerHTML = data.items[i].body
        items.id = 'itemsBody'
        const itemsBodyChildList = items.childNodes
        let indexWorship = -1
        const worship = _.find(itemsBodyChildList, (obj, index) => {
          if (obj.id === 'worship') {
            indexWorship = index
          }
          return obj.id === 'worship'
        })
        if (worship !== undefined) {
          const embedlyCard = _.find(worship.childNodes, (obj) => {
            return obj.className === 'embedly-card'
          })
          if (embedlyCard !== undefined) {
            parseVideoLink(embedlyCard)
            items.childNodes[indexWorship].innerHTML = embedlyCard.innerHTML
            data.items[i].body = items.innerHTML
            // if (data.items[i].description === '') {
            //   data.items[i].description = data.items[i].body.innerText.substring(0, 1500)
            // }
          }
        }
        state.devotionsListSetTimeoutId = setTimeout(function () {
          state.devotionsListAll.push(data.items[i])
        }, i * 100)
      }
    }
  },
  [types.SET_CURRENT_DEVOTIONS_COUNT] (state, { data }) {
    state.currentDevotionsCount = data
  },
  [types.SET_DEVOTIONS_FILTER_QUERY] (state, data) {
    state.devotionsFilterQuery = data
  },
  [types.SET_DEVOTIONS_SEARCH_QUERY] (state, data) {
    state.devotionsSearchQuery = data
  },
  [types.SET_INFINITE_LOADING_REF_DEVOTIONS] (state, data) {
    state.infiniteLoadingRefDevotions = data
  },
  [types.SET_NOT_FOUND_DEVOTIONS] (state, data) {
    state.notFoundDevotions = data
  },
  [types.SET_NO_DATA_DEVOTIONS_POST] (state, data) {
    state.noDataDevotionsPost = data
  },
  [types.PRESET_DEVOTIONS_IMAGE_UPDATE] (state, data) {
    state.presetDevotionsImg = data
  }
  // [types.SET_PRINT_STATUS] (state, data) {
  //   state.printStatus = data
  // }
}

function parseVideoLink (list) {
  if (list.childNodes.length > 0) {
    for (let i = 0; i < list.childNodes.length; i++) {
      if (list.childNodes[i].hostname && list.childNodes[i].hostname.includes('youtu')) {
        const videoLink = list.childNodes[i].href
        const host = list.childNodes[i].hostname
        const items = document.createElement('iframe')
        items.width = 1000
        items.height = 563
        items.setAttribute('frameborder', '0')
        items.setAttribute('allowfullscreen', 'true')

        items.setAttribute('src', 'https://www.youtube.com/embed/' + videoLink.split(`https://${host}/watch?v=`)[1])
        list.replaceChild(items, list.childNodes[i])
      } else {
        if (list.childNodes[i].childNodes.length > 0) {
          parseVideoLink(list.childNodes[i])
        }
      }
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
