import * as chat from '../../chatConst'

const state = {
  chatLoadingState: false,
  chatLoadingChannelsState: false,
  chatEntitySoundsEnabled: null,
  chatEntityActive: true, // chat widget is active
  chatEntityAccessManager: null,
  chatEntityChatClient: null, // Twilio.Chat.Client
  chatEntityChannels: {}, // User's channels
  chatEntityCurrentChannel: null, // current channel of chat
  chatEntityCurrentChannelReady: false, // current channel is ready to work
  chatEntityChannelInvitedTo: null, // private channel invited to
  chatEntityChannelStartDisabled: true, // start channel disabled or not
  chatEntityChannelMessages: {}, // channel messages
  chatEntityChannelUnreadMessagesCount: {}, // channel messages
  chatEntityChannelMessagesAnchor: {},
  chatEntityChannelUsers: {}, // channel authenticated users
  chatEntityUsers: {}, // users of chat
  chatEntityChatUserId: null, // ID of user for chat real user ID or guest id (string)
  chatEntityMe: null,
  chatEntityProfileUser: null,
  chatEntityProfileUserData: null,
  chatEntityProfileBackCallback: null,
  chatEntityPanel: null,
  chatEntityReady: true,
  chatEntityMenu: {
    settingsMenu: false,
    channelsMenu: false,
    messageSettingsMenu: false,
    messageProfileMenu: false
  },
  chatEntityEditableMessage: null,
  chatEntityEditableMessageText: null,
  chatEntityNewMessageText: '', // new message
  chatEntityNewChannel: { // new channel
    name: '',
    isPrivate: false,
    error: '',
    members: []
  },
  chatEntityConfirmation: {
    message: null,
    action: null,
    callback: null
  },
  chatEntityAlert: {
    message: null,
    action: null,
    callback: null
  },
  chatEntityReport: {
    reason: null,
    action: false,
    callback: null,
    block: null
  },
  chatEntityAddMessagePs: false,
  chatEntitySendMessagePs: false,
  chatEntityChannelChange: false,
  chatEntityShowTooltipChannelName: false,
  chatEntityIAmModerator: null,
  chatEntityIAmBlocked: null,
  chatEntityInitTime: new Date(),
  chatEntityChannelStatusLog: {},
  chatEntityAccessKey: null
}

const getters = {
  chatLoadingState: state => state.chatLoadingState,
  chatLoadingChannelsState: state => state.chatLoadingChannelsState,
  chatEntitySoundsEnabled: state => state.chatEntitySoundsEnabled,
  chatEntityActive: state => state.chatEntityActive, // chat widget is active
  chatEntityAccessManager: state => state.chatEntityAccessManager,
  chatEntityChatClient: state => state.chatEntityChatClient, // Twilio.Chat.Client
  chatEntityChannels: state => state.chatEntityChannels, // User's channels
  chatEntityCurrentChannel: state => state.chatEntityCurrentChannel, // current channel of chat
  chatEntityCurrentChannelReady: state => state.chatEntityCurrentChannelReady, // current channel is ready to work
  chatEntityChannelInvitedTo: state => state.chatEntityChannelInvitedTo, // private channel invited to
  chatEntityChannelStartDisabled: state => state.chatEntityChannelStartDisabled, // start channel disabled or not
  chatEntityChannelMessages: state => state.chatEntityChannelMessages, // channel messages
  chatEntityChannelUnreadMessagesCount: state => state.chatEntityChannelUnreadMessagesCount, // channel messages
  chatEntityChannelMessagesAnchor: state => state.chatEntityChannelMessagesAnchor,
  chatEntityChannelUsers: state => state.chatEntityChannelUsers, // channel authenticated users
  chatEntityUsers: state => state.chatEntityUsers, // users of chat
  chatEntityChatUserId: state => state.chatEntityChatUserId, // ID of user for chat real user ID or guest id (string)
  chatEntityMe: state => state.chatEntityMe,
  chatEntityProfileUser: state => state.chatEntityProfileUser,
  chatEntityProfileUserData: state => state.chatEntityProfileUserData,
  chatEntityProfileBackCallback: state => state.chatEntityProfileBackCallback,
  chatEntityPanel: state => state.chatEntityPanel,
  chatEntityReady: state => state.chatEntityReady,
  chatEntityMenu: state => state.chatEntityMenu,
  chatEntityEditableMessage: state => state.chatEntityEditableMessage,
  chatEntityEditableMessageText: state => state.chatEntityEditableMessageText,
  chatEntityNewMessageText: state => state.chatEntityNewMessageText, // new message
  chatEntityNewChannel: state => state.chatEntityNewChannel,
  chatEntityConfirmation: state => state.chatEntityConfirmation,
  chatEntityAlert: state => state.chatEntityAlert,
  chatEntityReport: state => state.chatEntityReport,
  chatEntityAddMessagePs: state => state.chatEntityAddMessagePs,
  chatEntitySendMessagePs: state => state.chatEntitySendMessagePs,
  chatEntityChannelChange: state => state.chatEntityChannelChange,
  chatEntityShowTooltipChannelName: state => state.chatEntityShowTooltipChannelName,
  chatEntityIAmModerator: state => state.chatEntityIAmModerator,
  chatEntityIAmBlocked: state => state.chatEntityIAmBlocked,
  chatEntityInitTime: state => state.chatEntityInitTime,
  chatEntityChannelStatusLog: state => state.chatEntityChannelStatusLog,
  chatEntityAccessKey: state => state.chatEntityAccessKey
}
const actions = {
  setChatLoadingState ({ commit }, data) {
    commit(chat.SET_CHAT_LOADING_STATE, { data: data })
  },
  setChatLoadingChannelsState ({ commit }, data) {
    commit(chat.SET_CHAT_LOADING_CHANNELS_STATE, { data: data })
  },
  setChatEntitySoundsEnabled ({ commit }, data) {
    commit(chat.SET_SOUND_ENABLED, { data: data })
  },
  setChatEntityActive ({ commit }, data) {
    commit(chat.SET_ACTIVE, { data: data })
  },
  setChatEntityAccessManager ({ commit }, data) {
    commit(chat.SET_ACCESS_MANAGER, { data: data })
  },
  setChatEntityChatClient ({ commit }, data) {
    commit(chat.SET_CHAT_CLIENT, { data: data })
  },
  setChatEntityChannels ({ commit }, data) {
    commit(chat.SET_CHAT_CHANNELS, { data: data })
  },
  setChatEntityCurrentChannel ({ commit }, data) {
    commit(chat.SET_CURRENT_CHANNEL, { data: data })
  },
  setChatEntityCurrentChannelReady ({ commit }, data) {
    commit(chat.SET_CURRENT_CHANNEL_READY, { data: data })
  },
  setChatEntityChannelInvitedTo ({ commit }, data) {
    commit(chat.SET_CHANNEL_INVITED_TO, { data: data })
  },
  setChatEntityChannelStartDisabled ({ commit }, data) {
    commit(chat.SET_START_DISABLED, { data: data })
  },
  setChatEntityChannelMessages ({ commit }, data) {
    commit(chat.SET_CHANNEL_MESSAGES, { data: data })
  },
  setChatEntityChannelUnreadMessagesCount ({ commit }, data) {
    commit(chat.SET_CHANNEL_UNREAD_MESSAGES_COUNT, { data: data })
  },
  setChatEntityChannelMessagesAnchor ({ commit }, data) {
    commit(chat.SET_CHANNEL_MESSAGES_ANCHOR, { data: data })
  },
  setChatEntityChannelUsers ({ commit }, data) {
    commit(chat.SET_CHANNEL_USERS, { data: data })
  },
  setChatEntityUsers ({ commit }, data) {
    commit(chat.SET_USERS, { data: data })
  },
  setChatEntityChatUserId ({ commit }, data) {
    commit(chat.SET_CHAT_USER_ID, { data: data })
  },
  setChatEntityMe ({ commit }, data) {
    commit(chat.SET_ME, { data: data })
  },
  setChatEntityProfileUser ({ commit }, data) {
    commit(chat.SET_PROFILE_USER, { data: data })
  },
  setChatEntityProfileUserData ({ commit }, data) {
    commit(chat.SET_PROFILE_USER_DATA, { data: data })
  },
  setChatEntityProfileBackCallback ({ commit }, data) {
    commit(chat.SET_PROFILE_BACK_CALLBACK, { data: data })
  },
  setChatEntityPanel ({ commit }, data) {
    commit(chat.SET_PANEL, { data: data })
  },
  setChatEntityReady ({ commit }, data) {
    commit(chat.SET_READY, { data: data })
  },
  setChatEntityMenu ({ commit }, data) {
    commit(chat.SET_MENU, { data: data })
  },
  setChatEntityEditableMessage ({ commit }, data) {
    commit(chat.SET_EDITABLE_MESSAGES, { data: data })
  },
  setChatEntityEditableMessageText ({ commit }, data) {
    commit(chat.SET_EDITABLE_MESSAGES_TEXT, { data: data })
  },
  setChatEntityNewMessageText ({ commit }, data) {
    commit(chat.SET_NEW_MESSAGES_TEXT, { data: data })
  },
  setChatEntityNewChannel ({ commit }, data) {
    commit(chat.SET_NEW_CHANNEL, { data: data })
  },
  setChatEntityConfirmation ({ commit }, data) {
    commit(chat.SET_CONFIRMATION, { data: data })
  },
  setChatEntityAlert ({ commit }, data) {
    commit(chat.SET_ALERT, { data: data })
  },
  setChatEntityReport ({ commit }, data) {
    commit(chat.SET_REPORT, { data: data })
  },
  setChatEntityAddMessagePs ({ commit }, data) {
    commit(chat.SET_ADD_MESSAGES_PS, { data: data })
  },
  setChatEntitySendMessagePs ({ commit }, data) {
    commit(chat.SET_SEND_MESSAGE_PS, { data: data })
  },
  setChatEntityChannelChange ({ commit }, data) {
    commit(chat.SET_CHANNEL_CHANGE, { data: data })
  },
  setChatEntityShowTooltipChannelName ({ commit }, data) {
    commit(chat.SET_SHOW_TOOLTIP_CHANNEL_NAME, { data: data })
  },
  setChatEntityIAmModerator ({ commit }, data) {
    commit(chat.SET_I_AM_MODERATOR, { data: data })
  },
  setChatEntityIAmBlocked ({ commit }, data) {
    commit(chat.SET_I_AM_BLOCKED, { data: data })
  },
  setChatEntityInitTime ({ commit }, data) {
    commit(chat.SET_INIT_TIME, { data: data })
  },
  setChatEntityChannelStatusLog ({ commit }, data) {
    commit(chat.SET_CHANNEL_STATUS_LOG, { data: data })
  },
  setChatEntityAccessKey ({ commit }, data) {
    commit(chat.SET_ACCESS_KEY, { data: data })
  }
}
const mutations = {
  [chat.SET_CHAT_LOADING_STATE] (state, { data }) {
    state.chatLoadingState = data
  },
  [chat.SET_CHAT_LOADING_CHANNELS_STATE] (state, { data }) {
    state.chatLoadingChannelsState = data
  },
  [chat.SET_SOUND_ENABLED] (state, { data }) {
    state.chatEntitySoundsEnabled = data
  },
  [chat.SET_ACTIVE] (state, { data }) {
    state.chatEntityActive = data
  },
  [chat.SET_ACCESS_MANAGER] (state, { data }) {
    state.chatEntityAccessManager = data
  },
  [chat.SET_CHAT_CLIENT] (state, { data }) {
    state.chatEntityChatClient = data
  },
  [chat.SET_CHAT_CHANNELS] (state, { data }) {
    state.chatEntityChannels = data
  },
  [chat.SET_CURRENT_CHANNEL] (state, { data }) {
    state.chatEntityCurrentChannel = data
  },
  [chat.SET_CURRENT_CHANNEL_READY] (state, { data }) {
    state.chatEntityCurrentChannelReady = data
  },
  [chat.SET_CHANNEL_INVITED_TO] (state, { data }) {
    state.chatEntityChannelInvitedTo = data
  },
  [chat.SET_START_DISABLED] (state, { data }) {
    state.chatEntityChannelStartDisabled = data
  },
  [chat.SET_CHANNEL_MESSAGES] (state, { data }) {
    state.chatEntityChannelMessages = data
  },
  [chat.SET_CHANNEL_UNREAD_MESSAGES_COUNT] (state, { data }) {
    state.chatEntityChannelUnreadMessagesCount = data
  },
  [chat.SET_CHANNEL_MESSAGES_ANCHOR] (state, { data }) {
    state.chatEntityChannelMessagesAnchor = data
  },
  [chat.SET_CHANNEL_USERS] (state, { data }) {
    state.chatEntityChannelUsers = data
  },
  [chat.SET_USERS] (state, { data }) {
    state.chatEntityUsers = data
  },
  [chat.SET_CHAT_USER_ID] (state, { data }) {
    state.chatEntityChatUserId = data
  },
  [chat.SET_ME] (state, { data }) {
    state.chatEntityMe = data
  },
  [chat.SET_PROFILE_USER] (state, { data }) {
    state.chatEntityProfileUser = data
  },
  [chat.SET_PROFILE_USER_DATA] (state, { data }) {
    state.chatEntityProfileUserData = data
  },
  [chat.SET_PROFILE_BACK_CALLBACK] (state, { data }) {
    state.chatEntityProfileBackCallback = data
  },
  [chat.SET_PANEL] (state, { data }) {
    state.chatEntityPanel = data
  },
  [chat.SET_READY] (state, { data }) {
    state.chatEntityReady = data
  },
  [chat.SET_MENU] (state, { data }) {
    state.chatEntityMenu = data
  },
  [chat.SET_EDITABLE_MESSAGES] (state, { data }) {
    state.chatEntityEditableMessage = data
  },
  [chat.SET_EDITABLE_MESSAGES_TEXT] (state, { data }) {
    state.chatEntityEditableMessageText = data
  },
  [chat.SET_NEW_MESSAGES_TEXT] (state, { data }) {
    state.chatEntityNewMessageText = data
  },
  [chat.SET_NEW_CHANNEL] (state, { data }) {
    state.chatEntityNewChannel = data
  },
  [chat.SET_CONFIRMATION] (state, { data }) {
    state.chatEntityConfirmation = data
  },
  [chat.SET_ALERT] (state, { data }) {
    state.chatEntityAlert = data
  },
  [chat.SET_REPORT] (state, { data }) {
    state.chatEntityReport = data
  },
  [chat.SET_CONTAINER_PS] (state, { data }) {
    state.chatEntityContainerPs = data
  },
  [chat.SET_ADD_MESSAGES_PS] (state, { data }) {
    state.chatEntityAddMessagePs = data
  },
  [chat.SET_SEND_MESSAGE_PS] (state, { data }) {
    state.chatEntitySendMessagePs = data
  },
  [chat.SET_CHANNEL_CHANGE] (state, { data }) {
    state.chatEntityChannelChange = data
  },
  [chat.SET_SHOW_TOOLTIP_CHANNEL_NAME] (state, { data }) {
    state.chatEntityShowTooltipChannelName = data
  },
  [chat.SET_I_AM_MODERATOR] (state, { data }) {
    state.chatEntityIAmModerator = data
  },
  [chat.SET_I_AM_BLOCKED] (state, { data }) {
    state.chatEntityIAmBlocked = data
  },
  [chat.SET_INIT_TIME] (state, { data }) {
    state.chatEntityInitTime = data
  },
  [chat.SET_CHANNEL_STATUS_LOG] (state, { data }) {
    state.chatEntityChannelStatusLog = data
  },
  [chat.SET_ACCESS_KEY] (state, { data }) {
    state.chatEntityAccessKey = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
