import * as types from './../../constants/store/mutations-types'
import _ from 'lodash'
import widgetsApi from './../../api/widgets'
import VueCookie from 'vue-cookie'
// import generalSettingsApi from '../../../../../api/generalSettings'

const state = {
  logo: '',
  whiteLogo: '',
  // generalSettings: {},
  campusesList: [],
  playerSettings: {},
  personalSettings: {},
  transcriptLang: '',
  closedCaptionsState: false,
  appLang: null
}

const getters = {
  logo: state => state.logo,
  // generalSettings: state => this.getters.generalSettingsSb,
  campusesList: state => state.campusesList,
  playerSettings: state => state.playerSettings,
  personalSettings: state => state.personalSettings,
  transcriptLang: state => state.transcriptLang,
  closedCaptionsState: state => state.closedCaptionsState,
  appLang: state => state.appLang,
  whiteLogo: state => state.whiteLogo
}
const actions = {
  // updateGeneralSettingsSb ({commit}, response) {
  //   commit(types.UPDATE_GENERAL_SETTINGS, {data: response.data})
  //   commit(types.UPDATE_LOGO, {data: response.data})
  // },
  updatePlayerSettings ({ commit }, data) {
    commit(types.UPDATE_PLAYER_SETTINGS, { data: data })
  },
  updatePlayerCaptions ({ commit }, data) {
    commit(types.UPDATE_PLAYER_CAPTIONS, data)
  },
  updatePersonalSettings ({ commit }, data) {
    commit(types.UPDATE_PERSONAL_SETTINGS, { data: data })
  },
  setTranscriptLang ({ commit }, data) {
    commit(types.SET_TRANSCRIPT_LANG, { data: data })
  },
  setAppLang ({ commit }, data) {
    commit(types.SET_APP_LANG, data)
  }
}
const mutations = {
  [types.SET_APP_LANG] (state, data) {
    if (data === undefined || data === null) state.appLang = this.getters.generalSettingsSb.translate.default_lang
    else state.appLang = data
    saveAppLang(state.appLang, this)
  },
  [types.SET_TRANSCRIPT_LANG] (state, { data }) {
    if (data === undefined) {
      state.transcriptLang = this.getters.generalSettingsSb.translate.default_lang
      saveUserLang(this.getters.generalSettingsSb.translate.default_lang, this)
    } else {
      try {
        if (this.getters.generalSettingsSb.translate !== undefined) {
          const el = _.find(this.getters.generalSettingsSb.translate.lang, function (obj) {
            return obj === data
          })
          if (el === undefined) {
            state.transcriptLang = this.getters.generalSettingsSb.translate.default_lang
            saveUserLang(this.getters.generalSettingsSb.translate.default_lang, this)
          } else {
            state.transcriptLang = data
            saveUserLang(data, this)
          }
        } else {
          this.getters.generalSettingsSb.translate = {
            default_lang: 'en'
          }
          state.transcriptLang = this.getters.generalSettingsSb.translate.default_lang
          saveUserLang(this.getters.generalSettingsSb.translate.default_lang, this)
        }
      } catch (e) {
        console.log(this)
        console.log(this.getters.generalSettingsSb)
        this.getters.generalSettingsSb.translate = {
          default_lang: 'en'
        }
        state.transcriptLang = this.getters.generalSettingsSb.translate.default_lang
        saveUserLang(this.getters.generalSettingsSb.translate.default_lang, this)
      }
    }
  },
  // [types.UPDATE_GENERAL_SETTINGS] (state, { data }) {
  //   this.getters.generalSettingsSb = data
  //   var style = document.createElement('style')
  //   var str = ':root {--accent-color-widgets: ' + data.organization.design.accentColor + '!important}'
  //   style.innerText = str
  //   var head = document.getElementsByTagName('head')[0]
  //   head.insertBefore(style, head.firstChild)
  //   if (data.campuses !== undefined) {
  //     this.dispatch('presetCampuses', data.campuses)
  //     state.campusesList = data.campuses
  //   }
  // },
  [types.UPDATE_LOGO] (state, { data }) {
    if (state.logo !== data.logo) {
      // TODO переделать на axios
      // $.get(data.logo, function (resp) {
      //   var doc = resp.getElementsByTagName('svg')
      //   doc[0].setAttribute('width', doc[0].viewBox.baseVal.width + 'px')
      //   doc[0].setAttribute('width', doc[0].viewBox.baseVal.width + 'px')
      //   doc[0].setAttribute('height', doc[0].viewBox.baseVal.height + 'px')
      //   var path = resp.getElementsByTagName('path')
      //   path[0].setAttribute('fill', 'black')
      //   var xml1 = new XMLSerializer().serializeToString(resp.documentElement)
      //   var svg641 = window.btoa(xml1)
      //   var b64Start1 = 'data:image/svg+xml;base64,'
      //   var image641 = b64Start1 + svg641
      //   state.logo = image641
      //   path[0].setAttribute('fill', 'white')
      //   xml1 = new XMLSerializer().serializeToString(resp.documentElement)
      //   svg641 = window.btoa(xml1)
      //   b64Start1 = 'data:image/svg+xml;base64,'
      //   image641 = b64Start1 + svg641
      //   state.whiteLogo = image641
      //   // FAVICON
      //   path[0].setAttribute('fill', data.organization.design.accentColor)
      //   resp.documentElement.setAttribute('height', '16px')
      //   resp.documentElement.setAttribute('width', '16px')
      //   var img = document.createElement('img')
      //   var canvas = document.createElement('canvas')
      //   canvas.setAttribute('height', '16px')
      //   canvas.setAttribute('width', '16px')
      //   var xml = new XMLSerializer().serializeToString(resp.documentElement)
      //   var svg64 = window.btoa(xml)
      //   var b64Start = 'data:image/svg+xml;base64,'
      //   var image64 = b64Start + svg64
      //   img.onload = function () {
      //     canvas.getContext('2d').drawImage(img, 0, 0)
      //     var dt = canvas.toDataURL('image/png')
      //     var link = document.createElement('link')
      //     link.setAttribute('rel', 'icon')
      //     link.setAttribute('href', dt)
      //     var head = document.getElementsByTagName('head')[0]
      //     head.appendChild(link)
      //   }
      //   img.src = image64
      // })
    }
  },
  [types.UPDATE_PLAYER_SETTINGS] (state, data) {
    state.playerSettings = data
  },
  [types.UPDATE_PLAYER_CAPTIONS] (state, data) {
    state.closedCaptionsState = data
    saveCaptionsState(data, this)
  },
  [types.UPDATE_PERSONAL_SETTINGS] (state, { data }) {
    state.personalSettings = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

function saveUserLang (lang, state) {
  if (state.getters.authorizeStatus) {
    const arr = state.getters.personalSettings
    const el = _.find(arr, function (obj) {
      return obj.name === 'closed_captions_lang'
    })
    if (el !== undefined) {
      el.value = lang
    } else {
      arr.push({
        name: 'closed_captions_lang',
        value: lang
      })
    }
    state.dispatch('updatePersonalSettings', arr)
    VueCookie.set('transcriptLang', lang, { expires: '1Y' })
    widgetsApi.updatePersonalSettings(arr, (resp) => {
    })
  } else {
    VueCookie.set('transcriptLang', lang, { expires: '1Y' })
  }
}

function saveCaptionsState (active, state) {
  if (state.getters.authorizeStatus) {
    const arr = state.getters.personalSettings
    const el = _.find(arr, function (obj) {
      return obj.name === 'closed_captions'
    })
    if (el !== undefined) {
      el.value = active
    } else {
      arr.push({
        name: 'closed_captions',
        value: active
      })
    }
    state.dispatch('updatePersonalSettings', arr)
    VueCookie.set('captionsState', active, { expires: '1Y' })
    widgetsApi.updatePersonalSettings(arr, (resp) => {
    })
  } else {
    VueCookie.set('captionsState', active, { expires: '1Y' })
  }
}

function saveAppLang (lang, state) {
  if (state.getters.authorizeStatus) {
    VueCookie.set('appLang', lang, { expires: '1Y' })
    // generalSettingsApi.updateUserLang({lang: lang, mid: state.getters.userData.id}, (resp) => {
    //
    // })
  } else {
    VueCookie.set('appLang', lang, { expires: '1Y' })
  }
}
