import VueAuthenticate from 'vue-authenticate'
import axios from 'axios'

const vueAuth = new VueAuthenticate(axios, {
  providers: {
    facebook: {
      name: 'facebook',
      // url: 'http://localhost:8080/',
      url: 'http://test2.ru/fb.php',
      authorizationEndpoint: 'https://www.facebook.com/v2.5/dialog/oauth',
      clientId: '1964828273737103',
      redirectUri: 'http://localhost:8080/',
      requiredUrlParams: ['display', 'scope'],
      scope: ['email', 'user_birthday', 'user_about_me'],
      // scopeDelimiter: ',',
      display: 'popup',
      oauthType: '2.0',
      popupOptions: { width: 100, height: 100 }
    },
    twitter: {
      name: 'twitter',
      // url: '/auth/twitter',
      url: 'http://test2.ru/fb.php',
      authorizationEndpoint: 'https://api.twitter.com/oauth/access_token',
      redirectUri: 'http:/localhost:8080/',
      popupOptions: { width: 100, height: 100 }
    }
  }
})

export default ({

  // You can use it as state property
  state: {
    isAuthenticated: false
  },

  getters: {
    isAuthenticated () {
      return vueAuth.isAuthenticated()
    }
  },

  mutations: {
    isAuthenticated (state, payload) {
      state.isAuthenticated = payload.isAuthenticated
    }
  },

  actions: {
    login (context, provider) {
      vueAuth.authenticate(provider).then(function (response) {
        // Execute application logic after successful social authentication
      })
    }
  }
})
