import * as types from './../../constants/store/mutations-types'
import _ from 'lodash'

const state = {
  sermonsHorizontalListAll: [],
  currentSermonsHorizontalCount: 1,
  sermonsHorizontalFilterQuery: '',
  sermonsHorizontalSearchQuery: '',
  infiniteLoadingRefSermonsHorizontal: false,
  notFoundSermonsHorizontal: false,
  noDataSermonsHorizontalPost: false,
  presetSermonsHorizontalImg: false,
  sermonsHorizontalListSetTimeoutId: false,
  activeSermonFilter: 'recent'
  // printStatus: false
}

const getters = {
  sermonsHorizontalListAll: state => state.sermonsHorizontalListAll,
  currentSermonsHorizontalCount: state => state.currentSermonsHorizontalCount,
  sermonsHorizontalFilterQuery: state => state.sermonsHorizontalFilterQuery,
  sermonsHorizontalSearchQuery: state => state.sermonsHorizontalSearchQuery,
  infiniteLoadingRefSermonsHorizontal: state => state.infiniteLoadingRefSermonsHorizontal,
  notFoundSermonsHorizontal: state => state.notFoundSermonsHorizontal,
  noDataSermonsHorizontalPost: state => state.noDataSermonsHorizontalPost,
  presetSermonsHorizontalImg: state => state.presetSermonsHorizontalImg,
  activeSermonFilter: state => state.activeSermonFilter
  // printStatus: state => state.printStatus
}
const actions = {
  setAllSermonsHorizontalData ({ commit }, data) {
    commit(types.SET_ALL_SERMONS_HORIZONTAL_DATA, { data: data })
  },
  pushNewSermonsHorizontalData ({ commit }, data) {
    commit(types.PUSH_NEW_SERMONS_HORIZONTAL_DATA, { data: data })
  },
  setCurrentSermonsHorizontalCount ({ commit }, data) {
    commit(types.SET_CURRENT_SERMONS_HORIZONTAL_COUNT, { data: data })
  },
  setSermonsHorizontalFilterQuery ({ commit }, data) {
    commit(types.SET_SERMONS_HORIZONTAL_FILTER_QUERY, data)
  },
  setSermonsHorizontalSearchQuery ({ commit }, data) {
    commit(types.SET_SERMONS_HORIZONTAL_SEARCH_QUERY, data)
  },
  setInfiniteLoadingRefSermonsHorizontal ({ commit }, data) {
    commit(types.SET_INFINITE_LOADING_REF_SERMONS_HORIZONTAL, data)
  },
  setNotFoundSermonsHorizontal ({ commit }, data) {
    commit(types.SET_NOT_FOUND_SERMONS_HORIZONTAL, data)
  },
  setNoDataSermonsHorizontalPost ({ commit }, data) {
    commit(types.SET_NO_DATA_SERMONS_HORIZONTAL_POST, data)
  },
  presetSermonsHorizontalImageUpdate ({ commit }, data) {
    commit(types.PRESET_SERMONS_HORIZONTAL_IMAGE_UPDATE, data)
  },
  setActiveSermonFilter ({ commit }, data) {
    commit(types.SET_ACTIVE_SERMON_FILTER, data)
  }
  // setPrintStatus ({commit}, data) {
  //   commit(types.SET_PRINT_STATUS, data)
  // }
}
const mutations = {
  [types.SET_ALL_SERMONS_HORIZONTAL_DATA] (state, { data }) {
    if (data.length > 0) {
      state.sermonsHorizontalListAll = data.items
    } else {
      state.sermonsHorizontalListAll = []
    }
  },
  [types.PUSH_NEW_SERMONS_HORIZONTAL_DATA] (state, { data }) {
    console.log(data.items)
    clearTimeout(state.sermonsHorizontalListSetTimeoutId)
    for (let i = 0; i < data.items.length; i++) {
      if (_.find(state.sermonsHorizontalListAll, function (obj) {
        return obj.id === data.items[i].id
      }) === undefined) {
        if (data.items[i].description === undefined) {
          data.items[i].description = ''
        }
        state.sermonsHorizontalListAll.push(data.items[i])
      }
    }
  },
  [types.SET_CURRENT_SERMONS_HORIZONTAL_COUNT] (state, { data }) {
    state.currentSermonsHorizontalCount = data
  },
  [types.SET_SERMONS_HORIZONTAL_FILTER_QUERY] (state, data) {
    state.sermonsHorizontalFilterQuery = data
  },
  [types.SET_SERMONS_HORIZONTAL_SEARCH_QUERY] (state, data) {
    state.sermonsHorizontalSearchQuery = data
  },
  [types.SET_INFINITE_LOADING_REF_SERMONS_HORIZONTAL] (state, data) {
    state.infiniteLoadingRefSermonsHorizontal = data
  },
  [types.SET_NOT_FOUND_SERMONS_HORIZONTAL] (state, data) {
    state.notFoundSermonsHorizontal = data
    state.notFoundSermonsHorizontal = data
  },
  [types.SET_NO_DATA_SERMONS_HORIZONTAL_POST] (state, data) {
    state.noDataSermonsHorizontalPost = data
  },
  [types.PRESET_SERMONS_HORIZONTAL_IMAGE_UPDATE] (state, data) {
    state.presetSermonsHorizontalImg = data
  },
  [types.SET_ACTIVE_SERMON_FILTER] (state, data) {
    state.activeSermonFilter = data
  }
  // [types.SET_PRINT_STATUS] (state, data) {
  //   state.printStatus = data
  // }
}

export default {
  state,
  getters,
  actions,
  mutations
}
