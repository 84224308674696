export const UPDATE_SB_FORM_LIST = 'UPDATE_SB_FORM_LIST'
export const UPDATE_DEFAULT_PAGES_TEMPLATES = 'UPDATE_DEFAULT_PAGES_TEMPLATES'
export const UPDATE_TREE = 'UPDATE_TREE'
export const SET_DRAG_REPLACE = 'SET_DRAG_REPLACE'
export const UPDATE_GLOBAL_FONT = 'UPDATE_GLOBAL_FONT'
export const SET_GENERAL_SETTINGS_SB = 'SET_GENERAL_SETTINGS_SB'
export const SET_ADMIN_ORGANIZATION_SETTINGS = 'SET_ADMIN_ORGANIZATION_SETTINGS'
export const SET_GLOBAL_COLORS_MAIN = 'SET_GLOBAL_COLORS_MAIN'
export const SET_GLOBAL_COLORS_MAIN_DARK = 'SET_GLOBAL_COLORS_MAIN_DARK'
export const SET_GLOBAL_BORDER_RADIUS = 'SET_GLOBAL_BORDER_RADIUS'
export const UPDATE_REVERT_STATUS = 'UPDATE_REVERT_STATUS'
export const UPDATE_REVERT_STATUS_SITE = 'UPDATE_REVERT_STATUS_SITE'
export const UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS'
export const UPDATE_CORE_SETTINGS_SITE_BUILDER = 'UPDATE_CORE_SETTINGS_SITE_BUILDER'
export const UPDATE_NOSAVED_LOGIC_STATUS = 'UPDATE_NOSAVED_LOGIC_STATUS'
export const DELETE_COLUMN_BY_ID = 'DELETE_COLUMN_BY_ID'
export const DELETE_SECTION_BY_ID = 'DELETE_SECTION_BY_ID'
export const DELETE_ROW_BY_ID = 'DELETE_ROW_BY_ID'
export const DELETE_ELEMENT_BY_ID = 'DELETE_ELEMENT_BY_ID'
export const ADD_SECTION_BY_POS = 'ADD_SECTION_BY_POS'
export const UPDATE_SAVE_STATUS = 'UPDATE_SAVE_STATUS'
export const UPDATE_VERSIONS = 'UPDATE_VERSIONS'
export const UPDATE_TREE_PAGE_DEPENED = 'UPDATE_TREE_PAGE_DEPENED'
export const UPDATE_END_TO_END_SECTIONS = 'UPDATE_END_TO_END_SECTIONS'
export const UPDATE_ACTIVE_GRID_EL = 'UPDATE_ACTIVE_GRID_EL'
export const UPDATE_ACTIVE_GRID_SECTION = 'UPDATE_ACTIVE_GRID_SECTION'
export const UPDATE_ACTIVE_MENU_EL = 'UPDATE_ACTIVE_MENU_EL'
export const UPDATE_ACTIVE_CTA_EL = 'UPDATE_ACTIVE_CTA_EL'
export const UPDATE_ACTIVE_SUB_MENU_EL = 'UPDATE_ACTIVE_SUB_MENU_EL'
export const UPDATE_ACTIVE_FOCUSED_GROUP = 'UPDATE_ACTIVE_FOCUSED_GROUP'
export const UPDATE_SHOW_ICONS_SMART_ASSISTANT = 'UPDATE_SHOW_ICONS_SMART_ASSISTANT'
export const UPDATE_ACTIVE_GRID_TYPE = 'UPDATE_ACTIVE_GRID_TYPE'
export const SET_ACTIVE_TAB_LEFT_SIDEBARS = 'SET_ACTIVE_TAB_LEFT_SIDEBARS'
export const SET_TABS_LEFT_SIDEBARS = 'SET_TABS_LEFT_SIDEBARS'
export const SET_TABS_RIGHT_SIDEBARS = 'SET_TABS_RIGHT_SIDEBARS'
export const SET_ACTIVE_TAB_LEFT_SIDEBARS_FW = 'SET_ACTIVE_TAB_LEFT_SIDEBARS_FW'
export const SET_ACTIVE_TAB_LEFT_SIDEBARS_CUSTOMIZATION = 'SET_ACTIVE_TAB_LEFT_SIDEBARS_CUSTOMIZATION'
export const SET_ACTIVE_TV_TAB_LEFT_SIDEBARS_CUSTOMIZATION = 'SET_ACTIVE_TV_TAB_LEFT_SIDEBARS_CUSTOMIZATION'
export const SET_ACTIVE_TAB_TOP_SIDEBARS_CUSTOMIZATION = 'SET_ACTIVE_TAB_TOP_SIDEBARS_CUSTOMIZATION'
export const UPDATE_FORMS_LIST = 'UPDATE_FORMS_LIST'
export const UPDATE_TEMPLATES_LIST = 'UPDATE_TEMPLATES_LIST'
export const UPDATE_ALL_TEMPLATES_LIST = 'UPDATE_ALL_TEMPLATES_LIST'
export const UPDATE_LOCATION_MAP = 'UPDATE_LOCATION_MAP'
export const UPDATE_SHOW_THANK_YOU_MODAL = 'UPDATE_SHOW_THANK_YOU_MODAL'
export const UPDATE_SHOW_POPUP = 'UPDATE_SHOW_POPUP'
export const UPDATE_PAGE_TEMPLATES_LIST = 'UPDATE_PAGE_TEMPLATES_LIST'
export const UPDATE_PAGE_TEMPLATES_USER_LIST = 'UPDATE_PAGE_TEMPLATES_USER_LIST'
export const UPDATE_TEMPLATES_USER_LIST = 'UPDATE_TEMPLATES_USER_LIST'
export const SET_ACTIVE_TAB_RIGHT_SIDEBARS = 'SET_ACTIVE_TAB_RIGHT_SIDEBARS'
export const SET_ACTIVE_ASSETS_MEDIA_LIBRARY_TABS_LIST = 'SET_ACTIVE_ASSETS_MEDIA_LIBRARY_TABS_LIST'
export const SET_ACTIVE_ASSETS_ASSETS_TABS_LIST = 'SET_ACTIVE_ASSETS_ASSETS_TABS_LIST'
export const SET_ACTIVE_TABS_LIST_ID = 'SET_ACTIVE_TABS_LIST_ID'
export const SET_ACTIVE_TYPOGRAPHY_TAB_NAME = 'SET_ACTIVE_TYPOGRAPHY_TAB_NAME'
export const SET_FONT_COMBINATIONS = 'SET_FONT_COMBINATIONS'
export const SET_IS_ACTIVE_TAB = 'SET_IS_ACTIVE_TAB'
export const SET_ID_SECTION = 'SET_ID_SECTION'
export const SET_IS_PREBUILD_ELEM = 'SET_IS_PREBUILD_ELEM'
export const SET_POS_SECTION = 'SET_POS_SECTION'
export const SET_ACTIVE_TAB_RIGHT_SIDEBARS_FW = 'SET_ACTIVE_TAB_RIGHT_SIDEBARS_FW'
export const SET_ACTIVE_TAB_RIGHT_SIDEBARS_CUSTOMIZATION = 'SET_ACTIVE_TAB_RIGHT_SIDEBARS_CUSTOMIZATION'
export const SET_ACTIVE_TAB_RIGHT_INSIDE_SIDEBARS_CUSTOMIZATION = 'SET_ACTIVE_TAB_RIGHT_INSIDE_SIDEBARS_CUSTOMIZATION'
export const SET_ACTIVE_MEDIA_TAB_CUSTOMIZATION = 'SET_ACTIVE_MEDIA_TAB_CUSTOMIZATION'
export const SET_ACTIVE_DRAG_TYPE = 'SET_ACTIVE_DRAG_TYPE'
export const SET_RESIZE_STATUS = 'SET_RESIZE_STATUS'
export const SET_RESIZE_LEFT_POS = 'SET_RESIZE_LEFT_POS'
export const UPDATE_POSITION_DRAG_TABLE = 'UPDATE_POSITION_DRAG_TABLE'
export const SET_DRAG_SECTION_POS = 'SET_DRAG_SECTION_POS'
export const SET_DRAG_ROW_POS = 'SET_DRAG_ROW_POS'
export const SET_DRAG_COL_POS = 'SET_DRAG_COL_POS'
export const SET_DRAG_EL_POS = 'SET_DRAG_EL_POS'
export const SET_DRAG_EL_TABLE_POS = 'SET_DRAG_EL_TABLE_POS'
export const SET_MOVED_EL = 'SET_MOVED_EL'
export const SET_ACTIVE_TAB_MEDIA = 'SET_ACTIVE_TAB_MEDIA'
export const SET_ACTIVE_TAB_SMART_ASSISTANT = 'SET_ACTIVE_TAB_SMART_ASSISTANT'
export const UPDATE_MEDIA_IMAGES_LIST = 'UPDATE_MEDIA_IMAGES_LIST'
export const UPDATE_BG_MODE_VIDEO_ITEM = 'UPDATE_BG_MODE_VIDEO_ITEM'
export const UPDATE_MEDIA_VIDEOS_LIST = 'UPDATE_MEDIA_VIDEOS_LIST'
export const UPDATE_USER_DATA_INFO = 'UPDATE_USER_DATA_INFO'
export const SET_FOLDER_IMAGE_ID = 'SET_FOLDER_IMAGE_ID'
export const SET_FOLDER_VIDEO_ID = 'SET_FOLDER_VIDEO_ID'
export const UPDATE_ACTIVE_CHOICE_IMAGES = 'UPDATE_ACTIVE_CHOICE_IMAGES'
export const UPDATE_ACTIVE_CHOICE_IMAGES_FROM_ASSETS = 'UPDATE_ACTIVE_CHOICE_IMAGES_FROM_ASSETS'
export const UPDATE_ACTIVE_CHOICE_VIDEOS = 'UPDATE_ACTIVE_CHOICE_VIDEOS'
export const UPDATE_ACTIVE_CHOICE_VIDEOS_FROM_ASSETS = 'UPDATE_ACTIVE_CHOICE_VIDEOS_FROM_ASSETS'
export const SET_CHOICE_IMAGE_ITEM = 'SET_CHOICE_IMAGE_ITEM'
export const SET_CHOICE_TEXT_ITEM = 'SET_CHOICE_TEXT_ITEM'
export const SET_CHOICE_VIDEO_ITEM = 'SET_CHOICE_VIDEO_ITEM'
export const SET_MODAL_MEDIA_CONTENT = 'SET_MODAL_MEDIA_CONTENT'
export const SET_MODAL_VIDEO_CONTENT = 'SET_MODAL_VIDEO_CONTENT'
export const UPDATE_VIEW_MODE = 'UPDATE_VIEW_MODE'
export const UPDATE_CURRENT_MODE_VIEW_PAGE = 'UPDATE_CURRENT_MODE_VIEW_PAGE'
export const SET_URL_MEDIA_CONTENT = 'SET_URL_MEDIA_CONTENT'
export const UPDATE_MODAL_SAVE_SMART_TEMPLATE = 'UPDATE_MODAL_SAVE_SMART_TEMPLATE'
export const UPDATE_SMART_TEMPLATE_SECTION = 'UPDATE_SMART_TEMPLATE_SECTION'
export const SET_URL_VIDEO_CONTENT = 'SET_URL_VIDEO_CONTENT'
export const UPDATE_ACCORDION_LAYOUT = 'UPDATE_ACCORDION_LAYOUT'
export const UPDATE_ACCORDION_BASIC = 'UPDATE_ACCORDION_BASIC'
export const UPDATE_ACCORDION_TYPOGRAPHY = 'UPDATE_ACCORDION_TYPOGRAPHY'
export const UPDATE_ACCORDION_MEDIA = 'UPDATE_ACCORDION_MEDIA'
export const UPDATE_ACCORDION_COMPONENTS = 'UPDATE_ACCORDION_COMPONENTS'
export const UPDATE_ELEMENTS_ACCORDION_SETTINGS = 'UPDATE_ELEMENTS_ACCORDION_SETTINGS'
export const UPDATE_SCROLL_POSITION = 'UPDATE_SCROLL_POSITION'
export const UPDATE_OPEN_MENU_GRID_EL = 'UPDATE_OPEN_MENU_GRID_EL'
export const UPDATE_OPEN_BURGER_MENU = 'UPDATE_OPEN_BURGER_MENU'
export const GET_FORM = 'GET_FORM'
export const SET_FORM = 'SET_FORM'
export const UPDATE_FORM_MODEL = 'UPDATE_FORM_MODEL'
export const UPDATE_FORM_PAYMENT_VALIDATOR = 'UPDATE_FORM_PAYMENT_VALIDATOR'
export const UPDATE_FORM_VALIDATOR = 'UPDATE_FORM_VALIDATOR'
export const TOGGLE_SHOW_POST_PAGE = 'TOGGLE_SHOW_POST_PAGE'
export const UPDATE_GENERAL_SETTINGS_SB = 'UPDATE_GENERAL_SETTINGS_SB'
export const TOGGLE_SHOW_NEWS_POST_PAGE = 'TOGGLE_SHOW_NEWS_POST_PAGE'
export const TOGGLE_SHOW_DEVOTIONS_POST_PAGE = 'TOGGLE_SHOW_DEVOTIONS_POST_PAGE'
export const TOGGLE_SHOW_READING_PLAN_POST_PAGE = 'TOGGLE_SHOW_READING_PLAN_POST_PAGE'
export const UPDATE_LOADING_STATUS_FW = 'UPDATE_LOADING_STATUS_FW'
export const UPDATE_TREE_FW = 'UPDATE_TREE_FW'
export const UPDATE_SAVE_STATUS_FW = 'UPDATE_SAVE_STATUS_FW'
export const UPDATE_VALIDATOR_FW = 'UPDATE_VALIDATOR_FW'
export const TOGGLE_SHOW_SERMONS_POST_PAGE = 'TOGGLE_SHOW_SERMONS_POST_PAGE'
export const UPDATE_GENERAL_SETTINGS_AUTH_USER = 'UPDATE_GENERAL_SETTINGS_AUTH_USER'
export const TOGGLE_LOADER_AUTH_USER = 'TOGGLE_LOADER_AUTH_USER'
export const SET_AUTH_USER_INFO = 'SET_AUTH_USER_INFO'
export const UPDATE_LOGO_AUTH = 'UPDATE_LOGO_AUTH'
export const CHANGE_AUTH_USER_PHOTO = 'CHANGE_AUTH_USER_PHOTO'
export const CHANGE_ACTIVE_MENU_ITEM_ELEMENT_SELECTED = 'CHANGE_ACTIVE_MENU_ITEM_ELEMENT_SELECTED'
export const CHANGE_ACTIVE_MENU_ITEM = 'CHANGE_ACTIVE_MENU_ITEM'
export const SET_REGISTER_AUTH_STATE = 'SET_REGISTER_AUTH_STATE'
export const SET_REGISTER_AUTH_OBJ = 'SET_REGISTER_AUTH_OBJ'
export const SET_AUTH_USER_REGISTER_MAIL = 'SET_AUTH_USER_REGISTER_MAIL'
export const LOGOUT_AUTH = 'LOGOUT_AUTH'
export const UPDATE_SMART_TEMPLATES_MENU_LIST = 'UPDATE_SMART_TEMPLATES_MENU_LIST'
export const SET_SHOW_LOGIN_MODAL_SB_STATE = 'SET_SHOW_LOGIN_MODAL_SB_STATE'
export const SET_SHOW_MODAL_AUTH_USER_SB_STATE = 'SET_SHOW_MODAL_AUTH_USER_SB_STATE'
export const SET_SHOW_MODAL_FORGOT_SB_STATE = 'SET_SHOW_MODAL_FORGOT_SB_STATE'
export const SET_SHOW_MODAL_FORGOT_SUCCESS_SB_STATE = 'SET_SHOW_MODAL_FORGOT_SUCCESS_SB_STATE'
export const SET_SHOW_MODAL_SIGNUP_SB_STATE = 'SET_SHOW_MODAL_SIGNUP_SB_STATE'
export const SET_REGISTRATION_FIRST_STEP_SB_STATE = 'SET_REGISTRATION_FIRST_STEP_SB_STATE'
export const SET_REGISTRATION_SECOND_STEP_SB_STATE = 'SET_REGISTRATION_SECOND_STEP_SB_STATE'
export const SET_REGISTRATION_THANKS_SB_STATE = 'SET_REGISTRATION_THANKS_SB_STATE'
export const SET_TERMS_SB_STATE = 'SET_TERMS_SB_STATE'
export const SET_COOKIE_POLICY = 'SET_COOKIE_POLICY'
export const SET_PRIVACY_POLICY = 'SET_PRIVACY_POLICY'
export const UPDATE_FORM_MODEL_PAID = 'UPDATE_FORM_MODEL_PAID'
export const SET_EVENTS_SHOPPING_CART_MODAL_STATE = 'SET_EVENTS_SHOPPING_CART_MODAL_STATE'
export const SET_FORGOT_EMAIL = 'SET_FORGOT_EMAIL'
export const SET_LOGIN_EMAIL = 'SET_LOGIN_EMAIL'
export const SET_SHOW_MODAL_TERMS = 'SET_SHOW_MODAL_TERMS'
export const SET_TERMS_EVENT = 'SET_TERMS_EVENT'
export const SET_IF_LOADER_FORM = 'SET_IF_LOADER_FORM'
export const SET_SHOW_EDIT_MENU_CONFIGURATION = 'SET_SHOW_EDIT_MENU_CONFIGURATION'
export const UPDATE_ASSETS_VIDEOS_LIST = 'UPDATE_ASSETS_VIDEOS_LIST'
export const UPDATE_ASSETS_IMAGES_LIST = 'UPDATE_ASSETS_IMAGES_LIST'
export const UPDATE_ASSETS_ICONS_LIST = 'UPDATE_ASSETS_ICONS_LIST'
export const UPDATE_FOLDER_ASSETS_IMAGE_ID = 'UPDATE_FOLDER_ASSETS_IMAGE_ID'
export const UPDATE_FOLDER_ASSETS_VIDEO_ID = 'UPDATE_FOLDER_ASSETS_VIDEO_ID'
export const UPDATE_FOLDER_ASSETS_ICONS_ID = 'UPDATE_FOLDER_ASSETS_ICONS_ID'
export const SET_ACTIVE_DRAG_SUB_TYPE = 'SET_ACTIVE_DRAG_SUB_TYPE'
export const SET_ACTIVE_SLIDER = 'SET_ACTIVE_SLIDER'
export const SET_ACTIVE_ROW_SLIDER = 'SET_ACTIVE_ROW_SLIDER'
export const SET_ACTIVE_ROW_SLIDER_SETTINGS = 'SET_ACTIVE_ROW_SLIDER_SETTINGS'
export const SET_TAB_SELECTED_FORM_EDITOR = 'SET_TAB_SELECTED_FORM_EDITOR'
export const SET_SIDEBAR_PANELS_CONTROL = 'SET_SIDEBAR_PANELS_CONTROL'
export const SET_ACTIVE_ACCORDION_SMART_ASSISTANT = 'SET_ACTIVE_ACCORDION_SMART_ASSISTANT'
export const SET_ASSISTANT_MODULES_LIST = 'SET_ASSISTANT_MODULES_LIST'
export const SET_SMART_ASSISTANT_MODULES = 'SET_SMART_ASSISTANT_MODULES'
export const SET_LIVE_STREAMING_BUILDER_DATA = 'SET_LIVE_STREAMING_BUILDER_DATA'
export const SET_SB_ELEMS_ACTIVE_MENU = 'SET_SB_ELEMS_ACTIVE_MENU'
export const SET_SB_ELEMS_ACTIVE_LIST = 'SET_SB_ELEMS_ACTIVE_LIST'
export const SET_SB_ELEMS_OPENED_SUB_PANEL = 'SET_SB_ELEMS_OPENED_SUB_PANEL'
export const SET_SB_ELEMS_SUB_PANEL_LIST = 'SET_SB_ELEMS_SUB_PANEL_LIST'
export const SET_SB_ELEMS_PREBUILD_LIST = 'SET_SB_ELEMS_PREBUILD_LIST'
export const SET_SITE_PUBLISHING_LIST = 'SET_SITE_PUBLISHING_LIST'
export const SET_ATTACH_PAGE_MENU_CONFIGURATION = 'SET_ATTACH_PAGE_MENU_CONFIGURATION'
export const UPDATE_CURRENT_SITE = 'UPDATE_CURRENT_SITE'
export const UPDATE_SAVE_PAGE_STATUS = 'UPDATE_SAVE_PAGE_STATUS'
export const UPDATE_ACTIVE_GROUP = 'UPDATE_ACTIVE_GROUP'
export const UPDATE_DRAG_CHILDREN_GRID_EL = 'UPDATE_DRAG_CHILDREN_GRID_EL'
export const UPDATE_DARK_MODE_BUILDER = 'UPDATE_DARK_MODE_BUILDER'
export const UPDATE_SHOW_GRID_BUILDER = 'UPDATE_SHOW_GRID_BUILDER'
export const SET_LANGUAGE = 'SET_LANGUAGE'
export const SET_GOOGLE_FONTS = 'SET_GOOGLE_FONTS'
export const SET_COLOR_LIST = 'SET_COLOR_LIST'
