import * as types from './types'
import { UPDATE_SAVE_STATUS } from '@@@/store/constant/mutationsTypes'
import { FORM } from '../constants/defaults/form'
import fields from '../constants/sets/fields'
import { SIDEBAR_FIELD_SETTINGS, SIDEBAR_FORM_SETTINGS } from '../constants/tabs/TabsRoutes'
import { generateId } from './utils'
import Vue from 'vue'
import { API_URL_FORMS } from '../constants/settings'
import _ from 'lodash'
import * as saveStatuses from '@@@/store/constant/saveStatuses'
import { ACTION_BUTTON, PAYMENT, UPLOAD_BUTTON } from '@@@/components/forms/constants/types/fieldsTypes'
import VueCookie from 'vue-cookie'
import { SET_CUSTOM_FIELDS } from './types'

function putForm (state, data, postFormUrl, commit) {
  if (state.formId) {
    postFormUrl += `/${state.formId}`
  }
  const { form } = data
  const { fields } = form
  const dataForm = {
    form: {
      ...form,
      fields: JSON.stringify(fields),
      id: undefined,
      updated_at: undefined,
      created_at: undefined,
      code: undefined
    }
  }
  delete dataForm.form.tagsArr
  dataForm.form.tags = dataForm.form.tags.map(obj => obj.tag)
  state.isSaving = true
  if (Array.isArray(typeof dataForm.form.adminNotificationEmail)) {
    dataForm.form.adminNotificationEmail = dataForm.form.adminNotificationEmail.join(',')
  }
  commit(UPDATE_SAVE_STATUS, saveStatuses.STATUS_SAVING)
  // const data = (formData === undefined) ? FORM : formData
  // const { fields, ...settings } = data
  // const form = {
  //   ...settings,
  //   fields: JSON.stringify(fields)
  // }
  Vue.http.put(postFormUrl, JSON.stringify(dataForm), {
    headers: {
      'X-Custom-Auth': VueCookie.get('mid')
    }
  }).then(response => {
    if (state.isSavingError) {
      state.isSavingError = false
    }
    state.isSaving = false
    commit(UPDATE_SAVE_STATUS, saveStatuses.STATUS_SAVED)
  }, error => {
    commit(UPDATE_SAVE_STATUS, saveStatuses.STATUS_ERROR)
    if (!state.isSavingError) {
      state.isSavingError = true
    }
    state.isSaving = false
    console.error(error)
  })
}

const putFormDebouced = _.debounce(putForm, 1500)

export default {
  [types.SET_CUSTOM_FIELDS] (state, data) {
    state.customFields = data
  },
  [types.ADD_NEW_FORM] (state) {
    state.form = FORM
  },
  [types.ADD_NEW_FIELD] (state, { fieldType, index = 0 }) {
    this.commit({
      type: types.CREATE_VERSION,
      actionName: types.CREATE_VERSION
    })
    const field = JSON.parse(JSON.stringify(fields[fieldType]))
    field.id = generateId()
    if (fieldType === ACTION_BUTTON || fieldType === UPLOAD_BUTTON || fieldType === PAYMENT) {
      // TODO Изменить generalSettings
      try {
        field.properties.buttonColor.selected = this.$store.getters.generalSettingsSb.organization.design.accentColor
      } catch (e) {
      }
    }
    const editForm = state.form
    editForm.fields.splice(index, 0, field)
    state.activeFieldIndex = index
    state.activeTab = SIDEBAR_FIELD_SETTINGS
    this.commit({
      type: types.CHANGE_FORM,
      form: editForm,
      isVersioned: true
    })
  },
  [types.CHANGE_SIDEBAR_TAB] (state, { tab, resetActiveField }) {
    this.commit({
      type: types.CREATE_VERSION,
      actionName: types.CHANGE_SIDEBAR_TAB
    })
    if (resetActiveField) {
      state.activeFieldIndex = null
    }
    if (!(tab === SIDEBAR_FIELD_SETTINGS && state.activeFieldIndex === null)) {
      state.activeTab = tab
    }
  },
  [types.CHANGE_FORM] (state, { form, withoutServerSaving, isVersioned }) {
    const data = { form }
    const postFormUrl = API_URL_FORMS
    state.form = form
    if (!withoutServerSaving) {
      if (!isVersioned) {
        this.commit({
          type: types.CREATE_VERSION,
          actionName: types.CHANGE_FORM
        })
      }
      putFormDebouced(state, data, postFormUrl, this.commit)
    }
  },
  [types.OPEN_FIELD_EDIT] (state, { index }) {
    if (index !== state.activeFieldIndex) {
      this.commit({
        type: types.CREATE_VERSION,
        actionName: types.OPEN_FIELD_EDIT
      })
      state.activeFieldIndex = index
      state.activeTab = SIDEBAR_FIELD_SETTINGS
    }
  },
  [types.CHANGE_FIELD] (state, { field, withoutServerSaving }) {
    this.commit({
      type: types.CREATE_VERSION,
      actionName: types.CHANGE_FIELD
    })
    const editForm = JSON.parse(JSON.stringify(state.form))
    editForm.fields[state.activeFieldIndex] = field
    this.commit({
      type: types.CHANGE_FORM,
      form: editForm,
      withoutServerSaving,
      isVersioned: true
    })
  },
  [types.CHANGE_FIELD_TYPE] (state, { newFieldType }) {
    this.commit({
      type: types.CREATE_VERSION,
      actionName: types.CHANGE_FIELD_TYPE
    })
    const form = state.form
    const newField = JSON.parse(JSON.stringify(fields[newFieldType]))
    const oldField = form.fields[state.activeFieldIndex]
    const resultField = Object.assign({}, newField, {
      type: newFieldType,
      name: oldField.name
    })
    form.fields[state.activeFieldIndex] = resultField
    this.commit({
      type: types.CHANGE_FORM,
      form: Object.assign({}, form),
      isVersioned: true
    })
  },
  [types.COPY_FIELD] (state, { index }) {
    this.commit({
      type: types.CREATE_VERSION,
      actionName: types.COPY_FIELD
    })
    const editForm = JSON.parse(JSON.stringify(state.form))
    if (index < this.activeFieldIndex) {
      this.activeFieldIndex += 1
    }
    editForm.fields.splice(index + 1, 0, JSON.parse(JSON.stringify(editForm.fields[index])))
    editForm.fields[index + 1].id = generateId()
    this.commit({
      type: types.CHANGE_FORM,
      form: editForm,
      isVersioned: true
    })
  },
  [types.DELETE_FIELD] (state, { index }) {
    this.commit({
      type: types.CREATE_VERSION,
      actionName: types.DELETE_FIELD
    })
    const editForm = JSON.parse(JSON.stringify(state.form))
    if (state.activeFieldIndex !== null) {
      if (index === state.activeFieldIndex) {
        if (index === 0) {
          if (editForm.fields.length === 1) {
            state.activeFieldIndex = null
            if (state.activeTab === SIDEBAR_FIELD_SETTINGS) {
              state.activeTab = SIDEBAR_FORM_SETTINGS
            }
          }
        } else if (index === editForm.fields.length - 1) {
          state.activeFieldIndex = index - 1
        }
      } else if (index < state.activeFieldIndex) {
        state.activeFieldIndex -= 1
      }
    }
    editForm.fields.splice(index, 1)
    this.commit({
      type: types.CHANGE_FORM,
      form: editForm,
      isVersioned: true
    })
  },
  [types.SET_FIELDS] (state, { fields }) {
    this.commit({
      type: types.CREATE_VERSION,
      actionName: types.SET_FIELDS
    })
    let newActiveFieldId
    if (state.activeFieldIndex !== null && state.activeTab === SIDEBAR_FIELD_SETTINGS) {
      newActiveFieldId = state.form.fields[state.activeFieldIndex].id
      state.activeFieldIndex = fields.findIndex(field => field.id === newActiveFieldId)
    }
    const editForm = JSON.parse(JSON.stringify(state.form))
    editForm.fields = fields
    this.commit({
      type: types.CHANGE_FORM,
      form: editForm,
      isVersioned: true
    })
  },
  [types.SET_FORM_ID] (state, { formId }) {
    state.formId = formId
  },
  [types.SET_NAV_STATUS] (state, { status }) {
    state.isNavOpen = status
  },
  [types.CREATE_VERSION] (state, { actionName }) {
    const version = {
      form: JSON.parse(JSON.stringify(state.form)),
      isNavOpen: state.isNavOpen,
      activeFieldIndex: state.activeFieldIndex,
      activeTab: state.activeTab,
      sidebarMessage: state.sidebarMessage,
      activeFieldName: state.activeFieldName,
      time: Date.now(),
      actionName
    }
    state.versions.push(version)
  },
  [types.SET_VERSION] (state) {
    if (state.versions.length) {
      const lastVersion = state.versions.pop()
      state.form = lastVersion.form
      state.isNavOpen = lastVersion.isNavOpen
      state.activeFieldIndex = lastVersion.activeFieldIndex
      state.activeTab = lastVersion.activeTab
      state.sidebarMessage = lastVersion.sidebarMessage
      state.activeFieldName = lastVersion.activeFieldName
    }
  },
  [types.SET_IF_LOADER_FORM] (state, { data }) {
    state.ifLoaderForm = data
  }
}
