export const EMPTY_MODAL = 'EMPTY_MODAL'
export const MODAL_LOGIN = 'MODAL_LOGIN'
export const MODAL_SIGN_UP = 'MODAL_SIGN_UP'
export const REGISTER_FIRST_STEP = 'REGISTER_FIRST_STEP'
export const REGISTER_SECOND_STEP = 'REGISTER_SECOND_STEP'
export const MODAL_TERMS = 'MODAL_TERMS'
export const MODAL_REGISTRATION_SUCCES = 'MODAL_REGISTRATION_SUCCES'
export const MODAL_REGISTRATION_THANKS = 'MODAL_REGISTRATION_THANKS'
export const MODAL_FORGOT = 'MODAL_FORGOT'
export const MODAL_FORGOT_SUCCES = 'MODAL_FORGOT_SUCCES'
export const MODAL_SEND_LINK = 'MODAL_SEND_LINK'
export const MODAL_PROFILE = 'MODAL_PROFILE'
export const MODAL_SELECT_LANG = 'MODAL_SELECT_LANG'
export const MODAL_EVENT_ITEM = 'MODAL_EVENT_ITEM'
export const MODAL_BLOG_POST = 'MODAL_BLOG_POST'
export const MODAL_NEWS_POST = 'MODAL_NEWS_POST'
export const MODAL_EVENT_COMPACT_ITEM = 'MODAL_EVENT_COMPACT_ITEM'
export const MODAL_BLOG_COMPACT_POST = 'MODAL_BLOG_COMPACT_POST'
export const MODAL_NEWS_COMPACT_POST = 'MODAL_NEWS_COMPACT_POST'
export const MODAL_DEVOTIONS_POST = 'MODAL_DEVOTIONS_POST'
export const MODAL_DEVOTIONS_COMPACT_POST = 'MODAL_DEVOTIONS_COMPACT_POST'
export const MODAL_READING_PLAN_POST = 'MODAL_READING_PLAN_POST'
export const MODAL_READING_PLAN_COMPACT_POST = 'MODAL_READING_PLAN_COMPACT_POST'
export const MODAL_PHOTO_ALBUM_VIEW = 'MODAL_PHOTO_ALBUM_VIEW'
export const MODAL_PHOTO_COMPACT_POST = 'MODAL_READING_PLAN_COMPACT_POST'
export const MODAL_THANK_YOU = 'MODAL_THANK_YOU'
export const MODAL_STANDARD_LIST_WIDGET_POST = 'MODAL_STANDARD_LIST_WIDGET_POST'
