import { GOOGLE_FONTS_DOMAIN } from './domains'
const axios = require('axios')

export default {
  getListFonts: () => {
    const instWithCred = axios.create({
      baseURL: GOOGLE_FONTS_DOMAIN
    })
    return instWithCred.get('')
  }
}
