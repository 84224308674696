import tickets from './modules/tickets/index'
import tabsTickets from './modules/tabs/tabsTickets'
import preferredPaymentMethod from './modules/preferredPaymentMethod'

const modules = {
  tickets,
  tabsTickets,
  preferredPaymentMethod
}

export default modules
