import * as modes from '../../../../../store/constant/modeViewNames'
import * as types from '../mutations-types'
import { MODE_PC, MODE_TABLET } from '../../../../../store/constant/modeViewNames'

const state = {
  viewMode: modes.MODE_PC,
  currentModeViewPage: -1
}

const getters = {
  viewMode: state => state.viewMode,
  currentModeViewPage: state => state.currentModeViewPage
}

const actions = {
  updateViewMode ({ commit }, data) {
    commit(types.UPDATE_VIEW_MODE, data)
  },
  updateCurrentModeViewPage ({ commit }, data) {
    commit(types.UPDATE_CURRENT_MODE_VIEW_PAGE, data)
  }
}

const mutations = {
  [types.UPDATE_VIEW_MODE] (state, data) {
    const widthTABLET = 1340
    const mode = data === MODE_PC && window.innerWidth < widthTABLET ? MODE_TABLET : data
    state.viewMode = mode
  },
  [types.UPDATE_CURRENT_MODE_VIEW_PAGE] (state, data) {
    state.currentModeViewPage = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
