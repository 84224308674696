import * as types from './../../constants/store/mutations-types'
import { find } from 'lodash'

const state = {
  ministriesList: [],
  currentMinistriesCount: 1
}

const getters = {
  ministriesList: state => state.ministriesList,
  currentMinistriesCount: state => state.currentMinistriesCount
}
const actions = {
  pushMinistriesData ({ commit }, data) {
    commit(types.PUSH_MINISTRIES_DATA, { data: data })
  },
  setCurrentMinistriesCount ({ commit }, data) {
    commit(types.SET_CURRENT_MINISTRIES_COUNT, { data: data })
  }
}
const mutations = {
  [types.PUSH_MINISTRIES_DATA] (state, { data }) {
    for (let i = 0; i < data.items.length; i++) {
      if (find(state.ministriesList, function (obj) {
        return obj.id === data.items[i].id
      }) === undefined) {
        if (data.items[i].description === undefined) {
          data.items[i].description = ''
        }
        state.ministriesList.push(data.items[i])
      }
    }
  },
  [types.SET_CURRENT_MINISTRIES_COUNT] (state, { data }) {
    state.currentMinistriesCount = data
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
